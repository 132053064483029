import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AirportLinkSettingsRequest,
  AirportNotification,
  AirportSpecification,
  MobileAppAuthenticationTokenLoginBody,
  PatchAirportBody,
  PatchRunwayBody,
  PatchUserRequest,
  PostAirportBody,
  PostRunwayBody,
  PutFavoriteAirportsRequest,
  UserAirportSubscriptionRequest,
  UserSettingsRequest,
  zAirportAccessCountResponse,
  zAirportLinkSettingsResponse,
  zAirportListingResponse,
  zAirportNotificationsResponse,
  zAirportResponse,
  zAirportRunwaysResponse,
  zAirportSearchResponse,
  zAirportSubscriptionStatusResponse,
  zAirportWeatherResponse,
  zAllAirportsResponse,
  zDailyStatisticsResponse,
  zEmailListingResponse,
  zFavoriteAirportResponse,
  zHomeAirportCountResponse,
  zMapSessionResponse,
  zMobileAppAuthenticationTokenResponse,
  zMyAirportRoleRequestsResponse,
  zMyAirportRolesResponse,
  zMyEmailVerificationRequestResponse,
  zRegisteredAirportsResponse,
  zUserListingResponse,
  zUserResponse,
  zUserRolesResponse,
  zUserSettingsResponse,
} from "awd-server-api";
import { buildFormData } from "utils/form";
import { sleep } from "utils/general";
import { z } from "zod";

export const awdApi = createApi({
  reducerPath: "awdApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URI,
    credentials: "include",
  }),
  tagTypes: [
    "UserMe",
    "MyAirportRoles",
    "MyAirportRoleRequests",
    "Airports",
    "Airport",
    "AirportWeather",
    "AirportNotifications",
    "MobileAppAuthenticationToken",
    "UserSettings",
    "AirportSubscriptionRequests",
    "UserListing",
    "User",
    "RegisteredAirports",
    "Statistics",
  ],
  endpoints: (builder) => ({
    getMapSessionToken: builder.query({
      query: (_: void) => "/api/map/session",
      transformResponse: (res) => zMapSessionResponse.parse(res),
    }),

    getUserMe: builder.query({
      query: (_: void) => "/api/user/me",
      transformResponse: (res) => zUserResponse.parse(res),
      providesTags: ["UserMe"],
    }),

    patchUserMe: builder.mutation({
      query: (body: {
        first_name?: string | null;
        last_name?: string | null;
        phone_number?: string | null;
        email?: string;
        old_password?: string;
        password?: string;
      }) => ({
        method: "PATCH",
        url: `/api/user/me`,
        body,
      }),
      invalidatesTags: ["UserMe"],
    }),

    deleteUserMe: builder.mutation({
      query: (body: { password: string }) => ({
        method: "DELETE",
        url: `/api/user/me`,
        body,
      }),
      invalidatesTags: ["UserMe"],
    }),

    getMyEmailVerificationRequest: builder.query({
      query: (_: void) => "/api/user/me/email/verification-request",
      transformResponse: (res) => zMyEmailVerificationRequestResponse.parse(res),
      providesTags: ["UserMe"],
    }),

    getMyAirportRoles: builder.query({
      query: (_: void) => "/api/user/me/airport-roles",
      transformResponse: (res) => zMyAirportRolesResponse.parse(res),
      providesTags: ["UserMe", "MyAirportRoles", "Airport"],
    }),

    getMyRoles: builder.query({
      query: (_: void) => "/api/user/me/roles",
      transformResponse: (res) => zUserRolesResponse.parse(res),
      providesTags: ["UserMe", "MyAirportRoles"],
    }),

    getMySettings: builder.query({
      query: (_: void) => "/api/user/me/settings",
      transformResponse: (res) => zUserSettingsResponse.parse(res),
      providesTags: ["UserMe", "UserSettings"],
    }),

    putMySettings: builder.mutation({
      query: (body: UserSettingsRequest) => ({
        method: "PUT",
        url: "/api/user/me/settings",
        body,
      }),
      invalidatesTags: ["UserSettings"],
    }),

    getAirportLinkSettings: builder.query({
      query: (args: { airportId: number | string }) =>
        `/api/airports/${args.airportId}/link-settings`,
      transformResponse: (res) => zAirportLinkSettingsResponse.parse(res),
      providesTags: ["Airport"],
    }),

    putAirportLinkSettings: builder.mutation({
      query: (args: { airportId: number | string; body: AirportLinkSettingsRequest }) => ({
        method: "PUT",
        url: `/api/airports/${args.airportId}/link-settings`,
        body: args.body,
      }),
      invalidatesTags: ["Airport"],
    }),

    getMyFavoriteAirports: builder.query({
      query: (_: void) => "/api/user/me/favorite-airports",
      transformResponse: (res) => z.array(zFavoriteAirportResponse).parse(res),
      providesTags: ["UserMe", "Airport"],
    }),

    putMyFavoriteAirports: builder.mutation({
      query: (body: PutFavoriteAirportsRequest) => ({
        method: "PUT",
        url: "/api/user/me/favorite-airports",
        body,
      }),
      transformResponse: () => sleep(300),
      invalidatesTags: ["Airport"],
    }),

    getMyAirportRoleRequests: builder.query({
      query: (_: void) => "/api/user/me/airport-roles/requests",
      transformResponse: (res) => zMyAirportRoleRequestsResponse.parse(res),
      providesTags: ["UserMe", "MyAirportRoleRequests"],
    }),

    postResendConfirmationEmail: builder.mutation({
      query: (_: void) => ({
        method: "POST",
        url: "/api/user/me/email/verification-request/resend",
      }),
      invalidatesTags: ["UserMe"],
    }),

    postResetPassword: builder.mutation({
      query: (body: { email: string }) => ({
        method: "POST",
        url: "/api/user/reset-password",
        body,
      }),
    }),

    postSetNewPassword: builder.mutation({
      query: (body: { password: string; token: string }) => ({
        method: "POST",
        url: "/api/user/reset-password/set-new",
        body,
      }),
    }),

    postConfirmEmail: builder.mutation({
      query: (body: { token: string }) => ({
        method: "POST",
        url: `/api/user/me/confirm-email`,
        body,
      }),
      invalidatesTags: ["UserMe"],
    }),

    signup: builder.mutation({
      query: (body: {
        signup_as: "pilot" | "airport";
        email: string;
        first_name?: string;
        last_name?: string;
        phone_number?: string | null;
        airport: AirportSpecification;
        password: string;
      }) => ({
        method: "POST",
        url: `/api/user/signup`,
        body,
      }),
      invalidatesTags: ["UserMe", "MyAirportRoles", "MyAirportRoleRequests", "Airport"],
    }),

    login: builder.mutation({
      query: (body: { email: string; password: string }) => ({
        method: "POST",
        url: `/api/user/login`,
        body,
      }),
      invalidatesTags: ["UserMe"],
    }),

    logout: builder.mutation({
      query: (_: void) => ({
        method: "POST",
        url: `/api/user/logout`,
      }),
      invalidatesTags: ["UserMe", "MyAirportRoles"],
    }),

    getAllAirports: builder.query({
      query: (params: { bounds: string }) => ({
        method: "GET",
        url: "api/airports",
        params,
      }),
      transformResponse: (res) => zAllAirportsResponse.parse(res),
      providesTags: ["Airports"],
    }),

    getAirport: builder.query({
      // airportId can be ICAO or id
      query: (args: { airportId: number | string }) => ({
        method: "GET",
        url: "/api/airports/" + args.airportId,
      }),
      transformResponse: (res) => {
        return zAirportResponse.parse(res);
      },
      providesTags: ["Airport"],
    }),

    getAirportWeather: builder.query({
      query: (args: { icao: string; fingerprint: string }) => ({
        method: "GET",
        url: "/api/airports/" + args.icao + "/weather",
        headers: { "x-fingerprint": args.fingerprint },
      }),
      transformResponse: (res) => {
        return zAirportWeatherResponse.parse(res);
      },
      providesTags: ["AirportWeather"],
    }),

    getAirportNotifications: builder.query({
      query: (args: { icao: string }) => ({
        method: "GET",
        url: "/api/airports/" + args.icao + "/notifications",
      }),
      transformResponse: (res) => zAirportNotificationsResponse.parse(res),
      providesTags: ["AirportNotifications"],
    }),

    postAirportNotification: builder.mutation({
      query: (args: { icao: string; body: Omit<AirportNotification, "id" | "created_at"> }) => ({
        method: "POST",
        url: "/api/airports/" + args.icao + "/notifications",
        body: args.body,
      }),
      invalidatesTags: ["AirportNotifications"],
    }),

    deleteAirportNotification: builder.mutation({
      query: (body: { id: number }) => ({
        method: "DELETE",
        url: `/api/airport-notifications/${body.id}`,
        body,
      }),
      invalidatesTags: ["AirportNotifications"],
    }),

    postAirport: builder.mutation({
      query: (args: { body: PostAirportBody }) => ({
        method: "POST",
        url: "/api/airports",
        body: args.body,
      }),
      invalidatesTags: ["Airport"],
    }),

    patchAirport: builder.mutation({
      // airportId can be ICAO or id
      query: (args: { airportId: number | string; body: PatchAirportBody }) => ({
        method: "PATCH",
        url: "/api/airports/" + args.airportId,
        body: args.body,
      }),
      invalidatesTags: ["Airport"],
    }),

    postRunway: builder.mutation({
      query: (args: { airportId: number | string; body: PostRunwayBody }) => ({
        method: "POST",
        url: "/api/airports/" + args.airportId + "/runways",
        body: args.body,
      }),
      invalidatesTags: ["Airport"],
    }),

    patchRunway: builder.mutation({
      query: (args: { runwayId: number; body: PatchRunwayBody }) => ({
        method: "PATCH",
        url: "/api/runways/" + args.runwayId,
        body: args.body,
      }),
      invalidatesTags: ["Airport"],
    }),

    deleteRunway: builder.mutation({
      query: (args: { runwayId: number }) => ({
        method: "DELETE",
        url: "/api/runways/" + args.runwayId,
      }),
      invalidatesTags: ["Airport"],
    }),

    getAirportRunways: builder.query({
      // airportId can be ICAO or id
      query: (args: { airportId: number | string }) => ({
        method: "GET",
        url: "/api/airports/" + args.airportId + "/runways",
      }),
      transformResponse: (res) => zAirportRunwaysResponse.parse(res),
      providesTags: ["Airport"],
    }),

    getAirportSubscriptionStatus: builder.query({
      query: (args: { airportId: number | string }) => ({
        method: "GET",
        url: "/api/airports/" + args.airportId + "/subscription/status",
      }),
      transformResponse: (res) => zAirportSubscriptionStatusResponse.parse(res),
      providesTags: ["Airport"],
    }),

    getAirportSubscriptionRequests: builder.query({
      query: (_: void) => "/api/airports/subscription-requests",
      transformResponse: (res) => zMyAirportRoleRequestsResponse.parse(res),
      providesTags: ["AirportSubscriptionRequests"],
    }),

    putAirportSubscriptionRequest: builder.mutation({
      query: (args: { requestId: number; status: UserAirportSubscriptionRequest["status"], airportId?: number | string }) => ({
        method: "PUT",
        url: `/api/airports/subscription-requests/${args.requestId}`,
        body: { status: args.status, airport_id: args.airportId },
      }),
      invalidatesTags: ["AirportSubscriptionRequests"],
    }),

    getRegisteredAirports: builder.query({
      query: (args: { page: number; text: string }) =>
        `/api/airports/registered?page=${args.page}&text=${args.text}`,
      transformResponse: (res) => zRegisteredAirportsResponse.parse(res),
      providesTags: ["RegisteredAirports"],
    }),

    patchRegisteredAirportSubscription: builder.mutation({
      query: (args: { airportId: number; status: boolean }) => ({
        method: "PATCH",
        url: `/api/airports/registered/${args.airportId}`,
        body: {
          suspend: !args.status,
        },
      }),
      invalidatesTags: ["RegisteredAirports"],
    }),

    patchRegisteredAirportSubscriptionEndDate: builder.mutation({
      query: (args: { airportId: number; endDate: Date }) => ({
        method: "PATCH",
        url: "/api/airports/registered/" + args.airportId + "/change-end-date",
        body: {
          airport_id: args.airportId,
          end_date: args.endDate,
        },
      }),
      invalidatesTags: ["RegisteredAirports"],
    }),

    getUserListing: builder.query({
      query: (args: { page: number; text: string }) =>
        `/api/user/listing?page=${args.page}&text=${args.text}`,
      transformResponse: (res) => zUserListingResponse.parse(res),
      providesTags: ["UserListing"],
    }),

    getUser: builder.query({
      query: (args: { userId: number }) => `/api/user/${args.userId}`,
      transformResponse: (res) => zUserResponse.parse(res),
      providesTags: (user) => (user ? [{ type: "User" as const, id: user?.id }] : ["User"]),
    }),

    patchUser: builder.mutation({
      query: (params: { userId: number; body: PatchUserRequest }) => ({
        method: "PATCH",
        url: `/api/user/${params.userId}`,
        body: params.body,
      }),
      invalidatesTags: (_user, _err, arg) => [{ type: "User", id: arg.userId }, "UserListing"],
    }),

    getAirportListing: builder.query({
      query: (args: { page: number; text: string }) =>
        `/api/airports/listing?page=${args.page}&text=${args.text}`,
      transformResponse: (res) => zAirportListingResponse.parse(res),
      providesTags: ["UserListing"],
    }),

    getStatisticsDaily: builder.query({
      query: (_: void) => "/api/statistics/daily",
      transformResponse: (res) => zDailyStatisticsResponse.parse(res),
      providesTags: ["Statistics"],
    }),

    getStatisticsHomeAirportCount: builder.query({
      query: (_: void) => "/api/statistics/home-airport-count",
      transformResponse: (res) => zHomeAirportCountResponse.parse(res),
      providesTags: ["Statistics"],
    }),

    getStatisticsAirportAccessCount: builder.query({
      query: (_: void) => "/api/statistics/airport-access-count",
      transformResponse: (res) => zAirportAccessCountResponse.parse(res),
      providesTags: ["Statistics"],
    }),

    getStatisticsEmailListing: builder.query({
      query: (_: void) => "/api/statistics/email-listing",
      transformResponse: (res) => zEmailListingResponse.parse(res),
      providesTags: ["Statistics"],
    }),

    searchHomeAirport: builder.query({
      query: (params: { user_input: string }) => {
        return {
          method: "GET",
          url: "/api/airports/search",
          params,
        };
      },
      transformResponse: (res) => {
        return zAirportSearchResponse.parse(res);
      },
      providesTags: ["Airport"],
    }),

    searchAirport: builder.query({
      query: (params: { user_input: string; offset: number }) => {
        return {
          method: "GET",
          url: "/api/airports/search",
          params,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (res) => {
        return zAirportSearchResponse.parse(res);
      },
      // Always merge incoming data to the existing cached data
      merge: (currentCache, newItems) => {
        currentCache.airports.push(...newItems.airports);
      },
      // Ommiting offset arguments from cache key
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.user_input}`;
      },
      // Refetch when the page arg changes
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
      providesTags: ["Airport"],
    }),

    getAirportBanner: builder.query({
      query: (args: { icao: string }) => ({
        method: "GET",
        url: `/api/airports/${args.icao}/banner`,
        headers: { accept: "application/json" },
      }),
      providesTags: ["Airport"],
    }),

    putAirportBanner: builder.mutation({
      query: (args: { icao: string; imageFile: File }) => ({
        method: "PUT",
        url: `/api/airports/${args.icao}/banner`,
        body: buildFormData({ image: args.imageFile }),
      }),
      invalidatesTags: ["Airport"],
    }),

    deleteAirportBanner: builder.mutation({
      query: (args: { icao: string }) => ({
        method: "DELETE",
        url: `/api/airports/${args.icao}/banner`,
      }),
      invalidatesTags: ["Airport"],
    }),

    postMobileAppAuthenticationToken: builder.mutation({
      query: (_: void) => ({
        method: "POST",
        url: "/api/user/me/mobile-app-authentication-token",
      }),
      invalidatesTags: ["MobileAppAuthenticationToken"],
      transformResponse: (res) => zMobileAppAuthenticationTokenResponse.parse(res),
    }),

    loginWithMobileAppAuthenticationToken: builder.mutation({
      query: (body: MobileAppAuthenticationTokenLoginBody) => ({
        method: "POST",
        url: "/api/user/me/mobile-app-authentication-token/login",
        body,
      }),
      invalidatesTags: ["MobileAppAuthenticationToken"],
    }),
  }),
});

export const {
  useLazyGetAllAirportsQuery,
  useSearchAirportQuery,
  useGetMyRolesQuery,
  useGetAirportSubscriptionStatusQuery,
  usePutAirportSubscriptionRequestMutation,
} = awdApi;

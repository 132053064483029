import { ChangeEvent, useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

export function useCumulativeSwitch() {
  const [cumulative, setCumulative] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCumulative(event.target.checked);
  };

  const jsx = (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={cumulative} onChange={handleChange} />}
        label="Display Cumulative"
      />
    </FormGroup>
  );
  return {
    cumulative,
    jsx,
  };
}

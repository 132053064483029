import { Stack } from "components/layout/Stack";
import { Widget } from "components/widget/Widget";
import { useAutoSelectFlightRules } from "logic/weather/dashboard/useAutoSelectFlightRules";
import { useAutoSelectRunway } from "logic/weather/dashboard/useAutoSelectRunway";
import { FC } from "react";

export type WidgetPreviewPageProps = {};

export const WidgetPreviewPage: FC<WidgetPreviewPageProps> = (props) => {
  const icao = "lktb";

  useAutoSelectRunway(icao);
  useAutoSelectFlightRules(icao);

  return (
    <Stack gap="0" css={{ alignItems: "center", width: "100%", padding: "3rem" }}>
      <Widget
        variant="forecast"
        icao={icao}
        width={600}
        variantConfiguration={{ highlights: { preferredShape: "horizontal" } }}
      />
    </Stack>
  );
};

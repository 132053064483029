import styled from "@emotion/styled";
import { FC } from "react";

type ArrowProps = {
  height?: number;
  heightCSS: number;
  angle?: number;
  active: number[] | undefined;
  index: number;
  runways: boolean;
  show: boolean;
  theme?: any;
  variable?: boolean;
};

const Arrows: FC<ArrowProps> = ({
  height,
  heightCSS,
  angle,
  active,
  index,
  runways,
  show,
  theme,
}) => {
  const rotateLogic = angle === undefined || !show ? 90 : angle;

  return (
    <StyledArrow heightCSS={heightCSS}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height={`${height}px`}
        viewBox="0 -104 100 166"
      >
        <StyledCentralLine
          fill="none"
          stroke="#8d8d8f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          strokeDasharray={2}
          x1="50"
          y1="56"
          x2="50"
          y2="-104"
          runways={runways}
        />
        <g transform={`rotate(${rotateLogic},50,-66)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="86"
            y1="-66"
            x2="67.909"
            y2="-66"
            active={active}
            index={index}
            lineKey={4}
            show={show}
            theme={theme}
          />
          <StyledPolygon
            points="76.335,-77.11 71.615,-66 76.335,-54.891 50,-66"
            active={active}
            index={index}
            polygonKey={4}
            show={show}
            theme={theme}
          />
        </g>
        <g transform={`rotate(${rotateLogic},50,-36)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="86"
            y1="-36"
            x2="67.909"
            y2="-36"
            active={active}
            index={index}
            lineKey={3}
            show={show}
            theme={theme}
          />
          <StyledPolygon
            points="76.335,-47.11 71.615,-36 76.335,-24.891 50,-36"
            active={active}
            index={index}
            polygonKey={3}
            show={show}
            theme={theme}
          />
        </g>
        <g transform={`rotate(${rotateLogic},50,-6)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="86"
            y1="-6"
            x2="67.909"
            y2="-6"
            active={active}
            index={index}
            lineKey={2}
            show={show}
            theme={theme}
          />
          <StyledPolygon
            points="76.335,-17.109 71.615,-6 76.335,5.109 50,-6"
            active={active}
            index={index}
            polygonKey={2}
            show={show}
            theme={theme}
          />
        </g>
        <g transform={`rotate(${rotateLogic},50,24)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="86"
            y1="24"
            x2="67.909"
            y2="24"
            active={active}
            index={index}
            lineKey={1}
            show={show}
            theme={theme}
          />
          <StyledPolygon
            points="76.335,12.89 71.615,24 76.335,35.109 50,24"
            active={active}
            index={index}
            polygonKey={1}
            show={show}
            theme={theme}
          />
        </g>
      </svg>
    </StyledArrow>
  );
};

const StyledArrow = styled.div<{ heightCSS: number }>`
  height: ${(props) => props.heightCSS}px;
`;

const StyledCentralLine = styled.line<{ runways: boolean }>`
  display: ${(props) => (props.runways ? "static" : "none")};
`;

const StyledLine = styled.line<{
  active?: number[];
  lineKey: number;
  show: boolean;
  index: number;
}>`
  stroke: ${(props) =>
    props.active && props.active.find((el) => el === props.lineKey) && props.show
      ? props.index === 0
        ? props.theme.primary
        : "#6d6e70"
      : "#e6e7e8"};
`;
const StyledPolygon = styled.polygon<{
  active?: number[];
  polygonKey: number;
  show: boolean;
  index: number;
}>`
  fill: ${(props) =>
    props.active && props.active.find((el) => el === props.polygonKey) && props.show
      ? props.index === 0
        ? props.theme.primary
        : "#6d6e70"
      : "#e6e7e8"};
`;

export default Arrows;

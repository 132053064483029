import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeAirport } from "logic/airport/describe";
import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";

export function useAirport(airportId: number | string | undefined) {
  const query = awdApi.endpoints.getAirport.useQuery(airportId == null ? skipToken : { airportId });
  return bound({
    query,
    describe() {
      return query.data ? describeAirport(query.data) : undefined;
    },
  });
}

import { css, Global } from "@emotion/react";
import { FC } from "react";
import styled from "@emotion/styled";

export type GlobalStylesProps = {};

export const GlobalStyles: FC<GlobalStylesProps> = (props) => {
  return <Global styles={styles} />;
};

const styles = css`
  p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  html,
  body {
    margin: 0;
    padding: 0;

    font-family: "Encode Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    cursor: pointer;

    /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable \`input\` types in iOS */
    -webkit-appearance: none;
  }
  /* default browser font size 16px */

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  .leaflet-control-layers {
    display: none;
  }

  .leaflet-control-attribution {
    background: none !important;
    font-size: 11px;
  }
  .leaflet-control-attribution a {
    color: #333;
  }
  .leaflet-attribution-flag {
    display: none !important;
  }

  #landingPage .leaflet-control-zoom {
    margin-top: 7.5rem !important;
  }

  .leaflet-tile-pane .baseMap {
    -webkit-filter: grayscale(85%);
    /* filter: grayscale(85%) sepia(10%); */
    filter: grayscale(85%);
  }

  /* .airportsTiles {
  z-index: 700 !important;
  position: absolute;
} */

  /* .leaflet-overlay-pane {
  z-index: 200 !important;
} */

  /* .leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
} */

  /* .leaflet-grab {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab {
  cursor: move;
} */

  @media (max-width: 500px) {
    .leaflet-control-attribution {
      font-size: 9px;
    }
  }
  @media (max-width: 500px) {
    #landingPage .leaflet-control-zoom {
      margin-top: 6rem !important;
    }
  }
`;

export const SResetedLink = styled.a`
  color: inherit;
  text-decoration: inherit;
`;

import { createContext, FC, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

export type FullScreenSearchProps = PropsWithChildren<{
  show: boolean;
  onClose: () => void;
}>;

export const FullScreenSearch: FC<FullScreenSearchProps> = (props) => {
  return createPortal(
    <FullScreenSearchContext.Provider value={{ close: () => props.onClose() }}>
      <div
        css={{
          display: props.show ? "block" : "none",
          padding: "1rem",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "white",
          zIndex: 5000,
        }}
      >
        {props.children}
      </div>
    </FullScreenSearchContext.Provider>,
    document.body
  );
};

export const FullScreenSearchContext = createContext<
  { close: () => void } | undefined
>(undefined);

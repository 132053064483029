import Frame, { FrameContextConsumer } from "react-frame-component";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useEffect, useRef } from "react";
import { iframeResizer } from "iframe-resizer";
import wf from "webfontloader";

/* eslint import/no-webpack-loader-syntax: off */
import iframeResizerContentWindow from "!!raw-loader!iframe-resizer/js/iframeResizer.contentWindow.js";

const CustomHead = () => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>AirportWeather.com</title>

      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta
        name="weather-airport-dashboard"
        content="Visualizing aviation weather forecast for any place on earth"
      />

      {/* <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      /> */}

      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    </>
  );
};

type IFrameWithStylesProps = {
  children: any;
  style: any;
};

export const IFrameWithStyles = ({ children, ...props }: IFrameWithStylesProps) => {
  const ref = useRef<HTMLIFrameElement>(null);

  // Component did mount
  useEffect(() => {
    if (!ref.current) return;
    iframeResizer(
      {
        checkOrigin: false,
      },
      ref.current
    );
  }, []);

  return (
    <Frame ref={ref} frameBorder={0} {...props} head={<CustomHead />}>
      <FrameContextConsumer>
        {({ document, window }) => {
          if (!document) return;
          const cache = createCache({
            key: "css",
            container: document?.head,
            prepend: true,
          });
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.innerHTML = iframeResizerContentWindow;
          document.body.appendChild(script);

          wf.load({
            google: {
              families: [
                "Encode Sans:100,300,400,500,600,700,800,900",
                "Encode Sans Condensed:400,500,600,700",
                "Roboto Mono:300,400,500,600,700",
              ],
            },
            context: window,
          });

          return <CacheProvider value={cache}>{children}</CacheProvider>;
        }}
      </FrameContextConsumer>
    </Frame>
  );
};

import styled from "@emotion/styled";
import { responsive } from "mixins";
import { useSelector } from "react-redux";
import { RunwaySchemaSvg } from "components/RunwaysViz/RunwaySchemaSvg";
import Runways from "components/Dashboard/weatherNow/Runways";
import { selectRunwayName, selectWeatherSource } from "components/RunwaysViz/selectors";
import { RunwayLongView } from "awd-server-api";
import { getDummyRunwaySchema } from "store/reducers/localStateSlice";
import { useRunwaySchemaData } from "pages/weather-dashboards/RunwaySchema";
import { useParams } from "react-router-dom";

export type RunwayVizWithRunwaySelectType = {
  angleWind?: number;
  runways?: RunwayLongView[] | null;
  dummy?: boolean;
};

export const RunwayVizWithRunwaySelect = ({
  angleWind,
  runways,
  dummy,
}: RunwayVizWithRunwaySelectType) => {
  const icao = String(useParams().icao);
  const awMetar = useSelector(selectWeatherSource);
  const dummyData = useSelector(getDummyRunwaySchema);
  let selectedRunway = useSelector(selectRunwayName);

  let { speedWind } = useRunwaySchemaData(icao);

  if (dummy || angleWind == null || runways == null) {
    angleWind = dummyData.angleWind;
    runways = dummyData.runways;
    speedWind = dummyData.speedWind;
    selectedRunway = dummyData.selectedRunwayName;
  }

  return (
    <>
      {angleWind && runways && speedWind && selectedRunway && (
        <StyledVizContainer awMetar={awMetar}>
          <div className="visualization">
            <RunwaySchemaSvg
              angleWind={angleWind}
              runways={runways}
              skip={dummy}
              speedWind={speedWind}
              selectedRunway={selectedRunway}
            />
          </div>
          <div className="airportRunways">
            <Runways skip={dummy} />
          </div>
        </StyledVizContainer>
      )}
    </>
  );
};

const StyledVizContainer = styled.div<{ awMetar: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 1.2rem;

  ${responsive.deskSM} {
    flex-direction: column;
    /* margin-top: 1rem; */
    gap: 0;
  }

  .visualization {
    ${responsive.deskSM} {
      order: 2;
      padding-right: 0.5rem;
      padding-bottom: 5rem;
      min-width: 400px;
    }
  }

  .airportRunways {
    padding: 0.7rem;

    border-radius: 0.5rem;

    background-color: ${(props) =>
      props.awMetar ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};

    ${responsive.deskSM} {
      margin-bottom: 1rem;
    }
    ${responsive.mobilL} {
      margin-right: 1rem;
    }
  }
`;

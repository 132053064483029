import React from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";

type ArrowProps = {
  angle: {
    crosswind: number;
    headwind: number;
  };
};

// function Arrow({ height, heightCSS, angle, active, index }) {
// function Arrow({ angle = { crosswind: 270, headwind: 180 }, theme }) {
function Arrow({ angle }: ArrowProps) {
  return (
    <StyledArrow heightCSS={`100px`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="60px" viewBox="10 -10 80 80">
        {/* <line
          fill="none"
          stroke="#8d8d8f"
          strokeWidth={1}
          strokeDasharray={2}
          x1={50}
          y1={30}
          x2="50"
          y2="0"
        /> */}
        <g transform={`rotate(${angle.crosswind !== undefined ? angle.crosswind + 90 : 90},50,30)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="-10"
            y1="30"
            x2="42"
            y2="30"
          />
          <StyledPolygon points="34,39 38,30 34,21 50,30" />
        </g>
        <g transform={`rotate(${angle.headwind !== undefined ? angle.headwind + 90 : 90},50,30)`}>
          <StyledLine
            fill="none"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="-10"
            y1="30"
            x2="42"
            y2="30"
          />
          <StyledPolygon points="34,39 38,30 34,21 50,30" />
        </g>
      </svg>
    </StyledArrow>
  );
}
const StyledArrow = styled.div<{ heightCSS: string }>`
  height: ${(props) => props.heightCSS}px;

  svg {
    ${responsive.tablet} {
      width: 50px;
    }
  }
`;

const StyledLine = styled.line`
  stroke: #6d6e70;
`;
const StyledPolygon = styled.polygon`
  fill: #6d6e70;
`;

export default Arrow;

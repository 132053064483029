import styled from "@emotion/styled";
import { useActiveTafSource } from "logic/weather/hooks";
import { responsive } from "mixins";

type ForecastedWindSpeedType = {
  index: number;
  theme: any;
};

export const ForecastedWindSpeed = ({ index, theme }: ForecastedWindSpeedType) => {
  const { source } = useActiveTafSource();
  const windSpeed = source?.formatWindSpeedAtIndex(index);
  const gustSpeed = source?.formatGustSpeedAtIndex(index);

  return (
    <StyledSpeed index={index} theme={theme}>
      <div className="wind">{windSpeed}</div>
      {gustSpeed && (
        <div className="gust">
          <p className="value">{gustSpeed}</p>
          <p className="label">gust</p>
        </div>
      )}
    </StyledSpeed>
  );
};

const StyledSpeed = styled.div<{ index: number; theme: any }>`
  line-height: 14px;

  ${responsive.tablet} {
    line-height: 12px;
  }

  .wind {
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => (props.index === 0 ? props.theme.primary : " #6d6e70")};
    text-align: right;

    ${responsive.tablet} {
      font-size: 11px;
    }
  }

  .gust {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    column-gap: 0.2rem;
    line-height: 0.5rem;
    margin-top: 0.5rem;

    .label {
      font-size: 10px;
      font-weight: 300;
      text-align: right;

      ${responsive.tablet} {
        font-size: 9px;
      }
    }

    .value {
      font-size: 11px;
      font-weight: 700;
      text-align: right;
      color: ${(props) => (props.index === 0 ? props.theme.primary : " #6d6e70")};

      ${responsive.tablet} {
        font-size: 10px;
      }
    }
  }
`;

import React, { FC } from "react";
import LittleArrow from "./LittleArrow";
import CrosswindArrow from "./CrosswindArrow";
import { CrosswindDataForViz } from "components/RunwaysViz/logic/runwayVisualizationLogic";
import { calculateCrosswind } from "logic/weather/wind/crosswindLogic";
import { describeUnitValue } from "logic/unit/describe";
import { useMySettings } from "logic/user/me/settings/hooks";

export type CrosswindType = {
  angleWind: number;
  speedWind: {
    unit?: string | undefined;
    value?: number | undefined;
    meaning?: string | undefined;
  };
  selectedRunway: string;
  crosswindData: CrosswindDataForViz;
};

const Crosswind: FC<CrosswindType> = ({ crosswindData, selectedRunway, angleWind, speedWind }) => {
  const crosswind = calculateCrosswind({
    windDirection: { value: angleWind, unit: "degreeT" },
    windSpeed: speedWind,
    selectedRunway: {
      name: selectedRunway,
    },
  });
  const settings = useMySettings().describeOrDefault().settings;

  if (!crosswind) return <></>;
  return (
    <>
      {/* dynamic wind */}
      {/* crosswind arrows and speed and crosswind line from little arrows */}
      {crosswindData.map((r, i) => {
        if (selectedRunway === r.nameLe || selectedRunway === r.nameHe)
          return (
            <g id="windLineAndCrosswind" key={i}>
              {r.arrayOfArrows.map((e, i) => {
                return <LittleArrow xCoord={e[0]} yCoord={e[1]} rotate={angleWind} key={i} />;
              })}
              {/* arrow showind headwind part */}
              <CrosswindArrow
                rotate={
                  r.windInQuadrant === "edgeOfQuadrantFourQuadrantOne" ||
                  r.windInQuadrant === "edgeOfQuadrantTwoQuadrantThree"
                    ? 999
                    : r.windInQuadrant === "quadrantOne" ||
                      r.windInQuadrant === "quadrantTwo" ||
                      r.windInQuadrant === "parallelDirection"
                    ? r.angleOfRunway
                    : r.angleOfRunway + 180
                }
                x={r.middleOfRunway[0]}
                y={r.middleOfRunway[1]}
                speed={describeUnitValue({
                  unit: "kt",
                  value: Math.abs(crosswind.headwind),
                })
                  ?.convertToUnit(settings.speed_unit)
                  ?.format({ postprocess: "round" })}
              />

              {/* arrow showing crosswind part */}
              <CrosswindArrow
                rotate={
                  r.windInQuadrant === "parallelDirection" ||
                  r.windInQuadrant === "oppositeDirection"
                    ? 999
                    : r.windInQuadrant === "quadrantTwo" ||
                      r.windInQuadrant === "quadrantThree" ||
                      r.windInQuadrant === "edgeOfQuadrantTwoQuadrantThree"
                    ? r.normalAngleOfRunway
                    : r.normalAngleOfRunway + 180
                }
                x={r.middleOfRunway[0]}
                y={r.middleOfRunway[1]}
                speed={describeUnitValue({
                  unit: "kt",
                  value: Math.abs(crosswind.crosswind),
                })
                  ?.convertToUnit(settings.speed_unit)
                  ?.format({ postprocess: "round" })}
              />
            </g>
          );
        else return null;
      })}
    </>
  );
};
export default Crosswind;

import styled from "@emotion/styled";
import { Stack } from "components/layout/Stack";
import { SunriseIcon } from "components/sunriseIcon/SunriseIcon";
import { SunsetIcon } from "components/sunsetIcon/SunsetIcon";
import { useActiveMetarSource } from "logic/weather/hooks";
import { responsive } from "mixins";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { isNonNullable } from "utils/general";

export type HighlightTileSource =
  | "flight_rule"
  | "visibility"
  | "temperature"
  | "qnh"
  | "sunrise_sunset";
export type HighlightTileProps = {
  className?: string;
  source: HighlightTileSource;
};

export const HighlightTile: FC<HighlightTileProps> = (props) => {
  const { source, weather } = useActiveMetarSource();
  const flightRulesText = useSelector((state: RootState) => state.localState.flightRulesCode)?.toUpperCase();

  const values =
    props.source === "flight_rule"
      ? { value: source != null && <span>{flightRulesText}</span> }
      : props.source === "visibility"
      ? { value: source?.formatCurrentVisibility(), label: "visibility" }
      : props.source === "temperature"
      ? { value: source?.formatCurrentTemperature(), label: "temperature" }
      : props.source === "qnh"
      ? { value: source?.formatCurrentQNH(), label: "QNH" }
      : props.source === "sunrise_sunset"
      ? {
          value: (
            <Stack horizontal gap="0" css={{ textAlign: "center" }}>
              <div className="sunContainer">
                <SValue css={{ fontSize: "18px" }}>{weather?.formatSunrise()}</SValue>
                <SunriseIcon />
              </div>
              <div className="sunContainer">
                <SValue css={{ fontSize: "18px" }}>{weather?.formatSunset()}</SValue>
                <SunsetIcon />
              </div>
            </Stack>
          ),
        }
      : { value: undefined };

  return (
    <STile
      className={[
        props.className,
        props.source === "flight_rule"
          ? "top"
          : props.source === "sunrise_sunset"
          ? "sun"
          : undefined,
      ]
        .filter(isNonNullable)
        .join(" ")}
    >
      {typeof values.value === "string" ? <SValue>{values.value}</SValue> : values.value}
      {values.label && <SLabel>{values.label}</SLabel>}
    </STile>
  );
};

const SLabel = styled.div`
  font-size: 16px;
  font-weight: 300;

  ${responsive.tablet} {
    font-size: 14px;
  }
`;

const SValue = styled.div`
  font-size: 22px;
  font-weight: bold;

  ${responsive.tablet} {
    font-size: 18px;
  }
  ${responsive.mobilP} {
    font-size: 15px;
  }
`;

const STile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 0.1rem solid #58585b96;
  border-radius: 1rem;

  background-color: #fff;

  &.top {
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};

    color: white;
    font-weight: bold;
    font-size: 30px;

    ${responsive.tablet} {
      font-size: 25px;
    }
    ${responsive.mobilP} {
      font-size: 20px;
    }
  }

  .sunContainer {
    width: 3.5rem;

    .image {
      width: 2rem;
      margin-top: 0.2rem;

      ${responsive.tablet} {
        width: 1.7rem;
      }
    }
  }

  &.sun {
    flex-direction: row;
    text-align: center;
  }

  p {
    margin: 0;
  }
`;

import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { describeMutationResult } from "logic/api/result/describe";
import { pageSpec } from "logic/navigation/spec";
import { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { useRunOnceReady } from "utils/hooks";
import { Meta } from "components/meta/Meta";

export type ConfirmEmailPageProps = {};

export const ConfirmEmailPage: FC<ConfirmEmailPageProps> = (props) => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useRunOnceReady(true, async () => {
    const token = search.get("token");

    if (!token) {
      dispatch(
        toastMessage("error", "Could not get e-mail confirmation token.")
      );
      navigate("/");
      return;
    }

    const result = await dispatch(
      awdApi.endpoints.postConfirmEmail.initiate({ token })
    ).then(describeMutationResult);

    if (result.error) {
      dispatch(
        toastError(result.error, "We could not verify your e-mail address.")
      );
      navigate("/");
      return;
    }

    dispatch(
      toastMessage("success", "Your e-mail address has been confirmed.")
    );
    navigate(pageSpec.dashboard.path);
  });

  return (
    <Stack
      gap="2rem"
      css={{ paddingTop: "6rem", alignItems: "center", width: "100%" }}
    >
      <Meta pageTitle="Confirm e-mail" />
      <Typography variant="h4">
        We are confirming your e-mail address.
      </Typography>
      <CircularProgress />
    </Stack>
  );
};

import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { AirportSelect } from "components/airportSelect/AirportSelect";
import { BackdropLoading } from "components/backdropLoading/BackdropLoading";
import { SettingsGroup } from "components/settings/group/SettingsGroup";
import { SettingsItem } from "components/settings/item/SettingsItem";
import { describeAirportSpecification } from "logic/airport/specification/describe";
import { errorHandled } from "logic/error/handle";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { toastMessage } from "store/reducers/toastSlice";

export type FavoriteAirportsSettingsGroupProps = {};

export const FavoriteAirportsSettingsGroup: FC<
  FavoriteAirportsSettingsGroupProps
> = (props) => {
  const favoriteAirports = useFavoriteAirports();
  const dispatch = useDispatch();

  return (
    <SettingsGroup title="Your favourite airports">
      <BackdropLoading query={favoriteAirports.mutationState} />
      {favoriteAirports.getItems()?.map((airport) => (
        <SettingsItem
          key={airport.id}
          title={airport.airport.name}
          button={false}
          actions={
            <>
              {airport.is_home_airport ? (
                <Chip label="Home airport" color="primary" />
              ) : (
                <Chip
                  onClick={errorHandled(async () => {
                    await favoriteAirports
                      .mutateHomeAirport(airport.airport_id)
                      .unwrap();
                    dispatch(toastMessage("success", "Set home airport."));
                  }, "Could not set home airport.")}
                  label="Set home airport"
                  color="primary"
                  variant="outlined"
                />
              )}
              <Tooltip
                title={
                  airport.is_home_airport
                    ? "Please select another home airport before removing this favourite."
                    : "Remove favourite airport"
                }
              >
                <div>
                  <IconButton
                    color="error"
                    size="small"
                    disabled={airport.is_home_airport}
                    onClick={errorHandled(async () => {
                      await favoriteAirports
                        .removeFavorite(airport.airport_id)
                        .unwrap();
                      dispatch(
                        toastMessage(
                          "success",
                          `Removed favourite airport ${airport.airport.name}.`
                        )
                      );
                    }, "Could not remove favourite airport.")}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          }
        />
      ))}

      {(favoriteAirports.getItems()?.length ?? 0) < 5 && (
        <AirportSelect
          key={favoriteAirports.mutationState.status}
          value={null}
          label={"Add favourite airport"}
          onChange={errorHandled(async (airportSpec) => {
            const airportId =
              airportSpec && describeAirportSpecification(airportSpec).asId();
            if (typeof airportId === "number") {
              await favoriteAirports.addFavorite(airportId).unwrap();
              dispatch(toastMessage("success", `Added favorite airport.`));
            }
          }, "Failed to add favourite airport.")}
          noAddAirport
        />
      )}
    </SettingsGroup>
  );
};

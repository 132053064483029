import { q2u } from "utils/global.functions";

type windDirection = {
  unit?: string;
  value?: number;
  meaning?: string | undefined;
};

type windSpeed = {
  unit?: string | undefined;
  value?: number | undefined;
  meaning?: string | undefined;
};

type selectedRunway = {
  name: string;
  elevation?: number | null | undefined;
  closed?: boolean | null | undefined;
};

export const calculateCrosswind = ({
  windDirection,
  windSpeed,
  selectedRunway,
}: {
  windDirection: windDirection;
  windSpeed: windSpeed;
  selectedRunway: selectedRunway;
}) => {
  if (!windDirection || !windSpeed || !selectedRunway) return undefined;

  // runways are named by azimuth /10
  // taking the runway name and not the actual precise heading of the runway
  const runwayAngle = parseInt(selectedRunway.name) * 10;

  // calculating wind angle relative to runway
  const windInUnits = q2u(windDirection, "degreeT", "floor");

  if (windInUnits == null) return undefined;
  const angle = runwayAngle - windInUnits;
  const angleRad = angle * (Math.PI / 180);

  // transposing speed into knots
  const speedInKnots = q2u(windSpeed, "kt", "floor");
  if (!speedInKnots && speedInKnots !== 0) return undefined;

  const crosswind = Math.sin(angleRad) * speedInKnots;
  const headwind = Math.cos(angleRad) * speedInKnots;

  return { angle, crosswind, headwind };
};

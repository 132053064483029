import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import Airport from "components/Dashboard/airport/Airport";
import CurrentData from "components/Dashboard/currentData/CurrentData";
import WeatherForecast from "components/Dashboard/weatherForecast/WeatherForecast";
import WeatherNow from "components/Dashboard/weatherNow/WeatherNow";

import { responsive } from "mixins";

import { Stack } from "components/layout/Stack";

import { WeatherDashboardContext } from "components/Dashboard/context";

import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { Block } from "components/layout/Block";
import { useAutoSelectFlightRules } from "logic/weather/dashboard/useAutoSelectFlightRules";
import { useAutoSelectRunway } from "logic/weather/dashboard/useAutoSelectRunway";
import { useSynchronizeMetarAndTaf } from "logic/weather/dashboard/useSynchronizeMetarAndTaf";

import Loader from "components/loader/Loader";
import { useAirportWeather, useDashboardAirport } from "logic/weather/hooks";
import { Meta } from "components/meta/Meta";
import { useOpenExplanationModalIfParamIsSet } from "./useOpenExplanationModalIfParamIsSet";
import { Creator } from "components/navigation/Creator";
import { ModalContentfulData } from "components/modal/ModalContentfulData";
import { FlexBlock } from "components/layout/FlexBlock";

const Dashboard = () => {
  return (
    <StyledDashboard>
      <Airport />

      <div className="currentWeatherWithHighlights">
        <WeatherNow />
        <CurrentData />
      </div>

      <SWeatherForecast />

      <div className="brand">
        <Creator />
      </div>
    </StyledDashboard>
  );
};

export const DashboardPage = () => {
  useOpenExplanationModalIfParamIsSet();
  const { icao } = useParams();

  const weather = useAirportWeather(icao);
  const { isLoading, isSuccess } = weather.query;
  const weatherError = weather.describeQueryError();

  const airportQuery = useDashboardAirport(icao);
  const airportError = airportQuery.describeQueryError();

  useSynchronizeMetarAndTaf(icao);
  useAutoSelectRunway(icao);
  useAutoSelectFlightRules(icao);

  if (airportError)
    return (
      <FlexBlock>
        <ErrorAlert errorDescription={airportError} />
      </FlexBlock>
    );

  return (
    <WeatherDashboardContext.Provider value={{ icao: icao ?? "" }}>
      <Meta pageTitle={icao?.toUpperCase()} />
      <ModalContentfulData icao={icao} type={"airportAccessLimit"} />
      <Stack gap="1rem" css={{ width: "100%" }}>
        {isLoading ? (
          <StyledDashboard>
            <Loader options="dashboard" />
          </StyledDashboard>
        ) : weatherError ? (
          <Block>
            <ErrorAlert errorDescription={weatherError} />
          </Block>
        ) : isSuccess ? (
          <Dashboard />
        ) : null}
      </Stack>
    </WeatherDashboardContext.Provider>
  );
};

const SWeatherForecast = styled(WeatherForecast)``;

export const StyledDashboard = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-width: 75rem; //1200px
  min-width: 16.25rem; //260px

  padding: 0 2rem;

  ${responsive.highlights} {
    padding: 0 1.5rem;
  }
  ${responsive.forecast} {
    padding: 0 1rem;
  }
  ${responsive.mobilP} {
    padding: 0 0.3rem;
  }
  ${responsive.mobilS} {
    padding: 0;
  }

  .currentWeatherWithHighlights {
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 0.1fr 1fr;
    grid-template-rows: 0.3fr 4.2fr;

    ${responsive.deskSM} {
      grid-template-columns: 1fr;
      grid-template-rows: 0.3fr min-content min-content;
    }
  }

  .brand {
    align-self: center;
    padding-bottom: 4rem;
    display: none;

    ${responsive.tablet} {
      display: block;
    }
  }
`;

export default Dashboard;

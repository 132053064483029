import { z } from "zod";

export const zLoginForm = z.object({
  email: z.string().email({
    message: "Please fill in a correct e-mail address.",
  }),
  password: z.string().nonempty({
    message: "Please fill in your password.",
  }),
});

export type LoginForm = z.infer<typeof zLoginForm>;

import { AirportMeteostation } from "components/airportMeteostation/AirportMeteostation";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Meta } from "components/meta/Meta";

export type AirportMeteostationSubpageProps = {};

export const AirportMeteostationSubpage: FC<
  AirportMeteostationSubpageProps
> = () => {
  const icao = String(useParams().icao);

  return (
    <>
      <Meta pageTitle={`${icao.toUpperCase()} Meteostation`} />
      <div css={{ padding: "3rem", paddingTop: 0 }}>
        <AirportMeteostation airportId={icao} />
      </div>
    </>
  );
};

import { Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { Perk } from "components/signup/features/perk/Perk";
import { responsive } from "mixins";
import { FC } from "react";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { useDefaultOptions } from "components/content/defaultOptions";
import { BLOCKS } from "@contentful/rich-text-types";
import ReactMarkdown from "react-markdown";
import { useContentfulData } from "utils/useContentfulData";
import { getSignupPageData } from "utils/contentful";

export type SignupFeaturesProps = {};

export const SignupFeatures: FC<SignupFeaturesProps> = (props) => {
  const signupPageContent = useContentfulData(getSignupPageData);

  const lengthOfPerks = signupPageContent?.perks?.length ?? 0;
  const cutNumber = Math.ceil(lengthOfPerks / 2);
  const firstColumnPerks = signupPageContent?.perks?.slice(0, cutNumber);
  const secondColumnPerks = signupPageContent?.perks?.slice(cutNumber);

  const defaultOptions = useDefaultOptions();
  const options: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Typography variant="h3" css={{ textAlign: "center" }}>
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography variant="body1" sx={{ mb: "0.5rem" }}>
          {children}
        </Typography>
      ),
    },
  };

  return (
    <Stack gap="2rem">
      <Typography variant="h2" css={{ textAlign: "center" }}>
        {signupPageContent?.benefitsTitle}
      </Typography>
      <div
        css={{
          display: "grid",
          columnGap: "4rem",
          rowGap: "0.5rem",
          gridTemplateColumns: "1fr 1fr",
          gridAutoFlow: "column",
          [responsive.mobilL]: {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Stack gap="0.5rem">
          {firstColumnPerks?.map((p) => {
            return (
              <Perk key={p.sys.id}>
                <SignupFeatureMarkdown text={p.fields.perk} />
              </Perk>
            );
          })}
        </Stack>
        <Stack gap="0.5rem">
          {secondColumnPerks?.map((p) => {
            return (
              <Perk key={p.sys.id}>
                <SignupFeatureMarkdown text={p.fields.perk} />
              </Perk>
            );
          })}
        </Stack>
      </div>
      <div>
        {signupPageContent?.descriptionText &&
          documentToReactComponents(signupPageContent.descriptionText, options)}
      </div>
    </Stack>
  );
};

const SignupFeatureMarkdown = ({ text }: { text: string }) => {
  return (
    <ReactMarkdown
      components={{
        strong: ({ node, ...props }) => <span css={{ color: "#ff0000" }}>{props.children}</span>,
      }}
    >
      {text}
    </ReactMarkdown>
  );
};

import { Footer } from "components/footer/Footer";
import { OutsideHamburgerButton } from "components/layout/page/outside/OutsideHamburgerButton";
import { OutsideMenuContext } from "components/layout/page/outside/OutsideMenuContext";
import { OutsideNavigationDrawer } from "components/layout/page/outside/OutsideNavigationDrawer";
import { Stack } from "components/layout/Stack";
import { OutsideNavigationLoginStatus } from "components/outsideNavigationLoginStatus/OutsideNavigationLoginStatus";
import { AirportSearch } from "components/search/airport/AirportSearch";
import { FullScreenSearch } from "components/search/FullScreenSearch";
import { useResponsiveValue } from "logic/responsive/styles";
import { ResponsiveValue } from "logic/responsive/types";
import { responsive } from "mixins";
import { FC, PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";

import awLogo from "assets/images/airport_weather.svg";

export type OutsidePageLayoutProps = PropsWithChildren<{
  noHeader?: boolean;
  noLogo?: ResponsiveValue<boolean>;
  noFooter?: boolean;
  showWeatherDashboardNavigationItems?: ResponsiveValue<boolean>;
  weatherDashboardIcao?: string;
}>;

export const OutsidePageLayout: FC<OutsidePageLayoutProps> = (props) => {
  const [showSearch, setShowSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const noLogo = useResponsiveValue(props.noLogo);
  const isTablet = useResponsiveValue({ tablet: true }) ?? false;

  return (
    <OutsideMenuContext.Provider value={{ open: () => setOpen(true) }}>
      <FullScreenSearch show={showSearch} onClose={() => setShowSearch(false)}>
        <AirportSearch />
      </FullScreenSearch>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          overflowX: "hidden",
          minHeight: "calc(var(--vh) * 100)",
          "& > *": {
            flexShrink: 0,
          },
        }}
      >
        {props.noHeader ? null : (
          <Stack
            horizontal
            gap="1rem"
            css={{
              padding: "1rem 1rem 0",

              [responsive.tablet]: {
                padding: "1rem 1rem 0rem 1rem",
              },
            }}
          >
            {!noLogo && (
              <Link to="/">
                <img
                  src={awLogo}
                  alt="AirportWeather logo"
                  css={{ maxWidth: "12rem" }}
                />
              </Link>
            )}
            <Stack
              gap="1rem"
              horizontal
              css={{
                flexGrow: 1,
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div
                css={{
                  [responsive.tablet]: { display: "none" },
                  input: { border: "1px solid #ddd" },
                  flexGrow: 1,
                  maxWidth: "20rem",
                }}
              >
                <AirportSearch />
              </div>
              <OutsideNavigationLoginStatus />
              <OutsideHamburgerButton />
            </Stack>
          </Stack>
        )}
        {props.children}
        <div css={{ zIndex: 15000, position: "relative" }}>
          <OutsideNavigationDrawer
            width="14rem"
            open={open}
            onCloseButtonClick={() => setOpen(false)}
            weatherDashboardIcao={props.weatherDashboardIcao}
            showWeatherDashboardNavigationItems={
              props.showWeatherDashboardNavigationItems
            }
            isTabletSize={isTablet}
            onSearchItemClick={() => {
              setOpen(false);
              setShowSearch(true);
            }}
          />
        </div>

        {!props.noFooter && (
          <>
            <div css={{ flexGrow: 1 }}></div>

            <Footer />
          </>
        )}
      </div>
    </OutsideMenuContext.Provider>
  );
};

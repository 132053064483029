import { FavoriteAirportsSettingsGroup } from "components/favoriteAirportsSettingsGroup/FavoriteAirportsSettingsGroup";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { QueryResult } from "components/queryResult/QueryResult";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";

export type FavoriteAirportsPageProps = {};

export const FavoriteAirportsPage: FC<FavoriteAirportsPageProps> = (props) => {
  const myAirportRoles = useMyAirportRoles();
  const managedAirport = myAirportRoles.managedAirport();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Favourite Airports" />
      <Stack gap="1rem" horizontal css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Favourite Airports</InsidePageTitle>
      </Stack>
      <Spacer size="1.5rem" />

      <QueryResult query={myAirportRoles.query}>
        {managedAirport == null && <FavoriteAirportsSettingsGroup />}
      </QueryResult>
    </InsidePageLayout>
  );
};

import { toastError } from "store/reducers/toastSlice";
import store from "store/store";

export function handleError(caughtError: unknown, fallbackMessage: string) {
  store.dispatch(toastError(caughtError, fallbackMessage));

  return undefined;
}

export function errorHandled<
  TCallback extends (...args: any[]) => Promise<unknown>,
>(callback: TCallback, fallbackMessage: string) {
  return async (...args: Parameters<TCallback>) => {
    try {
      return await callback(...args);
    } catch (err) {
      handleError(err, fallbackMessage);
      return undefined;
    }
  };
}

export function errorHandledSync<TCallback extends (...args: any[]) => unknown>(
  callback: TCallback,
  fallbackMessage: string
) {
  return (...args: Parameters<TCallback>) => {
    try {
      return callback(...args);
    } catch (err) {
      handleError(err, fallbackMessage);
      return undefined;
    }
  };
}

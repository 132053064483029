import { useState } from "react";
import { useInterval } from "usehooks-ts";

const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000;

export function getWeatherTilesUrl(params: {
  weatherLayer: string;
  weatherDateTime: string;
  weatherAltitude: string;
  sessionToken: string;
}) {
  // http://tiber.meandair.com/api/v1/tiles/{weatherLayer}/{z}_{weatherAltitude}_{x}_{y}_{weatherDateTime}.png?session={sessionToken}
  const url = Object.entries(params ?? {}).reduce((acc, entry) => {
    if (entry[1] != null) {
      const regexp = new RegExp("\\{" + entry[0] + "\\}", "g");
      return acc?.replace(regexp, entry[1]);
    }

    return acc;
  }, process.env.REACT_APP_WEATHER_TILES_URI);

  return url;
}

export function useGetWeatherTilesUrl(params: {
  weatherLayer: string;
  weatherDateTime: string;
  weatherAltitude: string;
  sessionToken: string;
}) {
  const [timer, setTimer] = useState(new Date().getTime());

  useInterval(() => {
    const date = new Date().getTime();
    setTimer(date);
  }, TWO_MINUTES_IN_MILLISECONDS);

  const url = getWeatherTilesUrl(params);
  return url + `&t=${timer}`;
}

import { InputAdornment, Typography } from "@mui/material";
import { FormRunwaySurfaceField } from "components/field/surface/FormRunwaySurfaceField";
import { FormTextField } from "components/field/text/FormTextField";
import { FormYesNoField } from "components/field/yesno/FormYesNoField";
import { Stack } from "components/layout/Stack";
import { describeRunway } from "logic/airport/runway/describe";
import { RunwayFormValues } from "logic/airport/runway/form";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { RunwayResponse } from "awd-server-api";

export type RunwayFormProps = {
  form: UseFormReturn<RunwayFormValues>;
  runway?: RunwayResponse;
};

export const RunwayForm: FC<RunwayFormProps> = ({ form, runway }) => {
  const feetUnitProps = {
    endAdornment: <InputAdornment position="end">ft</InputAdornment>,
  };
  const metersUnitProps = {
    endAdornment: <InputAdornment position="end">m</InputAdornment>,
  };
  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        columnGap: "2rem",
      }}
    >
      <Stack gap="1rem">
        <Typography variant="h5" css={{ visibility: "hidden" }}>
          &nbsp;
        </Typography>
        <FormTextField
          form={form}
          name="length"
          label="Length"
          InputProps={metersUnitProps}
        />
        <FormTextField
          form={form}
          name="width"
          label="Width"
          InputProps={metersUnitProps}
        />
        <FormRunwaySurfaceField
          form={form}
          name="surface"
          label="Runway surface"
        />
        <FormYesNoField form={form} name="lighted" label="Lighted" allowNA />
        <FormYesNoField form={form} name="closed" label="Closed" allowNA />
      </Stack>
      {runway &&
        describeRunway(runway)
          .getBothDirections()
          .map((d) => (
            <Stack gap="1rem" key={d.which}>
              <Typography variant="h5">
                Direction {d.formatIdentifier()}
              </Typography>
              <FormTextField
                form={form}
                name={d.getPropertyKey("Ident")}
                label="Direction Identifier"
              />
              <FormTextField
                form={form}
                name={d.getPropertyKey("Heading")}
                label="Heading"
              />
              <FormTextField
                form={form}
                name={d.getPropertyKey("Elevation")}
                label="Elevation"
                InputProps={feetUnitProps}
              />
              <FormTextField
                form={form}
                name={d.getPropertyKey("Latitude")}
                label="Latitude"
              />
              <FormTextField
                form={form}
                name={d.getPropertyKey("Longitude")}
                label="Longitude"
              />
              <FormTextField
                form={form}
                name={d.getPropertyKey("DisplacedThreshold")}
                label="Displaced Threshold"
                InputProps={feetUnitProps}
              />
            </Stack>
          ))}
    </div>
  );
};

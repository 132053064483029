import styled from "@emotion/styled";

import { responsive } from "mixins";

import { css } from "@emotion/react";
import { HighlightTile } from "components/Dashboard/highlightTile/HighlightTile";
import { useActiveMetarSource } from "logic/weather/hooks";

const CurrentData = () => {
  const { awMetar } = useActiveMetarSource();

  return (
    <StyledHighlights>
      <StyledSectionName checked={awMetar}>
        <p>highlights</p>
      </StyledSectionName>
      <StyledCurrentData checked={awMetar}>
        <HighlightTile source="flight_rule" />
        <HighlightTile source="visibility" />
        <HighlightTile source="temperature" />
        <HighlightTile
          source="qnh"
          css={css`
            ${responsive.highlights} {
              grid-column-start: 2;
              grid-column-end: 3;
              grid-row-start: 2;
              grid-row-end: 3;

              ${responsive.mobilP} {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: auto;
                grid-row-end: auto;
              }
            }
          `}
        />
        <HighlightTile
          source="sunrise_sunset"
          css={css`
            ${responsive.highlights} {
              grid-column-start: 3;
              grid-column-end: 4;
              grid-row-start: 2;
              grid-row-end: 3;
            }
            ${responsive.mobilP} {
              grid-column-start: 1;
              grid-column-end: 2;
              grid-row-start: auto;
              grid-row-end: auto;
              gap: 1rem;
            }
          `}
        />
      </StyledCurrentData>
    </StyledHighlights>
  );
};

const StyledHighlights = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 3;

  ${responsive.deskSM} {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  display: flex;
  flex-direction: column;
`;

const StyledSectionName = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;

  color: #58585b;

  ${responsive.deskSM} {
    background-color: ${(props) =>
      props.checked ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
    transition: background-color 0.5s;
    border-radius: 1rem 1rem 0 0;
  }

  p {
    margin: 5px 0 3px 15px;

    ${responsive.deskSM} {
      margin: 0;
      margin-left: 1.2rem;
      margin-top: 1rem;
    }

    ${responsive.tablet} {
      font-size: 11px;
    }
  }
`;

const StyledCurrentData = styled.div`
  height: 100%;

  background-color: ${(props) =>
    props.checked ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
  transition: background-color 0.5s;

  display: grid;
  grid-template-rows: repeat(5, 1fr);
  gap: 0.5rem;

  padding: 1rem;

  border-radius: 1rem;

  font-family: "Encode Sans Condensed";
  color: #58585b;

  ${responsive.deskSM} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;

    border-radius: 0;

    padding: 1.3rem;
    padding-top: 0.3rem;
  }

  ${responsive.highlights} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  ${responsive.mobilP} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
`;

export default CurrentData;

import { bound } from "utils/class";
import { matchMap } from "utils/general";
import { z } from "zod";

export function describePassword(password: string) {
  return bound({
    getInvalidityReason() {
      const rules = describePasswordRules();
      if (password.length < rules.length.min) return "too_short";
      if (password.length > rules.length.max) return "too_long";

      if (rules.requiredOccurence.lowercase && !password.match(/[a-z]/))
        return "no_lowercase_letter";
      if (rules.requiredOccurence.uppercase && !password.match(/[A-Z]/))
        return "no_uppercase_letter";
      if (rules.requiredOccurence.number && !password.match(/[0-9]/))
        return "no_number_character";
      return undefined;
    },
    getValidity() {
      const reason = this.getInvalidityReason();

      return reason
        ? ({ isValid: false, reason } as const)
        : ({ isValid: true } as const);
    },

    refine(ctx: z.RefinementCtx) {
      const rules = describePasswordRules();
      const passwordValidity = this.getValidity();
      if (!passwordValidity.isValid) {
        ctx.addIssue({
          code: "custom",
          message: matchMap(passwordValidity.reason, {
            no_lowercase_letter:
              "Your password must contain at least 1 lowercase letter.",
            no_uppercase_letter:
              "Your password must contain at least 1 uppercase letter.",
            no_number_character:
              "Your password must contain at least 1 number character.",
            too_short: `Your password must be at least ${rules.length.min} characters long.`,
            too_long: `Your password must be at most ${rules.length.max} characters long.`,
          }),
        });
      }
    },
  });
}

export function describePasswordRules() {
  return {
    length: {
      min: 8,
      max: 255,
    },
    requiredOccurence: {
      uppercase: true,
      lowercase: true,
      number: true,
    },
  };
}

export const zPassword = z
  .string()
  .superRefine((arg, ctx) => describePassword(arg).refine(ctx));

import { ArticlePage } from "components/content/ArticlePage";
import { Meta } from "components/meta/Meta";
import { TermlyDocument } from "components/termlyDocument/TermlyDocument";
import { FC } from "react";

export type TermsOfServicePageProps = {};

export const TermsOfServicePage: FC<TermsOfServicePageProps> = (props) => {
  return (
    <ArticlePage>
      <Meta pageTitle="Terms of Service" />
      <TermlyDocument type="terms_of_service" />
    </ArticlePage>
  );
};

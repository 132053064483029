import { createContext, FC, PropsWithChildren } from "react";
import { SResetedLink } from "styles/GlobalStyles";
import { useDashboardAirport } from "logic/weather/hooks";

// create a react context
export const WidgetContext = createContext<boolean>(false);
type WidgetWrapperProps = {};
export const WidgetWrapper: FC<PropsWithChildren<WidgetWrapperProps>> = (props) => {
  const url = useDashboardAirport().transform()?.getUrl();

  return (
    <SResetedLink href={url} target="_blank">
      <WidgetContext.Provider value={true}>{props.children}</WidgetContext.Provider>
    </SResetedLink>
  );
};

import { z } from "zod";
import { FC } from "react";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { useDispatch } from "store/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResultModal } from "components/modal/inside/ResultModal";
import { Button, DialogContent, Typography } from "@mui/material";
import { awdApi } from "store/reducers/awdApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SubscribedAirportProps } from "components/registeredAirports/RegisteredAirportTableRow";
import { Stack } from "components/layout/Stack";
import { toastMessage } from "store/reducers/toastSlice";

const zChangeSubscriptionEndDateForm = z.object({
  endDate: z.date(),
});
type ChangeSubscriptionEndDateForm = z.infer<typeof zChangeSubscriptionEndDateForm>;

export const ChangeSubscriptionEndDateModal: FC<SubscribedAirportProps> = (props) => {
  const { modalContext } = useModalContext();

  const dispatch = useDispatch();

  const form = useForm<ChangeSubscriptionEndDateForm>({
    resolver: zodResolver(zChangeSubscriptionEndDateForm),
    defaultValues: {
      endDate: props.airport.subscription_end_date
        ? new Date(props.airport.subscription_end_date)
        : new Date(),
    },
  });

  const formValues = form.watch();

  const onSubmit: SubmitHandler<ChangeSubscriptionEndDateForm> = (data) => {
    try {
      dispatch(
        awdApi.endpoints.patchRegisteredAirportSubscriptionEndDate.initiate({
          airportId: props.airport.airport_id,
          endDate: data.endDate,
        })
      );
      dispatch(toastMessage("success", "Subscription end date changed successfully."));
      modalContext?.close();
    } catch (e) {
      dispatch(toastMessage("error", "Something went wrong, please try again later."));
    }
  };

  return (
    <ResultModal
      title={`Subscription End Date - ${props.airport.airport_icao}`}
      submitButton={{
        hidden: true,
      }}
      cancelButton={{
        hidden: true,
      }}
    >
      <DialogContent>
        <Typography css={{ marginBottom: "1rem" }}>
          Change the subscription end date for the airport {props.airport.airport_icao}
        </Typography>
        <div css={{ paddingTop: "1rem" }}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Subscription End Date"
                value={formValues.endDate}
                format="dd-MM-yyyy"
                onChange={(date) => {
                  if (date) {
                    form.setValue("endDate", date);
                  }
                }}
                disablePast
              />
            </LocalizationProvider>
            <Stack gap="1rem" horizontal css={{ justifyContent: "flex-end", marginTop: "1rem" }}>
              <Button
                type="button"
                onClick={() => {
                  modalContext?.close();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </div>
      </DialogContent>
    </ResultModal>
  );
};

import { Grid, Typography } from "@mui/material";
import { pageSpec } from "logic/navigation/spec";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";
import { SignupOfferParagraphMarkdown } from "components/signupOfferBox/SignupOfferParagraph";
import { SignupOfferButton } from "components/signupOfferBox/SignupOfferButton";
import { useHandleDisplayCount, useShouldShow } from "logic/signup/offer/hooks";
import { SignupOfferCloseButton } from "components/signupOfferBox/SignupOfferCloseButton";
import { getSignupOfferBoxData } from "utils/contentful";
import { useContentfulData } from "utils/useContentfulData";

export type SignupOfferBoxProps = {};

export const SignupOfferBox: FC<SignupOfferBoxProps> = (props) => {
  useHandleDisplayCount();
  const showModal = useShouldShow();

  const signupOfferBoxContent = useContentfulData(getSignupOfferBoxData);
  if (!showModal || signupOfferBoxContent == null) return null;

  return (
    <SGrid container spacing="0.5rem">
      <SignupOfferCloseButton />

      <Grid item xs={5}>
        <Typography variant="subtitle2">
          {signupOfferBoxContent.pilotTitle}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle2">
          {signupOfferBoxContent.airportTitle}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <SignupOfferParagraphMarkdown
          text={signupOfferBoxContent.pilotDescription ?? ""}
        />
      </Grid>
      <Grid item xs={7}>
        <SignupOfferParagraphMarkdown
          text={signupOfferBoxContent.airportDescription ?? ""}
        />
      </Grid>

      <Grid item xs={5}>
        <SignupOfferButton variant="outlined" url={pageSpec.registerpilot.path}>
          {signupOfferBoxContent.pilotRegisterButtonText}
        </SignupOfferButton>
      </Grid>
      <Grid item xs={7}>
        <SignupOfferButton variant="contained" url={pageSpec.signup.path}>
          {signupOfferBoxContent.airportRegistrationButtonText}
        </SignupOfferButton>
      </Grid>
    </SGrid>
  );
};

const SGrid = styled(Grid)`
  position: fixed;
  top: 5rem;
  left: unset;
  right: 1rem;

  z-index: 1500;
  margin-left: 0;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: white;
  box-shadow: var(--box-shadow-light);
  width: 550px;
  max-width: 100vw;

  ${responsive.highlights} {
    left: 0;
    right: 0;
  }
`;

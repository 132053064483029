import { Button, Typography } from "@mui/material";
import { AirportNotificationList } from "components/airportNotificationList/AirportNotificationList";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Meta } from "components/meta/Meta";
import { AddNotificationModal } from "components/modal/inside/add/AddNotificationModal";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";
import { Stack } from "components/layout/Stack";

export type AirportNotificationsSettingsPageProps = {};

export const AirportNotificationsSettingsPage: FC<
  AirportNotificationsSettingsPageProps
> = (props) => {
  const icao = useMyAirportRoles().managedAirport()?.icao;
  const { show } = useModalContext();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Airport Notifications" />

      <InsidePageTitle>Airport Notifications</InsidePageTitle>

      <Spacer size="1.5rem" />

      <Typography variant="body1">
        You can set up airport notifications here to inform pilots about
        important operational updates and general information for your airport.
      </Typography>

      <Spacer size="1.5rem" />

      <Button
        onClick={() => icao && show(<AddNotificationModal icao={icao} />)}
      >
        Add Notification
      </Button>

      <Spacer size="1.5rem" />

      <Typography variant="subtitle1">Preview</Typography>

      <Spacer size="0.75rem" />

      <Stack gap="2rem">
        <Typography variant="h3">Important Operational Updates</Typography>
        {icao && (
          <AirportNotificationList
            icao={icao}
            location="inside_settings"
            airportName={undefined}
            category="operational_update"
          />
        )}
        <hr />
        <Typography variant="h3">General Information</Typography>
        {icao && (
          <AirportNotificationList
            icao={icao}
            location="inside_settings"
            airportName={undefined}
            category="general_information"
          />
        )}
      </Stack>
    </InsidePageLayout>
  );
};

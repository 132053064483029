import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { startWidgetMode } from "logic/widget/start";
import "./fonts";

const appEntrypoint = document.getElementById("root");

function determineAppMode() {
  const widgetScript = document.querySelector("script#airportweather-widget-loadscript");

  if (widgetScript) return "widget";
  if (appEntrypoint.dataset.awdAppEntrypoint != null) return "app";

  return "misconfigured";
}

const mode = determineAppMode();

if (mode === "app") {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
} else if (mode === "widget") {
  startWidgetMode();
}

import styled from "@emotion/styled";
import { Dispatch, Ref, SetStateAction, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { responsive } from "mixins";

import iconSearch from "assets/icons/IconSearch.svg";
import { OutsideHamburgerButton } from "components/layout/page/outside/OutsideHamburgerButton";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { OutsideNavigationLoginStatus } from "components/outsideNavigationLoginStatus/OutsideNavigationLoginStatus";
import { AirportSearch } from "components/search/airport/AirportSearch";
import { FullScreenSearch } from "components/search/FullScreenSearch";
import awLogo from "assets/images/airport_weather.svg";
import iconClouds from "assets/images/clouds-map.svg";
import iconRain from "assets/images/rain.svg";
import iconResetLocation from "assets/images/reset.svg";
import iconThunder from "assets/images/thunder.svg";
import Switch from "components/_buttons/Switch";
import { useShowExternalControlsText } from "components/LandingPage/useShowExternalControlsText";
import TimeSlider from "components/Map/TimeSlider";
import { isTimeSliderAtMapDisabled } from "../../utils/general";
import { OverlayType } from "components/kiosk/slides/KioskMapSlide";

type ExternalControlsProps = {
  reset: () => void;
  weatherOverlay: OverlayType;
  setWeatherOverlay: Dispatch<SetStateAction<OverlayType>>;
  forecastValue: number;
  setForecastValue: Dispatch<SetStateAction<number>>;
  inViewLogo: Ref<HTMLDivElement>;
};

function ExternalControls({
  reset,
  weatherOverlay,
  setWeatherOverlay,
  forecastValue,
  setForecastValue,
  inViewLogo,
}: ExternalControlsProps) {
  const [showSearch, setShowSearch] = useState(false);
  const forecastBar = useRef<HTMLInputElement>(null);

  const showText = useShowExternalControlsText();

  return (
    <>
      <FullScreenSearch show={showSearch} onClose={() => setShowSearch(false)}>
        <AirportSearch />
      </FullScreenSearch>
      <div className="logo" ref={inViewLogo}>
        <Link to="/">
          <img src={awLogo} alt="" />
        </Link>
        {/* <p>aviation nowcasting data</p> */}
      </div>

      <StyledMapControls>
        <SSearchControls>
          <Stack
            horizontal
            gap={{
              desktop: "1rem",
              forecast: "0.5rem",
            }}
            css={{
              alignItems: "center",
              [responsive.forecast]: {
                position: "fixed",
                right: "0.75rem",
                top: "0.75rem",
              },
            }}
          >
            <SAirportDesktopSearch>
              <AirportSearch portal />
            </SAirportDesktopSearch>
            <div css={{ [responsive.mobilP]: { display: "none" } }}>
              <OutsideNavigationLoginStatus />
            </div>
            <OutsideHamburgerButton />
          </Stack>
        </SSearchControls>
        <STopRightControls>
          <Spacer size="0.5rem" />

          <StyledWeatherLayer
            onClick={() => setShowSearch(true)}
            selected={false}
            css={{
              display: "none",
              [responsive.forecast]: { display: "flex" },
            }}
          >
            {showText && <span>Search airport</span>}
            <img src={iconSearch} width="30px" alt="" css={{ padding: "6px" }} />
          </StyledWeatherLayer>

          <StyledWeatherLayer
            onClick={() => setWeatherOverlay("clouds")}
            selected={weatherOverlay === "clouds"}
          >
            {showText && <span>Clouds</span>}
            <img src={iconClouds} alt="" />
          </StyledWeatherLayer>
          <StyledWeatherLayer
            onClick={() => setWeatherOverlay("crr-pc")}
            selected={weatherOverlay === "crr-pc"}
          >
            {showText && <span>Rain</span>}
            <img src={iconRain} alt="" />
          </StyledWeatherLayer>
          <StyledWeatherLayer
            onClick={() => setWeatherOverlay("rdt")}
            selected={weatherOverlay === "rdt"}
          >
            {showText && <span>Thunderstorms</span>}
            <img src={iconThunder} alt="" />
          </StyledWeatherLayer>
        </STopRightControls>
        <div className="switchPosition">
          <Switch text={"metar"} placement={"landingMap"} />
        </div>

        <button className="resetPosition" onClick={reset} title="Reset location">
          <img src={iconResetLocation} alt="" />
        </button>
      </StyledMapControls>
      {/* temporarily disabled */}
      {!isTimeSliderAtMapDisabled() && (
        <TimeSlider
          forecastBar={forecastBar}
          forecastValue={forecastValue}
          setForecastValue={setForecastValue}
        />
      )}
    </>
  );
}

// -----------------------------------------------------------------
// STYLING

const SAirportDesktopSearch = styled.div({
  width: "20rem",
  [responsive.highlights]: {
    width: "15rem",
  },
  [responsive.forecast]: {
    display: "none",
  },
});

const StyledMapControls = styled.div`
  .resetPosition {
    position: absolute;
    top: 190px;
    left: 10px;
    z-index: 1000;

    /* width: 30px; */
    /* height: 30px; */
    padding: 0;
    border: 2px solid rgba(0, 0, 0, 0.363);
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;

    ${responsive.forecast} {
      top: 10rem;
    }
  }

  .switchPosition {
    position: absolute;
    top: 68px;
    left: 10px;
    z-index: 1000;
    background-color: #fff;
    border-radius: 0.3rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.4rem;

    ${responsive.deskSM} {
      top: 68px;
    }

    ${responsive.tablet} {
      top: 60px;
    }

    ${responsive.forecast} {
      left: 50%;
      transform: translate(-50%);
    }

    ${responsive.mobilP} {
      top: 54px;
      min-width: 194px;
    }
  }
`;

const SSearchControls = styled.div`
  position: absolute;
  top: 1rem;

  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  ${responsive.tablet} {
    ${{ top: "0.5rem" }}
  }

  ${responsive.forecast} {
    top: 6rem;
  }
`;

const STopRightControls = styled.div`
  position: absolute;
  top: 5rem;

  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  ${responsive.forecast} {
    top: 6rem;
  }
`;

const StyledWeatherLayer = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  /* width: 30px; */

  align-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  text-shadow: 0px 0px 8px white;
  font-weight: bold;
  color: #231f20;

  &:after {
    content: "";
    background-color: #f1f1f2;
    position: absolute;
    top: 52px;
    right: 0;
    /* width: 30px; */
    height: 125px;
    z-index: -10;
    border-radius: 5px;

    ${responsive.forecast} {
      top: -2px;
      height: 165px;
    }
  }

  span {
    padding-left: 5px;
    /* text-decoration: ${(props) => (props.selected ? "underline" : "none")}; */
    font-weight: ${(props) => (props.selected ? 900 : "bold")};

    ${responsive.mobilP} {
      font-size: 12px;
    }
  }

  img {
    background-color: #fff;
    /* background-color: ${(props) => (props.selected ? "#3e97d2e1" : "#fff")}; */
    background-color: ${(props) => (props.selected ? "#009ff5" : "#fff")};
    /* border: 2px solid rgba(0, 0, 0, 0.363); */
    margin: 5px 0px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 black;
    /* box-shadow: ${(props) => (props.selected ? "0 0 4px 0 black" : "")}; */
  }
  :hover {
    span {
      text-decoration: underline;
    }
    img {
      /* background-color: #3e97d2e1; */
      background-color: #009ff5;
      border-radius: 5px;
    }
  }
`;

export default ExternalControls;

import React from "react";

function Runway({ height }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height={`${height}px`}
        viewBox="-478.873 546.22 140 191"
      >
        <g>
          <line
            fill="none"
            stroke="#8d8d8f"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="-408.874"
            y1="736.72"
            x2="-408.874"
            y2="735.72"
          />

          <line
            fill="none"
            stroke="#8d8d8f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="1.9789,1.9789"
            x1="-408.874"
            y1="733.741"
            x2="-408.874"
            y2="548.71"
          />

          <line
            fill="none"
            stroke="#8d8d8f"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="-408.874"
            y1="547.72"
            x2="-408.874"
            y2="546.72"
          />
        </g>

        <g>
          <path
            fill="#231F20"
            d="M-383.504,581.519l40.403,132.352h-131.564l38.22-132.352H-383.504 M-382.52,580.519h-54.923
			l-38.724,134.352h134.587L-382.52,580.519L-382.52,580.519z"
          />
        </g>
        <g>
          <path
            fill="#A6A8AB"
            d="M-389.964,676.875c2.788,0,4.182,0,6.97,0c1.428,11.308,2.857,22.616,4.286,33.924
			c-3.273,0-4.909,0-8.182,0C-387.915,699.491-388.939,688.183-389.964,676.875z"
          />
          <path
            fill="#A6A8AB"
            d="M-400.75,676.875c2.788,0,4.182,0,6.97,0c0.803,11.308,1.606,22.616,2.41,33.924c-3.273,0-4.909,0-8.182,0
			C-399.952,699.491-400.351,688.183-400.75,676.875z"
          />
          <path
            fill="#A6A8AB"
            d="M-379.675,676.875c2.788,0,4.182,0,6.97,0c2.025,11.308,4.05,22.616,6.075,33.924
			c-3.273,0-4.909,0-8.182,0C-376.433,699.491-378.054,688.183-379.675,676.875z"
          />
          <path
            fill="#A6A8AB"
            d="M-368.701,676.875c2.788,0,4.182,0,6.97,0l7.858,33.924c-3.273,0-4.909,0-8.182,0L-368.701,676.875z"
          />
        </g>
        <g>
          <path
            fill="#A6A8AB"
            d="M-430.856,710.799c-3.273,0-4.909,0-8.182,0c1.429-11.308,2.858-22.616,4.286-33.924
			c2.788,0,4.182,0,6.97,0C-428.807,688.183-429.831,699.491-430.856,710.799z"
          />
          <path
            fill="#A6A8AB"
            d="M-418.194,710.799c-3.273,0-4.909,0-8.182,0c0.804-11.308,1.607-22.616,2.41-33.924
			c2.788,0,4.182,0,6.97,0C-417.395,688.183-417.794,699.491-418.194,710.799z"
          />
          <path
            fill="#A6A8AB"
            d="M-442.934,710.799c-3.273,0-4.909,0-8.182,0c2.025-11.308,4.05-22.616,6.075-33.924
			c2.788,0,4.182,0,6.97,0C-439.692,688.183-441.313,699.491-442.934,710.799z"
          />
          <path
            fill="#A6A8AB"
            d="M-455.691,710.799c-3.273,0-4.909,0-8.182,0l7.858-33.924c2.788,0,4.182,0,6.97,0L-455.691,710.799z"
          />
        </g>
      </svg>
    </div>
  );
}

export default Runway;

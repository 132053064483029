import { FC, PropsWithChildren, ReactNode } from "react";

export interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
}

export const ConditionalWrapper: FC<
  PropsWithChildren<ConditionalWrapperProps>
> = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : <>{children}</>;
};

import { useAirportNotifications } from "logic/airport/notification";
import { FC } from "react";
import { iconColor } from "utils/themes";
import { AirportNotification as ApiAirportNotification } from "awd-server-api";
import { matchMap } from "utils/general";
import styled from "@emotion/styled";
import UpdateIcon from "@mui/icons-material/Update";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

const SDot = styled.div`
  position: absolute;
  background-color: red;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  bottom: 1.5rem;
  right: -0.25rem;
`;

export type AirportWarningIconProps = {
  icao: string;
  category: ApiAirportNotification["category"];
};

export const AirportNotificationIcon: FC<AirportWarningIconProps> = (props) => {
  const notifications = useAirportNotifications(props.icao);

  const hasNotification = matchMap(props.category, {
    operational_update: notifications.hasOperationalUpdate(),
    general_information: notifications.hasGeneralInformation(),
  });

  const Icon = matchMap(props.category, {
    operational_update: <UpdateIcon htmlColor={iconColor} />,
    general_information: <LiveHelpIcon htmlColor={iconColor} />,
  });

  return (
    <div css={{ position: "relative", display: "inline" }}>
      {hasNotification && <SDot />}
      {Icon}
    </div>
  );
};

import { ThemeProvider } from "@emotion/react";
import { Widget, WidgetProps } from "components/widget/Widget";
import { FC } from "react";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "store/store";
import { GlobalStyles } from "styles/GlobalStyles";
import { themes } from "utils/themes";

export type WidgetAppProps = WidgetProps;

export const WidgetApp: FC<WidgetAppProps> = ({ ...pass }) => {
  return (
    <Provider store={store}>
      <GlobalStyles />

      <WidgetAppWithRedux {...pass} />
    </Provider>
  );
};

export const WidgetAppWithRedux: FC<WidgetAppProps> = ({ ...pass }) => {
  const theme = useSelector((state: RootState) => state.localState.flightRulesCode) ?? "default";

  return (
    <ThemeProvider theme={themes[theme]}>
      <Widget {...pass} />
    </ThemeProvider>
  );
};

import { FC } from "react";
import { useMap, Polygon } from "react-leaflet";
import { useEffect, useState } from "react";
import { cellToBoundary, cellToLatLng, latLngToCell, CoordPair } from "h3-js";

export type HoveredPolygonType = {
  mobile: boolean | undefined;
};

export type LatLngCoords = {
  lat: number;
  lng: number;
};

const HoveredPolygon: FC<HoveredPolygonType> = ({ mobile }) => {
  const map = useMap();
  const [coords, setCoords] = useState<LatLngCoords>();

  useEffect(() => {
    if (!map) return;
    map.addEventListener("mousemove", (e) => {
      setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
    });
  }, [map]);

  const zoomIn = (zoom: number) => {
    map &&
      coords &&
      map.setView(
        cellToLatLng(latLngToCell(coords.lat, coords.lng, 3)),
        zoom < 7 ? 7 : 10
      );
  };

  const wrapCoords = (latLngCoords: LatLngCoords, coords: CoordPair[]): CoordPair[] => {
    // -135/+135 is rough optimization to fix only polygons which fit into such boundary bands
    if (latLngCoords.lng > 135) {
      return coords.map((cp) => cp[1] < -135 ? [cp[0], cp[1] + 360.0] : cp);
    } else if (latLngCoords.lng < -135) {
      return coords.map((cp) => cp[1] > 135 ? [cp[0], cp[1] - 360.0] : cp);
    }
    return coords;
  }

  return (
    <>
      {coords && map && map.getZoom() < 10 && !mobile ? (
        <Polygon
          className="polygon"
          pathOptions={{
            color: "#343435",
            fillColor: "grey",
            weight: 2,
            fillOpacity: 0.25,
          }}
          positions={wrapCoords(coords, cellToBoundary(
            latLngToCell(coords.lat, coords.lng, map.getZoom() < 7 ? 2 : 3)
          ))}
          eventHandlers={{
            click: () => {
              zoomIn(map.getZoom());
            },
          }}
        />
      ) : null}
    </>
  );
};

export default HoveredPolygon;

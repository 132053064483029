import React, { FC, PropsWithChildren } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { SignupOfferParagraph } from "components/signupOfferBox/SignupOfferParagraph";

export const SignupBenefitListItem: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ListItem sx={{ display: "list-item", pl: 0, py: 0 }}>
      <ListItemText
        disableTypography
        primary={<SignupOfferParagraph>{children}</SignupOfferParagraph>}
        sx={{ my: 0 }}
      ></ListItemText>
    </ListItem>
  );
};

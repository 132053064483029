import { ROW_PER_PAGE, StyledTableCell, useTablePage } from "utils/table";
import { awdApi } from "store/reducers/awdApi";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { AirportRow } from "components/registeredAirports/RegisteredAirportTableRow";
import { Stack } from "components/layout/Stack";
import { FormEventHandler, useState } from "react";

export const RegisteredAirportsTable = () => {
  const [page, setPage, handleChangePage] = useTablePage();
  const [controlledText, setControlledText] = useState("");
  const [searchText, setSearchText] = useState("");

  const registeredAirports = awdApi.endpoints.getRegisteredAirports.useQuery({
    page,
    text: searchText,
  });

  const airports = registeredAirports?.data?.airports;
  const count = registeredAirports?.data?.count;

  const textSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchText(controlledText);
  };

  return (
    <div>
      <Stack gap="0" horizontal css={{ justifyContent: "flex-end", marginBottom: "1rem" }}>
        <form onSubmit={textSearch}>
          <TextField
            label="Search via ICAO or Airport Name"
            variant="outlined"
            css={{ width: "250px" }}
            value={controlledText}
            onChange={(e) => setControlledText(e.target.value)}
          />
        </form>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ICAO</StyledTableCell>
              <StyledTableCell>Airport Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>User Name</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>End Date</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {airports?.map((airport) => <AirportRow key={airport.airport_id} airport={airport} />)}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count ?? 0}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

import {
  PatchRunwayBody,
  PostRunwayBody,
  RunwayResponse,
} from "awd-server-api";
import {
  parseFloatInputString,
  parseOptionalYesNo,
  zIntegerInput,
  zLatitudeInput,
  zLongitudeInput,
  zOptionalYesNoInput,
} from "utils/parse";
import { z } from "zod";

export const zRunwayForm = z.object({
  length: zIntegerInput,
  width: zIntegerInput,
  lighted: zOptionalYesNoInput,
  closed: zOptionalYesNoInput,

  surface: z.string(),
  leIdent: z.string(),
  heIdent: z.string(),

  leHeading: zIntegerInput,
  heHeading: zIntegerInput,

  leElevation: zIntegerInput,
  heElevation: zIntegerInput,

  leLatitude: zLatitudeInput,
  heLatitude: zLatitudeInput,

  leLongitude: zLongitudeInput,
  heLongitude: zLongitudeInput,

  leDisplacedThreshold: zIntegerInput,
  heDisplacedThreshold: zIntegerInput,
});
export type RunwayFormValues = z.infer<typeof zRunwayForm>;

export function prepareForm(runway: RunwayResponse): RunwayFormValues {
  return {
    length: String(runway.length ?? ""),
    width: String(runway.width ?? ""),
    lighted: runway.lighted ? "yes" : "no",
    closed: runway.closed ? "yes" : "no",
    surface: runway.surface ?? "n/a",
    heIdent: String(runway.heIdent ?? ""),
    leIdent: String(runway.leIdent ?? ""),
    heHeading: String(runway.heHeading ?? ""),
    leHeading: String(runway.leHeading ?? ""),
    heDisplacedThreshold: String(runway.heDisplacedThreshold ?? ""),
    leDisplacedThreshold: String(runway.leDisplacedThreshold ?? ""),
    heElevation: String(runway.heElevation ?? ""),
    leElevation: String(runway.leElevation ?? ""),
    heLatitude: String(runway.heLatitude ?? ""),
    heLongitude: String(runway.heLongitude ?? ""),
    leLatitude: String(runway.leLatitude ?? ""),
    leLongitude: String(runway.leLongitude ?? ""),
  };
}

export function preparePostBody(
  values: RunwayFormValues
): Pick<PostRunwayBody, keyof RunwayFormValues> {
  return {
    length: parseFloatInputString(values.length),
    width: parseFloatInputString(values.width),
    lighted: parseOptionalYesNo(values.lighted),
    closed: parseOptionalYesNo(values.closed),
    surface: values.surface ?? "UNK",
    heIdent: values.heIdent,
    leIdent: values.leIdent,
    heHeading: parseFloatInputString(values.heHeading),
    leHeading: parseFloatInputString(values.leHeading),
    heDisplacedThreshold: parseFloatInputString(values.heDisplacedThreshold),
    leDisplacedThreshold: parseFloatInputString(values.leDisplacedThreshold),
    heElevation: parseFloatInputString(values.heElevation),
    leElevation: parseFloatInputString(values.leElevation),
    heLatitude: parseFloatInputString(values.heLatitude),
    heLongitude: parseFloatInputString(values.heLongitude),
    leLatitude: parseFloatInputString(values.leLatitude),
    leLongitude: parseFloatInputString(values.leLongitude),
  };
}

export function preparePatchBody(
  values: RunwayFormValues
): Pick<PatchRunwayBody, keyof RunwayFormValues> {
  return preparePostBody(values);
}

import styled from "@emotion/styled";
import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openAirportMarker, closeAirportMarker } from "store/reducers/localStateSlice";
import { setCenterPoint } from "store/reducers/localStateSlice";
import { isNonNullable } from "utils/general";

const markerIcon = new L.Icon({
  iconUrl: require("assets/images/mvfr.png"),
  // iconUrl: markerIcon,
  // shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [64, 65],
  iconAnchor: [32, 27],
});

export type AirportMarkerType = {
  icao: string;
  icaoAlias?: string | null;
  name: string;
  lat: number;
  long: number;
  country?: string | null;
  status?: boolean | null;
};

const AirportMarker = ({
  icao,
  icaoAlias,
  name,
  lat,
  long,
  country,
  status,
}: AirportMarkerType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToWeatherDetails = () => {
    dispatch(setCenterPoint([lat, long]));
    navigate(`/${icao}`);
  };

  return (
    <Marker
      position={[lat, long]}
      opacity={0}
      icon={markerIcon}
      eventHandlers={{
        popupopen: (...args) => {
          dispatch(openAirportMarker());
        },
        popupclose: (...args) => {
          dispatch(closeAirportMarker());
        },
      }}
    >
      <Popup>
        <p className="status">{status ? "closed" : ""}</p>
        <p className="airportName">{name}</p>
        <p className="icaoCode">({[icao, icaoAlias].filter(isNonNullable).join(", ")})</p>
        <p>
          [{Math.round((lat + Number.EPSILON) * 1000) / 1000}
          {"; "}
          {Math.round((long + Number.EPSILON) * 1000) / 1000}]
        </p>
        <p className="end">country: {country}</p>
        <StyledButton onClick={() => goToWeatherDetails()}>show weather</StyledButton>
      </Popup>
    </Marker>
  );
};

const StyledButton = styled.button`
  font-family: "Encode Sans";
  background-color: #fff;
  border: 0.05rem #7c7c7c solid;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 black;
  padding: 0.4rem;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: #009ff5;
  }

  &:active {
    transform: translate(0.1rem, 0.1rem);
  }
`;

export default AirportMarker;

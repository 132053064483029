import styled from "@emotion/styled";
import { responsive } from "mixins";
import { FC } from "react";
import Arrow from "../_icons/Arrow";

import { ParsedRunway } from "logic/airport/runway/parse";
import { useActiveMetarSource } from "logic/weather/hooks";
import { calculateCrosswind } from "logic/weather/wind/crosswindLogic";
import { useMySettings } from "logic/user/me/settings/hooks";
import { describeUnitValue } from "logic/unit/describe";

export type CrosswindProps = {
  runway: ParsedRunway;
};

const Crosswind: FC<CrosswindProps> = ({ runway }) => {
  const { source } = useActiveMetarSource();
  const { from_direction: direction, speed } = source?.getCurrentWind() ?? {};
  const settings = useMySettings().describeOrDefault();
  const windVectorComponents =
    direction &&
    speed &&
    calculateCrosswind({
      windDirection: direction,
      windSpeed: speed,
      selectedRunway: runway,
    });

  if (!windVectorComponents) return <></>;

  return (
    <StyledCrosswind
      placement={
        (windVectorComponents.crosswind >= 0 && windVectorComponents.headwind >= 0) ||
        (windVectorComponents.crosswind >= 0 && windVectorComponents.headwind <= 0)
          ? "left"
          : "right"
      }
      value={windVectorComponents}
    >
      <Arrow
        angle={
          windVectorComponents && {
            crosswind: windVectorComponents.crosswind >= 0 ? 270 : 90,
            headwind: windVectorComponents.headwind >= 0 ? 0 : 180,
          }
        }
      />
      <span className="crossSpeedValue">
        {describeUnitValue({
          unit: "kt",
          value: Math.abs(windVectorComponents.crosswind),
        })
          ?.convertToUnit(settings.settings.speed_unit)
          ?.format({ postprocess: "round" })}
      </span>
      <span className="headSpeedValue">
        {describeUnitValue({
          unit: "kt",
          value: Math.abs(windVectorComponents.headwind),
        })
          ?.convertToUnit(settings.settings.speed_unit)
          ?.format({ postprocess: "round" })}
      </span>
    </StyledCrosswind>
  );
};

const StyledCrosswind = styled.div<{
  placement: string;
  value: { crosswind: number; headwind: number };
  angle?: number;
}>`
  position: absolute;
  bottom: 4.25rem;
  ${(props) => (props.placement === "left" ? { left: "2.5rem" } : { right: "3.5rem" })}
  font-size: 14px;
  font-weight: 500;

  ${responsive.tablet} {
    font-size: 10px;
  }
  ${responsive.highlights} {
    ${(props) => (props.placement === "left" ? { left: "1.5rem" } : { right: "1.5rem" })}
  }
  ${responsive.mobilP} {
    ${(props) => (props.placement === "left" ? { left: "0.5rem" } : { right: "1.5rem" })}
  }

  .crossSpeedValue {
    position: absolute;
    bottom: ${({ value }) =>
      (value.crosswind >= 0 || value.crosswind < 0) && value.headwind >= 0 ? "10px" : "37px"};
    left: ${({ value }) => (value.crosswind >= 0 ? "-15px" : "auto")};
    right: ${({ value }) => (value.crosswind >= 0 ? "auto" : "-20px")};

    ${responsive.tablet} {
      bottom: ${({ value }) =>
        (value.crosswind >= 0 || value.crosswind < 0) && value.headwind >= 0 ? "12px" : "30px"};
      left: ${({ value }) => (value.crosswind >= 0 ? "-7px" : "auto")};
      right: ${({ value }) => (value.crosswind >= 0 ? "auto" : "-6px")};
    }
  }
  .headSpeedValue {
    position: absolute;

    top: ${({ value }) => (value.headwind >= 0 ? "-10px" : "50px")};
    bottom: ${({ value }) => (value.headwind >= 0 ? "auto" : "-10px")};
    right: ${({ value }) => (value.headwind >= 0 ? "-6px" : "-2px")};

    ${responsive.tablet} {
      right: ${({ value }) => (value.headwind >= 0 ? "3px" : "3px")};
    }
  }
`;

export default Crosswind;

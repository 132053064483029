import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

import premiumHazards from "assets/images/premium-hazards.png";

import { useDashboardAirport } from "logic/weather/hooks";
import { Meta } from "components/meta/Meta";

const Hazards = () => {
  const { icao } = useParams();
  const airport = useDashboardAirport(icao).airport;
  const navigate = useNavigate();

  useEffect(() => {
    if (airport === null) {
      navigate(`/${icao}`);
    }
  });

  return (
    <StyledHazards>
      <Meta pageTitle={`${icao.toUpperCase()} Hazards`} />
      <div>
        <img src={premiumHazards} alt="" />
      </div>
    </StyledHazards>
  );
};

const StyledHazards = styled.div`
  margin: 100px auto;
  img {
    max-width: 100%;
  }
`;

export default Hazards;

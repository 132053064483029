import styled from "@emotion/styled";
import Ceiling from "../weatherNow/Ceiling";
import { ForecastWind } from "./ForecastWind";
import { themes } from "utils/themes";
import { responsive } from "mixins";
import { useSelector } from "react-redux";
import { useAirportWeather } from "logic/weather/hooks";
import { showCeiling } from "utils/global.functions";
import { useMySettings } from "logic/user/me/settings/hooks";
import { describeUnitValue } from "logic/unit/describe";
import { RootState } from "store/store";
import { UnitValue } from "awd-server-api";
import { WeatherForecastElement } from "components/Dashboard/weatherForecast/WeatherForecast";
import { FlightRulesCode } from "store/reducers/localStateSlice";

export type ForecastContainerType = {
  flightRules?: UnitValue;
  visibility?: UnitValue;
  ceiling?: UnitValue;
  cloudCover?: UnitValue;
  wind?: WeatherForecastElement;
  index: number;
  empty?: boolean;
};

function ForecastContainer({
  flightRules,
  visibility,
  ceiling,
  cloudCover,
  wind,
  index,
  empty,
}: ForecastContainerType) {
  const toggledToAw = useSelector(
    (state: RootState) => state.localState.toggledToAwMetarWeatherSource
  );

  const report = useAirportWeather().report();
  const settings = useMySettings().describeOrDefault();
  const disabledTaf = !report?.hasOriginalReport("taf");

  const flightRulesMeaning = flightRules?.meaning ?? "no data";
  const flightRulesCode = (
    flightRulesMeaning === "no data" ? "default" : flightRulesMeaning.toLocaleLowerCase()
  ) as FlightRulesCode;
  const flightRulesText = flightRulesMeaning.toUpperCase();

  let ceilingMeaning = ceiling ? ceiling.meaning ?? "CEILING" : "\u00A0";
  let cloudCoverOctas =
    cloudCover?.value || cloudCover?.value === 0
      ? Math.round(((cloudCover?.value ?? 0) / 100.0) * 8.0)
      : undefined;

  let ceilingShow = false;
  let ceilingText = "";
  let cloudShow = true;
  let sideCloudShow = false;
  let sideCloudDashed = false;

  if (cloudCoverOctas || cloudCoverOctas === 0) {
    if (cloudCoverOctas === 0) {
      ceilingMeaning = "NO CEILING";
      cloudShow = false;
    } else if (cloudCoverOctas <= 4) {
      ceilingMeaning = "NO CEILING";
      cloudShow = false;
      sideCloudShow = true;
    } else {
      ceilingMeaning = "CEILING";
    }
  }

  if (cloudShow && ceiling && showCeiling(ceiling)) {
    ceilingShow = true;
    ceilingText =
      describeUnitValue(ceiling)
        ?.convertToUnit(settings.settings.ceiling_unit, true)
        ?.format({ postprocess: "floor10" }) ?? "";
  }

  if (empty) {
    return (
      <StyledForecastContainer>
        <p className="empty">no data</p>
      </StyledForecastContainer>
    );
  }

  let visibilityData = "NO DATA";
  if (visibility && ceiling && visibility.meaning !== "no data" && ceiling.meaning !== "no data") {
    visibilityData =
      describeUnitValue(visibility)
        ?.convertToUnit(settings.settings.visibility_unit, true)
        ?.format({ postprocess: "round" }) ?? "";
  }

  return (
    <StyledForecastContainer
      theme={themes[flightRulesCode]}
      index={index}
      alert={ceilingMeaning}
      checked={disabledTaf ? true : toggledToAw}
    >
      {/* flight rules code */}
      <p className="code">{flightRulesText}</p>
      <p className="visibilityData">{visibilityData}</p>
      <p className="visibilityLabel">visibility</p>
      <ForecastWind wind={wind} index={index} theme={themes[flightRulesCode]} />
      <div className="ceiling">
        <p className="cloudsLabel">{ceilingMeaning}</p>
        <Ceiling
          ceilingShow={ceilingShow}
          cloudShow={cloudShow}
          sideCloudShow={sideCloudShow}
          sideCloudDashed={sideCloudDashed}
          ceilingText={ceilingText}
          index={index}
          theme={themes[flightRulesCode]}
        />
      </div>
    </StyledForecastContainer>
  );
}
const StyledForecastContainer = styled.div<{ index?: number; checked?: boolean; alert?: string }>`
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
  }
  .code {
    margin: 0;
    padding: 0.5rem 0;
    /* background-color: #52a3e6; */
    background-color: ${(props) =>
      props.index === 0
        ? props.theme.primary
        : props.checked
        ? props.theme.backgroundPrimary
        : props.theme.backgroundSecondary};
    transition: background-color 0.5s;
    border-radius: 1rem 1rem 0 0;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }

  .cloudsLabel {
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) =>
      props.alert === "no data" && props.index === 0
        ? "red"
        : props.index === 0
        ? props.theme.primary
        : "#808284"};
    font-family: "Encode Sans Condensed";

    font-weight: bold;
  }

  .visibilityData {
    margin: 0;
    padding: 1rem 0 0 0;
    font-weight: 500;
    /* color: #52a3e6; */
    color: #808284;
    line-height: 1rem;
  }
  .visibilityLabel {
    margin: 0;
    padding: 0.1rem 0;
    font-weight: 300;
    color: #808284;
    font-size: 13px;
    line-height: 0.8rem;
  }

  .ceiling {
    ${responsive.mobilP} {
      min-width: 120px;
    }
  }
`;

export default ForecastContainer;

import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useContentfulData } from "utils/useContentfulData";
import { useDispatch } from "store/hooks";
import Modal from "components/modal/Modal";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { useModalContext } from "components/modal/inside/ModalProvider";

import { useNavigate } from "react-router-dom";

import { modalWithDispatch } from "components/modal/modalSpec";
import { useModalOptions } from "components/content/defaultOptions";

import { localApi } from "store/reducers/localApi";

export type ModalContentfulDataType = {
  icao?: string;
  type:
    | "airportAccessLimit"
    | "metarSwitch"
    | "tafSwitch"
    | "runwaysInfo"
    | "airportSubscriptionStatus";
};

export const ModalContentfulData = ({ type, icao }: ModalContentfulDataType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalContext } = useModalContext();

  const isMobileApp = localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ?? false;

  const contentFromContentful = useContentfulData(modalWithDispatch[type].contentfulSource);
  const modalContent = contentFromContentful?.modalContent;
  const defaultOptions = useModalOptions();

  // if modal should pop out
  const { shouldShowModal } = useSelector(modalWithDispatch[type].shouldShowModalSelector);
  const showModal = shouldShowModal(icao);

  const navigateTo =
    modalWithDispatch[type].navigate?.backButtonPath === "/icao"
      ? `/${icao}`
      : modalWithDispatch[type].navigate?.backButtonPath;

  const onModalClose = () => {
    dispatch(modalWithDispatch[type].dispatchOnClose());
    // @ts-expect-error
    modalWithDispatch[type].navigation && navigate(navigateTo, { replace: true });
    !modalWithDispatch[type].navigation && modalContext?.close();
  };

  if (!modalContent) return null;

  return (
    <>
      <Modal onClose={onModalClose} showModal={showModal} blur={modalWithDispatch[type].navigation}>
        {documentToReactComponents(modalContent, defaultOptions)}
        {modalWithDispatch[type].navigation === true && (
          <Stack gap="1rem" horizontal css={{ justifyContent: "end" }}>
            <Button color="error" onClick={onModalClose}>
              {modalWithDispatch[type].navigate?.backButtonText}
            </Button>
            {!isMobileApp && (
              <Button href={modalWithDispatch[type].navigate?.subscribeButtonPath}>
                {modalWithDispatch[type].navigate?.subscribeButtonText}
              </Button>
            )}
          </Stack>
        )}
      </Modal>
    </>
  );
};

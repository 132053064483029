import styled from "@emotion/styled";
import { DashboardAirportNotification } from "components/airportNotification/DashboardAirportNotification";
import { WeatherDashboardContext } from "components/Dashboard/context";
import { TextWeatherReport } from "components/Dashboard/textWeatherReport/TextWeatherReport";
import { WeatherNowWindCard } from "components/Dashboard/weatherNow/WeatherNowWindCard";
import { Stack } from "components/layout/Stack";
import { useContext, ReactNode } from "react";
import { useSelector } from "react-redux";
import { responsive } from "mixins";
import Runways from "./Runways";
import WeatherNowCeiling from "./WeatherNowCeiling";
import SwitchWithRecalc from "components/_buttons/SwitchWithRecalc";
import { useAirportWeather } from "logic/weather/hooks";
import { useMySettings } from "logic/user/me/settings/hooks";
import { useAirportNotifications } from "logic/airport/notification";
import { RootState } from "store/store";

type TitleSectionProps = {
  color?: string;
  children?: ReactNode;
};

export const TitleSection = (props: TitleSectionProps) => {
  return <SCaption color={props.color}>{props.children}</SCaption>;
};

const SCaption = styled.p`
  margin: 0;
  margin-bottom: 0.3rem;
  /* margin-top: 1rem; */
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : " #58585b")};

  ${responsive.tablet} {
    font-size: 11px;
  }
`;

const WeatherNow = () => {
  const AwMetarChecked = useSelector(
    (state: RootState) => state.localState.toggledToAwMetarWeatherSource
  );
  const icao = useContext(WeatherDashboardContext)?.icao;
  const settings = useMySettings().settings;
  const weather = useAirportWeather().report()?.withSettings(settings);
  const notifications = useAirportNotifications(icao);
  const hasNotifications = notifications.hasWarnings();

  return (
    <SWeatherNowWithRecalc>
      <div className="switch">
        <SwitchWithRecalc weather={weather} source={"metar"} />
      </div>
      <StyledWeatherNow checked={AwMetarChecked}>
        {/* notification */}

        {hasNotifications && (
          <SNotifications>
            <Stack gap="1rem">
              <DashboardAirportNotification icao={icao} />
            </Stack>
          </SNotifications>
        )}

        <div className="title">
          <TitleSection>Current Weather</TitleSection>
        </div>
        <SWindCeiling>
          {/* current wind */}
          <WeatherNowWindCard />

          {/* ceiling */}
          <WeatherNowCeiling />

          {/* runways */}
          <div className="runwaysContainer">
            <Runways />
          </div>
        </SWindCeiling>

        {/* textual metar */}
        <STextualMetar>
          <SCaption>{AwMetarChecked ? "Aw-Metar" : "Metar"}</SCaption>
          <TextWeatherReport source={AwMetarChecked ? "aw-metar" : "metar"} />
        </STextualMetar>
      </StyledWeatherNow>
    </SWeatherNowWithRecalc>
  );
};

const SWeatherNowWithRecalc = styled.div`
  display: contents;
  .switch {
    padding-left: 1.2rem;
  }
`;

const SNotifications = styled.div``;

const STextualMetar = styled.div`
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;

  font-size: 13px;
  width: 60%;
  min-height: 5rem;

  ${responsive.hambrg} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ${responsive.tablet} {
    margin-top: 0.5rem;
  }
  ${responsive.mobilL} {
    min-height: 6.5rem;
  }
  ${responsive.forecast} {
    width: 100%;
  }
  ${responsive.mobilS} {
    min-height: 7.2rem;
  }
`;

const SWindCeiling = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.3rem;

  ${responsive.hambrg} {
    grid-template-columns: 1fr;
  }
`;

const StyledWeatherNow = styled.div<{ checked: boolean }>`
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  display: flex;
  flex-direction: column;

  padding: 0 1.3rem;

  background-color: ${(props) =>
    props.checked ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
  transition: background-color 0.5s;
  border-radius: 1rem;

  ${responsive.deskSM} {
    grid-row: 3 / 4;
  }

  ${responsive.deskSM} {
    /* grid-row-start: 4;
    grid-row-end: 5; */
    border-radius: 0 0 1rem 1rem;
  }

  .runwaysContainer {
    grid-column: 1 / 2;

    ${responsive.tablet} {
      margin: 0.5rem;
      margin-bottom: 1rem;
    }

    ${responsive.hambrg} {
      grid-row: 2 / 3;
    }
  }

  .title {
    margin-top: 1.5rem;
  }
`;

export default WeatherNow;

import { ModalContentfulData } from "components/modal/ModalContentfulData";
import { ModalContentfulDataWithoutSelectors } from "components/modal/ModalContentfulDataWithoutSelectors";
import { useRunwaySchemaData } from "pages/weather-dashboards/RunwaySchema";

export type LimitModalsType = {
  icao: string;
};

export const LimitModals = ({ icao }: LimitModalsType) => {
  const {
    isSubscribed,
    isManagerOfCurrentAirport,
    isAllRunwaysDataFilledIn,
    isRunwaysDataCorrect,
    isHeliport,
    isSuperUser,
  } = useRunwaySchemaData(icao);

  return (
    <>
      {!isSubscribed && !isSuperUser && (
        <ModalContentfulData icao={icao} type={"airportSubscriptionStatus"} />
      )}

      {/* all - when airport is subscribed */}
      {(isSubscribed || isSuperUser) &&
        !isHeliport &&
        !isAllRunwaysDataFilledIn &&
        !isManagerOfCurrentAirport && (
          <ModalContentfulDataWithoutSelectors
            icao={icao}
            type={"notAllRunwaysDataForVizFilledIn_General"}
          />
        )}
      {isSubscribed && !isHeliport && !isAllRunwaysDataFilledIn && isManagerOfCurrentAirport && (
        <ModalContentfulDataWithoutSelectors
          icao={icao}
          type={"notAllRunwaysDataForVizFilledIn_Manager"}
        />
      )}
      {(isSubscribed || isSuperUser) &&
        !isHeliport &&
        isAllRunwaysDataFilledIn &&
        !isRunwaysDataCorrect &&
        !isManagerOfCurrentAirport && (
          <ModalContentfulDataWithoutSelectors
            icao={icao}
            type={"runwaysDataForVizNotCorrect_General"}
          />
        )}
      {isSubscribed &&
        !isHeliport &&
        isAllRunwaysDataFilledIn &&
        !isRunwaysDataCorrect &&
        isManagerOfCurrentAirport && (
          <ModalContentfulDataWithoutSelectors
            icao={icao}
            type={"runwaysDataForVizNotCorrect_Manager"}
          />
        )}
    </>
  );
};

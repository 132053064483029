import spacetime from "spacetime";
import { RunwayType } from "store/reducers/localStateSlice";
import { UnitValue } from "awd-server-api";

export const showCeiling = (ceiling: UnitValue) => {
  if (!ceiling.value && ceiling.value !== 0) return false;
  if (!ceiling.meaning) return true;
  if (
    ceiling.meaning === "no data" ||
    ceiling.meaning === "cloud-free" ||
    ceiling.meaning === "no ceiling"
  )
    return false;
  else return true;
};

type quantity = {
  value?: number | undefined;
  unit?: string | undefined;
  meaning?: string | undefined;
};

// checking consistency of units
export const q2u = (quantity: quantity | undefined, unit: string, round = "none") => {
  if (quantity === undefined || quantity?.meaning) {
    return undefined;
  }

  // to/from base SI units convertor (the operator (* or /) defines the direction)
  const convert = (v: number, u: string, op: (arg0: number, arg1: number) => number) => {
    switch (u) {
      case "meter":
        return v;
      case "m":
        return v;
      case "km":
        return op(v, 1000.0);
      case "ft":
        return op(v, 0.3048); // 1 ft = 0.3048 m
      case "FL":
        return op(v, 30.48); // FL 1 = 100 ft = 30.48 m

      case "Pa":
        return v;
      case "hPa":
        return op(v, 100.0);

      case "degC": // degrees Celsius
        return v;

      case "degreeT": // wind direction degrees
        return v;

      case "m/s":
        return v;
      case "kt":
        return op(v, 0.51444); // 1 kt = 0.51444 m/s

      default:
        throw new Error("Cannot convert " + v + " to " + u + ". Unknown unit!");
    }
  };

  let v = quantity.value;
  const u = quantity.unit;

  if ((!v && v !== 0) || !u) return undefined;

  // pass values of equal units
  if (u !== unit) {
    // convert to base SI
    v = convert(v, u, (a, b) => a * b);
    // convert to target units
    v = convert(v, unit, (a, b) => a / b);
  }

  // round value if needed
  switch (round) {
    case "none":
      break;
    case "floor":
      v = Math.floor(v);
      break;
    case "floor100":
      v = Math.floor(v / 100) * 100;
      break;
    case "ceil":
      v = Math.ceil(v);
      break;
    case "ceil100":
      v = Math.ceil(v / 100) * 100;
      break;
    case "round":
      v = Math.round(v);
      break;
    case "round100":
      v = Math.round(v / 100) * 100;
      break;
    default:
      throw new Error("Unknown rounding mode: " + round + "!");
  }

  // direction degrees are 360 modulo
  if (quantity.unit === "degreeT") {
    if (quantity.value === 360) {
      v = 360;
    } else {
      v = v % 360;
    }
  }

  return v;
};

export const addAndFormatTime = (timeString: string | undefined, add: number) => {
  // timeString in the iso format got from weather backend json
  if (!timeString) return "";
  const timeZone = timeString.substring(timeString.indexOf("[") + 1, timeString.indexOf("]"));
  const time = timeString.substring(0, timeString.indexOf(":", 15));
  const s = spacetime(time, timeZone);
  const addition = s.add(add, "milliseconds");
  return addition.format("{hour-24-pad}:{minute-pad}");
};

export const calculateWindToRunway = (
  direction: { value: number; unit: string; meaning?: string },
  selectedRunway: RunwayType
) => {
  const runwayAngle = parseInt(selectedRunway.name) * 10; // runways are nabed by azimuth /10

  const formattedDegrees = q2u(direction, "degreeT", "round");
  if (!formattedDegrees && formattedDegrees !== 0) return undefined;
  const angle = formattedDegrees - runwayAngle - 90; // arrow coordinates are rotated -90°

  return angle;
};

// arrow color and position for the labels based on wind speed
export const activeArrow = (speed: number) => {
  switch (true) {
    case speed < 9: {
      return [1];
    }
    case speed >= 9 && speed < 20: {
      return [1, 2];
    }
    case speed >= 20 && speed < 30: {
      return [1, 2, 3];
    }
    case speed >= 30: {
      return [1, 2, 3, 4];
    }
    default:
      return [1];
  }
};

// calculate delay
export const formatDataAge = (weatherTime: string) => {
  const time = new Date(weatherTime.substring(0, weatherTime.indexOf("[")));

  const diff = new Date().getTime() - time.getTime();
  const minutes = Math.round(diff / 1000 / 60);
  return minutes;
};

// mobile tablet check
export const mobileTabletCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

import { FC, Fragment } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useParams } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import VideocamIcon from "@mui/icons-material/Videocam";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { iconColor } from "utils/themes";

export const NavigationWebcam: FC = () => {
  const { icao } = useParams();
  const query = awdApi.endpoints.getAirportLinkSettings.useQuery(
    icao ? { airportId: icao } : skipToken
  );
  const settings = query.data;

  if (!settings?.webcam_url) return <Fragment></Fragment>;

  return (
    <ListItemButton href={`/${icao}/webcam`}>
      <ListItemIcon>
        <VideocamIcon htmlColor={iconColor} />
      </ListItemIcon>
      <ListItemText primary="Webcam" />
    </ListItemButton>
  );
};

import { FullScreenSearchContext } from "components/search/FullScreenSearch";
import { Search } from "components/search/InfiniteSearch";
import { describeAirport } from "logic/airport/describe";
import { useInfiniteAirportSearch } from "logic/search/airport/useInfiniteAirportSearch";
import { FC, Fragment, useContext, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export type AirportSearchProps = {
  portal?: boolean;
};

export const AirportSearch: FC<AirportSearchProps> = (props) => {
  const {
    value,
    setValue,
    result,
    onSearchResultClick,
    isFetching,
    setOffset,
  } = useInfiniteAirportSearch();
  const fullScreenContext = useContext(FullScreenSearchContext);

  const endOfSearchResults = useSelector(
    (state: RootState) => state.localState.endOfSearchResults
  );

  const intObserver = useRef<IntersectionObserver>();
  const lastAirport = useCallback(
    (airport: HTMLElement) => {
      if (endOfSearchResults) {
        return;
      }
      if (isFetching || !result || result?.airports == null) return;
      if (intObserver.current) intObserver.current.disconnect();
      intObserver.current = new IntersectionObserver((airports) => {
        if (airports[0]?.isIntersecting && result?.airports) {
          setOffset((prev) => prev + 10);
        }
      });
      if (airport) intObserver.current.observe(airport);
    },
    [isFetching, result, endOfSearchResults]
  );

  return (
    <Search
      value={value}
      setValue={setValue}
      setOffset={setOffset}
      portal={props.portal}
      results={
        isFetching && !result ? (
          "Search results are loading"
        ) : result?.airports == null ? null : result.airports.length === 0 ? (
          "No airport matches the search."
        ) : (
          <>
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "max-content 1fr",
                rowGap: fullScreenContext ? "1.5rem" : "0.5rem",
              }}
            >
              {result?.airports.map((airport, i) => {
                return (
                  <Fragment key={airport.icao}>
                    <strong
                      ref={
                        result?.airports.length === i + 1
                          ? lastAirport
                          : undefined
                      }
                      css={{
                        textAlign: "right",
                        cursor: "pointer",
                        paddingRight: "0.5rem",
                      }}
                      onClick={() => {
                        onSearchResultClick(airport.icao);
                        fullScreenContext?.close();
                      }}
                    >
                      {describeAirport(airport).getIcaos().join(", ") ?? "n/a"}
                    </strong>

                    <div
                      onClick={() => {
                        onSearchResultClick(airport.icao);
                        fullScreenContext?.close();
                      }}
                      css={{ cursor: "pointer" }}
                    >
                      {airport.name ?? "n/a"}
                    </div>
                  </Fragment>
                );
              })}
            </div>
            {isFetching && <p>Loading more airports...</p>}
            {endOfSearchResults && <p>End of search results.</p>}
          </>
        )
      }
    />
  );
};

import { localApi } from "store/reducers/localApi";
import { describeSignupBoxSessionQuery } from "logic/signup/offer/describe";
import { useRunOnceReady } from "utils/hooks";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";

export function useHandleDisplayCount() {
  const dispatch = useDispatch();
  const signupBoxData = localApi.endpoints.getSignupBox.useQuery();
  const signupBoxSessionDescription =
    describeSignupBoxSessionQuery(signupBoxData);
  const { getLastDisplayCount, getLastClosedAt } = signupBoxSessionDescription;

  useRunOnceReady(
    !!dispatch && !!getLastClosedAt && signupBoxData.isSuccess,
    () => {
      const lastClosedAt = getLastClosedAt();
      const lastDisplayCount = getLastDisplayCount();
      let incrementedDisplayCount = lastDisplayCount + 1;
      if (incrementedDisplayCount >= 3) {
        incrementedDisplayCount = 0;
      }
      const incrementedDisplayCountStr = incrementedDisplayCount.toString();
      dispatch(
        localApi.endpoints.setSignupBox.initiate({
          last_closed_at: lastClosedAt?.toISOString(),
          display_count: incrementedDisplayCountStr,
        })
      );
    }
  );
}

export function useShouldShow() {
  const signupBoxData = localApi.endpoints.getSignupBox.useQuery();
  const signupBoxSessionDescription =
    describeSignupBoxSessionQuery(signupBoxData);
  const { shouldShow } = signupBoxSessionDescription.withUserMeQuery(
    awdApi.endpoints.getUserMe.useQuery()
  );

  return shouldShow();
}

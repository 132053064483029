import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { useResolveOnceDefined } from "utils/hooks";
import { z } from "zod";

export type EditNameModalProps = {};

const zForm = z.object({
  first_name: z.string(),
  last_name: z.string(),
});
type Form = z.infer<typeof zForm>;

export const EditNameModal: FC<EditNameModalProps> = (props) => {
  const dispatch = useDispatch();
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;
  const userMePromise = useResolveOnceDefined(userMe);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      userMePromise.then((me) => ({
        first_name: me.first_name ?? "",
        last_name: me.last_name ?? "",
      })),
  });

  return (
    <ResultModal
      title="Edit name"
      onSubmit={async () => {
        const values = form.getValues();
        const result = await dispatch(
          awdApi.endpoints.patchUserMe.initiate(values)
        ).then(describeMutationResult);
        if (result.error) {
          dispatch(toastError(result.error, "We could not update your name."));
          return "cancel";
        } else {
          dispatch(toastMessage("success", "Your full name has been updated."));
          return;
        }
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <Stack gap="1rem">
          <FormTextField
            form={form}
            name="first_name"
            label="First name"
            fullWidth
            variant="outlined"
          />
          <FormTextField
            form={form}
            name="last_name"
            label="Last name"
            fullWidth
            variant="outlined"
          />
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

import { WeatherDashboardContext } from "components/Dashboard/context";
import { WeatherWidgetHighlights } from "components/widgetComponentLayouts/WeatherWidgetHighlights";
import { WeatherWidgetWind } from "components/widgetComponentLayouts/WeatherWidgetWind";
import { useAutoSelectFlightRules } from "logic/weather/dashboard/useAutoSelectFlightRules";
import { useAutoSelectRunway } from "logic/weather/dashboard/useAutoSelectRunway";
import { FC } from "react";
import { matchMap } from "utils/general";
import { z } from "zod";
import { WeatherWidgetTitle } from "components/widgetComponentLayouts/WeatherWidgetTitle";
import { WidgetWrapper } from "components/widget/WidgetWrapper";
import { WeatherWidgetCeiling } from "components/widgetComponentLayouts/WeatherWidgetCeiling";
import { useSynchronizeMetarAndTaf } from "logic/weather/dashboard/useSynchronizeMetarAndTaf";
import { WeatherWidgetForecast } from "components/widgetComponentLayouts/WeatherWidgetForecast";
import { WeatherWidgetDashboard } from "components/widgetComponentLayouts/WeatherWidgetDashboard";
import { WeatherWidgetMetar } from "components/widgetComponentLayouts/WeatherWidgetMetar";
import { WeatherWidgetTextual } from "components/widgetComponentLayouts/WeatherWidgetTextual";

export const zWidgetVariant = z.enum([
  "wind",
  "ceiling",
  "highlights",
  "currentWeather",
  "forecast",
  "dashboard",
  "title",
  "textualWeatherReport",
]);
export type WidgetVariant = z.infer<typeof zWidgetVariant>;

export const zWidgetHighlightsPreferredShape = z.enum(["squareish", "horizontal", "vertical"]);
export type WidgetHighlightsPreferredShape = z.infer<typeof zWidgetHighlightsPreferredShape>;

export const zWidgetTextualWeatherReportType = z.enum(["aw-metar", "aw-taf", "metar", "taf"]);
export type WidgetTextualWeatherReportType = z.infer<typeof zWidgetTextualWeatherReportType>;

export const zWidgetVariantConfigurationParams = z.object({
  highlights: z
    .object({
      preferredShape: zWidgetHighlightsPreferredShape.optional(),
    })
    .optional(),

  wind: z
    .object({
      runwayIdentifier: z.string().optional(),
    })
    .optional(),

  textualWeatherReport: z
    .object({
      type: zWidgetTextualWeatherReportType,
    })
    .optional(),
});

export type WidgetVariantConfigurationParams = z.infer<typeof zWidgetVariantConfigurationParams>;

export const zWidgetParameters = z.object({
  icao: z.string(),
  variant: zWidgetVariant,
  width: z.union([z.number(), z.literal("stretch")]).optional(),
  variantConfiguration: zWidgetVariantConfigurationParams.optional(),
});
export type WidgetParameters = z.infer<typeof zWidgetParameters>;

export type WidgetProps = WidgetParameters;

export const Widget: FC<WidgetProps> = (props) => {
  useSynchronizeMetarAndTaf(props.icao);
  useAutoSelectFlightRules(props.icao);
  useAutoSelectRunway(props.icao);

  const WidgetComp = matchMap(props.variant, {
    highlights: <WeatherWidgetHighlights {...props} />,
    wind: <WeatherWidgetWind {...props} />,
    ceiling: <WeatherWidgetCeiling />,
    currentWeather: <WeatherWidgetMetar />,
    forecast: <WeatherWidgetForecast />,
    dashboard: <WeatherWidgetDashboard />,
    title: <WeatherWidgetTitle />,
    textualWeatherReport: (
      <WeatherWidgetTextual type={props.variantConfiguration?.textualWeatherReport?.type} />
    ),
  });

  return (
    <WeatherDashboardContext.Provider value={{ icao: props.icao }}>
      <WidgetWrapper>{WidgetComp}</WidgetWrapper>
    </WeatherDashboardContext.Provider>
  );
};

import { describeError } from "logic/error";
import { bound } from "utils/class";
import { safeParse } from "utils/parse";
import { z } from "zod";

export function describeWeatherQueryError(error: unknown) {
  return bound({
    describeAsError() {
      return describeError(error);
    },

    getStatusProperty() {
      return safeParse(z.object({ status: z.string() }), error)?.status;
    },

    isFetchError() {
      return this.getStatusProperty() === "FETCH_ERROR";
    },

    formatTitle() {
      if (this.isFetchError()) return "Failed to connect to the server";

      return this.describeAsError().formatTitle();
    },
  });
}

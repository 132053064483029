import { describeAirportIcao } from "logic/airport/icao/describe";
import {
  MyAirportRoleRequestsResponse,
  MyAirportRolesResponse,
  UserRolesResponse,
} from "awd-server-api";
import { bound } from "utils/class";
import { isNonNullable } from "utils/general";

// getMyRoles
export function describeMyUserRoleQuery(query: {
  data?: UserRolesResponse;
  isFetching?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  error?: unknown;
}) {
  return bound({
    query,
    transform() {
      return describeMyRoles(query.data);
    },
  });
}

export function describeMyRoles(data?: UserRolesResponse) {
  return bound({
    myRoles: data?.roles,
    isPilotAccount() {
      return data?.roles.includes("pilot");
    },
    isSuperdemo() {
      return data?.roles.includes("superdemo") ?? false;
    },
    isSupervizor() {
      return data?.roles.includes("supervizor") ?? false;
    },
  });
}

// --------------

export type MyAirportRolesQuery = {
  isSuccess: boolean;
  isLoading: boolean;
  data?: MyAirportRolesResponse;
};

// getMyAirportRoles
export function describeMyAirportRolesQuery(query: MyAirportRolesQuery) {
  const roles = query.data?.roles;

  return bound({
    query,
    isLoading: query.isLoading,

    pickRole() {
      return roles?.[0];
    },

    getMyAirportIcao() {
      return roles?.[0]?.airport.icao;
    },

    isManagerOfCurrentAirport(currentIcao?: string) {
      return (
        currentIcao?.toUpperCase() === this.pickRole()?.airport.icao.toUpperCase() &&
        this.pickRole()?.role === "manager"
      );
    },

    managedAirport() {
      const role = this.pickRole();
      if (!role) return undefined;

      return bound({
        ...role.airport,
        getBannerUrl() {
          return describeAirportIcao(role.airport.icao).getBannerUrl();
        },
        hasSubscription() {
          return role.airport_subscription != null;
        },
        canUseWidget() {
          return this.hasSubscription();
        },
        canBeEdited() {
          return this.hasSubscription() && role.role === "manager";
        },
        latlong(): [number, number] {
          return [role.airport.latitude, role.airport.longitude];
        },
      });
    },
  });
}

// --------------

export type MyAirportRoleRequestsQuery = {
  data?: MyAirportRoleRequestsResponse;
};

// getMyAirportRoleRequests
export function describeMyAirportRoleRequestsQuery(query: MyAirportRoleRequestsQuery) {
  return bound({
    pickRequest() {
      return query.data?.requests[0];
    },
    showVerificationInProgress() {
      return this.pickRequest()?.status === "requested";
    },
    getMyRoleRequestAirportIds(): number[] {
      return query.data?.requests.map((req) => req.airport_id).filter(isNonNullable) ?? [];
    },
  });
}

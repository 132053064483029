import { Stack } from "components/layout/Stack";
import { FullScreenSearchContext } from "components/search/FullScreenSearch";
import { FC, ReactNode, useContext, useState } from "react";
import { usePopper } from "react-popper";
import styled from "@emotion/styled";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { setEndOfSearchResults } from "store/reducers/localStateSlice";
import { handleDebugMode } from "components/search/handleDebugMode";

export type SearchProps = {
  value: string;
  setValue: (value: string) => void;
  results: ReactNode;
  portal?: boolean;
  setOffset: (offset: number) => void;
};

export const Search: FC<SearchProps> = ({
  value,
  setValue,
  results,
  portal,
  setOffset,
}) => {
  const fullScreenContext = useContext(FullScreenSearchContext);
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const dispatch = useDispatch();

  const { styles, attributes } = usePopper(inputElement, popperElement, {
    strategy: portal ? "fixed" : "absolute",
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const resultsJsx = (
    <SResults
      style={fullScreenContext ? undefined : styles.popper}
      isFullScreenSearch={fullScreenContext != null}
      ref={setPopperElement}
      {...(fullScreenContext ? undefined : attributes.popper)}
      portal={portal != null}
      elementHeight={typeof results === "string"}
    >
      {results}
    </SResults>
  );

  return (
    <>
      <Stack horizontal gap="1rem">
        <form onSubmit={handleDebugMode(value)} css={{ width: "100%" }}>
          <input
            ref={setInputElement}
            placeholder="Search airports by ICAO or name"
            css={{
              border: fullScreenContext ? "1px solid #ddd" : 0,
              borderRadius: "0.25rem",
              padding: "1rem",
              width: "100%",

              // must have at least 16px so that iOS doesn't try zooming in
              fontSize: fullScreenContext ? "16px" : undefined,
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setOffset(0);
              dispatch(setEndOfSearchResults(false));
            }}
          />
        </form>

        {fullScreenContext && (
          <SCloseButton onClick={() => fullScreenContext.close()}>
            &times;
          </SCloseButton>
        )}
      </Stack>

      {results == null
        ? null
        : portal
        ? createPortal(resultsJsx, document.body)
        : resultsJsx}
    </>
  );
};

const SResults = styled.div<{
  isFullScreenSearch: boolean;
  portal: boolean | undefined;
  elementHeight: boolean;
}>`
  ${{ zIndex: 15000, overflowY: "scroll" }}
  ${(p) =>
    p.isFullScreenSearch
      ? {
          paddingTop: "1.5rem",
          height: "50vh",
        }
      : {
          padding: "0.5rem 0.5rem",
          backgroundColor: "white",
          borderRadius: "0.25rem",
          boxShadow: "var(--box-shadow-light)",
          height: p.elementHeight ? "auto" : "15rem",
        }}

  ::-webkit-scrollbar {
    width: 0.6rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #3993d0;
    border-radius: 1rem;
  }
`;

const SCloseButton = styled.div({
  fontSize: "2rem",
  lineHeight: "1rem",
  padding: "0.5rem",
  marginLeft: "-0.5rem",
  marginRight: "-0.5rem",
});

import { zPassword } from "logic/signup/password/describe";
import { z } from "zod";

export const zResetPasswordForm = z.object({
  email: z.string().email({
    message: "Please fill in a correct e-mail address.",
  }),
});

export type ResetPasswordForm = z.infer<typeof zResetPasswordForm>;

export const zSetNewPasswordForm = z
  .object({
    password: zPassword,
    password_again: z.string(),
  })
  .refine((data) => data.password === data.password_again, {
    message: "The new passwords have to match.",
    path: ["password_again"],
  });
export type SetNewPasswordForm = z.infer<typeof zSetNewPasswordForm>;

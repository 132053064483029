import spacetime from "spacetime";
import { FormEvent } from "react";

export function ident<T>(x: T): T {
  return x;
}

export function keysOf<TMap extends Record<string, unknown>>(
  map: TMap
): (keyof TMap)[] {
  return Object.keys(map);
}

export function entriesOf<TMap extends Record<string, unknown>>(
  map: TMap
): [keyof TMap, TMap[keyof TMap]][] {
  return Object.entries(map) as unknown as [keyof TMap, TMap[keyof TMap]][];
}

export function valuesOf<TMap extends Record<string, unknown>>(
  map: TMap
): TMap[keyof TMap][] {
  return Object.values(map) as unknown as TMap[keyof TMap][];
}

export function fromEntries<TKey extends string, TValue>(
  entries: [TKey, TValue][]
): Record<TKey, TValue> {
  return Object.fromEntries(entries) as Record<TKey, TValue>;
}

export class SpecBuilder<TSpec> {
  build<TKey extends string>(specs: Record<TKey, TSpec>) {
    return specs;
  }
}

export type QueryParams = {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | undefined
    | null;
};

function filterQuery(
  x: [
    string,
    string | string[] | number | number[] | boolean | undefined | null,
  ]
): x is [string, string | number | boolean] {
  return x[1] != null && x[1] !== "";
}

export const encodeQuery = (url: string, args: QueryParams = {}): string => {
  const params = Object.entries(args)
    .filter(filterQuery)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value?.toString?.())}`
    );

  if (params.length > 0) {
    const questionMarkIndex = url.split("").findIndex((c) => c === "?");
    const urlHasQuestionMark = questionMarkIndex >= 0;
    const joiningCharacter = urlHasQuestionMark ? "&" : "?";
    return [url, params.join("&")].join(joiningCharacter);
  }
  return url;
};

export function resolvablePromise<T>() {
  let resolve!: (val: T) => void;
  let reject!: (err: Error) => void;
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    resolve,
    reject,
    promise,
  };
}

export function safeParseJson(json: string) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return undefined;
  }
}

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function isKeyof<T extends Record<string, any>>(
  type: string,
  record: T
  // @ts-expect-error
): type is keyof T {
  return type in record;
}
export function matchMap<
  TKey extends string,
  TMap extends Record<TKey, unknown>,
>(key: TKey, map: TMap): TMap[keyof TMap] {
  return map[key];
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isIos() {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
}

interface Positioned {
  latitude: number;
  longitude: number;
}

export function getGps(airport: Positioned, fractionDigits: number): string {
  return (
    airport.latitude.toFixed(fractionDigits) +
    ", " +
    airport.longitude.toFixed(fractionDigits)
  );
}

export function getLocalTime(
  date: Date | string,
  time_format?: "24h" | "12h"
): string {
  // convert to date if string
  if (typeof date === "string") {
    date = new Date(date);
  }
  const is24Hour = time_format !== "12h";
  const timeFormat = is24Hour ? "time-24" : "time";
  const format = `{month-short} {date} {year}, {${timeFormat}}`;
  return spacetime(date).goto("").format(format);
}

export function stopOtherEvents(e: Event | FormEvent) {
  e.stopPropagation();
  e.preventDefault();
}

export function areStringsEqualIgnoreCase(str1: string, str2: string): boolean {
  return str1.toLowerCase() === str2.toLowerCase();
}

export const isTimeSliderAtMapDisabled: () => boolean = () => true;

import { Alert, AlertTitle, Link } from "@mui/material";
import { pageSpec } from "logic/navigation/spec";
import { describeMyAirportRoleRequestsQuery } from "logic/user/me/role/describe";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type AirportRoleStatusProps = {};

export const AirportRoleStatus: FC<AirportRoleStatusProps> = (props) => {
  const { showVerificationInProgress } = describeMyAirportRoleRequestsQuery(
    awdApi.endpoints.getMyAirportRoleRequests.useQuery()
  );

  return (
    <>
      {showVerificationInProgress?.() && (
        <Alert severity="info">
          <AlertTitle>Welcome to AirportWeather.com.</AlertTitle>
          <p>
            Before we activate your account, we need to verify you can represent the airport you
            have selected during the registration process.
          </p>
          <p>
            Please update your details in the{" "}
            <Link href={pageSpec.account.path}>Account Settings</Link> after verifying your email.
          </p>
          <p>We aim to contact you within 3 working days.</p>
        </Alert>
      )}
    </>
  );
};

import { describeMutationResult } from "logic/api/result/describe";
import { SetNewPasswordForm } from "logic/login/resetPassword/form";
import { pageSpec } from "logic/navigation/spec";
import { NavigateFunction } from "react-router-dom";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { createAsyncThunk } from "store/thunk";

export const onSetNewPasswordFormSubmitted = createAsyncThunk(
  "onSetNewPasswordFormSubmitted",
  async (
    args: {
      values: SetNewPasswordForm;
      navigate: NavigateFunction;
      token: string | null;
    },
    ctx
  ) => {
    const result = await ctx
      .dispatch(
        awdApi.endpoints.postSetNewPassword.initiate({
          password: args.values.password,
          token: args.token ?? "",
        })
      )
      .then(describeMutationResult);

    if (result.error) {
      ctx.dispatch(
        toastError(result.error, "We could not set the new password.")
      );
      return;
    }

    ctx.dispatch(toastMessage("success", "We have updated your password"));
    args.navigate(pageSpec.dashboard.path);
  }
);

import { ArticlePage } from "components/content/ArticlePage";
import { Meta } from "components/meta/Meta";
import { TermlyDocument } from "components/termlyDocument/TermlyDocument";
import { FC } from "react";

export type PrivacyPolicyPageProps = {};

export const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = (props) => {
  return (
    <ArticlePage>
      <Meta pageTitle="Privacy Policy" />
      <TermlyDocument type="privacy_policy" />
    </ArticlePage>
  );
};

import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, IconButton, Skeleton, Typography } from "@mui/material";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { describeUserMe } from "logic/user/me/describe";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type InsidePageHeaderProps = {
  showHamburger?: boolean;
  onHamburgerClick?: () => void;
};

export const InsidePageHeader: FC<InsidePageHeaderProps> = (props) => {
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;
  const { getInitials = undefined, getDisplayName = undefined } = userMe
    ? describeUserMe(userMe)
    : {};
  const role = useMyAirportRoles().pickRole();
  const pilotHomeAirport = useFavoriteAirports().homeAirport()?.airport;

  return (
    <Stack
      gap="0"
      horizontal
      css={{ justifyContent: "space-between", alignItems: "center" }}
    >
      {props.showHamburger ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.onHamburgerClick}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <div />
      )}
      <Stack
        horizontal
        gap="1rem"
        css={{ alignItems: "center" }}
        accurateSpacers
      >
        <Spacer horizontal size="2rem" />
        {getInitials ? (
          <Avatar css={{ fontSize: "0.875rem" }} sx={{ bgcolor: "#4893cf" }}>
            {getInitials()}
          </Avatar>
        ) : (
          <Skeleton variant="circular" width="40px" height="40px" />
        )}
        <Stack gap="0.125rem">
          {getDisplayName ? (
            <>
              <Typography variant="h5">{getDisplayName()}</Typography>
              <Typography
                variant="h5"
                sx={{ color: "#777", fontSize: "0.875rem" }}
              >
                {role?.role === "manager"
                  ? "Airport Manager"
                  : role?.role === "supervizor"
                  ? "Supervisor"
                  : pilotHomeAirport != null
                  ? "Pilot"
                  : "User"}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton width="10rem" height="1rem" />
              <Skeleton width="5rem" height="0.75rem" />
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

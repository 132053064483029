import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParseRunwayWithBetweenAngle } from "logic/weather/dashboard/runwaySelectionLogic";
import { awdApi } from "store/reducers/awdApi";
import { RootState } from "store/store";
import { bound } from "utils/class";

type LocalStateSliceType = {
  modal: { isOn: boolean; source: "taf" | "metar" | "runways" | undefined };
  flightRulesCode?: FlightRulesCode;
  toggledToAwMetarWeatherSource: boolean;
  toggledToAwTafWeatherSource: boolean;
  selectedRunway?: SelectRunwayType;
  mapCenter?: MapCenterType | undefined;
  isAirportMarkerOpen: boolean;
  userGeolocation: [number, number] | undefined;
  endOfSearchResults: boolean;
  headwindRunways?: HeadwindRunways;
  dummyRunwaySchema: any;
};

const initialState: LocalStateSliceType = {
  modal: {
    isOn: false,
    source: undefined,
  },
  toggledToAwMetarWeatherSource: true,
  toggledToAwTafWeatherSource: true,
  isAirportMarkerOpen: false,
  userGeolocation: undefined,
  endOfSearchResults: false,
  dummyRunwaySchema: {
    name: "Unsubscribed Airport",
    angleWind: 200,
    icao: "UNSA",
    localTime: "Jan 11 at 11:11 UNSA",
    runways: [
      {
        leHeading: 65,
        leIdent: "06",
        leLongitude: 14.226300239562988,
        leLatitude: 50.10179901123047,
        leElevation: 1202,
        heHeading: 245,
        heIdent: "24",
        heLongitude: 14.27340030670166,
        heLatitude: 50.11600112915039,
        closed: false,
      },
      {
        leHeading: 127,
        leIdent: "12",
        leLongitude: 14.245400428771973,
        leLatitude: 50.108001708984375,
        leElevation: 1160,
        heHeading: 307,
        heIdent: "30",
        heLongitude: 14.281700134277344,
        heLatitude: 50.09049987792969,
        closed: false,
      },
    ],
    selectedRunwayName: "30",
    speedWind: { unit: "m/s", value: 11 },
  },
};

export const localStateSlice = createSlice({
  name: "localStateSlice",
  initialState,
  reducers: {
    toggleWeatherSourceAction: (st, act: PayloadAction<ToggleSwitchType>) => {
      if (act.payload.source === "metar") st.toggledToAwMetarWeatherSource = act.payload.value;
      else st.toggledToAwTafWeatherSource = act.payload.value;
    },
    toggleModalAction: (
      st,
      act: PayloadAction<{ isOn: boolean; source: "taf" | "metar" | "runways" | undefined }>
    ) => {
      st.modal = act.payload;
    },
    setFlightRulesCode: (st, act: PayloadAction<FlightRulesCode>) => {
      st.flightRulesCode = act.payload;
    },
    setRunway: (st, act: PayloadAction<SelectRunwayType>) => {
      st.selectedRunway = act.payload;
    },
    setHeadwindRunways: (st, act: PayloadAction<HeadwindRunways>) => {
      st.headwindRunways = act.payload;
    },
    setCenterPoint: (st, act: PayloadAction<MapCenterType | undefined>) => {
      st.mapCenter = act.payload;
    },
    openAirportMarker: (st) => {
      st.isAirportMarkerOpen = true;
    },
    closeAirportMarker: (st) => {
      st.isAirportMarkerOpen = false;
    },
    setUserGeolocation: (st, act: PayloadAction<[number, number]>) => {
      st.userGeolocation = act.payload;
    },
    setEndOfSearchResults: (st, act: PayloadAction<boolean>) => {
      st.endOfSearchResults = act.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(awdApi.endpoints.searchAirport.matchFulfilled, (state, { payload }) => {
      if (payload.airports.length < 10) {
        state.endOfSearchResults = true;
      }
    });
  },
});

export function selectRunway(airportIcao: string, runway: RunwayType) {
  return localStateSlice.actions.setRunway({ ...runway, airportIcao });
}

export function selectHeadwindRunways(airportIcao: string, runways: ParseRunwayWithBetweenAngle[]) {
  return localStateSlice.actions.setHeadwindRunways({ runways, airportIcao });
}

export function toggleModal(isOn: boolean, source: "taf" | "metar" | "runways") {
  return localStateSlice.actions.toggleModalAction({ isOn, source });
}

export function toggleWeatherSource(value: boolean, source: "taf" | "metar") {
  return localStateSlice.actions.toggleWeatherSourceAction({ value, source });
}

export const getDummyRunwaySchema = (state: RootState) => {
  return state.localState.dummyRunwaySchema;
};

const selModalIsOn = (state: RootState) => state.localState.modal.isOn;

export const selIfModalIsOn = createSelector([selModalIsOn], (isOn) => {
  return bound({
    isOn,
    shouldShowModal(icao?: string) {
      return isOn;
    },
  });
});

export const {
  setFlightRulesCode,
  setCenterPoint,
  openAirportMarker,
  closeAirportMarker,
  setUserGeolocation,
  setEndOfSearchResults,
} = localStateSlice.actions;

export type ToggleSwitchType = {
  value: boolean;
  source: "taf" | "metar";
};

export type SelectRunwayType = {
  name: string;
  elevation: number | null | undefined;
  closed: boolean | null | undefined;
  airportIcao: string;
};

export type RunwayType = {
  name: string;
  elevation: number | null | undefined;
  closed: boolean | null | undefined;
};

export type MapCenterType = [number, number];

export type HeadwindRunways = {
  runways: ParseRunwayWithBetweenAngle[];
  airportIcao: string;
};

export type FlightRulesCode = "vfr" | "mvfr" | "ifr" | "lifr" | "default";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useRunOnceReady } from "utils/hooks";
import { toggleModal } from "store/reducers/localStateSlice";

export function useOpenExplanationModalIfParamIsSet() {
  const dispatch = useDispatch();
  const location = useLocation();

  const possibleParams = ["metar", "taf"] as const;

  // Extract the query parameter 'modal' from the location search
  const queryParams = new URLSearchParams(location.search);
  const modalParam = queryParams.get("modal");
  const safeModalParam = possibleParams.find((p) => p === modalParam);

  useRunOnceReady(!!safeModalParam, () => {
    if (safeModalParam) {
      dispatch(toggleModal(true, safeModalParam));
    }
  });
}

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { awdApi } from "store/reducers/awdApi";
import { RootState } from "store/store";
import { bound } from "utils/class";
import { areStringsEqualIgnoreCase } from "utils/general";

export type AirportSubscriptionStatusType =
  | { hasSubscription: false; airportIcao?: string; hasClosedModal?: boolean }
  | { hasSubscription: true; airportIcao: string };

type AccessLimitSliceState = {
  airportAccessLimit:
    | {
        isOverLimit: false;
      }
    | {
        isOverLimit: true;
        airportIcao: string;
        hasClosedModal: boolean;
      };
  airportSubscriptionStatus: AirportSubscriptionStatusType;
};

const initialState: AccessLimitSliceState = {
  airportAccessLimit: {
    isOverLimit: false,
  },
  airportSubscriptionStatus: { hasSubscription: false },
};

export const accessLimitSlice = createSlice({
  name: "accessLimit",
  initialState,
  reducers: {
    closedAirportAccessLimitModal: (st) => {
      if (st.airportAccessLimit.isOverLimit) {
        st.airportAccessLimit.hasClosedModal = true;
      }
    },
    closedAirportSubscriptionStatusModal: (st) => {
      if (st.airportSubscriptionStatus.hasSubscription === false) {
        st.airportSubscriptionStatus.hasClosedModal = true;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(awdApi.endpoints.getAirportWeather.matchFulfilled, (st, act) => {
        const isOverAccessLimit = act.payload.isOverAccessLimit;
        // const isOverAccessLimit = true;
        const airportIcao = act.payload.icao;

        if (isOverAccessLimit && airportIcao) {
          st.airportAccessLimit = {
            isOverLimit: isOverAccessLimit,
            airportIcao,
            hasClosedModal: false,
          };
        }
      })
      .addMatcher(awdApi.endpoints.getAirportSubscriptionStatus.matchFulfilled, (st, act) => {
        const airportIcao = act.payload.icao;
        const hasSubscription = act.payload.hasSubscription;

        if (hasSubscription && airportIcao) {
          st.airportSubscriptionStatus = {
            hasSubscription,
            airportIcao,
          };
        }

        if (hasSubscription != null && airportIcao) {
          st.airportSubscriptionStatus = {
            hasSubscription,
            airportIcao,
            hasClosedModal: false,
          };
        }
      }),
});

const selectLimit = (st: RootState) => st.accessLimit.airportAccessLimit;

export const selAirportAccessLimit = createSelector([selectLimit], (limit) => {
  return bound({
    limit,
    isOverLimitForAirport(icao?: string) {
      if (!icao) return false;
      return limit.isOverLimit && areStringsEqualIgnoreCase(limit.airportIcao, icao);
    },
    shouldShowAccessLimitModal(detailPageIcao?: string) {
      return this.isOverLimitForAirport(detailPageIcao) && limit.isOverLimit;
    },
    shouldShowModal(detailPageIcao?: string) {
      return this.isOverLimitForAirport(detailPageIcao) && limit.isOverLimit;
    },
  });
});

// export type Selector<S> = (state: RootState) => S;

export const selectStatus = (st: RootState) => st.accessLimit.airportSubscriptionStatus;

export const selAirportSubscriptionStatus = createSelector([selectStatus], (status) => {
  return bound({
    status,
    hasSubscription(icao?: string) {
      if (!icao) return undefined;
      return status.hasSubscription && areStringsEqualIgnoreCase(status.airportIcao, icao);
    },
    shouldShowModal(icao?: string) {
      return !this.hasSubscription(icao);
    },
  });
});

import { Typography } from "@mui/material";
import { AirportPropertyView } from "components/airportPropertyView/AirportPropertyView";
import { Spacer } from "components/layout/Spacer";
import { QueryResult } from "components/queryResult/QueryResult";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { Meta } from "components/meta/Meta";

export type AirportAboutSubpageProps = {};

export const AirportAboutSubpage: FC<AirportAboutSubpageProps> = () => {
  const icao = String(useParams().icao);
  const airportQuery = awdApi.endpoints.getAirport.useQuery({
    airportId: icao,
  });
  const runwaysQuery = awdApi.endpoints.getAirportRunways.useQuery({
    airportId: icao,
  });

  const airport = airportQuery.data;
  const runways = runwaysQuery.data;

  return (
    <div css={{ padding: "3rem", paddingTop: 0 }}>
      <Meta pageTitle={`${icao.toUpperCase()} About Airport`} />
      <QueryResult query={[airportQuery, runwaysQuery]}>
        <Typography variant="h2">{airport?.name}</Typography>
        <Spacer size="2rem" />
        {airport && runways && (
          <AirportPropertyView
            airport={airport}
            runways={runways}
            editability="hidden"
          />
        )}
      </QueryResult>
    </div>
  );
};

import { createClient, Entry, EntryCollection } from "contentful";
import {
  CONTENT_TYPE,
  ICustomerSupportPageFields,
  IHomepageArticleFields,
  IModalFields,
  IRegisterPilotPageFields,
  ISignupOfferBoxFields,
  ISignupPageFields,
} from "types/generated/contentful";

const HOMEPAGE_ARTICLE_ID = process.env.REACT_APP_CONTENTFUL_HOMEPAGE_ARTICLE_ID as string;
const METAR_MODAL_ID = process.env.REACT_APP_CONTENTFUL_METAR_MODAL_ID as string;
const RUNWAYS_MODAL_ID = process.env.REACT_APP_CONTENTFUL_RUNWAYS_MODAL_ID as string;
const TAF_MODAL_ID = process.env.REACT_APP_CONTENTFUL_TAF_MODAL_ID as string;
const ACCESS_LIMIT_MODAL_ID = process.env.REACT_APP_CONTENTFUL_ACCESS_LIMIT_MODAL_ID as string;
const REGISTER_PILOT_PAGE_ID = process.env.REACT_APP_CONTENTFUL_REGISTER_PILOT_PAGE_ID as string;
const CUSTOMER_SUPPORT_PAGE_ID = process.env
  .REACT_APP_CONTENTFUL_CUSTOMER_SUPPORT_PAGE_ID as string;
const SIGNUP_PAGE_ID = process.env.REACT_APP_CONTENTFUL_SIGNUP_PAGE_ID as string;
const SIGNUP_OFFER_BOX_ID = process.env.REACT_APP_CONTENTFUL_SIGNUP_OFFER_BOX_ID as string;
const RUNWAYS_SCHEMA_NON_SUBSCRIBED_AIRPORT = process.env
  .REACT_APP_CONTENTFUL_RUNWAYS_SCHEMA_NON_SUBSCRIBED_AIRPORT as string;
const NOT_ALL_RUNWAYS_DATA_FILLED_IN = process.env
  .REACT_APP_CONTENTFUL_NOT_ALL_RUNWAYS_DATA_FILLED_IN as string;
const NOT_ALL_RUNWAYS_DATA_FILLED_IN_MANAGER = process.env
  .REACT_APP_CONTENTFUL_NOT_ALL_RUNWAYS_DATA_FILLED_IN_MANAGER as string;
const RUNWAYS_DATA_NOT_CORRECT = process.env
  .REACT_APP_CONTENTFUL_RUNWAYS_DATA_NOT_CORRECT as string;
const RUNWAYS_DATA_NOT_CORRECT_MANAGER = process.env
  .REACT_APP_CONTENTFUL_RUNWAYS_DATA_NOT_CORRECT_MANAGER as string;
const RUNWAYS_NOT_FULLY_SUBSCRIBED_MANAGER = process.env
  .REACT_APP_CONTENTFUL_RUNWAYS_NOT_FULLY_SUBSCRIBED_MANAGER as string;
const RUNWAYS_OR_AIRPORT_CLOSED_MANAGER = process.env
  .REACT_APP_CONTENTFUL_RUNWAYS_OR_AIRPORT_CLOSED_MANAGER as string;

export const contentfulClientFactory = () => {
  const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const accessToken = process.env.REACT_APP_CONTENTFUL_API_ACCESS_TOKEN;
  if (!space || !accessToken) throw new Error("Missing Contentful Space ID or Access Token");

  return createClient({ space, accessToken });
};

export type ContentfulResponse<T> = {
  status: "ok" | "error";
  data: T | null;
  errorReason?: string;
};

export type GetContentfulEntry<T> = () => Promise<ContentfulResponse<T>>;
type GetContentfulEntryWithId<T> = (id: string) => ReturnType<GetContentfulEntry<T>>;

export const getHomepageArticle: GetContentfulEntry<IHomepageArticleFields> = async () => {
  const contentfulClient = contentfulClientFactory();
  let entries: EntryCollection<IHomepageArticleFields>;
  try {
    entries = await contentfulClient.getEntries<IHomepageArticleFields>({
      content_type: "homepageArticle",
    });
  } catch (err) {
    return { status: "error" as const, errorReason: "unknown", data: null };
  }
  const itemWithId = entries.items.find((item) => item.sys.id === HOMEPAGE_ARTICLE_ID);
  const item = itemWithId || entries.items[0];

  if (!item) {
    return { status: "error" as const, errorReason: "unknown", data: null };
  }
  return {
    status: "ok" as const,
    data: item.fields,
  };
};

const getData = async <T>(
  contentType: CONTENT_TYPE,
  itemId: string
): Promise<ContentfulResponse<T>> => {
  const contentfulClient = contentfulClientFactory();
  let entries: EntryCollection<T>;
  try {
    entries = await contentfulClient.getEntries<T>({
      content_type: contentType,
    });
  } catch (e) {
    return { status: "error" as const, errorReason: "unknown", data: null };
  }
  const itemWithId = itemId ? entries.items.find((item) => item.sys.id === itemId) : undefined;
  const item = itemWithId || entries.items[0];
  if (!item) {
    return { status: "error" as const, errorReason: "No entry", data: null };
  }

  return {
    status: "ok" as const,
    data: item.fields,
  };
};

export const getSignupOfferBoxData: GetContentfulEntry<ISignupOfferBoxFields> = async () => {
  return getData<ISignupOfferBoxFields>("signupOfferBox", SIGNUP_OFFER_BOX_ID);
};

export const getSignupPageData: GetContentfulEntry<ISignupPageFields> = async () => {
  return getData<ISignupPageFields>("signupPage", SIGNUP_PAGE_ID);
};

export const getRegisterAsPilotPageData: GetContentfulEntry<
  IRegisterPilotPageFields
> = async () => {
  return getData<IRegisterPilotPageFields>("registerPilotPage", REGISTER_PILOT_PAGE_ID);
};

export const getCustomerSupportPageData: GetContentfulEntry<
  ICustomerSupportPageFields
> = async () => {
  return getData<ICustomerSupportPageFields>("customerSupportPage", CUSTOMER_SUPPORT_PAGE_ID);
};

const getModalContent: GetContentfulEntryWithId<IModalFields> = async (id) => {
  const contentfulClient = contentfulClientFactory();
  let entry: Entry<IModalFields>;
  try {
    entry = await contentfulClient.getEntry<IModalFields>(id);
  } catch (e) {
    return { status: "error" as const, errorReason: "unknown", data: null };
  }

  if (!entry) {
    return { status: "error" as const, errorReason: "No entry", data: null };
  }

  return {
    status: "ok" as const,
    data: entry.fields,
  };
};

export const getTafExplanationModalContent: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(TAF_MODAL_ID);
};

export const getMetarExplanationModalContent: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(METAR_MODAL_ID);
};

export const getRunwaysListModalContent: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_MODAL_ID);
};

export const getAccessLimitModalContent: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(ACCESS_LIMIT_MODAL_ID);
};

export const getRunwaysSchemaNonSubscribedAirport: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_SCHEMA_NON_SUBSCRIBED_AIRPORT);
};

export const getNotAllRunwaysDataFilledIn: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(NOT_ALL_RUNWAYS_DATA_FILLED_IN);
};

export const getNotAllRunwaysDataFilledInManager: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(NOT_ALL_RUNWAYS_DATA_FILLED_IN_MANAGER);
};

export const getRunwaysDataNotCorrect: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_DATA_NOT_CORRECT);
};

export const getRunwaysDataNotCorrectManager: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_DATA_NOT_CORRECT_MANAGER);
};

export const getRunwaysNotFullySubscribedManager: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_NOT_FULLY_SUBSCRIBED_MANAGER);
};

export const getRunwaysOrAirportClosedManager: GetContentfulEntry<IModalFields> = async () => {
  return getModalContent(RUNWAYS_OR_AIRPORT_CLOSED_MANAGER);
};

import { AirportSpecification } from "awd-server-api";
import { bound } from "utils/class";

export function describeAirportSpecification(
  specification: AirportSpecification
) {
  return bound({
    specification,
    asId() {
      return specification.specified_by === "id" ? specification.id : undefined;
    },
  });
}

import { WeatherDashboardContext } from "components/Dashboard/context";
import Map from "pages/weather-dashboards/Map";
import { FC, useContext } from "react";

export type OverlayType = "clouds" | "crr-pc" | "rdt";

export type KioskMapSlideProps = {
  overlay: OverlayType;
};

export const KioskMapSlide: FC<KioskMapSlideProps> = (props) => {
  const icao = useContext(WeatherDashboardContext)?.icao;
  if (icao == null) return null;

  return <Map key={props.overlay} icao={icao} overlay={props.overlay} />;
};

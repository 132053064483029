import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Footer } from "components/footer/Footer";
import { InsideNavigationDrawer } from "components/layout/page/inside/InsideNavigationDrawer";
import { InsidePageHeader } from "components/layout/page/inside/InsidePageHeader";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { pageSpec } from "logic/navigation/spec";
import { describeUserMeQuery } from "logic/user/me/describe";
import { FC, PropsWithChildren, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { encodeQuery } from "utils/general";
import { useRunOnceReady } from "utils/hooks";
import useWindowDimensions from "utils/useWindowDimensions";

export type InsideLayoutProps = PropsWithChildren<{ fullWidth?: boolean }>;

const drawerWidth = "15rem";

export const InsidePageLayout: FC<InsideLayoutProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const { width } = useWindowDimensions();
  const showPermanentNavigation = width > 780;

  useRunOnceReady(true, async () => {
    const { isLoggedIn } = await dispatch(awdApi.endpoints.getUserMe.initiate())
      .unwrap()
      .then((data) => ({ data }))
      .catch((error) => ({ error }))
      .then(describeUserMeQuery);

    if (!isLoggedIn()) {
      navigate(encodeQuery(pageSpec.login.path, { next: pathname }), {
        replace: true,
      });
    }
  });

  return (
    <SRoot showPermanentNavigation={showPermanentNavigation} gap="0">
      <InsideNavigationDrawer
        open={open || showPermanentNavigation}
        width={drawerWidth}
        showCloseButton={!showPermanentNavigation}
        onCloseButtonClick={() => setOpen(false)}
      />
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexGrow: 1,
          padding: "2rem",
        }}
      >
        <InsidePageHeader
          showHamburger={!showPermanentNavigation}
          onHamburgerClick={() => setOpen((open) => !open)}
        />
        <Spacer size="2rem" />
        <SColumn css={props.fullWidth ? { width: "100%" } : { width: "50rem" }}>
          {props.children}
        </SColumn>
        <div css={{ flexGrow: 1, minHeight: "4rem" }} />
        <Footer />
      </Box>
    </SRoot>
  );
};

const SRoot = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "showPermanentNavigation" && prop !== "open",
})<{ showPermanentNavigation: boolean }>(({ showPermanentNavigation }) => ({
  paddingLeft: showPermanentNavigation ? drawerWidth : 0,
  minHeight: "100vh",
  width: "100vw",
  maxWidth: "100vw",
  overflowX: "hidden",
}));

const SColumn = styled.div({
  alignSelf: "center",
  maxWidth: "100%",
});

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { z } from "zod";

export function describeQueryResult<
  T extends { error?: SerializedError | FetchBaseQueryError | string },
>(result: T) {
  return {
    ...result,
    apiError:
      result.error != null && typeof result.error !== "string"
        ? describeQueryResultError(result.error).apiError
        : undefined,
  };
}

export function describeQueryResultError<
  T extends SerializedError | FetchBaseQueryError,
>(result: T) {
  function getApiError() {
    const parsed = z
      .object({
        status: z.number().nullish(),
        data: z
          .object({
            error: z.string().nullish(),
          })
          .nullish(),
      })
      .safeParse(result);

    return parsed.success
      ? {
          httpStatus: parsed.data.status,
          ...parsed.data.data,
        }
      : undefined;
  }
  let _apiError: ReturnType<typeof getApiError> | null = null;
  return {
    ...result,
    get apiError() {
      if (_apiError !== null) return _apiError;
      _apiError = getApiError();
      return _apiError;
    },
  };
}

export function describeMutationResult<
  TData,
  TError extends SerializedError | FetchBaseQueryError,
>(result: { data: TData } | { error: TError }) {
  if ("error" in result) {
    const { apiError } = describeQueryResultError(result.error);
    return {
      error: result.error,
      apiError,
    };
  } else {
    const empty = {} as { error?: undefined; apiError?: undefined };
    return { ...empty, data: result.data };
  }
}

import { registerServiceWorker } from "serviceWorkerRegistration";
import { createAsyncThunk } from "store/thunk";

export const onKioskPageMounted = createAsyncThunk(
  "onKioskPageMounted",
  async (args: { icao: string }, ctx) => {
    registerServiceWorker({
      onSuccess() {},
      onUpdate() {},
    });

    return { result: "success" };
  }
);

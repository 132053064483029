import { AirportWebcam } from "components/airportWebcam/AirportWebcam";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Meta } from "components/meta/Meta";
import styled from "@emotion/styled";
import { responsive } from "mixins";

export type AirportWebcamSubpageProps = {};

const SWebcamWrapper = styled.div`
  padding: 0 3rem 3rem;
  ${responsive.tablet} {
    margin: auto;
    padding: 0;
  }
`;

export const AirportWebcamSubpage: FC<AirportWebcamSubpageProps> = () => {
  const icao = String(useParams().icao);

  return (
    <>
      <Meta pageTitle={`${icao.toUpperCase()} Webcam`} />
      <SWebcamWrapper>
        <AirportWebcam airportId={icao} />
      </SWebcamWrapper>
    </>
  );
};

import { Article } from "components/content/Article";
import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { FC, PropsWithChildren } from "react";

export type ArticlePageProps = PropsWithChildren<{}>;

export const ArticlePage: FC<ArticlePageProps> = (props) => {
  return (
    <OutsidePageLayout>
      <Article
        css={{
          width: "100vw",
          overflowX: "hidden",
        }}
      >
        {props.children}
      </Article>
    </OutsidePageLayout>
  );
};

import { Typography } from "@mui/material";
import { Code } from "components/code/Code";
import { Stack } from "components/layout/Stack";
import { WidgetParameters } from "components/widget/Widget";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";

export type WidgetCodeProps = {
  widgetParams: WidgetParameters;
};

export const WidgetCode: FC<WidgetCodeProps> = (props) => {
  const airport = useMyAirportRoles().managedAirport();

  const noSubscriptionMessage =
    "To be able to add a widget to your page, you need to start your subscription.";

  return (
    <Stack gap="1rem">
      <Typography>
        The widget can be added to your page by including the{" "}
        <strong>Loader script</strong> and 1 or more widget placement scripts
        into the source code of your website.
      </Typography>

      <Typography>
        <Stack gap="0.5rem">
          <div>
            <strong>Loader script</strong>
          </div>
          <Code
            content={
              airport?.canUseWidget()
                ? `<script id="airportweather-widget-loadscript" crossorigin="anonymous" async defer src="https://airportweather.com/widget/load.js"></script>`
                : noSubscriptionMessage
            }
          />
          <div>
            Place this code anywhere into the HTML source code of your website.
            It is responsible for loading the widget.
          </div>
          <div>
            Once the script is loaded, it looks for all widget placement scripts
            (see below) and renders the widget into them based on the settings.
          </div>
        </Stack>
      </Typography>

      <Typography>
        <Stack gap="0.5rem">
          <strong>Widget placement script</strong>
          <div>
            After you have added the loader script to your website, it is time
            to add <strong>1 or more</strong> widget placement scripts.
          </div>
          <div>
            Configure the widget settings to your liking and then add this
            script to the part of the source code where you want the widget to
            be shown.
          </div>
          <Code
            content={
              airport?.canUseWidget()
                ? `<script type="application/json" data-airportweather-widget>
${JSON.stringify(props.widgetParams, null, 3)}
</script>`
                : noSubscriptionMessage
            }
          />
        </Stack>
      </Typography>
    </Stack>
  );
};

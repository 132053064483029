import { localApi } from "store/reducers/localApi";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useDispatch } from "store/hooks";

export const SignupOfferCloseButton = () => {
  const dispatch = useDispatch();

  return (
    <div
      css={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: "0.5rem",
        cursor: "pointer",
      }}
      onClick={async () => {
        await dispatch(
          localApi.endpoints.setSignupBox.initiate({
            display_count: "-3",
            last_closed_at: new Date().toISOString(),
          })
        );
      }}
    >
      <CloseIcon />
    </div>
  );
};

import { ArticlePage } from "components/content/ArticlePage";
import CustomerSupportDocument from "components/content/CustomerSupportDocument.mdx";
import { Meta } from "components/meta/Meta";
import { FC } from "react";
import { getCustomerSupportPageData } from "utils/contentful";
import { useContentfulData } from "utils/useContentfulData";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export type CustomerSupportPageProps = {};

export const CustomerSupportPage: FC<CustomerSupportPageProps> = (props) => {
  const customerSupportContent = useContentfulData(getCustomerSupportPageData);

  return (
    <ArticlePage>
      <Meta pageTitle="Customer support" />
      {customerSupportContent?.customerSupportPageContent ? (
        documentToReactComponents(customerSupportContent.customerSupportPageContent)
      ) : (
        <CustomerSupportDocument />
      )}
    </ArticlePage>
  );
};

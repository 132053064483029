import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Meta } from "components/meta/Meta";
import { ConfirmDeleteAccountModal } from "components/modal/inside/confirm/ConfirmDeleteAccountModal";
import { EditEmailModal } from "components/modal/inside/edit/EditEmailModal";
import { EditNameModal } from "components/modal/inside/edit/EditNameModal";
import { EditPasswordModal } from "components/modal/inside/edit/EditPasswordModal";
import { EditPhoneNumberModal } from "components/modal/inside/edit/EditPhoneNumberModal";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { SettingsGroup } from "components/settings/group/SettingsGroup";
import { SettingsItem } from "components/settings/item/SettingsItem";
import { describeUserMe } from "logic/user/me/describe";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type AccountPageProps = {};

export const AccountPage: FC<AccountPageProps> = (props) => {
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;

  const { show } = useModalContext();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Account" />

      <InsidePageTitle>Account</InsidePageTitle>

      <Spacer size="1.5rem" />

      <SettingsGroup title="About">
        <SettingsItem
          title="E-mail address"
          onClick={() => show(<EditEmailModal />)}
        >
          {userMe?.email}
        </SettingsItem>
        <SettingsItem title="Full name" onClick={() => show(<EditNameModal />)}>
          {(userMe && describeUserMe(userMe).getFullName()) || "n/a"}
        </SettingsItem>
        <SettingsItem
          title="Password"
          onClick={() => show(<EditPasswordModal />)}
        >
          ****
        </SettingsItem>
        <SettingsItem
          title="Phone number"
          onClick={() => show(<EditPhoneNumberModal />)}
        >
          {userMe?.phone_number ?? "n/a"}
        </SettingsItem>
      </SettingsGroup>

      <Spacer size="2rem" />

      <SettingsGroup title="Actions">
        <SettingsItem
          title="I want to delete my account"
          button="delete"
          onClick={() => show(<ConfirmDeleteAccountModal />)}
        />
      </SettingsGroup>
    </InsidePageLayout>
  );
};

import React, { FC, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import IconArrowDown from "assets/icons/IconArrowDown.svg";
import { Button } from "components/button/Button";
import LandingMap from "components/LandingPage/LandingMap";
import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { SignupOfferBox } from "components/signupOfferBox/SignupOfferBox";
import { animateScroll as scroll, scroller } from "react-scroll";
import { useInView } from "react-intersection-observer";
import { usePreventGestureZoomingOnIos } from "logic/zooming/usePreventGestureZooming";
import { ContentfulArticle } from "components/content/ContentfulArticle";

export type HomepageProps = {};
export type ScrollDownIconProps = {};

const ScrollDownIcon: FC<ScrollDownIconProps> = () => {
  return (
    <Button
      css={{
        backgroundColor: "#f6f7f7",
        border: "1px solid #e1e1e8",
        boxShadow: "#62757f 0px 0px 4px 0px",
        "&:hover": {
          background: "#009ff5",
          border: "1px solid #62757f9e",
        },
      }}
      onClick={(e) => {
        if (e.target instanceof HTMLElement) {
          e.target.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      <img
        src={IconArrowDown}
        alt="scroll down"
        height="40"
        // width="20"
        css={{ padding: "0px" }}
      />
    </Button>
  );
};

export const Homepage: FC<HomepageProps> = () => {
  const refHeading = useRef();

  usePreventGestureZoomingOnIos();

  const {
    ref: inViewMap,
    inView: isMapInView,
    entry: entryMap,
  } = useInView({
    threshold: 0.1,
  });
  const {
    ref: inViewLogo,
    inView: isLogoInView,
    entry: entryLogo,
  } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (entryMap?.isIntersecting) {
      scroll.scrollToTop();
    }
  }, [isMapInView]);

  useEffect(() => {
    if (!entryLogo) return;
    if (!entryLogo.isIntersecting) {
      scroller.scrollTo("scrollToArticle", {
        smooth: "easeIn",
      });
    }
  }, [isLogoInView, refHeading]);

  return (
    <OutsidePageLayout noHeader>
      <SHomepageWrapper>
        <SignupOfferBox />
        <LandingMap inViewMap={inViewMap} inViewLogo={inViewLogo} />
        <SScrollDown>
          <ScrollDownIcon />
        </SScrollDown>
        <ContentfulArticle />
      </SHomepageWrapper>
    </OutsidePageLayout>
  );
};

const SHomepageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: calc(100vh - 6rem) 2rem 1fr;
  grid-template-columns: 1fr;
  position: relative;
`;

const SScrollDown = styled.div`
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  justify-self: center;
  align-self: end;
  z-index: 4000;
  position: absolute;
`;

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

import premiumWind from "assets/images/premium-winds-aloft.png";

import { useDashboardAirport } from "logic/weather/hooks";
import { Meta } from "components/meta/Meta";

const Wind = () => {
  const { icao } = useParams();
  const airport = useDashboardAirport(icao).airport;
  const navigate = useNavigate();

  useEffect(() => {
    if (airport === null) {
      navigate(`/${icao}`);
    }
  }, []);

  return (
    <StyledWind>
      <Meta pageTitle={`${icao.toUpperCase()} Wind`} />
      <div>
        <img src={premiumWind} alt="" />
      </div>
    </StyledWind>
  );
};

const StyledWind = styled.div`
  margin: 100px auto;
  img {
    max-width: 100%;
  }
`;

export default Wind;

import { ArticlePage } from "components/content/ArticlePage";
import { Meta } from "components/meta/Meta";
import { TermlyDocument } from "components/termlyDocument/TermlyDocument";
import { FC } from "react";

export type CookiePolicyPageProps = {};

export const CookiePolicyPage: FC<CookiePolicyPageProps> = (props) => {
  return (
    <ArticlePage>
      <Meta pageTitle="Cookie Policy" />
      <TermlyDocument type="cookie_policy" />
    </ArticlePage>
  );
};

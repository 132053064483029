import styled from "@emotion/styled";
import { FC, Ref, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserGeolocation } from "store/reducers/localStateSlice";
import { RootState } from "store/store";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { LayersControl, MapContainer, TileLayer } from "react-leaflet";

import { awdApi } from "store/reducers/awdApi";
import markerIconRetina from "assets/images/marker-2x.svg";
import { responsive } from "mixins";
import ExternalControls from "./ExternalControls";
import HoveredPolygon from "./HoveredPolygon";
import { useGetWeatherTilesUrl } from "logic/weather/tiles";
import { useMyHomeAirportLatLong } from "logic/user/me/hooks";
import Loader from "components/loader/Loader";
import AirportPopups from "./AirportPopups";
import { LegendWithIcon } from "components/Map/Legend";
import { OverlayType } from "components/kiosk/slides/KioskMapSlide";
import { currentDateTime } from "utils/currentDateTime";

// @ts-expect-error
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  // iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconRetinaUrl: markerIconRetina,
  // iconUrl: require("leaflet/dist/images/marker-icon.png"),
  // iconUrl: markerIcon,
  // shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: new L.Point(40, 54),
  iconAnchor: [20, 54],
});

const { Overlay } = LayersControl;

export type LandingPageMap = {
  inViewMap: Ref<HTMLDivElement>;
  inViewLogo: Ref<HTMLDivElement>;
};

const CENTER_INITIAL: [number, number] = [25, 18];

const LandingMap: FC<LandingPageMap> = ({ inViewMap, inViewLogo }) => {
  const dispatch = useDispatch();

  const [map, setMap] = useState<L.Map>();

  const [weatherOverlay, setWeatherOverlay] = useState<OverlayType>("crr-pc");
  const [forecastValue, setForecastValue] = useState(0);
  const [mobile, setMobile] = useState<boolean>();

  const awMetar = useSelector((state: RootState) => state.localState.toggledToAwMetarWeatherSource);

  const center = useSelector((state: RootState) => state.localState.mapCenter);
  const geolocation = useSelector((state: RootState) => state.localState.userGeolocation);

  const userHomeAirportLatLong = useMyHomeAirportLatLong();

  // when user clicks the reset position button
  const reset = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // set view to the user geolocation
        dispatch(setUserGeolocation([position.coords.latitude, position.coords.longitude]));
        map?.setView([position.coords.latitude, position.coords.longitude], 8);
      },
      (_err) => {
        // if geolocation not allowed and user is logged in with their home airport, go home airport
        if (userHomeAirportLatLong.isLoggedIn && userHomeAirportLatLong.homeAirport) {
          map?.setView(userHomeAirportLatLong.homeAirport, 10);
        } else {
          // if geolocation not allowed and user logged out, go to zoomed out map of europe
          map?.setView(CENTER_INITIAL, 2.5);
        }
      }
    );
  };

  // functions that set up the url path of weather and airport tiles
  const weatherDateTime = currentDateTime(forecastValue)().toISOString();

  function altitude(altitude = -20) {
    const result = altitude >= 0 ? altitude : 999;
    return result.toString(10);
  }

  const weatherAltitude = altitude().toString();

  const airportTilesLayer = awMetar ? "airports" : "metar";

  let sessionTokenQuery = awdApi.endpoints.getMapSessionToken.useQuery();
  const [sessionToken, setSessionToken] = useState("");
  if (!sessionToken && sessionTokenQuery.isSuccess) {
    setSessionToken(sessionTokenQuery.data.token);
  }

  // only sets the center of the map on the first load (map container immutable)
  // undefined, geolocation, home pilot, home airport, center of previous map view
  const setMapCenter = (): [number, number] => {
    // on very first load undefined, only after browsing through the map gets defined
    if (center) {
      return center;
    }
    if (userHomeAirportLatLong.homeAirport) {
      return userHomeAirportLatLong.homeAirport;
    }
    return geolocation ? geolocation : CENTER_INITIAL;
  };

  const zoom = center ? 10 : 2.5;

  const overlayTileUrl = useGetWeatherTilesUrl({
    weatherLayer: weatherOverlay,
    weatherDateTime,
    weatherAltitude,
    sessionToken,
  });
  const dotsTileUrl = useGetWeatherTilesUrl({
    weatherLayer: airportTilesLayer,
    weatherDateTime,
    weatherAltitude,
    sessionToken,
  });

  const showMap = () => {
    return (
      <MapContainer
        center={setMapCenter()}
        maxBounds={[[-90, -180], [90, 180]]}
        maxBoundsViscosity={0.9}
        zoom={zoom}
        scrollWheelZoom={true}
        maxZoom={12}
        minZoom={2.5}
        ref={(mapInstance) => {
          mapInstance && setMap(mapInstance);
        }}
      >
        {/* Base Layer with OSM map */}
        <TileLayer
          updateWhenIdle={false}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank"
          rel="noreferrer">OpenStreetMap</a> contributors | Weather by <a href="https://www.meandair.com" target="_blank"
          rel="noreferrer">Meandair</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          className="baseMap"
        />
        <LayersControl collapsed={false}>
          <Overlay name="clouds" checked={true}>
            {/* Overlay that changes based on active button. Clouds layer is the default. */}
            <TileLayer
              updateWhenIdle={false}
              className="weatherTiles"
              url={overlayTileUrl}
              minNativeZoom={0}
              maxNativeZoom={20}
              opacity={0.5}
            />
            {/* layer with airport dots */}
            <TileLayer
              updateWhenIdle={false}
              className="airportsTiles"
              url={dotsTileUrl}
              minNativeZoom={0}
              maxNativeZoom={12}
              opacity={1}
            />
            <HoveredPolygon mobile={mobile} />
          </Overlay>
        </LayersControl>
        <AirportPopups setMobile={setMobile} />
      </MapContainer>
    );
  };

  return (
    <>
      {
        <StyledMap id="landingPage" zoom={!!(map && map.getZoom() < 10)} ref={inViewMap}>
          <ExternalControls
            reset={reset}
            weatherOverlay={weatherOverlay}
            setWeatherOverlay={setWeatherOverlay}
            forecastValue={forecastValue}
            setForecastValue={setForecastValue}
            inViewLogo={inViewLogo}
          />
          <LegendWithIcon weatherOverlay={weatherOverlay} />
          {userHomeAirportLatLong && !userHomeAirportLatLong.isLoading &&
            sessionToken && !sessionTokenQuery.isLoading ? (
            showMap()
          ) : (
            <Loader options={"map"} />
          )}
        </StyledMap>
      }
    </>
  );
};

// -----------------------------------------------------------------
// STYLING

const StyledMap = styled.div<{ zoom: boolean }>`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: lavender;

  min-width: 260px;

  /* border-bottom: 1px solid #e1e1e8; */
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.363);

  z-index: 1;

  .zoomInfo {
    display: ${(props) => (props.zoom ? "block" : "none")};
    position: absolute;
    z-index: 1000;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: #e4582d solid;
    border-radius: 0.4rem;

    ${responsive.hambrg} {
      top: auto;
      bottom: 10%;
    }
    p {
      font-size: 13pt;
      padding: 0.5rem;
      font-family: "Encode Sans";
      font-weight: 500;

      ${responsive.hambrg} {
        font-size: 10pt;
        padding: 0.2rem;
        width: 200px;
      }
    }
  }

  p {
    margin: 0;
  }

  .polygon {
    cursor: zoom-in;
  }

  .leaflet-container {
  }

  .logo {
    width: 13.1rem;
    /* height: 12rem; */
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1000;
    margin-top: 1rem;
    margin-left: 0.7rem;

    background-color: #fff;
    border-radius: 0.3rem;
    padding: 0.4rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    ${responsive.deskSM} {
      font-size: 12px;
    }

    ${responsive.tablet} {
      display: flex;
      margin: 0.5rem;
      margin-left: 0.7rem;
      align-items: center;
    }
    ${responsive.forecast} {
      margin-left: 0rem;
      left: 50%;
      transform: translate(-50%);
    }
    ${responsive.mobilP} {
      /* margin:0; */
      margin-top: 0.5rem;
    }

    a {
      color: #52a3e6;

      /* .image { */
      img {
        width: 11.5rem;

        ${responsive.deskSM} {
          width: 11.5rem;
        }

        ${responsive.mobilP} {
          width: 10rem;
        }

        ${responsive.mobilS} {
          width: 10rem;
        }
      }
    }

    p {
      font-size: 8pt;
    }
  }

  p {
    font-weight: 100;
    font-size: 14px;
    margin-top: 0.1rem;

    ${responsive.deskSM} {
      font-size: 12px;
    }

    ${responsive.tablet} {
      /* display: none; */
    }
  }

  .airportName {
    font-family: "Encode Sans";
    font-weight: bold;
    margin-bottom: 0.1rem;
    text-transform: uppercase;
  }
  .icaoCode {
    font-family: "Encode Sans";
    font-weight: bold;
    margin-top: -0.2rem;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  .end {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .status {
    color: #e65552;
    font-family: "Encode Sans";
    font-weight: bold;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }

  /* margin: 100px auto; */
`;

export default LandingMap;

import { Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Stack } from "components/layout/Stack";

export type PerkProps = PropsWithChildren<{}>;

export const Perk: FC<PerkProps> = (props) => {
  return (
    <Stack gap="0.5rem" horizontal css={{ alignItems: "start" }}>
      <CheckIcon
        css={{ fontSize: "1rem", transform: "translateY(0.125rem)" }}
      />
      <Typography variant="body1">
        <strong>{props.children}</strong>
      </Typography>
    </Stack>
  );
};

import { AirportNotification } from "awd-server-api";
import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";
import { skipToken } from "@reduxjs/toolkit/query/react";

export function useAirportNotifications(icao?: string) {
  const query = awdApi.endpoints.getAirportNotifications.useQuery(icao ? { icao } : skipToken, {
    pollingInterval: 60e3,
  });

  return bound({
    query,
    hasWarnings() {
      return !!this.getList()?.length;
    },
    hasOperationalUpdate() {
      return !!this.getOperationalUpdateList()?.length;
    },
    hasGeneralInformation() {
      return !!this.getGeneralInformationList()?.length;
    },
    hasNoNotifications() {
      return !this.hasWarnings();
    },
    getList() {
      return query.data?.notifications;
    },
    getOperationalUpdateList() {
      return query.data?.notifications.filter((n) => n.category === "operational_update");
    },
    getGeneralInformationList() {
      return query.data?.notifications.filter((n) => n.category === "general_information");
    },
    pickFirstWithCategory(category: AirportNotification["category"]) {
      return this.getList()?.find((w) => w.category === category);
    },
  });
}

export type UseAirportNotificationsReturn = ReturnType<typeof useAirportNotifications>;

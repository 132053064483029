import { ResultModal } from "components/modal/inside/ResultModal";
import { DialogContent, Typography } from "@mui/material";
import { AirportSelect, AirportSelectValue } from "components/airportSelect/AirportSelect";
import { useState } from "react";

interface IModalProps {
  userName: string;
  airport: AirportSelectValue;
  onSubmit: (airport?: AirportSelectValue) => void;
}
export const SubscribeModal = ({
  userName,
  airport,
  onSubmit,
}: IModalProps) => {
  const [airportState, setAirportState] = useState(airport);

  return (
    <ResultModal
      title="Subscribe to Airport"
      submitButton={{
        text: "Subscribe",
      }}
      onSubmit={() => onSubmit(airportState)}
    >
      <DialogContent sx={{height: "10rem"}}>
        <Typography sx={{marginBottom: "1rem"}}>
          This action will subscribe this user <b>'{userName}'</b> to the following airport:
        </Typography>
        <AirportSelect
          key={null}
          value={airportState}
          onChange={setAirportState}
          label={"Airport"}
          noAddAirport={false}
          listboxSx={{ maxHeight: 110 }}
        />
      </DialogContent>
    </ResultModal>
  );
};

export const DowngradeToPilotAccountModal = ({
  userName,
  onSubmit,
}: Omit<IModalProps, "airport">) => {
  return (
    <ResultModal
      title="Downgrade to Pilot Account"
      submitButton={{ text: "Downgrade" }}
      onSubmit={() => onSubmit()}
    >
      <DialogContent>
        This action will downgrade this user <b>'{userName}'</b> to a pilot
        account.
      </DialogContent>
    </ResultModal>
  );
};

export const DeclineModal = ({
  userName,
  airport,
  onSubmit,
}: IModalProps) => {
  return (
    <ResultModal
      title="Decline Subscription"
      submitButton={{ text: "Decline" }}
      onSubmit={() => onSubmit()}
    >
      <DialogContent>
        This action will decline the subscription of this user{" "}
        <b>'{userName}'</b> to the airport <b>'{airport?.specified_by === "id"? airport.id : airport?.name ?? "N/A"}'</b>?
      </DialogContent>
    </ResultModal>
  );
};

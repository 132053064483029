import { FC } from "react";

export type SunriseIconProps = {};

export const SunriseIcon: FC<SunriseIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.657"
      height="32.576"
      x="0"
      y="0"
      viewBox="-0.637 -2.566 33.657 32.576"
    >
      <g>
        <g>
          <path
            fill="none"
            stroke="#6D6E70"
            strokeMiterlimit="10"
            d="M16.33 11.755L16.33 -1.364"
          ></path>
          <path
            fill="#6D6E70"
            d="M21.129 5.47a.5.5 0 01-.69-.153L16.33-1.136l-4.11 6.453a.499.499 0 11-.843-.537l4.531-7.115a.5.5 0 01.844 0l4.531 7.115a.499.499 0 01-.154.69z"
          ></path>
          <path
            fill="none"
            stroke="#6D6E70"
            strokeMiterlimit="10"
            d="M8.996 27.194a7.334 7.334 0 1114.668 0"
          ></path>
          <g fill="none" stroke="#6D6E70" strokeMiterlimit="10">
            <path d="M25.946 26.114L30.578 26.114"></path>
            <path d="M23.939 20.71L27.413 18.085"></path>
            <path d="M19.307 17.545L21.16 13.067"></path>
            <path d="M13.46 17.545L11.608 13.067"></path>
            <path d="M6.713 26.114L2.081 26.114"></path>
            <path d="M8.72 20.71L5.246 18.085"></path>
          </g>
          <path
            fill="none"
            stroke="#6D6E70"
            strokeMiterlimit="10"
            d="M-0.637 29.51L33.02 29.51"
          ></path>
        </g>
      </g>
    </svg>
  );
};

import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeAirportIcao } from "logic/airport/icao/describe";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { useDebouncedValue } from "utils/hooks";

export function useAirportSearch() {
  const navigate = useNavigate();

  const [value, setValue] = useState("");

  const debouncedValue = useDebouncedValue(value, 300);

  const { data, isLoading, isFetching } = awdApi.endpoints.searchHomeAirport.useQuery(
    debouncedValue.trim() === "" ? skipToken : { user_input: debouncedValue }
  );

  const result = value.trim() === "" ? undefined : data;

  return {
    value,
    setValue,
    isLoading,
    isFetching,
    result,
    onSearchResultClick: (icao: string | undefined | null) => {
      if (icao != null) {
        navigate(describeAirportIcao(icao).getWeatherUrl());
        setValue("");
      }
    },
  };
}

import { ListItemButton, ListItemText } from "@mui/material";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";
import { FC } from "react";

export type NavigationFavoriteAirportsProps = {};

export const NavigationFavoriteAirports: FC<NavigationFavoriteAirportsProps> = (
  props
) => {
  const favoriteAirports = useFavoriteAirports();
  return (
    <>
      {favoriteAirports.getItems()?.map((favorite) => (
        <ListItemButton key={favorite.id} href={`/${favorite.airport.icao}`}>
          <ListItemText primary={favorite.airport.name} />
        </ListItemButton>
      ))}
    </>
  );
};

import { SpecBuilder } from "utils/general";
import { pageSpec } from "logic/navigation/spec";
import {
  GetContentfulEntry,
  getRunwaysSchemaNonSubscribedAirport,
  getAccessLimitModalContent,
  getTafExplanationModalContent,
  getMetarExplanationModalContent,
  getRunwaysListModalContent,
  getNotAllRunwaysDataFilledInManager,
  getNotAllRunwaysDataFilledIn,
  getRunwaysDataNotCorrect,
  getRunwaysDataNotCorrectManager,
} from "utils/contentful";
import { IModalFields } from "types/generated/contentful";
import {
  accessLimitSlice,
  selAirportAccessLimit,
  selAirportSubscriptionStatus,
} from "store/reducers/accessLimitSlice";
import { selIfModalIsOn, toggleModal } from "store/reducers/localStateSlice";
import { RootState } from "store/store";

// export type ModalSpecificationType = {
//   type: string;
//   contentfulSource: GetContentfulEntry<IModalFields>;
//   shouldShowModalSelector: any;
//   dispatchOnClose: any;
//   navigate: boolean;
//   backButtonText?: string;
//   backButtonPath?: string;
//   subscribeButtonText?: string;
//   subscribeButtonPath?: string;
// };

type modalTypeWithDispatch = {
  type: string;
  contentfulSource: GetContentfulEntry<IModalFields>;
  shouldShowModalSelector: (state: RootState) => {
    shouldShowModal: (icao: string | undefined) => boolean;
  };
  dispatchOnClose: any;
  navigation: boolean;
  navigate?: {
    backButtonText: string;
    backButtonPath: string;
    subscribeButtonText: string;
    subscribeButtonPath: string;
  };
};

type modalTypeWithoutDispatch = {
  type: string;
  contentfulSource: GetContentfulEntry<IModalFields>;
  navigation: boolean;
  navigate: {
    backButtonText: string;
    backButtonPath: string;
    subscribeButtonText: string;
    subscribeButtonPath: string;
  };
};

export type ModalSpecificationType = {
  type: string;
  contentfulSource: GetContentfulEntry<IModalFields>;
  shouldShowModalSelector: (state: RootState) => {
    shouldShowModal: (icao: string | undefined) => boolean;
  };
  dispatchOnClose: any;
  navigation: boolean;
  navigate?: {
    backButtonText: string;
    backButtonPath: string;
    subscribeButtonText: string;
    subscribeButtonPath: string;
  };
};

export const modalWithDispatch = new SpecBuilder<modalTypeWithDispatch>().build({
  metarSwitch: {
    type: "metar",
    navigation: false,
    contentfulSource: getMetarExplanationModalContent,
    shouldShowModalSelector: selIfModalIsOn,
    dispatchOnClose: () => toggleModal(false, "metar"),
  },
  tafSwitch: {
    type: "taf",
    navigation: false,
    contentfulSource: getTafExplanationModalContent,
    shouldShowModalSelector: selIfModalIsOn,
    dispatchOnClose: () => toggleModal(false, "taf"),
  },
  runwaysInfo: {
    navigation: false,
    type: "runways",
    contentfulSource: getRunwaysListModalContent,
    shouldShowModalSelector: selIfModalIsOn,
    dispatchOnClose: () => toggleModal(false, "runways"),
  },
  airportSubscriptionStatus: {
    type: "airportSubscription",
    contentfulSource: getRunwaysSchemaNonSubscribedAirport,
    shouldShowModalSelector: selAirportSubscriptionStatus,
    dispatchOnClose: accessLimitSlice.actions.closedAirportSubscriptionStatusModal,
    navigation: true,
    navigate: {
      backButtonText: "Back to dashboard",
      backButtonPath: "/icao",
      subscribeButtonText: "Subscribe",
      subscribeButtonPath: pageSpec.signup.path,
    },
  },
  airportAccessLimit: {
    type: "airportAccessLimit",
    contentfulSource: getAccessLimitModalContent,
    shouldShowModalSelector: selAirportAccessLimit,
    dispatchOnClose: accessLimitSlice.actions.closedAirportAccessLimitModal,
    navigation: true,
    navigate: {
      backButtonText: "Back to map",
      backButtonPath: "/",
      subscribeButtonText: "Subscribe",
      subscribeButtonPath: pageSpec.signup.path,
    },
  },
});

export const modalWithoutDispatch = new SpecBuilder<modalTypeWithoutDispatch>().build({
  notAllRunwaysDataForVizFilledIn_General: {
    type: "notAllRunwaysDataForVizFilledInGeneral",
    contentfulSource: getNotAllRunwaysDataFilledIn,
    navigation: true,
    navigate: {
      backButtonText: "Back to dashboard",
      backButtonPath: "/icao",
      subscribeButtonText: "Log in",
      subscribeButtonPath: pageSpec.login.path,
    },
  },
  notAllRunwaysDataForVizFilledIn_Manager: {
    type: "notAllRunwaysDataForVizFilledIn_Manager",
    contentfulSource: getNotAllRunwaysDataFilledInManager,
    navigation: true,
    navigate: {
      backButtonText: "Back to dashboard",
      backButtonPath: "/icao",
      subscribeButtonText: "Fill out data",
      subscribeButtonPath: pageSpec.airport.path,
    },
  },
  runwaysDataForVizNotCorrect_General: {
    type: "runwaysDataForVizNotCorrect_General",
    contentfulSource: getRunwaysDataNotCorrect,
    navigation: true,
    navigate: {
      backButtonText: "Back to dashboard",
      backButtonPath: "/icao",
      subscribeButtonText: "Log in",
      subscribeButtonPath: pageSpec.login.path,
    },
  },
  runwaysDataForVizNotCorrect_Manager: {
    type: "runwaysDataForVizNotCorrect_Manager",
    contentfulSource: getRunwaysDataNotCorrectManager,
    navigation: true,
    navigate: {
      backButtonText: "Back to dashboard",
      backButtonPath: "/icao",
      subscribeButtonText: "Fix data",
      subscribeButtonPath: pageSpec.airport.path,
    },
  },
});

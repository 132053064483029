import { FC, FormEventHandler, useState } from "react";
import { ROW_PER_PAGE, StyledTableCell, StyledTableRow, useTablePage } from "utils/table";
import { useDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { Stack } from "components/layout/Stack";
import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { awdApi } from "store/reducers/awdApi";
import { AirportResponse } from "awd-server-api";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { describeMutationResult } from "logic/api/result/describe";

type AirportListingTableProps = {};

export const AirportListingTable: FC<AirportListingTableProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage, handleChangePage] = useTablePage();
  const [controlledText, setControlledText] = useState("");
  const [searchText, setSearchText] = useState("");

  const airportListing = awdApi.endpoints.getAirportListing.useQuery({
    page,
    text: searchText,
  });

  const airports = airportListing?.data?.airports;
  const count = airportListing?.data?.count;

  const textSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchText(controlledText);
  };

  const addAirport = async () => {
    try {
      const result = await dispatch(
        awdApi.endpoints.postAirport.initiate({
          body: {
            name: "<replace>",
            latitude: 0.0,
            longitude: 0.0,
          },
        })
      ).then(describeMutationResult);

      if (result.error) {
        dispatch(toastError(result.error, `Adding new airport went wrong`));
      } else {
        navigate(`/${result.data.id}/edit`);
      }
    } catch (error) {
      dispatch(
        toastMessage("error", "Something went wrong, please try again later.")
      );
    }
  };

  return (
    <div css={{ marginTop: "1rem" }}>
      <Stack gap="0" horizontal css={{ justifyContent: "space-between", marginBottom: "1rem" }}>
        <Button onClick={addAirport}>Add airport</Button>
        <form onSubmit={textSearch}>
          <TextField
            label="Search via icao / airport name"
            variant="outlined"
            css={{ width: "250px" }}
            value={controlledText}
            onChange={(e) => setControlledText(e.target.value)}
          />
        </form>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Airport Id</StyledTableCell>
              <StyledTableCell>ICAO</StyledTableCell>
              <StyledTableCell>Airport Name</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>ICAO Alias</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {airports?.map((airport) => <AirportTableRow key={airport.id} airport={airport} />)}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count ?? 0}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

type AirportTableRowProps = {
  airport: AirportResponse;
};
const AirportTableRow = ({ airport }: AirportTableRowProps) => {
  return (
    <StyledTableRow>
      <StyledTableCell>{airport.id}</StyledTableCell>
      <StyledTableCell>{airport.icao}</StyledTableCell>
      <StyledTableCell>{airport.name}</StyledTableCell>
      <StyledTableCell>{airport.isoCountry}</StyledTableCell>
      <StyledTableCell>{airport.icaoAlias}</StyledTableCell>
      <StyledTableCell>{<Link href={`/${airport.icao}/edit`}>Edit</Link>}</StyledTableCell>
    </StyledTableRow>
  );
};

import { QueryResult } from "components/queryResult/QueryResult";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type AirportMeteostationProps = {
  airportId: string | number;
};
export const AirportMeteostation: FC<AirportMeteostationProps> = ({
  airportId,
}) => {
  const query = awdApi.endpoints.getAirportLinkSettings.useQuery({ airportId });
  const settings = query.data;

  return (
    <QueryResult query={query}>
      {settings?.meteostation_url && (
        <iframe
          title="meteostation"
          src={settings.meteostation_url}
          frameBorder="none"
          width="600"
          height="400"
        />
      )}
    </QueryResult>
  );
};

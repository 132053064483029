import styled from "@emotion/styled";
import { Stack } from "components/layout/Stack";
import { ParsedRunway } from "logic/airport/runway/parse";
import { FC } from "react";

export type RunwayIdentifierProps = {
  selectedRunway: ParsedRunway;
  className?: string;
  index?: number;
  theme?: any;
};

export const RunwayIdentifier: FC<RunwayIdentifierProps> = (props) => {
  const name = props.selectedRunway.name.slice(0, 3);

  return (
    <SIdentifier className={props.className} index={props.index} theme={props.theme}>
      <Stack gap={name.length === 2 ? "0.5rem" : "0"} horizontal css={{ justifyContent: "center" }}>
        {name.split("").map((x, i) => (
          <span className="value" key={i}>
            {x}
          </span>
        ))}
      </Stack>
    </SIdentifier>
  );
};

const SIdentifier = styled.div<{ index?: number }>`
  font-size: 50px;
  font-weight: bold;
  color: ${(props) =>
    props.index ? (props.index === 0 ? props.theme.primary : " #6d6e70") : props.theme.primary};
  width: 100%;
  text-align: center;
  transform-style: preserve-3d;

  transform: perspective(150px) rotateX(40deg);

  .value {
    background-color: ${(props) => (props.index || props.index === 0 ? "#fff" : "#f6f7f7")};
  }
`;

import { describeAirportIcao } from "logic/airport/icao/describe";
import { pageSpec } from "logic/navigation/spec";
import { awdApi } from "store/reducers/awdApi";
import { AppDispatch } from "store/store";

export async function queryUserHome(dispatch: AppDispatch) {
  const favoriteAirports = await dispatch(
    awdApi.endpoints.getMyFavoriteAirports.initiate(undefined, {
      forceRefetch: true,
    })
  );

  const homeAirport = favoriteAirports.data?.find((airport) => airport.is_home_airport)
  const firstAirport = favoriteAirports.data?.[0];

  const airportToDisplayAfterLogin = homeAirport || firstAirport;

  if (airportToDisplayAfterLogin) {
    return describeAirportIcao(airportToDisplayAfterLogin.airport.icao).getWeatherUrl();
  }

  return pageSpec.dashboard.path;
}

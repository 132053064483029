import { Button } from "@mui/material";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { UploadKioskBannerModal } from "components/modal/inside/upload/UploadKioskBannerModal";
import { FC } from "react";

export type ChangeBannerButtonProps = {
  icao: string;
  onSuccess?: () => void;
};

export const ChangeBannerButton: FC<ChangeBannerButtonProps> = (props) => {
  const { show } = useModalContext();
  return (
    <Button
      onClick={() =>
        show(
          <UploadKioskBannerModal
            icao={props.icao}
            onUploadSuccess={props.onSuccess}
          />
        )
      }
    >
      Change banner
    </Button>
  );
};

import { DialogContent, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { ComponentProps, FC, useState } from "react";
import { useRunOnceAllValuesAreDefined } from "utils/hooks";

export type InfoAirportEditationModalProps = {
  section?: "location" | "contact" | "runways";
};

export const InfoAirportEditationModal: FC<InfoAirportEditationModalProps> = (
  props
) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  useRunOnceAllValuesAreDefined(
    [props.section, element] as const,
    ([section, element]) => {
      const sectionElement = element.querySelector(
        `*[data-section=${section}]`
      );
      sectionElement?.scrollIntoView();
    }
  );
  return (
    <ResultModal
      title="Keep in mind."
      cancelButton={{ hidden: true }}
      submitButton={{
        text: "Confirm",
      }}
    >
      <DialogContent sx={{ width: "40rem", maxWidth: "100%" }}>
        <Stack gap="0.5rem" ref={setElement}>
          <Subtitle section="location">Location</Subtitle>

          <Paragraph>
            General GPS location of the airport. The location is used for
            showing the markers on the map and other geo-location related
            information.
          </Paragraph>

          <Subtitle section="contact">Contact</Subtitle>

          <Paragraph>
            The publicly available airport contact, which the pilots can use to
            reach operation, official or administrative information about the
            airport.
          </Paragraph>

          <Subtitle section="runways">Runways</Subtitle>

          <Paragraph>
            The parameters of the runways of the airport. Currently, we track
            following information:
            <Paragraph />
            <Paragraph>
              <strong>Length</strong> - Length of the runway in feet.
              <br />
              <strong>Width</strong> - Width of the runway in feet.
              <br />
              <strong>Surface</strong> - Type of the runway surface (Asphalt,
              Turf, Concrete, Grass, Gravel, Water)
              <br />
              <strong>Lighted</strong> - Indicates whether the runway is
              lighted.
              <br />
              <strong>Closed</strong> - Indicates whether the runway is closed.
              Closed runways are not shown in the runway switch on the airport
              weather dashboard.
              <br />
            </Paragraph>
            <strong>Direction Identifier</strong> - Textual identifier of the
            runway (e.g., 22L).
            <br />
            <strong>Heading</strong> - Heading of the runway in degrees (e.g.,
            217).
            <br />
            <strong>Elevation</strong> - Runway altitude in feet AMSL.
            <br />
            <strong>Latitude</strong> - GPS position of the runway.
            <br />
            <strong>Longitude</strong> - GPS position of the runway.
            <br />
            <strong>Displaced Threshold (DTHR)</strong> - A threshold located at
            a point other than the physical beginning or end of the runway in
            feet.
          </Paragraph>
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

const Subtitle: FC<
  ComponentProps<typeof Typography> & {
    section: InfoAirportEditationModalProps["section"];
  }
> = ({ section, ...pass }) => (
  <Typography variant="subtitle2" data-section={section} {...pass} />
);
const Paragraph: FC<ComponentProps<typeof Typography>> = (pass) => (
  <Typography variant="body1" {...pass} />
);

import { Typography } from "@mui/material";
import { Block } from "components/layout/Block";
import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { SignupForm } from "components/signup/SignupForm";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { AirportSpecification } from "awd-server-api";
import { useContentfulData } from "utils/useContentfulData";
import { getRegisterAsPilotPageData } from "utils/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export type RegisterPilotPageProps = {};

export const RegisterPilotPage: FC<RegisterPilotPageProps> = (props) => {
  const registerPilotPageContent = useContentfulData(getRegisterAsPilotPageData);

  const textAboveReg = registerPilotPageContent?.textAboveRegisterForm;
  const textBelowReg = registerPilotPageContent?.textBelowRegisterForm;

  const [params] = useSearchParams();
  const defaultAirportId = parseInt(params.get("airport_id") ?? "", 10);
  const defaultAirportName = params.get("airport_name");
  const defaultAirport: AirportSpecification | undefined = !Number.isNaN(defaultAirportId)
    ? { specified_by: "id", id: defaultAirportId }
    : defaultAirportName
    ? { specified_by: "name", name: defaultAirportName }
    : undefined;

  return (
    <OutsidePageLayout>
      <Block>
        <Stack
          gap="2rem"
          css={{ alignItems: "center", width: "100%", paddingTop: "6rem" }}
          accurateSpacers
        >
          <Meta pageTitle="Get started" />

          {textAboveReg && (
            <div css={{ marginBottom: "2rem" }}>{documentToReactComponents(textAboveReg)}</div>
          )}

          <Typography variant="h1" css={{ textAlign: "center" }}>
            Register as pilot
          </Typography>

          <Spacer size="4rem" />

          <div id="signup-form" css={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <SignupForm defaultAirport={defaultAirport} variant="pilot" />
          </div>

          {textBelowReg && (
            <div css={{ marginTop: "2rem" }}>{documentToReactComponents(textBelowReg)}</div>
          )}

          <Spacer size="10rem" />
        </Stack>
      </Block>
    </OutsidePageLayout>
  );
};

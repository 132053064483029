import { UserRolesResponse } from "awd-server-api";
import { pageSpec } from "logic/navigation/spec";
import { bound } from "utils/class";

export function describeInsideNavigation() {
  return bound({
    getItems(roles: UserRolesResponse | undefined) {
      return [
        pageSpec.dashboard,
        pageSpec.account,
        pageSpec.airport,
        pageSpec.airport_warning_settings,
        pageSpec.kiosk_settings,
        pageSpec.widget_editor,
        pageSpec.user_settings,
        pageSpec.favorite_airports,
        pageSpec.airport_webcam_settings,
        pageSpec.airport_meteostation_settings,
        pageSpec.airport_subscription,
        pageSpec.user_listing,
        pageSpec.registered_airports,
        pageSpec.statistics,
        pageSpec.airport_listing,

        // uncomment these once they are implemented
        // pageSpec.invoicing,
      ].filter(
        (item) => item.requirements?.role == null || roles?.roles.includes(item.requirements.role)
      );
    },
    isItemSelected(item: { path: string }, pathname: string) {
      return item.path === pathname;
    },
  });
}

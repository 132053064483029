import styled from "@emotion/styled";
import { useDashboardAirport } from "logic/weather/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectRunway } from "store/reducers/localStateSlice";
import { stopOtherEvents } from "utils/general";
import clsx from "clsx";
import { RootState } from "store/store";
import { ParsedRunway } from "logic/airport/runway/parse";
import { responsive } from "mixins";
import IconInfo from "components/_icons/IconInfo";
import { toggleModal } from "store/reducers/localStateSlice";
import { useContext, useMemo } from "react";
import { WidgetContext } from "components/widget/WidgetWrapper";
import { sortRunways } from "logic/weather/dashboard/runwaySelectionLogic";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ModalContentfulData } from "components/modal/ModalContentfulData";
import { useParams } from "react-router-dom";

export type RunwaysType = {
  skip?: boolean;
};

const Runways = ({ skip }: RunwaysType) => {
  const icao = String(useParams().icao);
  const text = "runways";
  const inWidget = useContext(WidgetContext);
  const dispatch = useDispatch();

  const runways = useDashboardAirport(undefined, { skip: skip ?? false })
    .transform()
    ?.getRunwayPairsIfAirportOpen();

  const url = useDashboardAirport(undefined, { skip: skip ?? false })
    .transform()
    ?.getUrl();
  const selectedRunway = useSelector((state: RootState) => state.localState.selectedRunway);

  const sortedRunways = useMemo(() => {
    if (runways == null) return;
    return sortRunways(runways);
  }, [runways]);

  const { show } = useModalContext();

  const chooseRunway = (icao: string, runway: ParsedRunway) => (e: any) => {
    stopOtherEvents(e);
    dispatch(selectRunway(icao.toLowerCase(), runway));
  };

  const handleOpenNewTab = () => {
    if (!window) return;
    const urlForModal = url + "?modal=" + text;
    const newTab = window.open(urlForModal, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const showInfo = (e: any) => {
    stopOtherEvents(e);
    if (inWidget) {
      handleOpenNewTab();
    } else {
      dispatch(toggleModal(true, text));
      show(<ModalContentfulData icao={icao} type={"runwaysInfo"} />);
    }
  };

  return (
    <StyledRunway>
      {icao && runways?.length !== 0 ? (
        <>
          <p className="label">
            <span>runways </span>
            <button className="button icon" onClick={showInfo}>
              <IconInfo />
            </button>
          </p>

          {sortedRunways !== undefined &&
            selectedRunway &&
            sortedRunways
              .filter((r) => r[0] && r[1] && "name" in r[0] && "name" in r[1])
              .map((r, i) => {
                return (
                  <span key={r[0].name + r[1].name} className="buttonGroup">
                    <button
                      className={clsx("first", r[0].name === selectedRunway.name && "selected")}
                      onClick={chooseRunway(icao, r[0])}
                      title={r[0].name}
                    >
                      <div className="content">
                        <p className="runwayNumber">{r[0].name}</p>
                      </div>
                    </button>

                    <button
                      className={clsx("second", r[1].name === selectedRunway.name && "selected")}
                      onClick={chooseRunway(icao, r[1])}
                      title={r[1].name}
                    >
                      <div className="content">
                        <p className="runwayNumber"> {r[1].name}</p>
                      </div>
                    </button>
                  </span>
                );
              })}
        </>
      ) : (
        //when no runways, show nothing
        <></>
      )}
    </StyledRunway>
  );
};

const StyledRunway = styled.div`
  grid-column: 2/3;
  grid-row: 3/4;

  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  row-gap: 0.4rem;
  column-gap: 0.7rem;

  p {
    font-size: 14px;
    font-weight: 100;

    ${responsive.tablet} {
      font-size: 12px;
    }
  }

  .label {
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  .buttonGroup {
    margin: 0;
    white-space: nowrap;
    display: flex;
  }

  button {
    position: relative;
    font-size: 14px;
    border: 2px solid #323233be;
    border-radius: 0.3rem;
    background-color: #fff;
    cursor: pointer;

    ${responsive.mobilP} {
      font-size: 12px;
    }

    &.first {
      border-right: 1px solid #323233be;
      border-radius: 0.3rem 0 0 0.3rem;
      color: #000;

      &:hover {
        border: 2px solid black;
        border-right: 1px solid black;
      }
    }
    &.second {
      border-left: 1px solid #323233be;
      border-radius: 0 0.3rem 0.3rem 0;
      color: #000;
      &:hover {
        border: 2px solid black;
        border-left: 1px solid black;
      }
    }
    &.selected {
      color: #fff;
      background-color: #58585b96;
    }

    &.icon {
      border: none;
      background: none;
      margin: 0.2rem 0 0 0.2rem;
      padding: 0;
    }
  }
  .content {
    display: flex;
    align-items: center;

    .runwayNumber {
      text-decoration: underline;
      padding: 0.4rem 0.5rem;
      font-weight: 500;
    }

    line {
      stroke: black;
      stroke-width: 3px;
    }
  }
`;

export default Runways;

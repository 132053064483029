import { Backdrop, CircularProgress } from "@mui/material";
import { FC, ReactNode } from "react";

export type BackdropLoadingQuery = { isLoading?: boolean };

export type BackdropLoadingProps = {
  query: BackdropLoadingQuery | BackdropLoadingQuery[];
  children?: ReactNode;
};
export const BackdropLoading: FC<BackdropLoadingProps> = (props) => {
  const queries = [props.query].flat();

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={queries.some((q) => q.isLoading)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {props.children}
    </>
  );
};

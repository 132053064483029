import { Dispatch, SetStateAction } from "react";
import clsx from "clsx";

type BurgerProps = {
  activeBurger: boolean;
  setActiveBurger: Dispatch<SetStateAction<boolean>>;
};
export const BurgerIcon = (props: BurgerProps) => {
  const { activeBurger, setActiveBurger } = props;
  return (
    <div
      className={clsx("hamburger", activeBurger && "activeBurger")}
      onClick={() => setActiveBurger((a) => !a)}
    >
      <svg className="defs" width="0" height="0">
        <filter id="gooeyness">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2.2" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
            result="gooeyness"
          />
          <feComposite in="SourceGraphic" in2="gooeyness" operator="atop" />
        </filter>
      </svg>
      <svg className="burger" viewBox="0 0 100 100">
        <path className="line line1" d="M 50,35 H 30" />
        <path className="line line2" d="M 50,35 H 70" />
        <path className="line line3" d="M 50,50 H 30" />
        <path className="line line4" d="M 50,50 H 70" />
        <path className="line line5" d="M 50,65 H 30" />
        <path className="line line6" d="M 50,65 H 70" />
      </svg>
      <svg className="x" viewBox="0 0 100 100">
        <path className="line" d="M 34,32 L 66,68" />
        <path className="line" d="M 66,32 L 34,68" />
      </svg>
    </div>
  );
};

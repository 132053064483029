import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { CookieConsentProvider } from "components/consent/cookie/CookieConsentProvider";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import { ModalProvider } from "components/modal/inside/ModalProvider";
import { pageSpec } from "logic/navigation/spec";
import { ConfirmEmailPage } from "pages/confirm-email";
import { CookiePolicyPage } from "pages/cookie-policy";
import { CustomerSupportPage } from "pages/customer-support";
import { DisclaimerPage } from "pages/disclaimer";
import { DocumentsPage } from "pages/documents";
import { Homepage } from "pages/homepage/Homepage";
import { KioskPage } from "pages/kiosk/[icao]";
import { LoginPage } from "pages/login";
import { AccountPage } from "pages/me/account";
import { AirportPage } from "pages/me/airport";
import { AirportNotificationsSettingsPage } from "pages/me/airport/notifications";
import { DashboardPage } from "pages/me/dashboard";
import { FavoriteAirportsPage } from "pages/me/favorite-airports";
import { KioskSettingsPage } from "pages/me/kiosk";
import { NotImplementedPage } from "pages/me/not-implemented";
import { WidgetEditorPage } from "pages/me/WidgetEditorPage";
import { MobileAppEntrypointPage } from "pages/mobile-app-entrypoint";
import { PrivacyPolicyPage } from "pages/privacy-policy";
import { RegisterPilotPage } from "pages/registerpilot";
import { RequestResetPasswordPage } from "pages/reset-password/request";
import { SetNewPasswordPage } from "pages/reset-password/set-new";
import { SignupPage } from "pages/signup";
import { TermsOfServicePage } from "pages/terms-of-service";
import { AirportNotificationsSubpage } from "pages/weather-dashboards/AirportNotificationsSubpage";
import { WidgetPreviewPage } from "pages/WidgetPreviewPage";
import AirportWeatherDetail from "./components/AirportWeatherDetail";
import Clouds from "./components/Clouds/Clouds";
import Forecast from "./components/Forecast/Forecast";
import Hazards from "./components/Hazards/Hazards";
import Wind from "./components/Wind/Wind";
import { DashboardPage as WeatherDashboardPage } from "./pages/weather-dashboards/Dashboard";
import Map from "./pages/weather-dashboards/Map";
import { UserSettingsPage } from "pages/me/settings";
import { AirportAboutSubpage } from "pages/weather-dashboards/AirportAboutSubpage";
import { AirportWebcamSettingsPage } from "pages/me/airport/webcam";
import { AirportMeteostationSettingsPage } from "pages/me/airport/meteostation";
import { AirportMeteostationSubpage } from "pages/weather-dashboards/AirportMeteostationSubpage";
import { AirportWebcamSubpage } from "pages/weather-dashboards/AirportWebcamSubpage";
import { AirportSubscriptionPage } from "./pages/me/airport-subscription";
import { RunwaySchema } from "pages/weather-dashboards/RunwaySchema";
import { UserListingPage } from "./pages/user-listing";
import { RegisteredAirportsPage } from "./pages/me/registered-airports";
import { StatisticsPage } from "./pages/me/statisticsPage";
import { AirportListing } from "./pages/me/airport-listing";
import { AirportSupervizorEdit } from "./pages/me/edit";

const RUNWAY_VIZ_APPEAR = process.env.REACT_APP_VIZ === "true";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<CookieConsentProvider />} errorElement={<ErrorBoundary />}>
      <Route element={<ModalProvider />}>
        <Route path="/" element={<Homepage />} />
        <Route path={pageSpec.cookie_policy.path} element={<CookiePolicyPage />} />
        <Route path={pageSpec.documents.path} element={<DocumentsPage />} />
        <Route path={pageSpec.disclaimer.path} element={<DisclaimerPage />} />
        <Route path={pageSpec.dashboard.path} element={<DashboardPage />} />
        <Route path={pageSpec.account.path} element={<AccountPage />} />
        <Route path={pageSpec.confirm_email.path} element={<ConfirmEmailPage />} />
        <Route path={pageSpec.reset_password_request.path} element={<RequestResetPasswordPage />} />
        <Route path={pageSpec.reset_password_set_new.path} element={<SetNewPasswordPage />} />
        <Route path={pageSpec.airport.path} element={<AirportPage />} />
        <Route
          path={pageSpec.airport_warning_settings.path}
          element={<AirportNotificationsSettingsPage />}
        />
        <Route
          path={pageSpec.airport_meteostation_settings.path}
          element={<AirportMeteostationSettingsPage />}
        />
        <Route
          path={pageSpec.airport_webcam_settings.path}
          element={<AirportWebcamSettingsPage />}
        />
        <Route path={pageSpec.invoicing.path} element={<NotImplementedPage name="Invoicing" />} />
        <Route path={pageSpec.widget_editor.path} element={<WidgetEditorPage />} />
        <Route path={pageSpec.user_settings.path} element={<UserSettingsPage />} />
        <Route path={pageSpec.favorite_airports.path} element={<FavoriteAirportsPage />} />
        <Route path={pageSpec.kiosk_settings.path} element={<KioskSettingsPage />} />
        <Route path={pageSpec.login.path} element={<LoginPage />} />
        <Route path={pageSpec.signup.path} element={<SignupPage />} />
        <Route path={pageSpec.registerpilot.path} element={<RegisterPilotPage />} />
        <Route path={pageSpec.mobile_app_entrypoint.path} element={<MobileAppEntrypointPage />} />
        <Route path={pageSpec.terms_of_service.path} element={<TermsOfServicePage />} />
        <Route path={pageSpec.privacy_policy.path} element={<PrivacyPolicyPage />} />
        <Route path={pageSpec.customer_support.path} element={<CustomerSupportPage />} />

        <Route path={pageSpec.kiosk.path} element={<KioskPage />} />
        <Route path={pageSpec.widget_preview.path} element={<WidgetPreviewPage />} />
        <Route path={pageSpec.airport_subscription.path} element={<AirportSubscriptionPage />} />
        <Route path={pageSpec.user_listing.path} element={<UserListingPage />} />
        <Route path={pageSpec.airport_listing.path} element={<AirportListing />} />
        <Route path={pageSpec.registered_airports.path} element={<RegisteredAirportsPage />} />

        <Route path={pageSpec.statistics.path} element={<StatisticsPage />} />
        <Route element={<AirportWeatherDetail />}>
          <Route
            path={pageSpec.weather_dashboard.path}
            element={<WeatherDashboardPage />}
            loader={async ({ params }) => {
              return params;
            }}
          />
          <Route path={pageSpec.weather_map.path} element={<Map />} />
          <Route path=":icao/hazards" element={<Hazards />} />
          <Route path=":icao/forecast" element={<Forecast />} />
          <Route path=":icao/clouds" element={<Clouds />} />
          <Route path=":icao/wind" element={<Wind />} />
          <Route
            path=":icao/general-information"
            element={<AirportNotificationsSubpage category="general_information" />}
          />
          <Route
            path=":icao/operational-updates"
            element={<AirportNotificationsSubpage category="operational_update" />}
          />
          <Route path=":icao/about" element={<AirportAboutSubpage />} />
          {RUNWAY_VIZ_APPEAR && (
            <Route path={pageSpec.runway_schema.path} element={<RunwaySchema />} />
          )}
          <Route path=":icao/meteostation" element={<AirportMeteostationSubpage />} />
          <Route path=":icao/webcam" element={<AirportWebcamSubpage />} />
          <Route path={pageSpec.airport_supervizor_edit.path} element={<AirportSupervizorEdit />} />
        </Route>
      </Route>
    </Route>
  )
);

export default router;

import { useDashboardAirport } from "logic/weather/hooks";
import { useActiveTafSource } from "logic/weather/hooks";
import { ForecastWindCompass } from "components/Dashboard/weatherForecast/ForecastWindCompass";
import { ForecastWindRunways } from "components/Dashboard/weatherForecast/ForecastWindRunways";

type ForecastedWindType = {
  index: number;
  theme: any;
  wind: any;
};

export const ForecastWind = ({ wind, index, theme }: ForecastedWindType) => {
  const runways = useDashboardAirport().transform()?.getRunwayPairsIfAirportOpen();
  const { source } = useActiveTafSource();
  const numberOfArrowsShown = Math.max(...(source?.getActiveWindArrowsForecast(index) ?? []));

  //show a different component when there are no runways
  return runways?.length !== 0 ? (
    <ForecastWindRunways
      wind={wind}
      index={index}
      theme={theme}
      numOfArrows={numberOfArrowsShown}
    />
  ) : (
    <ForecastWindCompass
      wind={wind}
      index={index}
      theme={theme}
      numOfArrows={numberOfArrowsShown}
    />
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { zPassword } from "logic/signup/password/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { z } from "zod";

export type EditPasswordModalProps = {};

const zForm = z
  .object({
    old_password: z.string().nonempty({
      message: "Please fill in your current password.",
    }),
    password: zPassword,
    password_again: z.string(),
  })
  .refine((data) => data.password === data.password_again, {
    message: "The new passwords have to match.",
    path: ["password_again"],
  });

type Form = z.infer<typeof zForm>;

export const EditPasswordModal: FC<EditPasswordModalProps> = (props) => {
  const dispatch = useDispatch();

  const form = useForm<Form>({ resolver: zodResolver(zForm) });

  return (
    <ResultModal
      title="Edit password"
      onSubmit={async (cancel) => {
        await form.handleSubmit(async (values) => {
          const result = await dispatch(
            awdApi.endpoints.patchUserMe.initiate({
              password: values.password,
              old_password: values.old_password,
            })
          ).then(describeMutationResult);

          if (result.error) {
            dispatch(
              toastError(result.error, "We could not update your password.")
            );
            cancel();
            return;
          } else {
            dispatch(
              toastMessage("success", "Your password has been updated.")
            );
          }
        }, cancel)();
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <Stack gap="1rem">
          <FormTextField
            form={form}
            name="old_password"
            label="Your current password"
            type="password"
            fullWidth
            variant="outlined"
          />
          <FormTextField
            form={form}
            name="password"
            label="New password"
            type="password"
            fullWidth
            variant="outlined"
          />
          <FormTextField
            form={form}
            name="password_again"
            label="Confirm new password"
            type="password"
            fullWidth
            variant="outlined"
          />
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { copyToClipboard } from "logic/clipboard";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";

export type CodeProps = {
  content: string;
};

export const Code: FC<CodeProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <SCode>
      <Stack gap="0.5rem">
        <div>{props.content}</div>

        <Button
          css={{ alignSelf: "end" }}
          onClick={async () => {
            try {
              await copyToClipboard(props.content);
              dispatch(toastMessage("info", "Copied to clipboard."));
            } catch (err) {
              dispatch(toastError(err, "Failed to copy to clipboard."));
            }
          }}
        >
          Copy to clipboard
        </Button>
      </Stack>
    </SCode>
  );
};

const SCode = styled.code({
  position: "relative",
  borderRadius: "0.25rem",
  backgroundColor: "#eee",
  padding: "0.75rem",
  overflowX: "auto",
  color: "black",
  fontSize: "0.875rem",
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
});

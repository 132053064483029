import { bound } from "utils/class";

export function describeAirportIcao(icao: string) {
  return bound({
    getWeatherUrl() {
      return "/" + icao.toLowerCase();
    },

    getKioskUrl() {
      return `/kiosk/${icao.toLowerCase()}`;
    },

    getBannerUrl() {
      return `${process.env.REACT_APP_SERVER_URI}/api/airports/${icao}/banner`;
    },

    getWebcamUrl() {
      return `${process.env.REACT_APP_SERVER_URI}/api/airports/${icao}/webcam-url`;
    },
  });
}

import styled from "@emotion/styled";
import { FC, PropsWithChildren } from "react";

export type BlockProps = PropsWithChildren<{
  inside?: boolean;
}>;

export const FlexBlock: FC<BlockProps> = (props) => {
  return <SFlexBlock inside={props.inside}>{props.children}</SFlexBlock>;
};

const SFlexBlock = styled.div<{ inside: boolean | undefined }>(
  { display: "flex", width: "100%", margin: "1rem 1rem" },
  (props) => ({
    justifyContent: props.inside ? "left" : "center",
  })
);

import React, { FC, SetStateAction } from "react";
import styled from "@emotion/styled";
import { useAirportWeather } from "logic/weather/hooks";
import { useParams } from "react-router-dom";
import { responsive } from "mixins";
import { addAndFormatTime } from "utils/global.functions";

import Slider from "@mui/material/Slider";

export type TimeSliderProps = {
  forecastValue: number;
  setForecastValue: React.Dispatch<SetStateAction<number>>;
};

const TimeSlider: FC<TimeSliderProps> = ({
  forecastValue,
  setForecastValue,
}) => {
  const params = useParams();
  const icao = params.icao;

  const weather = useAirportWeather(icao).report();
  const formattedTimeSteps = weather
    ?.selectForecastSource({ toggledToAw: true })
    ?.getWind()
    ?.getFormattedTimeStepsInObject();
  const uniqueDays = weather
    ?.selectForecastSource({ toggledToAw: true })
    ?.getWind()
    ?.getUniqueDays();
  const validTimeSteps = weather
    ?.selectForecastSource({ toggledToAw: true })
    ?.getWind().time_steps;

  // calculating forecasted time when user moves the slider
  const forecastedTime = () => {
    if (!validTimeSteps?.[0]) return "HH:MM";
    const offset = forecastValue * 60 * 1000;
    const formatted = addAndFormatTime(validTimeSteps[0].valid_time, offset);
    return formatted;
  };

  const updateWeatherNowcast = (e: any) => {
    e.preventDefault();
    setForecastValue(e?.target?.value);
  };

  return (
    <StyledNowcastControl>
      <div className="forecastLabels">
        <div className="title">Nowcast</div>
        <div className="dateTime">{uniqueDays?.join(" / ")}</div>
      </div>

      {/* forecast slider */}
      <div className="forecastSlider">
        <Slider
          sx={{
            height: 8,
            marginBottom: "0rem",
            transform: "translateY(-0.6rem)",
            paddingBottom: "1.5rem",
            "& .MuiSlider-valueLabel": {
              backgroundColor: "#009ff5",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: "#009ff5",
            },
            "& .MuiSlider-track": {
              borderColor: "#009ff5",
              backgroundColor: "#009ff5",
            },
            "& .MuiSlider-mark": {
              display: "none",
            },
            "& .MuiSlider-markLabel": {
              color: "black",
              top: "2rem",
              fontSize: "13px",
            },
          }}
          defaultValue={0}
          step={10}
          min={0}
          max={300}
          marks={formattedTimeSteps}
          valueLabelDisplay="on"
          onChange={updateWeatherNowcast}
          valueLabelFormat={forecastedTime()}
        />
      </div>
    </StyledNowcastControl>
  );
};

export default TimeSlider;

const StyledNowcastControl = styled.div`
  position: absolute;
  bottom: 20px;

  width: 100%;
  z-index: 1000;

  display: flex;
  padding: 0 10px;

  /* iOS address bar height */
  /* padding-bottom: env(safe-area-inset-bottom); */
  /* padding-bottom: calc(1rem + env(safe-area-inset-bottom)); */
  /* margin-bottom: max(0.2rem, env(safe-area-inset-bottom)); */
  /* margin-bottom: env(safe-area-inset-bottom); */
  padding-bottom: calc(
    0.2rem + env(safe-area-inset-bottom) + env(safe-area-inset-top)
  );

  .forecastLabels {
    background-color: rgba(241, 241, 242, 0.9);
    width: 100px;
    font-size: 11px;
    padding: 2px;
    padding-left: 7px;

    border-radius: 0.5rem 0 0 0.5rem;

    ${responsive.mobilP} {
      font-size: 9px;
      width: 80px;
    }

    ${responsive.mobilS} {
      font-size: 8px;
    }

    .title {
      text-transform: uppercase;
      padding-bottom: 0.3rem;
      padding-top: 0.2rem;
    }
  }

  .forecastSlider {
    width: 100%;
    background-color: rgba(241, 241, 242, 0.9);
    position: relative;
    padding-right: 25px;
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

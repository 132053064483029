import { FC } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { awdApi, useGetMyRolesQuery } from "store/reducers/awdApi";
import { localApi } from "store/reducers/localApi";
import { useRunOnceReady } from "utils/hooks";
import { encodeQuery } from "utils/general";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";
import { describeAirportIcao } from "logic/airport/icao/describe";

export type MobileAppEntrypointPageProps = {};

export const MobileAppEntrypointPage: FC<MobileAppEntrypointPageProps> = (
  props
) => {
  const [setConfig] = localApi.endpoints.setMobileAppConfig.useMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const userMeData = awdApi.endpoints.getUserMe.useQuery();
  const airportRoleData = awdApi.endpoints.getMyAirportRoles.useQuery();
  const myRolesQuery = useGetMyRolesQuery();

  const isSupervizor = myRolesQuery.data?.roles.some((r) => r === "supervizor");
  const isSuperdemo = myRolesQuery.data?.roles.some((r) => r === "superdemo");
  const isManager = airportRoleData.data?.roles.some(
    (r) => r.role === "manager"
  );

  const icao = useFavoriteAirports().homeAirport()?.airport?.icao;
  const status = userMeData.status;

  const readyUnauthenticated = status === "rejected";
  const readyAuthenticatedPilot = status === "fulfilled" && !!icao;
  const readyAuthenticatedManager = status === "fulfilled" && isManager;
  const readyAuthenticatedSupervizor =
    status === "fulfilled" && (isSupervizor || isSuperdemo);

  useRunOnceReady(
    readyUnauthenticated ||
      !!readyAuthenticatedManager ||
      readyAuthenticatedPilot ||
      !!readyAuthenticatedSupervizor,
    async () => {
      const authToken = searchParams.get("auth_token");
      await setConfig({ is_mobile_app: true });

      if (authToken) {
        await dispatch(
          awdApi.endpoints.loginWithMobileAppAuthenticationToken.initiate({
            token: authToken,
          })
        );
      }

      if (readyAuthenticatedManager || readyAuthenticatedSupervizor) {
        navigate(encodeQuery("/me/dashboard"), { replace: true });
      } else if (readyAuthenticatedPilot) {
        const url = describeAirportIcao(icao).getWeatherUrl();
        navigate(encodeQuery(url), { replace: true });
      } else if (readyUnauthenticated) {
        navigate(encodeQuery("/"), { replace: true });
      }
    }
  );
  return null;
};

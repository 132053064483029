import { describeAirportIcao } from "logic/airport/icao/describe";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type KioskBannerProps = { icao: string };

export const KioskBanner: FC<KioskBannerProps> = (props) => {
  const { error } = awdApi.endpoints.getAirportBanner.useQuery({
    icao: props.icao,
  });

  if (error) return null;

  return (
    <img
      src={describeAirportIcao(props.icao).getBannerUrl()}
      alt="banner"
      css={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
    />
  );
};

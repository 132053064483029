import { Alert, Typography } from "@mui/material";
import { localApi } from "store/reducers/localApi";

export const DebugModeAlert = () => {
  const atStaging =
    process.env.REACT_APP_ENV === "staging" ||
    process.env.REACT_APP_ENV === "release-candidate" ||
    process.env.REACT_APP_ENV === "development";

  const isMobileApp =
    localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ??
    false;

  if (!atStaging || !isMobileApp) return null;
  return (
    <Alert variant="standard" color="error" icon={false}>
      <Typography color="red">Warning - {process.env.REACT_APP_ENV}</Typography>
    </Alert>
  );
};

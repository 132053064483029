import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Table, TableCell, TableRow } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player/youtube";
import { ModalSubtitle } from "components/modal/Modal";

export const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_node, children) => <h4>{children}</h4>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // check if content is image
      if (node.data.target.fields.file.contentType.includes("image")) {
        return (
          <img
            src={node.data.target.fields.file.url}
            alt={node.data.target.fields.file.name}
          />
        );
      }
      // check if content is video
      if (node.data.target.fields.file.contentType.includes("video")) {
        return <video src={node.data.target.fields.file.url} controls />;
      }
      return null;
    },
    [BLOCKS.TABLE]: (node, children) => {
      return <Table>{children}</Table>;
    },
    [BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_CELL]: (node, children) => {
      const content = node.content[0];
      if (!content || content.nodeType === "text") {
        return <TableCell>{children}</TableCell>;
      }

      const contents = content.content;
      const embeddedItem = contents.find(
        (c) => c.nodeType === "embedded-entry-inline"
      );
      if (!embeddedItem) {
        return <TableCell>{children}</TableCell>;
      }
      return (
        <TableCell>
          {
            <img
              src={embeddedItem.data.target.fields.icon.fields.file.url}
              alt={embeddedItem.data.target.fields.icon.fields.file.name}
            />
          }
        </TableCell>
      );
    },
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <TableCell>{children}</TableCell>
    ),
  },
};

export const useDefaultOptions = () => {
  const options: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // check if text
        if (node.content[0]?.nodeType === "text") {
          // check if YouTube link
          const text = node.content[0].value;
          if (text.startsWith("https://www.youtube.com")) {
            return (
              <div css={{ aspectRatio: "16/9" }}>
                <ReactPlayer
                  url={text}
                  width="100%"
                  height="100%"
                  playsinline
                />
              </div>
            );
          }
        }

        return <p>{children}</p>;
      },
    },
  };

  return options;
};

export const useModalOptions = () => {
  const defaultOptions = useDefaultOptions();
  const options: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      [BLOCKS.HEADING_4]: (node, children) => (
        <ModalSubtitle>{children}</ModalSubtitle>
      ),
    },
  };
  return options;
};

import { isNonNullable } from "utils/general";
import { GeoPath, GeoPermissibleObjects, GeoProjection } from "d3-geo";

export const calculateMiddleOfRunway = ({
  startPoint,
  endPoint,
  path,
  inParallelX,
  inParallelY,
  projectionFitted,
}: {
  startPoint: [number, number];
  endPoint: [number, number];
  inParallelX: number;
  inParallelY: number;
  path: GeoPath<any, GeoPermissibleObjects>;
  projectionFitted: GeoProjection;
}) => {
  const projection = projectionFitted(startPoint);
  if (!projection) return undefined;

  const projectedRunwayLength = path.measure({
    type: "LineString",
    coordinates: [startPoint, endPoint],
  });

  const middleOfRunwayParams = [
    (inParallelX * projectedRunwayLength) / 2,
    (inParallelY * projectedRunwayLength) / 2,
  ];

  if (!middleOfRunwayParams[0] || !middleOfRunwayParams[1]) return undefined;

  const middleOfRunway = [
    projection[0] + middleOfRunwayParams[0],
    projection[1] - middleOfRunwayParams[1],
  ];

  return middleOfRunway as [number, number];
};

// calculating both intersections of line and circle based on a point and tangens of an angle and center of a circle
const calculateIntersectionsLineCircle = (
  middleOfRunway: [number, number],
  tangensAngle: number,
  centerOfCircle: [number, number],
  circleRadius: number
) => {
  const q = middleOfRunway[1] - tangensAngle * middleOfRunway[0];

  const aPart = 1 + tangensAngle ** 2;
  const bPart = 2 * (tangensAngle * q - tangensAngle * centerOfCircle[1] - centerOfCircle[0]);
  const cPart =
    centerOfCircle[0] ** 2 +
    q ** 2 -
    2 * q * centerOfCircle[1] +
    centerOfCircle[1] ** 2 -
    circleRadius ** 2;

  const determinant = Math.sqrt(bPart ** 2 - 4 * aPart * cPart);

  const intersection1A = (-bPart + determinant) / (2 * aPart);
  const intersection1B = (-bPart - determinant) / (2 * aPart);

  const intersection2A = tangensAngle * intersection1A + q;
  const intersection2B = tangensAngle * intersection1B + q;

  const intersection1 = [intersection1A, intersection2A];
  const intersection2 = [intersection1B, intersection2B];

  return [intersection1, intersection2];
};

// selecting which intersection point to use for wind line
const selectIntersection = ({
  angleWind,
  intersection1,
  intersection2,
}: {
  angleWind: number;
  intersection1: number[];
  intersection2: number[];
}) => {
  if (angleWind > 0 && angleWind < 180) {
    return intersection1;
  } else {
    return intersection2;
  }
};

// calculating positions for little arrows along the wind line
const calculateInBetweenPositionsOnLine = ({
  windLineBoundary,
  windLineStep,
  inWindXArrows,
  inWindYArrows,
}: {
  windLineBoundary: { x: number; y: number }[];
  windLineStep: number;
  inWindXArrows: number;
  inWindYArrows: number;
}) => {
  let i = 0;
  let run = true;
  const arrayOfArrows = [];
  if (!windLineBoundary[0] || !windLineBoundary[1]) return undefined;
  do {
    const xCoef = windLineBoundary[0].x + (i + 1) * windLineStep * inWindXArrows;
    const yCoef = windLineBoundary[0].y - (i + 1) * windLineStep * inWindYArrows;
    if (xCoef < windLineBoundary[1].x) arrayOfArrows.push([xCoef, yCoef]);
    else run = false;
    i++;
  } while (run);

  return arrayOfArrows;
};

export const calculateArrayOfArrows = ({
  middleOfRunway,
  tangensWind,
  inWindXArrows,
  inWindYArrows,
  angleWind,
  centerOfCompass,
  circleRadius,
}: {
  middleOfRunway: [number, number];
  tangensWind: number;
  inWindXArrows: number;
  inWindYArrows: number;
  angleWind: number;
  centerOfCompass: [number, number];
  circleRadius: number;
}) => {
  const windLineStep = 25;

  // both intersections of line and circle
  const intersections = calculateIntersectionsLineCircle(
    middleOfRunway,
    tangensWind,
    centerOfCompass,
    circleRadius
  );

  if (!intersections[0] || !intersections[1]) return undefined;

  //  selecting the right intersection based on wind direction
  const intersection = selectIntersection({
    angleWind,
    intersection1: intersections[0],
    intersection2: intersections[1],
  });

  if (!intersection[0] || !intersection[1]) return undefined;

  // calculating wind line start and end points, sorted by x coefficient from min to max
  const windLineBoundary = [
    { x: intersection[0], y: intersection[1] },
    { x: middleOfRunway[0], y: middleOfRunway[1] },
  ].sort((a, b) => a.x - b.x);

  // positions of little arrows along the wind line
  const arrayOfArrows = calculateInBetweenPositionsOnLine({
    windLineBoundary,
    windLineStep,
    inWindXArrows,
    inWindYArrows,
  });

  return arrayOfArrows as [number, number][];
};

// normalizing angles to be between (0,360)
const isOver360Below0 = (angle: number) => {
  const returnAngle = angle > 360 ? angle - 360 : angle < 0 ? 360 + angle : angle;
  return returnAngle;
};

// calculating quadrants for each runway - where the directions for crosswind arrows change
export const countRunwayInflectionPoints = (runwayAngle: number) => {
  const inflectionPoints: [number, number][] = [
    [isOver360Below0(runwayAngle - 90), runwayAngle],
    [runwayAngle, isOver360Below0(runwayAngle + 90)],
    [isOver360Below0(runwayAngle + 90), isOver360Below0(runwayAngle + 180)],
    [isOver360Below0(runwayAngle + 180), isOver360Below0(runwayAngle - 90)],
  ];

  return inflectionPoints
    .map((e: [number, number]) => {
      // when it gets over 360, add 'true', otherwise 'false'
      return e[0] > e[1]
        ? { isOver360: true, interval: [e[0], e[1]] }
        : { isOver360: false, interval: [e[0], e[1]] };
    })
    .filter(isNonNullable);
};

// deciding comparison which quadrant the angle falls into
const isInWhichQuadrant = (
  angleWind: number,
  inflectionPoints: { isOver360: boolean; interval: number[] }[],
  quadrant: 1 | 2 | 3 | 4
) => {
  const lowerBoundary = inflectionPoints[quadrant - 1]?.interval[0];
  const higherBounday = inflectionPoints[quadrant - 1]?.interval[1];
  if ((!lowerBoundary && lowerBoundary !== 0) || (!higherBounday && higherBounday !== 0))
    return undefined;
  return inflectionPoints[quadrant - 1]?.isOver360
    ? (angleWind > lowerBoundary && angleWind <= 360) ||
        (angleWind >= 0 && angleWind < higherBounday)
    : angleWind > lowerBoundary && angleWind < higherBounday;
};

// all the variants how the crosswind arrows can be oriented based on angle of wind
export const isWhichQuadrant = (
  angleWind: number,
  inflectionPoints: { isOver360: boolean; interval: number[] }[]
) => {
  if (!inflectionPoints[0] || !inflectionPoints[1] || !inflectionPoints[2] || !inflectionPoints[3])
    return undefined;
  let quadrant = "";
  switch (true) {
    case angleWind === inflectionPoints[0].interval[1]:
      quadrant = "parallelDirection";
      break;
    case angleWind === inflectionPoints[2].interval[1]:
      quadrant = "oppositeDirection";
      break;
    case angleWind === inflectionPoints[1].interval[1]:
      quadrant = "edgeOfQuadrantTwoQuadrantThree";
      break;
    case angleWind === inflectionPoints[3].interval[1]:
      quadrant = "edgeOfQuadrantFourQuadrantOne";
      break;
    case isInWhichQuadrant(angleWind, inflectionPoints, 1):
      quadrant = "quadrantOne";
      break;
    case isInWhichQuadrant(angleWind, inflectionPoints, 2):
      quadrant = "quadrantTwo";
      break;
    case isInWhichQuadrant(angleWind, inflectionPoints, 3):
      quadrant = "quadrantThree";
      break;
    case isInWhichQuadrant(angleWind, inflectionPoints, 4):
      quadrant = "quadrantFour";
      break;
  }
  return quadrant;
};

import { RunwayLongView } from "awd-server-api";
import { isNonNullable } from "utils/general";
import { geoMercator, geoPath } from "d3-geo";

import {
  calculateRunwayPolygons,
  calculateInnerPolygons,
  calculateCentralLine,
  calculateRunwayEdge,
  calculateTextHe,
  calculateTextLe,
} from "components/RunwaysViz/logic/runwayPartVizLogis";
import {
  calculateArrayOfArrows,
  isWhichQuadrant,
  countRunwayInflectionPoints,
  calculateMiddleOfRunway,
} from "components/RunwaysViz/logic/crosswindPartVizLogic";

export const filterOutClosedRunways = (runways: Array<RunwayLongView>) => {
  return runways.filter((r) => !r.closed);
};

const initializeStartingPoints = ({
  lowerLng,
  lowerLat,
  higherLng,
  higherLat,
}: {
  lowerLng: number;
  lowerLat: number;
  higherLng: number;
  higherLat: number;
}): { lowerPoint: [number, number]; higherPoint: [number, number] } => {
  return {
    lowerPoint: [lowerLng, lowerLat],
    higherPoint: [higherLng, higherLat],
  };
};

const calculateRunwayAngles = (lowerHeading: number) => {
  const angleRunway = lowerHeading * (Math.PI / 180);
  const anglePerpendicular = (lowerHeading + 90) * (Math.PI / 180);
  const inParallelX = Math.sin(angleRunway);
  const inParallelY = Math.cos(angleRunway);
  const inPerpX = Math.sin(angleRunway - Math.PI / 2);
  const inPerpY = Math.cos(angleRunway - Math.PI / 2);

  return {
    angleOfRunwayDegrees: lowerHeading,
    normalAngleOfRunwayDegrees: lowerHeading + 90,
    angleRunway,
    anglePerpendicular,
    inParallelX,
    inParallelY,
    inPerpX,
    inPerpY,
  };
};

const calculateWindAngles = (angleWind: number) => {
  const inWindX = Math.sin(angleWind * (Math.PI / 180));
  const inWindY = Math.cos(angleWind * (Math.PI / 180));
  const inWindXArrows =
    angleWind === 180
      ? -Math.sin(180.001 * (Math.PI / 180))
      : angleWind === 360
      ? -Math.sin(359.999 * (Math.PI / 180))
      : angleWind === 0
      ? -Math.sin(0.001 * (Math.PI / 180))
      : angleWind > 180 && angleWind < 360
      ? -Math.sin(angleWind * (Math.PI / 180))
      : Math.sin(angleWind * (Math.PI / 180));
  const inWindYArrows =
    angleWind === 180
      ? -Math.cos(180.001 * (Math.PI / 180))
      : angleWind === 360
      ? -Math.cos(359.999 * (Math.PI / 180))
      : angleWind === 0
      ? -Math.cos(0.001 * (Math.PI / 180))
      : angleWind > 180 && angleWind < 360
      ? -Math.cos(angleWind * (Math.PI / 180))
      : Math.cos(angleWind * (Math.PI / 180));
  const tangensWind = Math.tan(
    (angleWind === 180 ? 180.001 - 90 : angleWind === 360 ? 359.999 - 90 : angleWind - 90) *
      (Math.PI / 180)
  );
  return {
    angleWind,
    inWindX,
    inWindY,
    inWindXArrows,
    inWindYArrows,
    tangensWind,
  };
};

const calculateVisualizationParameters = (runways: Array<RunwayLongView>) => {
  // putting runways data into geoJson to generate projection to fit into svg
  const geoJson = {
    type: "FeatureCollection",
    geometries: [],
    features: runways
      .map((r) => {
        if (r.closed) return null;
        if (!r.leLatitude || !r.leLongitude || !r.heLatitude || !r.heLongitude) return null;
        return {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: [
              [r.leLongitude, r.leLatitude],
              [r.heLongitude, r.heLatitude],
            ],
          },
        };
      })
      .filter(isNonNullable),
  };

  // using mercator projection - getting x/y from lng/lat
  // fitting projection into svg with fitExtent
  const projectionBase = runways && geoMercator();
  const projectionFitted =
    projectionBase &&
    projectionBase.fitExtent(
      [
        [50, 50],
        [750, 750],
      ],
      geoJson
    );

  // path generator function returning d for svg path element
  // to be able to reach bounds of the projection
  const path = geoPath().projection(projectionFitted);

  // getting bounds of runways in projection
  const bounds = path.bounds(geoJson);

  // DATA FOR COMPASS
  // calculating center of the compass
  const centerOfCompass =
    bounds &&
    ([
      (bounds[1][0] - bounds[0][0]) / 2 + bounds[0][0],
      (bounds[1][1] - bounds[0][1]) / 2 + bounds[0][1],
    ] as [number, number]);

  // calculating radius of the compass
  const circleRadius =
    bounds &&
    Math.sqrt((bounds[1][0] - bounds[0][0]) ** 2 + (bounds[1][1] - bounds[0][1]) ** 2) / 2;

  const svgBounds = {
    x: [
      Math.ceil(centerOfCompass[0] - circleRadius - 135 - 5),
      Math.ceil(centerOfCompass[0] + circleRadius + 135 + 5),
    ],
    y: [
      Math.ceil(centerOfCompass[1] - circleRadius - 180 - 5),
      Math.ceil(centerOfCompass[1] + circleRadius + 135 + 5),
    ],
  };

  return {
    vizBounds: bounds,
    centerOfCompass,
    circleRadius,
    projection: projectionFitted,
    path,
    svgBounds,
  };
};

// aggregator function that transforms runways data and wind data
// into appropriate format for visualization
export const transformRunwayWindDataForViz = (
  runways: Array<RunwayLongView> | undefined,
  angleWind: number
) => {
  if (!runways || angleWind == null) return undefined;

  const vizParams = calculateVisualizationParameters(runways);
  const filteredOutClosedRunways = filterOutClosedRunways(runways);
  const windAngles = calculateWindAngles(angleWind);

  const runwayWidth = 22;

  const dataForViz = filteredOutClosedRunways
    ?.map((r) => {
      if (
        !r.leHeading ||
        !r.leLongitude ||
        !r.leLatitude ||
        !r.heLongitude ||
        !r.heLatitude ||
        !r.leIdent ||
        !r.heIdent
      )
        return undefined;

      const runwayAngles = calculateRunwayAngles(r.leHeading);
      const startingPoints = initializeStartingPoints({
        lowerLng: r.leLongitude,
        lowerLat: r.leLatitude,
        higherLng: r.heLongitude,
        higherLat: r.heLatitude,
      });

      // coefficients for middle of a runway
      const middleOfRunway = calculateMiddleOfRunway({
        startPoint: startingPoints.lowerPoint,
        endPoint: startingPoints.higherPoint,
        inParallelX: runwayAngles.inParallelX,
        inParallelY: runwayAngles.inParallelY,
        path: vizParams.path,
        projectionFitted: vizParams.projection,
      });

      if (!middleOfRunway) return undefined;

      const runwayPolygon = calculateRunwayPolygons({
        lowerPoint: startingPoints?.lowerPoint,
        higherPoint: startingPoints?.higherPoint,
        anglePerpendicular: runwayAngles?.anglePerpendicular,
        runwayWidth: runwayWidth,
        projectionFitted: vizParams.projection,
      });

      if (!runwayPolygon) return undefined;

      const innerPolygons = calculateInnerPolygons({
        lowerPoint: startingPoints.lowerPoint,
        higherPoint: startingPoints.higherPoint,
        inParallelX: runwayAngles.inParallelX,
        inParallelY: runwayAngles.inParallelY,
        inPerpX: runwayAngles.inPerpX,
        inPerpY: runwayAngles.inPerpY,
        projectionFitted: vizParams.projection,
        runwayWidth: runwayWidth,
      });

      if (!innerPolygons) return undefined;

      const centralLine = calculateCentralLine({
        lowerPoint: startingPoints?.lowerPoint,
        higherPoint: startingPoints?.higherPoint,
        inParallelX: runwayAngles.inParallelX,
        inParallelY: runwayAngles.inParallelY,
        projectionFitted: vizParams.projection,
        lineParameter: 40,
      });

      if (!centralLine) return undefined;

      const nameLe = r.leIdent;
      const nameHe = r.heIdent;

      const textLe = calculateTextLe({
        lowerPoint: startingPoints?.lowerPoint,
        inParallelX: runwayAngles.inParallelX,
        inParallelY: runwayAngles.inParallelY,
        textParameter: 20,
        projectionFitted: vizParams.projection,
      });
      const textHe = calculateTextHe({
        higherPoint: startingPoints?.higherPoint,
        inParallelX: runwayAngles.inParallelX,
        inParallelY: runwayAngles.inParallelY,
        textParameter: 20,
        projectionFitted: vizParams.projection,
      });

      if (!textLe || !textHe) return undefined;

      const runwayEdgeLe = calculateRunwayEdge({
        point: startingPoints.lowerPoint,
        anglePerpendicular: runwayAngles?.anglePerpendicular,
        projectionFitted: vizParams.projection,
        runwayWidth: runwayWidth,
      });
      const runwayEdgeHe = calculateRunwayEdge({
        point: startingPoints.higherPoint,
        anglePerpendicular: runwayAngles?.anglePerpendicular,
        projectionFitted: vizParams.projection,
        runwayWidth: runwayWidth,
      });

      if (!runwayEdgeLe || !runwayEdgeHe) return undefined;

      // positions of little arrows along the wind line
      const arrayOfArrows = calculateArrayOfArrows({
        middleOfRunway: middleOfRunway,
        tangensWind: windAngles.tangensWind,
        inWindXArrows: windAngles.inWindXArrows,
        inWindYArrows: windAngles.inWindYArrows,
        angleWind: angleWind,
        centerOfCompass: vizParams.centerOfCompass,
        circleRadius: vizParams.circleRadius,
      });

      if (!arrayOfArrows) return undefined;

      const windInQuadrant = isWhichQuadrant(angleWind, countRunwayInflectionPoints(r.leHeading));

      if (!windInQuadrant) return undefined;

      return {
        runways: {
          runwayPolygon,
          innerPolygons,
          centralLine,
          runwayEdgeLe,
          runwayEdgeHe,
          nameLe,
          textLe,
          nameHe,
          textHe,
          angleOfRunway: runwayAngles.angleOfRunwayDegrees,
        },

        crosswind: {
          nameLe,
          nameHe,
          middleOfRunway,
          arrayOfArrows,
          windInQuadrant,
          angleOfRunway: runwayAngles.angleOfRunwayDegrees,
          normalAngleOfRunway: runwayAngles.normalAngleOfRunwayDegrees,
        },
      };
    })
    .filter(isNonNullable);

  return {
    runways: dataForViz.map((r) => r.runways),
    crosswind: dataForViz.map((r) => r.crosswind),
    compass: {
      centerOfCompass: vizParams.centerOfCompass,
      circleRadius: vizParams.circleRadius,
      svgBounds: vizParams.svgBounds,
    },
    svg: {
      svgBounds: vizParams.svgBounds,
    } as { svgBounds: { x: [number, number]; y: [number, number] } },
  };
};

export type RunwaysDataForViz = {
  runwayPolygon: [[number, number], [number, number], [number, number], [number, number]];
  innerPolygons: [[number, number], [number, number], [number, number], [number, number]][];
  centralLine: [[number, number], [number, number]];
  runwayEdgeLe: [[number, number], [number, number]];
  runwayEdgeHe: [[number, number], [number, number]];
  nameLe: string;
  textLe: number[];
  nameHe: string;
  textHe: number[];
  angleOfRunway: number;
}[];

export type CrosswindDataForViz = {
  windInQuadrant: string;
  angleOfRunway: number;
  normalAngleOfRunway: number;
  middleOfRunway: [number, number];
  nameLe: string;
  nameHe: string;
  arrayOfArrows: [number, number][];
}[];

export const sortSelectedRunwayOnTop = (selectedRunway: string, runwaysData: RunwaysDataForViz) => {
  const sorted = runwaysData.filter(isNonNullable).sort((a, b) => {
    if (
      (a.nameLe === selectedRunway || a.nameHe === selectedRunway) &&
      (b.nameLe !== selectedRunway || b.nameHe !== selectedRunway)
    ) {
      return 1;
    } else if (
      (a.nameLe !== selectedRunway || a.nameHe !== selectedRunway) &&
      (b.nameLe === selectedRunway || b.nameHe === selectedRunway)
    ) {
      return -1;
    } else return 0;
  });
  return sorted;
};

export const checkIfRunwayDataForVizAreMissing = (runways: RunwayLongView[]) => {
  if (runways.length === 0) return true;
  return runways
    .map((r) => {
      if (
        !r.leHeading ||
        !r.heHeading ||
        !r.leIdent ||
        !r.heIdent ||
        !r.leLongitude ||
        !r.leLatitude ||
        !r.heLongitude ||
        !r.heLatitude
      )
        return false;
      else return true;
    })
    .some((e) => e === false);
};

export const checkIfRunwayDataForVizAreCorrect = (runways: RunwayLongView[]) => {
  if (runways.length === 0) return false;

  const checkRunways = runways
    .map((r) => {
      if (r.leHeading === r.heHeading) return false;
      if (r.leIdent === r.heIdent) return false;
      if (r.leLongitude === r.heLongitude) return false;
      if (r.leLatitude === r.heLatitude) return false;
      return true;
    })
    .some((e) => e === false);

  return !checkRunways;
};

// const compose = (...fns) =>
//   fns.reduce((accumulatorFn, currentFn) => {
//     return (...args) => {
//       return accumulatorFn(currentFn(...args));
//     };
//   });

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { Spacer } from "components/layout/Spacer";
import { ResultModal } from "components/modal/inside/ResultModal";
import { RunwayForm } from "components/runwayForm/RunwayForm";
import { emptyRunway } from "logic/airport/runway/empty";
import {
  preparePostBody,
  RunwayFormValues,
  zRunwayForm,
} from "logic/airport/runway/form";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";

export type AddRunwayModalProps = {
  icao: string;
};

export const AddRunwayModal: FC<AddRunwayModalProps> = (props) => {
  const dispatch = useDispatch();

  const form = useForm<RunwayFormValues>({
    resolver: zodResolver(zRunwayForm),
  });

  return (
    <ResultModal
      title="Add runway"
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.postRunway.initiate({
              airportId: props.icao,
              body: preparePostBody(body),
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(toastError(result.error, "We could not add the runway."));
            cancel();
          } else {
            dispatch(toastMessage("success", "The runway has been added."));
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "80rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        {!form.formState.isLoading && (
          <RunwayForm form={form} runway={emptyRunway} />
        )}
      </DialogContent>
    </ResultModal>
  );
};

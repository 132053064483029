import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ShouldNeverHappenError } from "utils/error";
import { resolvablePromise } from "utils/general";
import { NonNullableTuple } from "utils/types";

export function useDebouncedValue<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type UseRunOnChangeCallback<T> = (prev: T, next: T) => void;

/**
 * Similar to useEffect, but the callback is triggered only if a single value changes.
 * This can be used to access variables from the callback's scope without the need to have them trigger the callback should they change.
 *
 * Another feature is that the callback remembers the last value and passes it along with the current value in the callback arguments.
 */
export function useRunOnChange<T>(
  value: T,
  callback: UseRunOnChangeCallback<T>,
  opts?: { noRunOnMount?: boolean }
) {
  const ref = useRef<
    { hasMounted: false } | { hasMounted: true; lastValue: T }
  >({
    hasMounted: false,
  });

  useEffect(() => {
    const isMount = !ref.current.hasMounted;
    if (isMount) {
      ref.current = {
        hasMounted: true,
        lastValue: value,
      };
    }
    if (!ref.current.hasMounted) {
      // Has mounted should have been set in all code paths.
      throw new ShouldNeverHappenError();
    }
    const hasChanged = ref.current.lastValue !== value;
    const runningCallbackOnMount = isMount && !opts?.noRunOnMount;

    const shouldRun = hasChanged || runningCallbackOnMount;

    if (!shouldRun) return;

    callback(ref.current.lastValue, value);
    ref.current.lastValue = value;
  }, [value, callback, opts]);
}

export function useRunOnlyOnChange<T>(
  value: T,
  callback: UseRunOnChangeCallback<T>
) {
  return useRunOnChange(value, callback, { noRunOnMount: true });
}

/**
 * Works pretty much like useEffect, but there are a few benefits over useEffect:
 * - you get access to the previous and next dependencies tuple
 * - you can access functions or values without having them trigger the effect when they change
 * - you can choose to not run the effect on mount
 */
export function useRunOnTupleChange<T extends readonly [...unknown[]]>(
  value: T,
  callback: UseRunOnChangeCallback<T>,
  opts?: { noRunOnMount?: boolean }
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memo = useMemo(() => value, [...value]);
  useRunOnChange(memo, callback, opts);
}

export function useRunOnlyOnTupleChange<T extends readonly [...unknown[]]>(
  value: T,
  callback: UseRunOnChangeCallback<T>
) {
  useRunOnTupleChange(value, callback, { noRunOnMount: true });
}

export function useLatestValueRef<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

/**
 * Combines the useLatestValueRef and useCallback to provide a callback that doesn't change reference, yet uses the latest closure.
 */
export const useLatestCallback = <
  TCallback extends (...args: any[]) => Promise<unknown> | unknown,
>(
  callback: TCallback
): TCallback => {
  const latest = useLatestValueRef(callback);
  const latestCallback = useCallback(
    (...args: Parameters<TCallback>) => latest.current(...args),
    [latest]
  );
  return latestCallback as TCallback;
};

export function useRunOnceResolved<T>(
  getPromise: () => Promise<T>,
  callback: (value: T) => void | Promise<void>
) {
  const latestCallback = useLatestCallback(callback);
  useRunOnceReady(true, async () => {
    await latestCallback(await getPromise());
  });
}

export function useRunOnceReady(
  ready: boolean,
  callback: () => void | (() => void) | Promise<void>
) {
  const done = useRef(false);
  const latestCallback = useLatestCallback(callback);

  ready = done.current || ready;

  useEffect(() => {
    if (!done.current && ready) {
      done.current = true;
      const destructorOrPromise = latestCallback?.();
      const destructor =
        destructorOrPromise && "then" in destructorOrPromise
          ? undefined
          : destructorOrPromise;
      return destructor;
    }
  }, [ready, latestCallback]);
}

export function useRunOnceAllValuesAreDefined<
  TTuple extends readonly [...unknown[]],
>(tuple: TTuple, callback: (tuple: NonNullableTuple<TTuple>) => void) {
  useRunOnceReady(
    tuple.every((val) => val != null),
    () => callback(tuple as NonNullableTuple<TTuple>)
  );
}

export function useResolveOnceDefined<T>(
  value: T | undefined | null
): Promise<T> {
  const [{ resolve, promise }] = useState(() => resolvablePromise<T>());

  useRunOnceAllValuesAreDefined([value] as const, ([defined]) =>
    resolve(defined)
  );

  return promise;
}

export function useInterval(ms: number, callback: () => void) {
  const latest = useLatestCallback(callback);
  useEffect(() => {
    const interval = setInterval(latest, ms);
    return () => clearInterval(interval);
  }, [latest, ms]);
}

export function useObserveElementSize() {
  const [element, ref] = useState<HTMLElement | null>(null);
  const updateSize = useLatestCallback(() => {
    setSize({ width: element?.offsetWidth, height: element?.offsetHeight });
  });
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({ width: undefined, height: undefined });
  const [observer] = useState(() => new ResizeObserver(updateSize));

  useEffect(() => {
    if (!element) return;

    updateSize();

    observer.observe(element);
    return () => observer.unobserve(element);
  }, [element, observer, updateSize]);

  return { size, ref };
}

export const useLoadingCallback = <
  TCallback extends (...args: any[]) => Promise<unknown> | unknown,
>(
  callback: TCallback
) => {
  const [loading, setLoading] = useState<
    { args: Parameters<TCallback> } | undefined
  >(undefined);

  const loadingCallback = useLatestCallback(
    async (...args: Parameters<TCallback>) => {
      try {
        setLoading({ args });
        return await callback(...args);
      } finally {
        setLoading(undefined);
      }
    }
  );

  return {
    trigger: loadingCallback,
    isLoading: loading != null,
    loadingArgs: loading?.args,
  };
};

export const useIsMounted = () => {
  const mounted = useRef<boolean>();

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};

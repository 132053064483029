import { skipToken } from "@reduxjs/toolkit/query/react";
import { WeatherDashboardContext } from "components/Dashboard/context";
import { KioskBannerSlide } from "components/kiosk/slides/KioskBannerSlide";
import { KioskMapSlide } from "components/kiosk/slides/KioskMapSlide";
import { KioskMetarSlide } from "components/kiosk/slides/KioskMetarSlide";
import { KioskTafSlide } from "components/kiosk/slides/KioskTafSlide";
import { Stack } from "components/layout/Stack";
import { describeAirportIcao } from "logic/airport/icao/describe";
import { onKioskPageMounted } from "logic/airport/kiosk/onKioskPageMounted";
import { useAutoSelectFlightRules } from "logic/weather/dashboard/useAutoSelectFlightRules";
import { useAutoSelectRunway } from "logic/weather/dashboard/useAutoSelectRunway";
import { useAirportWeather, useDashboardAirport } from "logic/weather/hooks";
import { FC, ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { localApi } from "store/reducers/localApi";
import { toastMessage } from "store/reducers/toastSlice";
import { matchMap, SpecBuilder } from "utils/general";
import { useInterval, useRunOnceReady, useRunOnChange } from "utils/hooks";
import { KioskWrapper } from "components/kiosk/slides/KioskWrapper";
import { ConditionalWrapper } from "components/util/ConditionalWrapper";

export type KioskPageProps = {};

export const KioskPage: FC<KioskPageProps> = (props) => {
  const icao = useParams().icao;
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const weatherResponse = useAirportWeather(icao);
  const weather = weatherResponse.report();
  const airport = useDashboardAirport(icao).transform()?.airport;

  useRunOnChange({ weatherResponse, airport }, (prev, next) => {
    if (
      (prev.weatherResponse.reportSource.weather !== next.weatherResponse.reportSource.weather ||
        prev.airport !== next.airport) &&
      icao &&
      next.weatherResponse.reportSource.type === "awd_server" &&
      next.weatherResponse.reportSource.weather != null
    ) {
      dispatch(
        localApi.endpoints.setLastWeatherReport.initiate({
          airport: next.airport,
          report: next.weatherResponse.reportSource.weather,
        })
      );
    }
  });

  const displayFilters = {
    if_banner_exists: awdApi.endpoints.getAirportBanner.useQuery(icao ? { icao } : skipToken)
      .isSuccess,
    if_taf_exists: weather?.hasOriginalReport("taf"),
    if_metar_exists: weather?.hasOriginalReport("metar"),
  };

  useRunOnceReady(weatherResponse.isKioskDisabled(), () => {
    if (icao) {
      if (Math.random() > -1) return;
      dispatch(
        toastMessage("warning", "You tried to access the kiosk mode for an unauthorized airport.")
      );
      navigate(describeAirportIcao(icao).getWeatherUrl(), { replace: true });
    }
  });

  useRunOnceReady(true, () => {
    if (icao) {
      dispatch(onKioskPageMounted({ icao }));
    }
  });

  const slides = slideOrder.filter((slide) =>
    slide.display_filter == null ? true : matchMap(slide.display_filter, displayFilters)
  );

  function increment() {
    const newInd = (index + 1) % slides.length;
    setIndex(newInd);
  }

  useInterval(12e3, () => {
    increment();
  });

  useAutoSelectRunway(icao);
  useAutoSelectFlightRules(icao);

  const activeSlide = slides[index];
  // console.log(activeSlide?.name, "name");
  if (icao == null || !activeSlide) return null;
  return (
    <WeatherDashboardContext.Provider value={{ icao }}>
      <Stack
        gap="0"
        css={{
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-start",
          height: activeSlide?.type === "map" ? "100vh" : "auto",
        }}
      >
        <ConditionalWrapper
          condition={activeSlide.type === "other"}
          wrapper={(children) => <KioskWrapper key={activeSlide.key}>{children}</KioskWrapper>}
        >
          {activeSlide?.jsx}
        </ConditionalWrapper>
      </Stack>
    </WeatherDashboardContext.Provider>
  );
};

export const slideSpec = new SpecBuilder<{
  key: number;
  display_filter?: "if_banner_exists" | "if_taf_exists" | "if_metar_exists";
  jsx: ReactNode;
  type: "map" | "other";
  name: string;
}>().build({
  aw_metar: {
    key: 1,
    jsx: <KioskMetarSlide awMetar />,
    type: "other",
    name: "aw_metar",
  },
  metar: {
    key: 2,
    jsx: <KioskMetarSlide />,
    type: "other",
    display_filter: "if_metar_exists",
    name: "metar",
  },
  banner: {
    key: 3,
    jsx: <KioskBannerSlide />,
    type: "other",
    display_filter: "if_banner_exists",
    name: "banner",
  },
  aw_taf: {
    key: 4,
    jsx: <KioskTafSlide awTaf />,
    type: "other",
    name: "aw_taf",
  },
  taf: {
    key: 5,
    jsx: <KioskTafSlide />,
    type: "other",
    display_filter: "if_taf_exists",
    name: "taf",
  },
  clouds: {
    key: 6,
    jsx: <KioskMapSlide overlay="clouds" />,
    type: "map",
    name: "clouds",
  },
  rain: {
    key: 7,
    jsx: <KioskMapSlide overlay="crr-pc" />,
    type: "map",
    name: "rain",
  },
  thunderstorms: {
    key: 8,
    jsx: <KioskMapSlide overlay="rdt" />,
    type: "map",
    name: "thunderstorms",
  },
});

const slideOrder = [
  slideSpec.aw_metar,
  slideSpec.metar,
  slideSpec.banner,
  slideSpec.aw_taf,
  slideSpec.taf,
  slideSpec.banner,
  slideSpec.clouds,
  slideSpec.banner,
  slideSpec.rain,
  slideSpec.banner,
  slideSpec.thunderstorms,
  slideSpec.banner,
];

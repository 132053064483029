import { useAirportWeather, useDashboardAirport, useHookOptionsType } from "logic/weather/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "store/hooks";
import { RootState } from "store/store";
import { selectRunway } from "store/reducers/localStateSlice";
import { selectRunwaysAccordingWind, sortRunways } from "./runwaySelectionLogic";

export function useAutoSelectRunway(icao: string | undefined, useHookOptions?: useHookOptionsType) {
  const dispatch = useDispatch();
  const { skip } = useHookOptions || {};
  const weather = useAirportWeather(icao, { skip: skip ? skip : false }).report();
  const toggledToAw = useSelector(
    (state: RootState) => state.localState.toggledToAwMetarWeatherSource
  );
  const weatherReportIcao = weather?.getIcao();
  const runwayAirportIcao = useSelector(
    (state: RootState) => state.localState?.selectedRunway?.airportIcao
  );
  const runways = useDashboardAirport(icao, { skip: skip ? skip : false })
    .transform()
    ?.runwayPairs();

  const windFromDirection = Math.ceil(
    weather?.selectMetarSource({ toggledToAw }).getCurrentWind()?.from_direction?.value ?? 0
  );

  useEffect(() => {
    if (
      runways &&
      weatherReportIcao &&
      runwayAirportIcao?.toLowerCase() !== weatherReportIcao?.toLowerCase()
    ) {
      // sorted runways
      const sortedRunways = sortRunways(runways);

      if (!sortedRunways[0]) return;

      const runwayInHeadwind = selectRunwaysAccordingWind(
        [sortedRunways[0]],
        windFromDirection === 0 ? 0.001 : windFromDirection
      );

      if (runwayInHeadwind[0] != null)
        dispatch(selectRunway(weatherReportIcao, runwayInHeadwind[0]));
    }
  }, [weatherReportIcao, runways, runwayAirportIcao]);
}

import { TextFieldProps } from "@mui/material";
import { FormSelectField } from "components/field/select/FormSelectField";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { isNonNullable } from "utils/general";

export function FormYesNoField<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
    allowNA?: boolean;
  } & TextFieldProps
) {
  const { allowNA, ...pass } = props;

  return (
    <FormSelectField
      {...pass}
      options={[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        allowNA ? { label: "n/a", value: "n/a" } : undefined,
      ].filter(isNonNullable)}
    />
  );
}

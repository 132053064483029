import styled from "@emotion/styled";
import Runway from "components/Dashboard/_icons/Runway2";
import { useDashboardAirport } from "logic/weather/hooks";
import { useSelectedRunway } from "logic/airport/runway/useSelectedRunway";
import { useObserveElementSize } from "utils/hooks";

import { RunwayIdentifier } from "../weatherNow/RunwayIdentifier";
import { ForecastWindArrows } from "./ForecastWindArrows";
import { ForecastedWindSpeed } from "./ForecastedWindSpeed";
import { ForecastedWindDirection } from "./ForecastedWindDirection";

type ForecastedWindType = {
  index: number;
  theme: any;
  wind: any;
  numOfArrows: number;
};

export const ForecastWindRunways = ({ wind, index, theme, numOfArrows }: ForecastedWindType) => {
  const runways = useDashboardAirport().transform()?.getRunwayPairsIfAirportOpen();
  const selectedRunway = useSelectedRunway();

  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  if (runways === undefined) return null;

  if (!selectedRunway) return null;

  return (
    <StyledForecastWind index={index} theme={theme} ref={ref}>
      <div className="graphic">
        <ForecastWindArrows
          type={"runway"}
          wind={wind}
          index={index}
          theme={theme}
          selectedRunway={selectedRunway}
        />
        <Runway height={100} />
        <SRunwayWindDataRow
          css={{
            top: (5 - numOfArrows) * 12,
            gap: width != null && width < 300 ? "3rem" : "6rem",
          }}
        >
          <ForecastedWindDirection index={index} theme={theme} />
          <ForecastedWindSpeed index={index} theme={theme} />
        </SRunwayWindDataRow>

        <RunwayIdentifier
          selectedRunway={selectedRunway}
          index={index}
          theme={theme}
          css={[
            { position: "absolute" },
            {
              bottom: "33px",
              fontSize: "25px",
            },
          ]}
        />
      </div>
    </StyledForecastWind>
  );
};

const SRunwayWindDataRow = styled.div({
  display: "flex",
  position: "absolute",
  justifyContent: "space-around",
  width: "100%",
  fontSize: "12px",
});

const StyledForecastWind = styled.div<{ index: number }>`
  font-weight: 100;

  .graphic {
    align-self: center;
    position: relative;
    margin: 1rem 0;

    .arrows {
      z-index: 1;
      position: relative;
    }
  }
`;

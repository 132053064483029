import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkBehaviorProps = Omit<RouterLinkProps, "to"> & {
  href: RouterLinkProps["to"];
};

export const LinkBehavior = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
  }
);

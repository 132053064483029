import React, { FC } from "react";
import styled from "@emotion/styled";
import { responsive } from "../../mixins";
import iconMap from "assets/icons/IconMap.svg";

export type LoaderType = {
  options: "map" | "dashboard";
};

const Loader: FC<LoaderType> = ({ options }) => {
  return (
    <StyledLoader option={options === "dashboard" ? "dashboard" : "map"} />
  );
};

export const StyledLoader = styled.div<{ option: string }>`
  ${(props) =>
    props.option === "dashboard"
      ? `
    grid-column: 1/6;
    grid-row: 1/7;

    width: 800px;
    height: 80vh;
    margin: auto;
    padding-right: 15px;
    display: block;
    position: relative;
    background: #fff;
    box-sizing: border-box;

    ${responsive.deskSM} {
      grid-column: 1/4;
      width: 100%;
    }

    &::after {
      content: "";
      width: calc(100%);
      height: calc(100%);
      top: 15px;
      left: 15px;
      position: absolute;
      background-image: linear-gradient(
          100deg,
          transparent,
          rgba(255, 255, 255, 0.5) 50%,
          transparent 80%
        ),
        linear-gradient(#ddd 56px, transparent 0),
        linear-gradient(#ddd 24px, transparent 0),
        linear-gradient(#ddd 220px, transparent 0),
        linear-gradient(#ddd 220px, transparent 0),
        linear-gradient(#ddd 290px, transparent 0),
        linear-gradient(#ddd 290px, transparent 0),
        linear-gradient(#ddd 290px, transparent 0),
        linear-gradient(#ddd 290px, transparent 0),
        linear-gradient(#ddd 290px, transparent 0);
      background-repeat: no-repeat;
      background-size: 75px 1000px, 60% 56px, 40% 30px, 48% 220px, 47% 220px,
        19% 290px, 19% 290px, 19% 290px, 19% 290px, 19% 290px;
      background-position: 0% 0, 0 0, 0px 60px, 0px 98px, 93% 98px, 0% 350px,
        24% 350px, 48% 350px, 72% 350px, 96% 350px;
      box-sizing: border-box;
      animation: dashborad-anim-loader 1s linear infinite;


      @keyframes dashborad-anim-loader {
      0% {
        background-position: 0% 0, 0 0, 0px 60px, 0px 98px, 93% 98px, 0% 350px,
          24% 350px, 48% 350px, 72% 350px, 96% 350px;
      }
      100% {
        background-position: 150% 0, 0 0, 0px 60px, 0px 98px, 93% 98px, 0% 350px,
          24% 350px, 48% 350px, 72% 350px, 96% 350px;
      }
     }
    }
  `
      : `
    width: 100%;
    height: 100%;
    margin: auto;

    display: block;
    position: relative;
    background: #fff;

    ${responsive.deskSM} {
      grid-column: 1/4;
      width: 100%;
    }

    &::before {
      content: "";
      width: calc(100%);
      height: calc(100%);
      position: absolute;
      background-image: linear-gradient(
          100deg,
          transparent 30%,
          rgba(255, 255, 255, 0.823) 50%,
          transparent 70%
        ), url('${iconMap}');
      background-size: 75px 95%, 100% 35%;
      background-position: 0% 0,100% 45%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      animation: map-anim-loader 2s linear infinite;

      ${responsive.mobilL} {
        background-size: 75px 95%, 100% 25%;
      }

      @keyframes map-anim-loader {
      0% {
        background-position: 15% 0, 100% 45%;
        
      }
      50% {
        background-position: 50% 0, 100% 50%;

      }
      100% {
        background-position: 80% 0, 100% 45%;

      }
     }
    }

    
  `}
`;

export default Loader;

// import { createStore, combineReducers, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import { accessLimitSlice } from "store/reducers/accessLimitSlice";
import { awdApi } from "store/reducers/awdApi";
import { consentApi } from "store/reducers/consentApi";
import { localApi } from "store/reducers/localApi";
import { toastSlice } from "store/reducers/toastSlice";
import { localStateSlice } from "store/reducers/localStateSlice";

const reducer = {
  accessLimit: accessLimitSlice.reducer,
  localState: localStateSlice.reducer,
  [awdApi.reducerPath]: awdApi.reducer,
  [consentApi.reducerPath]: consentApi.reducer,
  [localApi.reducerPath]: localApi.reducer,

  toast: toastSlice.reducer,
};

// The store now has redux-thunk added and the Redux DevTools Extension is turned on
const store = configureStore({
  reducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(
      consentApi.middleware,
      awdApi.middleware,
      localApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

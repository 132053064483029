import { Button } from "@mui/material";
import { describeAirportIcao } from "logic/airport/icao/describe";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";

export type OpenWeatherButtonProps = {};

export const OpenWeatherButton: FC<OpenWeatherButtonProps> = (props) => {
  const pilotHomeAirport = useFavoriteAirports().homeAirport()?.airport;

  const icao =
    useMyAirportRoles().managedAirport()?.icao ?? pilotHomeAirport?.icao;

  if (!icao) return null;

  return (
    <Button href={describeAirportIcao(icao).getWeatherUrl()}>
      Open {icao} weather
    </Button>
  );
};

import { Stack } from "components/layout/Stack";
import { responsive } from "mixins";
import { FC } from "react";
import { isIos } from "utils/general";

export type StoreBadgesProps = {};

export const StoreBadges: FC<StoreBadgesProps> = (props) => {
  return (
    <Stack gap="0rem" horizontal css={{ alignItems: "center" }}>
      <a
        href="https://play.google.com/store/apps/details?id=com.meandair.airport"
        target="_blank"
        rel="noreferrer"
        css={{
          [responsive.mobilL]: isIos() && {
            display: "none",
          },
        }}
      >
        <img
          height="82px"
          alt="Get it on Google Play"
          src="/play-store.png"
          css={{
            [responsive.mobilL]: {
              height: "60px",
            },
          }}
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/airportweather-com/id6447589155"
        target="_blank"
        rel="noreferrer"
        css={{
          [responsive.mobilL]: !isIos() && {
            display: "none",
          },
        }}
      >
        <img
          height="56px"
          alt="Download on the App Store"
          src="/app-store.png"
          css={{
            [responsive.mobilL]: {
              height: "42px",
            },
          }}
        />
      </a>
    </Stack>
  );
};

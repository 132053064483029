import { describeMutationResult } from "logic/api/result/describe";
import { pageSpec } from "logic/navigation/spec";
import { SignupForm } from "logic/signup/form";
import { NavigateFunction } from "react-router-dom";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { createAsyncThunk } from "store/thunk";

export const onSignupFormSubmitted = createAsyncThunk(
  "onSignupFormSubmitted",
  async (
    args: {
      signup_as: "pilot" | "airport";
      values: SignupForm;
      navigate: NavigateFunction;
    },
    ctx
  ) => {
    const result = await ctx
      .dispatch(
        awdApi.endpoints.signup.initiate({
          signup_as: args.signup_as,
          //
          first_name: args.values.first_name ?? undefined,
          last_name: args.values.last_name ?? undefined,
          phone_number: args.values.phone_number,
          airport: args.values.airport,
          email: args.values.email,
          password: args.values.password,
        })
      )
      .then(describeMutationResult);

    if (result.error) {
      ctx.dispatch(
        toastError(result.error, "Failed to create a user account.")
      );
      return;
    }

    ctx.dispatch(toastMessage("success", "Created a user account."));

    args.navigate(pageSpec.dashboard.path);
  }
);

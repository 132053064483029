import { ChangeEvent, FC, useState } from "react";
import { awdApi } from "store/reducers/awdApi";
import { Stack } from "components/layout/Stack";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { EmailListingDataPoint } from "awd-server-api";
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";
import { ROW_PER_PAGE, StyledTableCell, StyledTableRow, useTablePage } from "utils/table";


type EmailListingsProps = {};

export const EmailListings: FC<EmailListingsProps> = (props) => {
  const [page, setPage, handleChangePage] = useTablePage();
  const [verifiedEmailsOnly, setVerifiedEmailsOnly] = useState(true);
  const [showPilots, setShowPilots] = useState(true);
  const [showSubscribedAirports, setShowSubscribedAirports] = useState(true);
  const handleChangeVerified = (event: ChangeEvent<HTMLInputElement>) => {
    setVerifiedEmailsOnly(event.target.checked);
    setPage(0);
  };
  const handleChangePilots = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPilots(event.target.checked);
    if (showPilots) {
      setShowSubscribedAirports(true);
    }
    setPage(0);
  };
  const handleChangeSubscribed = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSubscribedAirports(event.target.checked);
    if (showSubscribedAirports) {
      setShowPilots(true);
    }
    setPage(0);
  };

  const { data, isLoading } = awdApi.endpoints?.getStatisticsEmailListing.useQuery();

  // filter data according to the switches
  const filteredData = data?.emailListing.filter((user) =>
    (!verifiedEmailsOnly || user.verified) &&
    (showPilots || user.subscribed_airport) &&
    (showSubscribedAirports || !user.subscribed_airport)
  );

  // paginated filtered data
  const paginatedFilteredData = filteredData?.slice(page * ROW_PER_PAGE, (page + 1) * ROW_PER_PAGE);

  const download = () => {
    if (filteredData) {
      const csvData = filteredData.map((item) => item.email).join("\n");
      const blob = new Blob([`Email\n${csvData}`], {
        type: "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `User Listing${
        verifiedEmailsOnly ? " - (Verified Emails Only)" : ""
      }${
        showPilots ? " - (Pilots)" : ""
      }${
        showSubscribedAirports ? " - (Airports)" : ""
      } ${new Date().toLocaleDateString()}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  if (isLoading) return null;
  return (
    <div>
      <Stack gap="1rem" css={{ alignItems: "flex-start", marginBottom: "2rem" }}>
        <Button onClick={download}>Download Email Listing</Button>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={verifiedEmailsOnly} onChange={handleChangeVerified} />}
            label="Only show Verified emails"
          />
          <FormControlLabel
            control={<Switch checked={showPilots} onChange={handleChangePilots} />}
            label="Include Pilots into listing"
          />
          <FormControlLabel
            control={<Switch checked={showSubscribedAirports} onChange={handleChangeSubscribed} />}
            label="Include Airports into listing"
          />
        </FormGroup>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Verified</StyledTableCell>
              <StyledTableCell>Pilot Account</StyledTableCell>
              <StyledTableCell>Airport Account</StyledTableCell>
              </TableRow>
          </TableHead>

          <TableBody>
            {paginatedFilteredData?.map((user) => <UserEmailListingRow user={user} key={user.id} />)}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={filteredData?.length ?? 0}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  );
};
type UserEmailListingRowProps = {
  user: EmailListingDataPoint;
};

const UserEmailListingRow: FC<UserEmailListingRowProps> = ({ user }) => {
  const getIcon = (bool: boolean) =>
    bool ? <CheckIcon css={{ color: "green" }} /> : <Close css={{ color: "red" }} />;

  return (
    <StyledTableRow>
      <StyledTableCell>{user.email}</StyledTableCell>
      <StyledTableCell>{getIcon(user.verified)}</StyledTableCell>
      <StyledTableCell>{getIcon(!user.subscribed_airport)}</StyledTableCell>
      <StyledTableCell>{getIcon(user.subscribed_airport)}</StyledTableCell>
    </StyledTableRow>
  );
};

import { Avatar } from "@mui/material";
import { pageSpec } from "logic/navigation/spec";
import { describeUserMeQuery } from "logic/user/me/describe";
import { FC } from "react";
import { Link } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";

export type OutsideNavigationLoginStatusProps = {};

export const OutsideNavigationLoginStatus: FC<
  OutsideNavigationLoginStatusProps
> = (props) => {
  const { isLoggedIn, userMe } = describeUserMeQuery(
    awdApi.endpoints.getUserMe.useQuery()
  );

  const { getInitials = undefined } = userMe() ?? {};

  return (
    <>
      <Link
        to={isLoggedIn() ? pageSpec.dashboard.path : pageSpec.login.path}
        css={{ textDecoration: "none" }}
      >
        <Avatar
          css={{ fontSize: "0.875rem", cursor: "pointer" }}
          sx={{ bgcolor: isLoggedIn() ? "#4893cf" : "#555" }}
        >
          {getInitials?.()}
        </Avatar>
      </Link>
    </>
  );
};

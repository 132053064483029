import { FC, useEffect } from "react";
import { SpecBuilder } from "utils/general";

export type TermlyDocumentProps = {
  type: TermlyDocumentType;
};

export const TermlyDocument: FC<TermlyDocumentProps> = (props) => {
  useEffect(() => {
    const existing = document.getElementById("termly-script");
    if (existing) {
      existing.remove();
    }

    const script = document.createElement("script");
    script.id = "termly-script";
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div
      // @ts-expect-error
      name="termly-embed"
      data-id={documentSpec[props.type].documentId}
      data-type="iframe"
    ></div>
  );
};

const documentSpec = new SpecBuilder<{ documentId: string }>().build({
  terms_of_service: {
    documentId: "13ab2651-4f99-40da-b337-6b1ae4324fa0",
  },
  privacy_policy: {
    documentId: "c57ae2c5-b8cd-4425-8885-bdb5649ccfb7",
  },
  cookie_policy: {
    documentId: "33981d79-3d07-4f1e-b171-91103d9d2bdb",
  },
  disclaimer: {
    documentId: "286c93b4-11f5-4faa-8f15-6aba2a135207",
  },
});
type TermlyDocumentType = keyof typeof documentSpec;

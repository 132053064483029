import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { useResolveOnceDefined } from "utils/hooks";
import { z } from "zod";
import { FormPhoneNumberField } from "components/field/phoneNumber/FormPhoneNumberField";

export type EditAirportContactModalProps = {
  icao: string;
};

const zForm = z.object({
  contactEmail: z.literal("").or(z.string().email()),
  contactPhone: z.string(),
});
type Form = z.infer<typeof zForm>;

export const EditAirportContactModal: FC<EditAirportContactModalProps> = (props) => {
  const dispatch = useDispatch();
  const airport = awdApi.endpoints.getAirport.useQuery({
    airportId: props.icao,
  }).data;
  const airportPromise = useResolveOnceDefined(airport);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      airportPromise.then((airport) => ({
        contactEmail: airport.contactEmail ?? "",
        contactPhone: airport.contactPhone ?? "",
      })),
  });

  return (
    <ResultModal
      title="Edit airport contact"
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.patchAirport.initiate({
              airportId: props.icao,
              body,
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(toastError(result.error, "We could not update the airport contact."));
            cancel();
          } else {
            dispatch(toastMessage("success", "The airport contact has been updated."));
            return;
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        {!form.formState.isLoading && (
          <Stack gap="1rem">
            <FormTextField
              form={form}
              type="email"
              name="contactEmail"
              label="Contact e-mail"
              fullWidth
            />
            <FormPhoneNumberField form={form} name="contactPhone" label="Contact phone" fullWidth />
          </Stack>
        )}
      </DialogContent>
    </ResultModal>
  );
};

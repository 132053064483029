import { localApi } from "store/reducers/localApi";
import { AppDispatch } from "store/store";
import { bound } from "utils/class";

export async function queryMobileAppConfig(dispatch: AppDispatch) {
  const result = await dispatch(
    localApi.endpoints.getMobileAppConfig.initiate(undefined, {
      forceRefetch: true,
    })
  );
  return bound({
    isMobileApp() {
      return result.data?.is_mobile_app;
    },
  });
}

type NearCompassLabel = {
  near360: boolean;
  near90: boolean;
  near180: boolean;
  near270: boolean;
};

export function isNearCompassLabel(angleWind: number) {
  let near: NearCompassLabel = { near360: false, near90: false, near180: false, near270: false };
  if (angleWind >= 80 && angleWind <= 100) near.near90 = true;
  if (angleWind >= 170 && angleWind <= 190) near.near180 = true;
  if (angleWind >= 260 && angleWind <= 280) near.near270 = true;
  if ((angleWind >= 350 && angleWind <= 360) || (angleWind >= 0 && angleWind <= 10))
    near.near360 = true;
  return near;
}

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { AirportLinkSettingsResponse } from "awd-server-api";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { z } from "zod";

export type SetupWebcamModalProps = {
  airportId: number;
  settings: AirportLinkSettingsResponse;
};

export const SetupWebcamModal: FC<SetupWebcamModalProps> = (props) => {
  const dispatch = useDispatch();

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: {
      url: props.settings.webcam_url ?? "",
    },
  });

  const webcamExists = props.settings.webcam_url != null;

  return (
    <ResultModal
      title={!webcamExists ? "Set up webcam" : "Edit webcam"}
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.putAirportLinkSettings.initiate({
              airportId: props.airportId,
              body: {
                ...props.settings,
                webcam_url: body.url,
              },
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(
              toastError(result.error, "We could not set up the webcam.")
            );
            cancel();
          } else {
            dispatch(toastMessage("success", "The webcam has been set up."));
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "20rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <Stack gap="1rem">
          <FormTextField form={form} name="url" label="WebCam URL" />
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

const zForm = z.object({
  url: z.string(),
});
type Form = z.infer<typeof zForm>;

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { useResolveOnceDefined } from "utils/hooks";
import { z } from "zod";

export type EditEmailModalProps = {};

const zForm = z.object({
  email: z
    .string()
    .email({ message: "Please provide a valid e-mail address." }),
  old_password: z.string().nonempty({
    message: "Please fill in your current password.",
  }),
});
type Form = z.infer<typeof zForm>;

export const EditEmailModal: FC<EditEmailModalProps> = (props) => {
  const dispatch = useDispatch();
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;
  const userMePromise = useResolveOnceDefined(userMe);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      userMePromise.then((me) => ({ email: me.email, old_password: "" })),
  });

  return (
    <ResultModal
      title="Edit e-mail address"
      onSubmit={async (cancel) => {
        await form.handleSubmit(async (values) => {
          const result = await dispatch(
            awdApi.endpoints.patchUserMe.initiate(values)
          ).then(describeMutationResult);

          if (result.error) {
            dispatch(
              toastError(
                result.error,
                "We could not update your e-mail address."
              )
            );
            cancel();
            return;
          } else {
            dispatch(
              toastMessage(
                "success",
                "We have sent a confirmation e-mail to your new e-mail address."
              )
            );
          }
        }, cancel)();
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <Stack gap="1rem">
          <FormTextField
            form={form}
            name="email"
            label="E-mail address"
            type="email"
            fullWidth
          />
          <FormTextField
            form={form}
            name="old_password"
            label="Your current password"
            type="password"
            fullWidth
          />
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

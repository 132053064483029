import Arrows from "components/Dashboard/_icons/Arrows";
import { calculateWindToRunway } from "utils/global.functions";
import { ParsedRunway } from "logic/airport/runway/parse";
import { useActiveTafSource } from "logic/weather/hooks";

type ForecastWindArrowsType = {
  type: "runway" | "compass";
  wind: any;
  theme: any;
  index: number;
  selectedRunway?: ParsedRunway;
};

export const ForecastWindArrows = ({
  wind,
  theme,
  selectedRunway,
  index,
  type,
}: ForecastWindArrowsType) => {
  const { source } = useActiveTafSource();

  let show = !wind.from_direction?.meaning && Math.floor(wind?.speed?.value ?? -1) !== 0;

  if (type === "runway")
    return (
      // <div className="arrows">
      <Arrows
        height={75}
        heightCSS={60}
        angle={wind && selectedRunway && calculateWindToRunway(wind.from_direction, selectedRunway)}
        active={source?.getActiveWindArrowsForecast(index)}
        index={index}
        runways={true}
        theme={theme}
        show={show}
      />
      // </div>
    );
  // type compass
  else
    return (
      // <div className="arrows">
      <Arrows
        height={90}
        heightCSS={70}
        angle={-90 + wind?.from_direction?.value ?? 0}
        active={source?.getActiveWindArrowsForecast(index)}
        index={index}
        runways={false}
        theme={theme}
        show={show}
      />
      // </div>
    );
};

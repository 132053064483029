import { Alert, AlertTitle } from "@mui/material";
import { ResendText } from "components/emailConfirmationStatus/resend/ResendText";
import { describeMyUserVerificationRequestQuery } from "logic/user/me/email/describe";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type EmailConfirmationStatusProps = {};

export const EmailConfirmationStatus: FC<EmailConfirmationStatusProps> = (props) => {
  const { showWarning = undefined, isEmailChange } =
    describeMyUserVerificationRequestQuery(
      awdApi.endpoints.getMyEmailVerificationRequest.useQuery()
    ).request() ?? {};

  if (!showWarning?.()) return null;

  if (isEmailChange?.()) {
    return (
      <Alert severity="warning">
        <AlertTitle>Your new email address has not yet been verified.</AlertTitle>
        <div>
          You have requested to change your e-mail address. To finish the change, please click on
          the link we've sent you to your new e-mail address.
        </div>
        <ResendText />
      </Alert>
    );
  }

  return (
    <Alert severity="warning">
      <AlertTitle>Your email address has not yet been verified.</AlertTitle>
      <div>Please verify your e-mail address by clicking the link we’ve sent you.</div>
      <ResendText />
    </Alert>
  );
};

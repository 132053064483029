import { z } from "zod";

export const zUnitValue = z
  .object({
    unit: z.string(),
    value: z.number(),
    meaning: z.string(),
  })
  .deepPartial();

export type UnitValue = z.infer<typeof zUnitValue>;

export const zWeatherWind = z
  .object({
    time_steps: z.array(
      z.object({
        speed: zUnitValue,
        from_direction: zUnitValue,
        valid_time: z.string(),
        gust_speed: zUnitValue,
      })
    ),
  })
  .deepPartial();

export type WeatherWind = z.infer<typeof zWeatherWind>;

export const zSurfaceVisibility = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type SurfaceVisibility = z.infer<typeof zSurfaceVisibility>;

export const zCeilingAgl = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type CeilingAgl = z.infer<typeof zCeilingAgl>;

export const zCloudCover = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type CloudCover = z.infer<typeof zCloudCover>;

export const zAirTemperature2mAgl = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type AirTemperature2mAgl = z.infer<typeof zAirTemperature2mAgl>;

export const zAirPressureQnh = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type AirPressureQnh = z.infer<typeof zAirPressureQnh>;

export const zFlightRules = z.object({
  time_steps: z.array(
    z.object({
      quantity: zUnitValue,
      valid_time: z.string(),
    })
  ),
});
export type FlightRules = z.infer<typeof zFlightRules>;

export const zWeatherNowcast = z
  .object({
    latest_modified_time: z.string(),
    publish_time: z.string(),
    wind_10m_agl: zWeatherWind,
    surface_visibility: zSurfaceVisibility,
    ceiling_agl: zCeilingAgl,
    cloud_cover: zCloudCover,
    flight_rules: zFlightRules,
    air_temperature_2m_agl: zAirTemperature2mAgl,
    air_pressure_qnh: zAirPressureQnh,
    synthetic_metar_report: z.string(),
    synthetic_taf_report: z.string(),
  })
  .deepPartial();

export type WeatherNowcast = z.infer<typeof zWeatherNowcast>;

export const zWeatherReportMetar = z
  .object({
    original_report: z.string(),
    observation_time: z.string(),
    wind_10m_agl: zWeatherWind,
    surface_visibility: zSurfaceVisibility,
    ceiling_agl: zCeilingAgl,
    cloud_cover: zCloudCover,
    flight_rules: zFlightRules,
    air_temperature_2m_agl: zAirTemperature2mAgl,
    air_pressure_qnh: zAirPressureQnh,
  })
  .deepPartial();

export const zWeatherReportTaf = z
  .object({
    original_report: z.string(),
    wind_10m_agl: zWeatherWind,
    issued_time: z.string(),
    surface_visibility: zSurfaceVisibility,
    ceiling_agl: zCeilingAgl,
    cloud_cover: zCloudCover,
    flight_rules: zFlightRules,
  })
  .deepPartial();

export const zWeatherReport = z
  .object({
    icao_id: z.string(),
    metar: zWeatherReportMetar,
    taf: zWeatherReportTaf,
    nowcast: zWeatherNowcast,
    local_time: z.string(),
    next_sunrise: z.string(),
    next_sunset: z.string(),
    elevation: zUnitValue,
  })
  .deepPartial();

export type WeatherReport = z.infer<typeof zWeatherReport>;

import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Meta } from "components/meta/Meta";
import { FC } from "react";

export type NotImplementedPageProps = {
  name: string;
};

export const NotImplementedPage: FC<NotImplementedPageProps> = (props) => {
  return (
    <InsidePageLayout>
      <Meta pageTitle="Not implemented" />
      <InsidePageTitle>
        Page {props.name} has not been implemented yet.
      </InsidePageTitle>
    </InsidePageLayout>
  );
};

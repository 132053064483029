import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Typography,
} from "@mui/material";
import { AirportSelect } from "components/airportSelect/AirportSelect";
import { FormPhoneNumberField } from "components/field/phoneNumber/FormPhoneNumberField";
import { FormTextField } from "components/field/text/FormTextField";
import { Stack } from "components/layout/Stack";
import { pageSpec } from "logic/navigation/spec";
import {
  SignupForm as SignupFormValues,
  zAirportSignupForm,
  zPilotSignupForm,
} from "logic/signup/form";
import { onSignupFormSubmitted } from "logic/signup/onSignupFormSubmitted";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { AirportSpecification } from "awd-server-api";
import { matchMap, SpecBuilder } from "utils/general";

export type SignupFormProps = {
  variant: "pilot" | "airport";
  defaultAirport?: AirportSpecification;
};

export const SignupForm: FC<SignupFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { airportLabel, canAddAirport, isContactRequired } =
    variantSpecs[props.variant];

  const form = useForm<SignupFormValues>({
    resolver: zodResolver(
      matchMap(props.variant, {
        airport: zAirportSignupForm,
        pilot: zPilotSignupForm,
      })
    ),
    defaultValues: {
      airport: props.defaultAirport,
    },
  });

  const onSuccess = async (values: SignupFormValues) => {
    dispatch(
      onSignupFormSubmitted({
        signup_as: props.variant,
        values,
        navigate,
      })
    );
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSuccess)}
      css={{
        maxWidth: "min(100%, 20rem)",
        width: "100%",
      }}
    >
      <Stack gap="1rem">
        <FormTextField form={form} name="email" label="E-mail" type="email" />
        <FormTextField
          form={form}
          name="password"
          label="Password"
          type="password"
          autoComplete="new-password"
        />
        <FormTextField
          form={form}
          name="password_again"
          label="Confirm password"
          type="password"
          autoComplete="new-password"
        />
        {isContactRequired && (
          <>
            <FormTextField
              form={form}
              name="first_name"
              label="Your first name"
            />
            <FormTextField
              form={form}
              name="last_name"
              label="Your last name"
            />
            <FormPhoneNumberField
              form={form}
              name="phone_number"
              label="Phone number"
            />
          </>
        )}
        <Controller
          control={form.control}
          name="airport"
          render={({
            field: { onChange, onBlur, ref, value },
            fieldState: { error },
          }) => (
            <AirportSelect
              error={error}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              inputRef={ref}
              label={airportLabel}
              noAddAirport={!canAddAirport}
            />
          )}
        />
        <FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...form.register("i_agree_with_tos")}
                  sx={{ padding: "4px", pl: "9px" }}
                />
              }
              label={
                <>
                  I agree with the{" "}
                  <Link href="/terms-of-service">
                    <strong>Terms of Service</strong>
                  </Link>
                  .
                </>
              }
            />
            {form.formState.isSubmitted &&
              !form.getValues("i_agree_with_tos") && (
                <FormHelperText sx={{ color: "#d32f2f", mt: 0, ml: "14px" }}>
                  Please indicate that you agree to the Terms of Service
                </FormHelperText>
              )}
          </FormGroup>

          <FormGroup>
            {props.variant === "airport" && (
              <FormControlLabel
                control={
                  <Checkbox
                    {...form.register("confirm_airport")}
                    sx={{ padding: "4px", pl: "9px" }}
                  />
                }
                label="I confirm that I represent the airport operator"
              />
            )}
            {props.variant === "airport" &&
              form.formState.isSubmitted &&
              !form.getValues("confirm_airport") && (
                <FormHelperText sx={{ color: "#d32f2f", mt: 0, ml: "14px" }}>
                  Only representatives of airport operators and owners can
                  subscribe as an airport.{" "}
                  <Link href={pageSpec.registerpilot.path}>
                    <strong>Register as a pilot</strong>
                  </Link>
                </FormHelperText>
              )}
          </FormGroup>
        </FormGroup>
        {props.variant === "airport" && (
          <Button type="submit" variant="contained" size="large">
            Subscribe
          </Button>
        )}
        {props.variant === "pilot" && (
          <Button type="submit" variant="contained" size="large">
            Register
          </Button>
        )}
        <Typography variant="body1">
          Already have an account?{" "}
          <Link href={pageSpec.login.path}>
            <strong>Log in here</strong>
          </Link>
        </Typography>
      </Stack>
    </form>
  );
};

const variantSpecs = new SpecBuilder<{
  airportLabel: string;
  canAddAirport: boolean;
  isContactRequired: boolean;
}>().build<SignupFormProps["variant"]>({
  airport: {
    airportLabel: "Airport",
    canAddAirport: true,
    isContactRequired: true,
  },
  pilot: {
    airportLabel: "Home Airport",
    canAddAirport: false,
    isContactRequired: false,
  },
});

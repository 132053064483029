import { Alert, AlertTitle, Link } from "@mui/material";
import { describeError } from "logic/error";
import { FC, ReactNode } from "react";
import { pageSpec } from "logic/navigation/spec";

export type ErrorAlertProps = {
  title?: string;
  errorDescription?: {
    formatTitle?: () => ReactNode;
  };
  error?: unknown;
};

export const ErrorAlert: FC<ErrorAlertProps> = (props) => {
  const error = props.errorDescription ?? (props.error ? describeError(props.error) : undefined);

  return (
    <Alert severity="error">
      <AlertTitle sx={{ marginBottom: 0 }}>{props.title ?? error?.formatTitle?.()}</AlertTitle>
      {error?.formatTitle?.() === "Could not find airport." ? (
        <p css={{ margin: "10px 0 0 0" }}>
          We do not have information about this airport. If this is a mistake, please contact us at
          support@airportweather.com or go and <Link href={pageSpec.signup.path}>register</Link>{" "}
          your airport.
        </p>
      ) : null}
    </Alert>
  );
};

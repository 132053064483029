import styled from "@emotion/styled";
import { useActiveMetarSource } from "logic/weather/hooks";
import { responsive } from "mixins";
import { FC } from "react";

export type CurrentWindSpeedProps = {};

export const CurrentWindSpeed: FC<CurrentWindSpeedProps> = (props) => {
  const { source } = useActiveMetarSource();
  const gustSpeed = source?.formatGustSpeed();

  return (
    <StyledSpeed>
      <div className="wind">{source?.formatWindSpeed()}</div>
      {gustSpeed && (
        <div className="gust">
          <p className="label">gust:</p>
          <p className="value">{gustSpeed}</p>
        </div>
      )}
    </StyledSpeed>
  );
};

const StyledSpeed = styled.div`
  line-height: 22px;

  ${responsive.tablet} {
    line-height: 20px;
  }

  .wind {
    font-size: 22px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};

    ${responsive.tablet} {
      font-size: 20px;
    }
  }

  .gust {
    display: flex;
    align-items: baseline;
    column-gap: 0.2rem;

    .label {
      font-size: 14px;
      font-weight: 300;

      ${responsive.tablet} {
        font-size: 12px;
      }
    }

    .value {
      font-size: 15px;
      font-weight: 700;
      color: ${(props) => props.theme.primary};

      ${responsive.tablet} {
        font-size: 13px;
      }
    }
  }
`;

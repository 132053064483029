import { Button, Card, CardContent, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { AirportNotification as ApiAirportNotification } from "awd-server-api";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useMySettings } from "logic/user/me/settings/hooks";
import { getLocalTime } from "utils/general";

export type AirportNotificationProps = {
  notification: ApiAirportNotification;
  showRemove?: boolean;
  category: ApiAirportNotification["category"];
};

export const AirportNotification: FC<AirportNotificationProps> = ({
  notification,
  showRemove,
}) => {
  const time_format = useMySettings().describe()?.settings?.time_format;
  const issuedAt = getLocalTime(notification.created_at, time_format);
  return (
    <Stack gap="0.5rem" css={{ alignItems: "start" }}>
      <Card css={{ width: "500px", maxWidth: "100%" }}>
        <CardContent>
          <Typography color="text.secondary" gutterBottom>
            Issued at {issuedAt}
          </Typography>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ fontWeight: 600 }}
          >
            {notification.title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {notification.description}
          </Typography>
          <Typography
            variant="body1"
            css={{ whiteSpace: "pre-line" }}
          ></Typography>

          {showRemove && (
            <div css={{ marginTop: "0.5rem" }}>
              <RemoveNotificationButton notification={notification} />
            </div>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

const RemoveNotificationButton: FC<{
  notification: ApiAirportNotification;
}> = ({ notification }) => {
  const { show } = useModalContext();
  const dispatch = useDispatch();
  return (
    <Button
      color="error"
      sx={{ p: 0 }}
      onClick={() =>
        show(
          <ResultModal
            title="Do you really want to remove this notification?"
            submitButton={{ color: "error", text: "Remove" }}
            onSubmit={async (cancel) => {
              const result = await dispatch(
                awdApi.endpoints.deleteAirportNotification.initiate({
                  id: notification.id,
                })
              ).then(describeMutationResult);

              if (result.error) {
                dispatch(
                  toastError(
                    result.error,
                    "We could not remove the notification."
                  )
                );

                cancel();
              } else {
                dispatch(
                  toastMessage("success", "The notification has been removed.")
                );
              }
            }}
          />
        )
      }
    >
      Remove notification
    </Button>
  );
};

import styled from "@emotion/styled";
import { CurrentWindAngle } from "components/Dashboard/weatherNow/CurrentWindAngle";
import { CurrentWindSpeed } from "components/Dashboard/weatherNow/CurrentWindSpeed";
import Arrows from "components/Dashboard/_icons/Arrows";
import Compass from "components/Dashboard/_icons/Compass";
import { useActiveMetarSource } from "logic/weather/hooks";
import { FC } from "react";

export type WindCompassGraphicProps = {};

export const WindCompassGraphic: FC<WindCompassGraphicProps> = (props) => {
  const { source } = useActiveMetarSource();
  const { from_direction: direction } = source?.getCurrentWind() ?? {};

  return (
    <div css={{ position: "relative" }}>
      <SWindDataRow>
        <CurrentWindAngle />
        <CurrentWindSpeed />
      </SWindDataRow>
      <SCompassWithArrows>
        <SArrows>
          <Arrows
            height={145}
            heightCSS={110}
            angle={-90 + (direction?.value ?? 0)}
            active={source?.getActiveWindArrows()}
            index={0}
            runways={false}
            show={!source?.doesWindDirectionHaveSpecialMeaning() && !source?.isWindCalm()}
          />
        </SArrows>

        <SCompass>
          <Compass height={235} />
        </SCompass>
      </SCompassWithArrows>
    </div>
  );
};

const SCompassWithArrows = styled.div({
  marginTop: "2rem",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
  justifyItems: "center",
  alignItems: "center",
});

const SWindDataRow = styled.div({
  display: "flex",
  position: "absolute",
  justifyContent: "space-between",
  top: "1rem",
  left: 0,
  right: 0,
});

const SArrows = styled.div({
  gridColumn: "1 / 2",
  gridRow: "1 / 2",
  zIndex: 90,
  // position: "absolute",
  // left: "50%",
  // top: "40%",
  // transform: "translate(-50%, -35%)",
});
const SCompass = styled.div({
  gridColumn: "1 / 2",
  gridRow: "1 / 2",
});

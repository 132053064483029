import styled from "@emotion/styled";

export const Button = styled.button({
  border: 0,
  background: "#eee",
  padding: "0.5rem 1rem",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover": {
    background: "#ddd",
  },
});

import { DialogContent } from "@mui/material";
import { AirportNotificationList } from "components/airportNotificationList/AirportNotificationList";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ResultModal } from "components/modal/inside/ResultModal";
import { useAirportSubscriptionStatus } from "logic/airport/subscription";
import { pageSpec } from "logic/navigation/spec";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { useRunOnceReady } from "utils/hooks";
import { localApi } from "store/reducers/localApi";
import { Meta } from "components/meta/Meta";
import { AirportNotification as ApiAirportNotification } from "awd-server-api";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Stack } from "components/layout/Stack";
import { matchMap } from "utils/general";
import styled from "@emotion/styled";
import { responsive } from "mixins";

export type AirportNotificationsSubpageProps = {
  category: ApiAirportNotification["category"];
};

export const AirportNotificationsSubpage: FC<AirportNotificationsSubpageProps> = ({ category }) => {
  const icao = String(useParams().icao);
  const { show } = useModalContext();
  const navigate = useNavigate();
  const { data: airport, isFetching } = awdApi.endpoints.getAirport.useQuery({
    airportId: icao,
  });
  const { hasSubscription } = useAirportSubscriptionStatus(icao);

  const isMobileApp = localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ?? false;

  useRunOnceReady(hasSubscription() === false && !isFetching, () => {
    show(
      <ResultModal
        title="Airport Notifications is a subscription-only feature."
        submitButton={{ text: "Subscribe", hidden: isMobileApp }}
        onSubmit={() => navigate(pageSpec.signup.path)}
      >
        <DialogContent css={{ maxWidth: "40rem" }}>
          <p>The AirportWeather.com Notifications are available only to subscribed airports.</p>
          {!isMobileApp && (
            <p>
              If you are the operator of <strong>{airport?.name ?? "this airport"}</strong>, please
              subscribe to unlock the possibility to issue warnings for your airport to keep the
              pilots operating from your base up-to-date.
            </p>
          )}
        </DialogContent>
      </ResultModal>
    );
  });

  const title = matchMap(category, {
    operational_update: "Important Operational Updates",
    general_information: "General Information",
  } as const);

  return (
    <PageWrapper css={{ padding: "3rem", paddingTop: 0 }}>
      <Meta pageTitle={`${icao.toUpperCase()} Notifications`} />
      <Stack gap="2rem">
        <InsidePageTitle>{title}</InsidePageTitle>
        <AirportNotificationList
          icao={icao}
          location="airport_dashboard"
          airportName={airport?.name}
          category={category}
        />
      </Stack>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: 0 3rem 3rem;
  ${responsive.tablet} {
    padding: 0 1rem 1rem;
  }
  ${responsive.mobilL} {
    padding: 0 0.5rem 1rem;
  }
`;

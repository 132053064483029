import Runways from "components/Dashboard/weatherNow/Runways";
import WeatherNowCeiling from "components/Dashboard/weatherNow/WeatherNowCeiling";
import { WeatherNowWindCard } from "components/Dashboard/weatherNow/WeatherNowWindCard";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";
import styled from "@emotion/styled";
import { responsive } from "mixins";
import { TitleSection } from "components/Dashboard/weatherNow/WeatherNow";
import { useAirportWeather } from "logic/weather/hooks";
import { useMySettings } from "logic/user/me/settings/hooks";
import SwitchWithRecalc from "components/_buttons/SwitchWithRecalc";

export type WeatherWidgetForecastProps = {};

export const WeatherWidgetMetar: FC<WeatherWidgetForecastProps> = () => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  const settings = useMySettings().settings;
  const weather = useAirportWeather().report()?.withSettings(settings);

  return (
    <div ref={ref}>
      {width != null && width < 250 ? (
        <ErrorAlert title="This widget variant requires at least 250 wide space." />
      ) : (
        <>
          <div
            css={{
              marginBottom: "0.7rem",
            }}
          >
            <SwitchWithRecalc weather={weather} source={"metar"} />
          </div>
          <TitleSection>Current weather</TitleSection>
          <SWidgetMetar>
            <div
              css={{
                minWidth: "15rem",
              }}
            >
              <WeatherNowWindCard />
            </div>

            <div
              css={{
                minWidth: "15rem",
              }}
            >
              <WeatherNowCeiling />
            </div>
            <div
              css={{
                marginBottom: "1.5rem",
              }}
              className="runwaysContainer"
            >
              <Runways />
            </div>
          </SWidgetMetar>
        </>
      )}
    </div>
  );
};

const SWidgetMetar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 0.3rem;

  ${responsive.forecast} {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    max-width: 23rem;
  }

  .runwaysContainer {
    grid-column: 1 / 2;
    ${responsive.forecast} {
      grid-row: 2 / 3;
    }
  }
`;

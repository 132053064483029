import { RegisteredAirportResponse } from "awd-server-api";
import { FC, MouseEvent, useState } from "react";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { describeActiveSubscription } from "logic/airport/subscription";
import { StyledTableCell, StyledTableRow } from "utils/table";
import { Menu, MenuItem } from "@mui/material";
import { ChangeSubscriptionEndDateModal } from "components/registeredAirports/ChangeSubscriptionEndDateForm";
import { EndSubscriptionDialog } from "components/registeredAirports/EndSubscriptionDialog";
import { MenuButton } from "components/menu/MenuButton";

export type SubscribedAirportProps = { airport: RegisteredAirportResponse };

export const AirportRow: FC<SubscribedAirportProps> = (props) => {
  const description = describeActiveSubscription(props.airport);
  const endDateCellText = description.getEndDateCellText();

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{props.airport.airport_icao}</StyledTableCell>
        <StyledTableCell>{props.airport.airport_name}</StyledTableCell>
        <StyledTableCell>{props.airport.user_email}</StyledTableCell>
        <StyledTableCell>{props.airport.user_name}</StyledTableCell>
        <StyledTableCell>{props.airport.user_phone_number}</StyledTableCell>
        <StyledTableCell>{endDateCellText}</StyledTableCell>
        <StyledTableCell>
          <RegisteredAirportTableRowMenuButton airport={props.airport} />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

type RegisteredAirportTableRowMenuButtonProps = {
  airport: RegisteredAirportResponse;
};

const RegisteredAirportTableRowMenuButton: FC<
  RegisteredAirportTableRowMenuButtonProps
> = (props) => {
  const { show } = useModalContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const description = describeActiveSubscription(props.airport);

  return (
    <>
      <MenuButton
        open={open}
        handleClick={handleClick}
        controlId="menu-list-grow"
      >
        Actions
      </MenuButton>
      <Menu
        id="menu-list-grow"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            show(<ChangeSubscriptionEndDateModal airport={props.airport} />)
          }
        >
          Change Subscription End Date
        </MenuItem>
        {description.isActive() && (
          <MenuItem
            sx={{ color: "red" }}
            onClick={() => {
              show(<EndSubscriptionDialog airport={props.airport} />);
            }}
          >
            End Subscription
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

import { FC } from "react";
import { Heading } from "components/heading/Heading";

type WeatherWidgetTitleProps = {};
export const WeatherWidgetTitle: FC<WeatherWidgetTitleProps> = (props) => {
  return (
    <div
      css={{
        padding: "0.3rem 0",
      }}
    >
      <Heading />
    </div>
  );
};

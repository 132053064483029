import { FC, SyntheticEvent, useState } from "react";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { Meta } from "components/meta/Meta";
import { Stack } from "components/layout/Stack";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { PilotRegistrationPerDay } from "components/statistics/PilotRegistrationPerDay";
import { AirportSubscriptionsPerDay } from "components/statistics/AirportSubscriptionsPerDay";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { match } from "ts-pattern";
import { HomeAirportCounts } from "components/statistics/HomeAirportCounts";
import { AirportAccessCount } from "components/statistics/AirportAccessCount";
import { EmailListings } from "components/statistics/EmailListings";

type StatisticsPageProps = {};

type TabValue = "daily" | "home_airport" | "airport_access" | "email_listing";

export const StatisticsPage: FC<StatisticsPageProps> = (props) => {
  const [value, setValue] = useState<TabValue>("daily");

  const handleChange = (event: SyntheticEvent, newValue: TabValue) => {
    setValue(newValue);
  };

  const content = match(value)
    .with("daily", () => (
      <>
        <PilotRegistrationPerDay />
        <Divider />
        <AirportSubscriptionsPerDay />
      </>
    ))
    .with("home_airport", () => <HomeAirportCounts />)
    .with("airport_access", () => <AirportAccessCount />)
    .with("email_listing", () => <EmailListings />)
    .exhaustive();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Statistics" />
      <Stack gap="1rem" css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Statistics</InsidePageTitle>
        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} aria-label="Tabs">
            <Tab value="daily" label="Daily Statistics" />
            <Tab value="home_airport" label="Home Airport Counts" />
            <Tab value="airport_access" label="Airport Access Counts" />
            <Tab value="email_listing" label="Email Listing" />
          </Tabs>
        </Box>
        {content}
      </Stack>
    </InsidePageLayout>
  );
};

import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import {
  FieldPath,
  FieldValues,
  useController,
  UseFormReturn,
} from "react-hook-form";

export function FormPhoneNumberField<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
  } & MuiTelInputProps
) {
  const { form, name, ...pass } = props;

  const { field } = useController({ name: props.name, control: form.control });

  const state = form.getFieldState(props.name, form.formState);
  const inputProps = {
    ...pass.inputProps,
    style: { ...pass.inputProps?.style, fontSize: 16 },
  };
  return (
    <MuiTelInput
      {...field}
      defaultCountry="NL"
      error={state.error != null}
      helperText={state.error?.message}
      {...pass}
      inputProps={inputProps}
    />
  );
}

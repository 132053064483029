import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { describeFlightConditionsTable } from "components/flightConditionsTable/describe";
import { FC } from "react";

export type FlightConditionsTableProps = {};

export const FlightConditionsTable: FC<FlightConditionsTableProps> = (
  props
) => {
  const { getRows } = describeFlightConditionsTable();
  return (
    <div css={{ maxWidth: "100%", overflowX: "auto", display: "block" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Icon</TableCell>
            <TableCell>Colour</TableCell>
            <TableCell>Flight condition</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Visibility</TableCell>
            <TableCell>Cloud ceiling</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows().map((row) => (
            <TableRow
              key={row.key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <img alt="flight condition icon" src={row.icon_image_url} />
              </TableCell>
              <TableCell>{row.color}</TableCell>
              <TableCell>{row.flight_condition}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell css={{ whiteSpace: "nowrap" }}>
                {row.visibility}
              </TableCell>
              <TableCell css={{ whiteSpace: "nowrap" }}>
                {row.cloud_ceiling}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

import { WeatherDescribedType } from "logic/weather/describe";
import styled from "@emotion/styled";
import { FC } from "react";
import { responsive } from "mixins";
import { useSelector } from "react-redux";
import { getDummyRunwaySchema } from "store/reducers/localStateSlice";

const useLocalTimeData = (weather: WeatherDescribedType | undefined, dummy?: boolean) => {
  let localTime = weather?.getFormattedLocalTime("airport", "long");
  const dummyData = useSelector(getDummyRunwaySchema);

  if (dummy) {
    localTime = dummyData.localTime;
  }

  return { localTime };
};

export type LocalTimeType = {
  weather: WeatherDescribedType | undefined;
  dummy?: boolean;
};

const LocalTime: FC<LocalTimeType> = ({ weather, dummy }) => {
  const { localTime } = useLocalTimeData(weather, dummy);

  return (
    <StyledLocalTime>
      <p className="label">airport local time: </p>
      <p className="localTimeData">{localTime}</p>
    </StyledLocalTime>
  );
};

const StyledLocalTime = styled.div`
  padding-left: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 0.5rem;

  p {
    margin: 0;
  }

  .localTimeData {
    font-size: 18px;
    font-weight: 500;

    ${responsive.tablet} {
      font-size: 15px;
    }
    ${responsive.mobilP} {
      font-size: 12px;
    }
  }

  .label {
    font-weight: 300;
    font-size: 14px;
    font-family: "Encode Sans", "sans-serif";

    ${responsive.tablet} {
      font-size: 13px;
    }
    ${responsive.mobilP} {
      font-size: 11px;
    }
  }
`;

export default LocalTime;

import { UnitValue } from "awd-server-api";
import { bound } from "utils/class";
import { isKeyof } from "utils/general";
import { unitSpec } from "./spec";
import { Unit } from "./types";

export function describeUnitValue(unitValue: UnitValue) {
  const spec =
    unitValue.unit != null && isKeyof(unitValue.unit, unitSpec)
      ? unitSpec[unitValue.unit]
      : undefined;

  if (!spec) {
    if (unitValue.unit == null && unitValue.meaning != null) return undefined;

    throw new Error(`Cannot describe unit ${unitValue.unit}. Unknown unit.`);
  }

  return bound({
    unitValue,
    format(opts?: {
      postprocess?: "floor" | "ceil" | "round" | "ceil100" | "floor100" | "round100" | "floor10" | "ceil10" | "round10";
    }) {
      let value = unitValue.value;

      if (value == null) return undefined;

      if (opts?.postprocess === "floor") {
        value = Math.floor(value);
      } else if (opts?.postprocess === "ceil") {
        value = Math.ceil(value);
      } else if (opts?.postprocess === "ceil100") {
        value = Math.ceil(value / 100) * 100;
      } else if (opts?.postprocess === "ceil10") {
        value = Math.ceil(value / 10) * 10;
      } else if (opts?.postprocess === "floor100") {
        value = Math.floor(value / 100) * 100;
      } else if (opts?.postprocess === "floor10") {
        value = Math.floor(value / 10) * 10;
      } else if (opts?.postprocess === "round100") {
        value = Math.round(value / 100) * 100;
      } else if (opts?.postprocess === "round10") {
        value = Math.round(value / 10) * 10;
      } else if (opts?.postprocess === "round") {
        value = Math.round(value);
      }

      return [value, spec.displayName].join(" ");
    },
    convertToUnit(targetUnit: Unit, canBeZero: boolean = false) {
      const val = unitValue.value;
      // either value is nonzero or value is zero and canBeZero
      const willCalculate = !!val || (val === 0 && canBeZero);
      return describeUnitValue({
        value: willCalculate
          ? unitSpec[targetUnit].conversion.fromBase(
              spec.conversion.toBase(val)
            )
          : undefined,
        unit: targetUnit,
        meaning: unitValue.meaning,
      });
    },
  });
}

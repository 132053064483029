import wf from "webfontloader";

wf.load({
  google: {
    families: [
      "Encode Sans:100,300,400,500,600,700,800,900",
      "Encode Sans Condensed:300,400,500,600,700",
      "Roboto Mono:300,400,500,600,700",
    ],
  },
});

import {
  createContext,
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from "react";
import { Outlet } from "react-router-dom";

export type ModalProviderProps = PropsWithChildren<{}>;

export const ModalProvider: FC<ModalProviderProps> = (props) => {
  const [shownModals, setShownModals] = useState<
    {
      id: string;
      jsx: ReactNode;
    }[]
  >([]);

  const value: ModalContextValue = {
    show: (modal) => {
      setShownModals((x) => x.concat({ id: Math.random().toString(), jsx: modal }));
    },
  };

  return (
    <ModalContext.Provider value={value}>
      <Outlet />
      {props.children}
      {shownModals.map((modal, i) => {
        return (
          <ModalContext.Provider
            key={modal.id}
            value={{
              ...value,
              modalContext: {
                close: () => {
                  setShownModals((modals) => modals.filter((x) => x.id !== modal.id));
                },
              },
            }}
          >
            <Fragment>{modal.jsx}</Fragment>
          </ModalContext.Provider>
        );
      })}
    </ModalContext.Provider>
  );
};

export type ModalContextValue = {
  show: (modal: ReactNode) => void;
  modalContext?: {
    close: () => void;
  };
};

export const ModalContext = createContext<ModalContextValue>({
  show: () => undefined,
});

export function useModalContext() {
  return useContext(ModalContext);
}

import styled from "@emotion/styled";
import { useActiveTafSource } from "logic/weather/hooks";
import { responsive } from "mixins";

type ForecastedWindDirectionType = {
  index: number;
  theme: any;
};

export const ForecastedWindDirection = ({ index, theme }: ForecastedWindDirectionType) => {
  const { source } = useActiveTafSource();

  let windAngle;
  if (source?.isWindCalmAtIndex(index) && !source?.shouldShowWindAlertColor(index)) {
    windAngle = "CALM";
  } else {
    windAngle = source?.formatWindAngleAtIndex(index);
  }

  return (
    <StyledDirection index={index} theme={theme} alert={source?.shouldShowWindAlertColor(index)}>
      <div className="angle">{windAngle}</div>
    </StyledDirection>
  );
};

const StyledDirection = styled.div<{ index: number; alert?: boolean; theme: any }>`
  line-height: 14px;

  ${responsive.tablet} {
    line-height: 12px;
  }

  .angle {
    font-size: ${(props) => (props.alert ? "10px" : "12px")};
    font-weight: bold;
    color: ${(props) =>
      props.index === 0 ? (props.alert ? "red" : props.theme.primary) : " #6d6e70"};
    font-family: ${(props) => (props.alert ? "Encode Sans Condensed" : "Encode Sans")};
    text-transform: ${(props) => (props.alert ? "uppercase" : "default")};

    ${responsive.tablet} {
      font-size: 11px;
    }
  }
`;

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { describeQueryResultError } from "logic/api/result/describe";
import { UserResponse } from "awd-server-api";
import { bound } from "utils/class";

export function describeUserMe(userMe: UserResponse) {
  return bound({
    isLoggedIn() {
      // if userMe exists, we are logged in
      return true;
    },
    getFullName() {
      return [userMe.first_name, userMe.last_name]
        .filter((x) => !!x)
        .join(" ")
        .trim();
    },
    getDisplayName() {
      return this.getFullName() || userMe.email;
    },
    getShortDisplayName() {
      return this.getDisplayName().match(/^(.*?)(?:@|$|\s)/)?.[1];
    },
    getInitials() {
      return this.getDisplayName()
        .replace(/@.*$/, "")
        .split(/[\s.]/)
        .map((part) => part[0])
        .join("")
        .toUpperCase()
        .slice(0, 2);
    },
  });
}

export type UserMeQuery = {
  data?: UserResponse;
  error?: FetchBaseQueryError | SerializedError;
  isFetching?: boolean;
  isLoading?: boolean;
};

export function describeUserMeQuery(query: UserMeQuery) {
  return bound({
    isLoading: query.isLoading,
    hasCurrentData() {
      return !query.isFetching;
    },
    getLoginStatus() {
      const { apiError = undefined } =
        query.error != null ? describeQueryResultError(query.error) : {};

      if (
        apiError?.error === "not_authenticated" ||
        apiError?.error === "user_not_found"
      ) {
        return "logged_out";
      }

      if (query.data == null) {
        return undefined;
      }

      return "logged_in";
    },

    isLoggedIn() {
      return this.getLoginStatus() === "logged_in";
    },

    userMe() {
      return this.isLoggedIn() && query.data
        ? describeUserMe(query.data)
        : undefined;
    },
  });
}

import { Typography } from "@mui/material";
import { FC } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";

export type FileDropzoneProps = {
  onDrop: DropzoneOptions["onDrop"];
  accept: DropzoneOptions["accept"];
};

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    accept: props.accept,
    multiple: false,
    // 5 MiB
    maxSize: 5 * 1024 * 1024,
  });

  return (
    <div
      {...getRootProps()}
      css={{
        height: "5rem",
        border: "1px dashed #bbb",
        borderRadius: "0.25rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
      }}
    >
      <input {...getInputProps()} />
      <Typography variant="body1">
        {isDragActive
          ? "Drop a file here"
          : "Drop a file or click and select one"}
      </Typography>
    </div>
  );
};

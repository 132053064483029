import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormPhoneNumberField } from "components/field/phoneNumber/FormPhoneNumberField";
import { Spacer } from "components/layout/Spacer";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { matchIsValidTel } from "mui-tel-input";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useResolveOnceDefined } from "utils/hooks";
import { z } from "zod";

export type EditPhoneNumberModalProps = {};

const zForm = z.object({
  phone_number: z.string().refine(matchIsValidTel, {
    message: "Please provide a valid phone number.",
  }),
});
type Form = z.infer<typeof zForm>;

export const EditPhoneNumberModal: FC<EditPhoneNumberModalProps> = (props) => {
  const dispatch = useDispatch();
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;
  const userMePromise = useResolveOnceDefined(userMe);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      userMePromise.then((me) => ({ phone_number: me.phone_number ?? "" })),
  });

  return (
    <ResultModal
      title="Edit phone number"
      onSubmit={async (cancel) => {
        await form.handleSubmit(async (values) => {
          const result = await dispatch(
            awdApi.endpoints.patchUserMe.initiate(values)
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(
              toastError(result.error, "We could not update your phone number.")
            );
            cancel();
            return;
          } else {
            dispatch(
              toastMessage("success", "Your phone number has been updated.")
            );
          }
        }, cancel)();
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <FormPhoneNumberField
          form={form}
          name="phone_number"
          label="Phone number"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
    </ResultModal>
  );
};

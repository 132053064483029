import { Autocomplete, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { AirportSpecification } from "awd-server-api";
import { Stack } from "components/layout/Stack";
import { describeAirport } from "logic/airport/describe";
import { useAirportSearch } from "logic/search/airport/useAirportSearch";
import { CSSProperties, FC, Ref } from "react";
import { FieldError } from "react-hook-form";
import { awdApi } from "store/reducers/awdApi";
import { ident, isNonNullable } from "utils/general";

export type AirportSelectValue = AirportSpecification | null | undefined;

export type AirportSelectProps = {
  value: AirportSelectValue;
  onChange?: (airport_id: AirportSelectValue) => void;
  onBlur?: () => void;
  inputRef?: Ref<HTMLInputElement>;
  error?: FieldError;
  label?: string;
  noAddAirport?: boolean;
  listboxSx?: CSSProperties;
};

export const AirportSelect: FC<AirportSelectProps> = (props) => {
  const { value: searchValue, setValue, isFetching, result } = useAirportSearch();

  const { data } = awdApi.endpoints.getAirport.useQuery(
    props.value?.specified_by === "id" && props.value.id ? { airportId: props.value.id } : skipToken
  );

  const addOptionName = props.value?.specified_by === "name" ? props.value.name : searchValue;

  const addOption = {
    type: "add_own" as const,
    label: `Add new airport "${addOptionName}"`,
    specification: ident<AirportSpecification>({
      specified_by: "name",
      name: addOptionName,
    }),
    airport: undefined,
  };

  const value =
    props.value?.specified_by === "name"
      ? addOption
      : data && props.value != null
      ? describeAirport(data).asSearchItem()
      : null;

  const searchedOptions =
    result?.airports.map((airport) => describeAirport(airport).asSearchItem()) ?? [];

  const options = [value ? value : searchValue.trim() !== "" ? addOption : null, ...searchedOptions]
    .filter(isNonNullable)
    .filter((option) => {
      if (option.type === "add_own" && props.noAddAirport) return false;
      return true;
    });

  return (
    <Autocomplete
      disablePortal
      loading={isFetching}
      onBlur={props.onBlur}
      isOptionEqualToValue={(a, b) => a.airport?.icao === b.airport?.icao && a.type === b.type}
      options={options}
      handleHomeEndKeys
      freeSolo
      selectOnFocus
      clearOnBlur
      renderOption={(props, option) => (
        <li {...props}>
          {option.type === "airport" ? (
            <Stack horizontal gap="0.25rem">
              <strong css={{ width: "5rem", textAlign: "right", flexShrink: 0 }}>
                {option.airport?.icao}
              </strong>
              <span>{option.airport?.name}</span>
            </Stack>
          ) : (
            <div>{option.label}</div>
          )}
        </li>
      )}
      value={value}
      onChange={(_, value) => {
        if (typeof value === "string") return;

        props.onChange?.(value?.specification ?? null);
      }}
      onInputChange={(_event, newInputValue) => {
        setValue(newInputValue);
      }}
      filterOptions={ident}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={props.inputRef}
          label={props.label ?? "Airport"}
          error={props.error != null}
          helperText={props.error?.message}
        />
      )}
      ListboxProps={{style: props.listboxSx}}
    />
  );
};

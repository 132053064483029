import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { FormSelectField } from "components/field/select/FormSelectField";
import { Stack } from "components/layout/Stack";
import {
  WidgetHighlightsPreferredShape,
  WidgetParameters,
  WidgetTextualWeatherReportType,
  WidgetVariant,
} from "components/widget/Widget";
import { WidgetCode } from "components/WidgetCode";
import { useAirportRunways } from "logic/airport/runway/useAirportRunways";
import { useAirport } from "logic/airport/useAirport";
import { WidgetInIframe } from "logic/widget/start";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { zFloatInput } from "utils/parse";
import { zUnchecked } from "utils/zod";
import { z } from "zod";

export type WidgetEditorProps = {
  airportId: string | number;
};

export const WidgetEditor: FC<WidgetEditorProps> = (props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(zFormValues),
    defaultValues: {
      variant: "highlights",
      highlightsPreferredShape: "horizontal",
      width: "400",
      stretch: true,
      textualWeatherReportType: "aw-metar",
    },
  });
  const values = form.watch();

  const airport = useAirport(props.airportId).describe();
  const runways = useAirportRunways(props.airportId).describeItems();

  const icao = airport?.getIcaos()[0];

  const width = parseFloat(values.width);

  const widgetParams: WidgetParameters | undefined =
    icao != null
      ? {
          icao,
          variant: values.variant,
          width: values.stretch ? "stretch" : Number.isFinite(width) ? width : 300,
          variantConfiguration:
            values.variant === "highlights"
              ? {
                  highlights: {
                    preferredShape: values.highlightsPreferredShape,
                  },
                }
              : values.variant === "wind"
              ? {
                  wind: { runwayIdentifier: values.windRunwayIdentifier },
                }
              : values.variant === "textualWeatherReport"
              ? { textualWeatherReport: { type: values.textualWeatherReportType } }
              : undefined,
        }
      : undefined;

  return (
    <Stack gap="2rem" horizontal css={{ justifyContent: "start", width: "100%", padding: "3rem" }}>
      <Stack gap="1rem" css={{ flexBasis: "20rem" }}>
        <Typography variant="subtitle1">Editor</Typography>
        <FormSelectField
          form={form}
          name="variant"
          label="Variant"
          options={[
            { label: "Highlights", value: "highlights" },
            { label: "Wind", value: "wind" },
            { label: "Ceiling", value: "ceiling" },
            { label: "Current weather", value: "currentWeather" },
            { label: "Forecast", value: "forecast" },
            { label: "Title", value: "title" },
            { label: "Textual Weather Report", value: "textualWeatherReport" },
            // { label: "Dashboard", value: "dashboard" },
          ]}
        />

        {values.variant === "textualWeatherReport" && (
          <FormSelectField
            form={form}
            name="textualWeatherReportType"
            label="Type of Textual report"
            options={[
              { label: "AW-Metar", value: "aw-metar" },
              { label: "AW-Taf", value: "aw-taf" },
              { label: "Metar", value: "metar" },
              { label: "Taf", value: "taf" },
            ]}
          />
        )}

        {values.variant === "highlights" && (
          <FormSelectField
            form={form}
            name="highlightsPreferredShape"
            label="Preferred shape"
            options={[
              { label: "Horizontal", value: "horizontal" },
              { label: "Vertical", value: "vertical" },
              { label: "Squareish", value: "squareish" },
            ]}
          />
        )}

        {values.variant === "wind" && (
          <FormSelectField
            form={form}
            name="windRunwayIdentifier"
            label="Runway"
            options={runways?.flatMap((runway) => runway.asDirectionOptions()) ?? []}
          />
        )}

        {widgetParams ? (
          <>
            <Typography variant="subtitle1">Code</Typography>
            <WidgetCode widgetParams={widgetParams} />
          </>
        ) : undefined}
      </Stack>

      <Stack
        gap="1rem"
        css={{
          width: "100%",
          maxWidth: widgetParams?.variant === "dashboard" ? "100%" : "50rem",
        }}
      >
        <Typography variant="subtitle1">Widget preview</Typography>
        {widgetParams ? (
          <WidgetInIframe params={widgetParams} />
        ) : (
          <Typography>Please select an airport.</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const zFormValues = z.object({
  variant: zUnchecked<WidgetVariant>(),
  stretch: z.boolean(),
  width: zFloatInput,
  highlightsPreferredShape: zUnchecked<WidgetHighlightsPreferredShape>(),
  windRunwayIdentifier: z.string(),
  textualWeatherReportType: zUnchecked<WidgetTextualWeatherReportType>(),
});
export type FormValues = z.infer<typeof zFormValues>;

import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Meta } from "components/meta/Meta";
import { WidgetEditor } from "components/widgetEditor/WidgetEditor";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";

export type WidgetEditorPageType = {};

export const WidgetEditorPage: FC<WidgetEditorPageType> = (props) => {
  const airport = useMyAirportRoles().managedAirport();

  return (
    <InsidePageLayout fullWidth>
      <Meta pageTitle="Widget" />
      <InsidePageTitle>Widget</InsidePageTitle>

      {airport?.id && <WidgetEditor airportId={airport.id} />}
    </InsidePageLayout>
  );
};

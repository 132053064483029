export function formatOptional(value: string | number | null | undefined) {
  return value === 0 ? "0" : value || "n/a";
}

export function formatYesNo(value: boolean | null | undefined) {
  if (value == null) return "n/a";
  return value ? "yes" : "no";
}

export function formatFeet(value: string | number | null | undefined) {
  return [formatOptional(value), "ft"].join(" ");
}

export function formatMeter(value: string | number | null | undefined) {
  return [formatOptional(value), "m"].join(" ");
}

import { AirportResponse, AirportSpecification } from "awd-server-api";
import { describeWeatherQueryError } from "logic/weather/error/describe";
import { LastWeatherReport } from "store/reducers/localApi";
import { bound } from "utils/class";
import { ident, isNonNullable } from "utils/general";
import { parseRunways } from "logic/airport/runway/parse";
import { describeRunwayIdentifier } from "logic/airport/runway/describe";
import {
  checkIfRunwayDataForVizAreMissing,
  filterOutClosedRunways,
  checkIfRunwayDataForVizAreCorrect,
} from "components/RunwaysViz/logic/runwayVisualizationLogic";

export const describeAirportQuery = (
  query: {
    originalArgs?: { airportId?: string | number };
    data?: AirportResponse;
    isFetching?: boolean;
    error?: unknown;
    isLoading?: boolean;
    isSuccess?: boolean;
  },
  storedQuery: {
    data?: LastWeatherReport | null;
    error?: unknown;
  }
) => {
  const airportSource =
    query.data == null &&
    storedQuery.data?.airport != null &&
    describeAirport(storedQuery.data.airport).hasIcao(
      query.originalArgs?.airportId as string | undefined
    )
      ? {
          type: "stored_last_report" as const,
          airport: storedQuery.data.airport,
          queryError: storedQuery.error,
        }
      : {
          type: "awd_server" as const,
          airport: query.data,
          queryError: query.error,
        };

  return bound({
    query,
    airportSource,
    transform() {
      if (airportSource.airport == null) return undefined;
      return describeAirport(airportSource.airport);
    },
    describeQueryError() {
      return airportSource.queryError != null
        ? describeWeatherQueryError(airportSource.queryError)
        : undefined;
    },
  });
};

export function describeAirport(airport: AirportResponse) {
  return bound({
    airport,
    runways: airport.runways,
    runwayPairs() {
      return this.runways ? parseRunways(this.runways) : undefined;
    },
    getSearchLabel() {
      return [airport.icao, airport.name].join(" ");
    },
    asSearchItem() {
      return {
        type: "airport" as const,
        label: this.getSearchLabel(),
        airport,
        specification: ident<AirportSpecification>({
          specified_by: "id",
          id: airport.id,
        }),
      };
    },
    getIcaos() {
      return [airport.icao, airport.icaoAlias].filter(isNonNullable);
    },
    hasIcao(icao: string | undefined) {
      return this.getIcaos().some(
        (airportIcao) => String(airportIcao).toUpperCase() === String(icao).toUpperCase()
      );
    },
    latlong(): [number, number] {
      return [airport.latitude, airport.longitude];
    },
    selectRunwayWithIdentifier(identifier: string) {
      const runwayIdentifier = describeRunwayIdentifier(identifier);

      const directions = this.runwayPairs()?.flat();

      return directions?.find((direction) => runwayIdentifier.doesIdentifyParsedRunway(direction));
    },
    getFirstRunway() {
      return this.runwayPairs()?.[0]?.[0];
    },
    getRunwayPairsIfAirportOpen() {
      if (airport.closed) {
        return [];
      }
      return this.runwayPairs();
    },
    isAirportOpen() {
      return !airport.closed;
    },
    isHeliport() {
      return airport.type === "heliport" ? true : false;
    },
    getUrl() {
      const icao = airport.icao;
      const safeIcao = icao ? icao : "";
      return process.env.REACT_APP_URI + "/" + safeIcao;
    },
    getOpenRunwaysIfAirportOpen() {
      if (airport.closed) {
        return [];
      }
      if (this.runways == null) return [];
      return filterOutClosedRunways(this.runways);
    },
    isAirportOrAllRunwaysClosed() {
      const openItems = this.getOpenRunwaysIfAirportOpen();
      if (openItems.length === 0) return true;
      else return false;
    },
    isAllRunwaysDataFilledIn() {
      if (this.runways == null) return false;
      return !checkIfRunwayDataForVizAreMissing(this.runways);
    },
    isRunwaysDataCorrect() {
      if (this.runways == null) return false;
      return checkIfRunwayDataForVizAreCorrect(this.runways);
    },
  });
}

import { useAirportWeather } from "logic/weather/hooks";
import { useSelector } from "react-redux";
import { useDispatch } from "store/hooks";
import { toggleWeatherSource } from "store/reducers/localStateSlice";
import { RootState } from "store/store";
import { useRunOnTupleChange } from "utils/hooks";

export function useSynchronizeMetarAndTaf(icao?: string) {
  const weather = useAirportWeather(icao).report();
  const dispatch = useDispatch();

  const { toggledToAwMetarWeatherSource, toggledToAwTafWeatherSource } = useSelector(
    (st: RootState) => st.localState
  );

  const isTafUnavailable = weather?.isOriginalReportUnavailable("taf");
  const isMetarUnavailable = weather?.isOriginalReportUnavailable("metar");

  // synchronize metar and taf switches
  useRunOnTupleChange(
    [
      toggledToAwTafWeatherSource,
      toggledToAwMetarWeatherSource,
      isTafUnavailable,
      isMetarUnavailable,
      isTafUnavailable,
      isMetarUnavailable,
      weather?.report,
    ] as const,
    ([prev_awTaf], [next_awTaf, next_awMetar]) => {
      // let's wait for weather to load before synchronizing anything
      if (weather == null) return;

      if (isTafUnavailable && !toggledToAwTafWeatherSource) {
        // detoggle taf if it's not available
        dispatch(toggleWeatherSource(true, "taf"));
        return;
      }
      if (isMetarUnavailable && !toggledToAwMetarWeatherSource) {
        // detoggle metar if it's not available
        dispatch(toggleWeatherSource(true, "metar"));
        return;
      }

      if (isTafUnavailable || isMetarUnavailable) return;

      // the following only runs if both taf and metar are available

      const nextValuesAreDesynchronized = next_awMetar !== next_awTaf;

      if (nextValuesAreDesynchronized) {
        if (prev_awTaf !== next_awTaf) {
          // change in taf -> synchronize metar to match taf value
          dispatch(toggleWeatherSource(next_awTaf, "metar"));
          return;
        } else {
          // there is either a change in metar or no change and this runs for the first time
          // in that case, we synchronize taf to match metar value
          dispatch(toggleWeatherSource(next_awMetar, "taf"));
          return;
        }
      }
    }
  );
}

// formatting time for map layer, to get url for weather tiles
export function currentDateTime(forecastValue: number) {
  return function (noOffset?: boolean, res?: number, forecast?: number) {
    let offset = forecastValue;
    if (forecast || forecast === 0) {
      offset = forecast;
    }

    let resolution = 1000 * 60 * 5;

    if (noOffset && res) {
      offset = 0;
      resolution = 1000 * 60 * res;
    }
    const time = new Date(
      Math.floor(new Date().getTime() / resolution) * resolution +
        1000 * 60 * offset
    );

    return time;
  };
}

import { OverlayType } from "components/kiosk/slides/KioskMapSlide";
import { FC, useState } from "react";
import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";
import { matchMap } from "utils/general";

type OverlayProps = {
  weatherOverlay: OverlayType;
  type?: "landing" | "vicinity";
};

const SRoundedWhiteBox = styled.div`
  padding: 0.5rem;
  border-radius: 0.3rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
`;

const Legend: FC<OverlayProps> = ({ weatherOverlay }) => {
  const src = matchMap(weatherOverlay, {
    "crr-pc": "/crr-pc.png",
    rdt: "/rdt.png",
    clouds: "/clouds.png",
  });

  const width = matchMap(weatherOverlay, {
    "crr-pc": "140px",
    rdt: "75px",
    clouds: "90px",
  });

  return (
    <SRoundedWhiteBox>
      <img src={src} alt="legend" width={width} />;
    </SRoundedWhiteBox>
  );
};

const SLegendWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 80px;
  z-index: 999;
`;
export const LegendAtRightSide: FC<OverlayProps> = ({ weatherOverlay }) => {
  return (
    <SLegendWrapper>
      <Legend weatherOverlay={weatherOverlay} />
    </SLegendWrapper>
  );
};

const SLegendWithIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: 999;
  button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const SLegendContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 32px;
`;

const SSpan = styled.span`
  font-weight: bold;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  color: #231f20;
`;

export const LegendWithIcon: FC<OverlayProps> = ({ weatherOverlay, type }) => {
  const [showLegend, setShowLegend] = useState(false);

  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  return (
    <SLegendWithIconWrapper css={type === "vicinity" && { bottom: "80px" }}>
      <button onClick={toggleLegend}>
        <div css={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <SSpan>Weather Legend</SSpan>
          <InfoIcon css={{ width: "2rem", height: "2rem", color: "#4893cf" }} />
        </div>
      </button>
      {showLegend && (
        <SLegendContainer>
          <Legend weatherOverlay={weatherOverlay} />
        </SLegendContainer>
      )}
    </SLegendWithIconWrapper>
  );
};

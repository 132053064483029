import { HighlightTile } from "components/Dashboard/highlightTile/HighlightTile";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { WidgetHighlightsPreferredShape, WidgetParameters } from "components/widget/Widget";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";

export type WeatherWidgetHighlightsProps = WidgetParameters;

export const WeatherWidgetHighlights: FC<WeatherWidgetHighlightsProps> = (props) => {
  const { preferredShape = "horizontal" } = props.variantConfiguration?.highlights ?? {};

  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  const shape: WidgetHighlightsPreferredShape =
    (width != null && width < 250) || preferredShape === "vertical"
      ? "vertical"
      : width != null && width < 500
      ? "squareish"
      : preferredShape;

  return (
    <div css={{ width: "100%", overflow: "hidden", padding: "0.5rem 0" }} ref={ref}>
      {width != null && width < 120 ? (
        <ErrorAlert title="This widget variant requires at least 120 wide space." />
      ) : (
        <div
          css={[
            { display: "grid", gap: "1rem" },
            shape === "squareish" && {
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(3, 1fr)",
            },
            shape === "horizontal" && {
              gridTemplateColumns: "repeat(5, 1fr)",
            },
            shape === "vertical" && {
              gridTemplateRows: "repeat(5, 1fr)",
            },
          ]}
        >
          <HighlightTile
            source="flight_rule"
            css={shape === "squareish" && { gridColumn: "span 2" }}
          />
          <HighlightTile source="temperature" />
          <HighlightTile source="visibility" />
          <HighlightTile source="qnh" />
          <HighlightTile source="sunrise_sunset" />
        </div>
      )}
    </div>
  );
};

import { FC } from "react";

export type SunsetIconProps = {};

export const SunsetIcon: FC<SunsetIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.928"
      height="33.412"
      x="0"
      y="0"
      viewBox="-0.318 -3.402 32.928 33.412"
    >
      <g>
        <path
          fill="none"
          stroke="#6D6E70"
          strokeMiterlimit="10"
          d="M16.28 16.61L16.28 28.808"
        ></path>
        <path
          fill="#6D6E70"
          d="M11.48 21.973a.5.5 0 01.69.153l4.109 6.453 4.109-6.453a.499.499 0 11.843.537L16.7 29.778a.5.5 0 01-.844 0l-4.531-7.115a.496.496 0 01.155-.69z"
        ></path>
        <path
          fill="none"
          stroke="#6D6E70"
          strokeMiterlimit="10"
          d="M9.106 10.61a7.175 7.175 0 0114.35 0"
        ></path>
        <g fill="none" stroke="#6D6E70" strokeMiterlimit="10">
          <path d="M25.688 9.552L30.22 9.552"></path>
          <path d="M23.725 4.266L27.123 1.698"></path>
          <path d="M19.193 1.169L21.006 -3.211"></path>
          <path d="M13.473 1.169L11.661 -3.211"></path>
          <path d="M6.872 9.552L2.341 9.552"></path>
          <path d="M8.836 4.266L5.437 1.698"></path>
        </g>
        <path
          fill="none"
          stroke="#6D6E70"
          strokeMiterlimit="10"
          d="M-0.318 12.875L32.609 12.875"
        ></path>
      </g>
    </svg>
  );
};

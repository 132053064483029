import { FC } from "react";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { Meta } from "components/meta/Meta";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Stack } from "components/layout/Stack";
import { UserListingTable } from "components/userListing/UserListingTable";

type UserListingPageProps = {};

export const UserListingPage: FC<UserListingPageProps> = () => {
  return (
    <InsidePageLayout>
      <Meta pageTitle="User Listing" />
      <Stack gap="1rem" css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>User Listing</InsidePageTitle>
      </Stack>
      <UserListingTable />
    </InsidePageLayout>
  );
};

import React, { FC } from "react";
import { useTheme } from "@emotion/react";
import { q2u } from "utils/global.functions";

export type WindArrowType = {
  angleWind: number;
  centerOfCompass: [number, number];
  circleRadius: number;
  speedWind: string | undefined;
};

const WindArrow: FC<WindArrowType> = ({ angleWind, centerOfCompass, circleRadius, speedWind }) => {
  const transformCondition = (moveParameter: number) =>
    angleWind <= 45 || angleWind >= 315 ? `rotate(180,0, ${52 + moveParameter})` : undefined;

  const theme = useTheme();

  return (
    // big wind arrow, wind angle, wind speed
    <g
      id="wind"
      transform={`translate(${centerOfCompass[0]}, ${centerOfCompass[1]})
                  rotate(-180)
                  rotate(${angleWind})
                  translate(0,${circleRadius + 10})
                `}
    >
      {/* wind arrow */}
      <path
        d="M 0,0
           L 23.4,55.12
           L 3.64,46.28
           L 3.64,47.32
           C 3.64,50.44 1.56,52 0,52
           C -1.56,52 -3.64,50.44 -3.64,47.32
           L -3.64,46.28
           L-23.4,55.12 z"
        fill={theme.primary}
      />
      {/* wind angle */}
      <text
        x={0}
        y={85}
        textAnchor="middle"
        fontWeight="800"
        fontSize="34px"
        fill={theme.primary}
        transform={transformCondition(20)}
      >
        {q2u({ value: angleWind, unit: "degreeT" }, "degreeT", "round")}°
      </text>
      {/* wind speed */}
      <text
        x={0}
        y={110}
        textAnchor="middle"
        fontWeight="600"
        fontSize="22px"
        fill={theme.primary}
        transform={transformCondition(50)}
      >
        {speedWind ?? ""}
      </text>
    </g>
  );
};

export default WindArrow;

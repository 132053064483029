import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import Dashboard from "pages/weather-dashboards/Dashboard";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";

export type WeatherWidgetDashboardProps = {};

export const WeatherWidgetDashboard: FC<WeatherWidgetDashboardProps> = () => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();
  return (
    <div ref={ref}>
      {width != null && width < 278 ? (
        <ErrorAlert title="This widget variant requires at least 278 wide space." />
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

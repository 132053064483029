import { CookieConsent } from "components/consent/cookie/CookieConsent";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export type CookieConsentProviderProps = {};

/**
 * Maybe you're wondering why we don't render `CookieConsent` in App.js and we create another provider component for it.
 *
 * That's a very good question, the reason is that `CookieConsent` uses `Link` from react-router-dom to link to Cookie Policy.
 *
 * For `LLink` to work, the component must be inside react-router context, not outside it.
 * We achieve that by rendering `CookieConsentProvider` inside a `Route`.
 */
export const CookieConsentProvider: FC<CookieConsentProviderProps> = (
  props
) => {
  return (
    <>
      <Outlet />
      <CookieConsent />
    </>
  );
};

import { FC } from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";
import { useSelector } from "react-redux";
import { getDummyRunwaySchema } from "store/reducers/localStateSlice";
import { useDashboardAirport } from "logic/weather/hooks";

const useHeadingData = (dummy?: boolean) => {
  let airportName = useDashboardAirport(undefined, { skip: dummy ?? false })?.transform()?.airport
    ?.name;
  let icaos = useDashboardAirport(undefined, { skip: dummy ?? false })
    ?.transform()
    ?.getIcaos()
    .join(", ");

  const dummyData = useSelector(getDummyRunwaySchema);
  if (dummy) {
    airportName = dummyData.name;
    icaos = dummyData.icao;
  }
  return { airportName, icaos };
};

export type HeadingType = {
  dummy?: boolean;
};

export const Heading: FC<HeadingType> = ({ dummy }) => {
  const { airportName, icaos } = useHeadingData(dummy);
  return (
    <StyledHeading>
      {airportName} ({icaos})
    </StyledHeading>
  );
};

const StyledHeading = styled.p`
  text-align: left;
  font-weight: bold;
  font-size: 34px;
  text-transform: uppercase;
  color: #404041;
  margin-top: 0;
  margin-bottom: 0;

  ${responsive.deskSM} {
    margin-left: 1rem;
  }

  ${responsive.tablet} {
    font-size: 27px;
  }

  ${responsive.mobilP} {
    font-size: 22px;
  }
`;

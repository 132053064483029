import { isNonNullable } from "utils/general";
import { z, ZodType } from "zod";

export const zFloatInput = z.string().refine(
  (input) => {
    if (typeof input === "string") {
      const invalidCharacters = input.trim().replace(/[0-9.-]/g, "");
      return invalidCharacters === "";
    }
    return true;
  },
  { message: "Please enter a valid number." }
);

export function parseFloatInputString(input: string) {
  if (input === "") return null;
  return parseFloat(input);
}

export const zIntegerInput = zFloatInput.refine(
  (input) => input === "" || Number.isInteger(parseFloatInputString(input)),
  { message: "Please enter an integer." }
);

export const zLongitudeInput = zFloatInput.refine(
  (x) => {
    const number = parseFloatInputString(x);
    return number != null && number >= -180 && number <= 180;
  },
  { message: "Please provide a number between -180 and 180" }
);

export const zRequiredLongitudeInput = zLongitudeInput.refine(isNonNullable, {
  message: "Please provide the longitude.",
});

export const zLatitudeInput = zFloatInput.refine(
  (x) => {
    const number = parseFloatInputString(x);
    return number != null && number >= -90 && number <= 90;
  },
  { message: "Please provide a number between -90 and 90." }
);

export const zRequiredLatitudeInput = zLongitudeInput.refine(isNonNullable, {
  message: "Please provide the latitude.",
});

export const zYesNoInput = z.enum(["yes", "no"]);
export const zOptionalYesNoInput = z.enum(["yes", "no", "n/a"]);

export function parseYesNo(option: "yes" | "no") {
  return option === "yes";
}

export function parseOptionalYesNo(option: "yes" | "no" | "n/a") {
  return option === "n/a" ? null : parseYesNo(option);
}

export function safeParse<ZOutput>(type: ZodType<ZOutput>, data: unknown) {
  const parsed = type.safeParse(data);
  return parsed.success ? parsed.data : undefined;
}

import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";
import { AirportSubscriptionStatusResponse, RegisteredAirportResponse } from "awd-server-api";
import { match } from "ts-pattern";
import { Typography } from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeWeatherQueryError } from "logic/weather/error/describe";

export function useAirportSubscriptionStatus(airportId?: number | string) {
  const query = awdApi.endpoints.getAirportSubscriptionStatus.useQuery(
    airportId ? { airportId } : skipToken,
    { refetchOnReconnect: true }
  );
  return describeAirportSubscriptionStatus(query);
}

export function describeAirportSubscriptionStatus(query: {
  data?: AirportSubscriptionStatusResponse;
  error?: unknown;
  isFetching: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}) {
  return bound({
    query,
    hasSubscription() {
      return query.data?.hasSubscription;
    },
    describeQueryError() {
      return query.error != null ? describeWeatherQueryError(query.error) : undefined;
    },
  });
}

export function describeActiveSubscription(airport: RegisteredAirportResponse) {
  return bound({
    airport,
    isActive() {
      return airport.subscription_is_active;
    },
    getEndDateCellText() {
      const { airport } = this;
      return match(this.isActive())
        .with(true, () =>
          airport.subscription_end_date
            ? new Date(airport.subscription_end_date).toDateString()
            : "No end date"
        )
        .with(false, () => "Inactive")
        .exhaustive();
    },
    getButtonElementText() {
      return match(this.isActive())
        .with(true, () => "Disable")
        .with(false, () => "Enable")
        .exhaustive();
    },
    getDialogTitle() {
      return match(this.isActive())
        .with(true, () => "Disable Subscription")
        .with(false, () => "Enable Subscription")
        .exhaustive();
    },
    getDialogDescription() {
      return match(this.isActive())
        .with(true, () => (
          <Typography>
            Are you sure you want to disable the subscription of <b>{this.airport.airport_icao}?</b>
          </Typography>
        ))
        .with(false, () => (
          <Typography>
            Are you sure you want to enable the subscription of <b>{this.airport.airport_icao}?</b>
          </Typography>
        ))
        .exhaustive();
    },
    getSubmitButtonData() {
      const text = match(this.isActive())
        .with(true, () => "Disable")
        .with(false, () => "Enable")
        .exhaustive();
      const color = match<boolean, "error" | undefined>(this.isActive())
        .with(true, () => "error")
        .with(false, () => undefined)
        .exhaustive();
      return { text, color };
    },
  });
}

import { Button } from "@mui/material";
import { describeAirportIcao } from "logic/airport/icao/describe";
import { FC } from "react";

export type OpenKioskButtonProps = {
  icao: string;
};

export const OpenKioskButton: FC<OpenKioskButtonProps> = (props) => {
  return (
    <Button href={describeAirportIcao(props.icao).getKioskUrl()}>
      Open kiosk
    </Button>
  );
};

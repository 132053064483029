import styled from "@emotion/styled";
import { useAirportWeather } from "logic/weather/hooks";
import { FC } from "react";

export type TextWeatherReportProps = {
  source: "metar" | "taf" | "aw-metar" | "aw-taf";
};

export const TextWeatherReport: FC<TextWeatherReportProps> = (props) => {
  const text = useAirportWeather().report()?.formatTextReport(props.source);

  return (
    <PText>{text}</PText>
  );
};

const PText = styled.pre({
  color: "black",
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
});

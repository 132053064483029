import { describeMutationResult } from "logic/api/result/describe";
import { ResetPasswordForm } from "logic/login/resetPassword/form";
import { NavigateFunction } from "react-router-dom";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { createAsyncThunk } from "store/thunk";

export const onResetPasswordFormSubmitted = createAsyncThunk(
  "onResetPasswordFormSubmitted",
  async (
    args: { values: ResetPasswordForm; navigate: NavigateFunction },
    ctx
  ) => {
    const result = await ctx
      .dispatch(awdApi.endpoints.postResetPassword.initiate(args.values))
      .then(describeMutationResult);

    if (result.error) {
      ctx.dispatch(
        toastError(
          result.error,
          "Failed to send a link to your e-mail address."
        )
      );
      return;
    }

    ctx.dispatch(
      toastMessage("success", "We have sent a link to your e-mail address.")
    );
    args.navigate("/");
  }
);

import { Link, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { pageSpec } from "logic/navigation/spec";
import { responsive } from "mixins";
import { FC } from "react";
import styled from "@emotion/styled";

export type FooterProps = {};

const SFooter = styled.footer`
  padding: 2rem 2rem 3rem;
  display: flex;
  justify-content: center;

  ${responsive.tabletM} {
    padding: 0.2rem;
  }
  ${responsive.mobilS} {
    padding: 2rem;
  }
`;

const SFooterGrid = styled.div`
  display: grid;
  gap: 2rem;
  justify-content: space-between;
  grid-template-columns: repeat(5, 1fr);

  max-width: 80rem;
  width: 100%;
  padding: 0.5rem;

  ${responsive.tabletM} {
    gap: 0.7rem;
  }

  ${responsive.mobilL} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${responsive.mobilS} {
    gap: 2rem;
  }
`;

export const Footer: FC<FooterProps> = (props) => {
  return (
    <SFooter>
      <SFooterGrid>
        <Stack gap="1rem">
          <Typography variant="body1">App</Typography>
          <Link href="/">Weather map</Link>
        </Stack>
        <Stack gap="1rem">
          <Typography variant="body1">Terms</Typography>
          <Link href={pageSpec.terms_of_service.path}>Terms of Service</Link>
          <Link href={pageSpec.privacy_policy.path}>Privacy Policy</Link>
          <Link href={pageSpec.cookie_policy.path}>Cookie Policy</Link>
          <Link href={pageSpec.disclaimer.path}>Disclaimer</Link>
        </Stack>
        <Stack gap="1rem">
          <Typography variant="body1">Company</Typography>
          <Link href="https://meandair.com">About us</Link>
          <Link href={pageSpec.customer_support.path}>Customer support</Link>
        </Stack>
        <Stack gap="1rem">
          <Typography variant="body1">Socials</Typography>
          <Link href="https://www.linkedin.com/showcase/airport-weather-com">
            LinkedIn
          </Link>
          <Link href="https://www.instagram.com/airportwx">Instagram</Link>
          <Link href="https://twitter.com/AirportWX">Twitter</Link>
        </Stack>
        <Stack
          gap="1rem"
          css={{
            textAlign: "right",
            [responsive.mobilL]: { gridColumn: "span 2" },
          }}
        >
          <Typography variant="body1">
            AirportWeather.com
            <br />
            Olof Palmestraat 14
            <br />
            2616 LR Delft
            <br />
            The Netherlands
          </Typography>
          <Typography variant="body1">Phone: +31 (0)15 2629 889</Typography>
          <Typography variant="body1">
            Email: support@airportweather.com
          </Typography>
        </Stack>
      </SFooterGrid>
    </SFooter>
  );
};

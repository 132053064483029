import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DialogContent } from "@mui/material";
import { Spacer } from "components/layout/Spacer";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ResultModal } from "components/modal/inside/ResultModal";
import { RunwayForm } from "components/runwayForm/RunwayForm";
import { describeRunway } from "logic/airport/runway/describe";
import {
  prepareForm,
  preparePatchBody,
  RunwayFormValues,
  zRunwayForm,
} from "logic/airport/runway/form";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { isNonNullable } from "utils/general";
import { useResolveOnceDefined } from "utils/hooks";

export type EditRunwayModalProps = {
  icao: string;
  runwayId: number;
};

export const EditRunwayModal: FC<EditRunwayModalProps> = (props) => {
  const { show, modalContext } = useModalContext();
  const dispatch = useDispatch();
  const airportRunways = awdApi.endpoints.getAirportRunways.useQuery({
    airportId: props.icao,
  }).data;
  const runway = airportRunways?.runways.find((r) => r.id === props.runwayId);
  const runwayPromise = useResolveOnceDefined(runway);
  const { formatIdentifier = undefined } = runway ? describeRunway(runway) : {};

  const form = useForm<RunwayFormValues>({
    resolver: zodResolver(zRunwayForm),
    defaultValues: () => runwayPromise.then(prepareForm),
  });

  return (
    <ResultModal
      title={["Edit runway", formatIdentifier?.()]
        .filter(isNonNullable)
        .join(" ")}
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.patchRunway.initiate({
              runwayId: props.runwayId,
              body: preparePatchBody(body),
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(
              toastError(result.error, "We could not update the runway.")
            );
            cancel();
          } else {
            dispatch(toastMessage("success", "The runway has been updated."));
          }
        }, cancel)()
      }
      extraActions={
        <Button
          color="error"
          onClick={() =>
            show(
              <ResultModal
                submitButton={{ color: "error", text: "Remove" }}
                title={`Do you really want to remove the ${formatIdentifier?.()} runway?`}
                onSubmit={async (cancel) => {
                  const result = await dispatch(
                    awdApi.endpoints.deleteRunway.initiate({
                      runwayId: props.runwayId,
                    })
                  ).then(describeMutationResult);

                  if (result.error) {
                    dispatch(
                      toastError(
                        result.error,
                        "We could not remove the runway."
                      )
                    );
                    cancel();
                  } else {
                    dispatch(
                      toastMessage("success", "The runway has been removed.")
                    );
                    modalContext?.close();
                  }
                }}
              />
            )
          }
        >
          Remove
        </Button>
      }
    >
      <DialogContent sx={{ width: "80rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        {!form.formState.isLoading && (
          <RunwayForm form={form} runway={runway} />
        )}
      </DialogContent>
    </ResultModal>
  );
};

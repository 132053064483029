import { KioskBanner } from "components/Dashboard/airport/kiosk/KioskBanner";
import { WeatherDashboardContext } from "components/Dashboard/context";
import { Stack } from "components/layout/Stack";
import { FC, useContext } from "react";

export type KioskBannerSlideProps = {};

export const KioskBannerSlide: FC<KioskBannerSlideProps> = (props) => {
  const icao = useContext(WeatherDashboardContext)?.icao;
  if (icao == null) return null;

  return (
    <Stack gap="0">
      <KioskBanner icao={icao} />;
    </Stack>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";
import { z } from "zod";

export type ConfirmDeleteAccountModalProps = {};

const zForm = z.object({
  password: z.string(),
});
type Form = z.infer<typeof zForm>;

export const ConfirmDeleteAccountModal: FC<ConfirmDeleteAccountModalProps> = (
  props
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
  });

  return (
    <ResultModal
      title="Do you really want to delete your account?"
      submitButton={{
        color: "error",
        text: "Delete",
      }}
      onSubmit={async () => {
        const values = form.getValues();
        const result = await dispatch(
          awdApi.endpoints.deleteUserMe.initiate(values)
        ).then(describeMutationResult);

        if (result.error) {
          dispatch(
            toastError(result.error, "We could not delete your account.")
          );
          return "cancel";
        } else {
          dispatch(toastMessage("success", "Your account has been deleted."));
          navigate("/");
          return;
        }
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <FormTextField
          form={form}
          name="password"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
        />
      </DialogContent>
    </ResultModal>
  );
};

import React, { useEffect, useRef, FC, SetStateAction, MutableRefObject } from "react";
import styled from "@emotion/styled";
import { useAirportWeather } from "logic/weather/hooks";
import { useParams } from "react-router-dom";
import { responsive } from "mixins";
import { addAndFormatTime } from "utils/global.functions";
import useWindowDimensions from "utils/useWindowDimensions";

export type TimeSliderProps = {
  forecastBar: MutableRefObject<HTMLInputElement | null>;
  forecastValue: number;
  setForecastValue: React.Dispatch<SetStateAction<number>>;
};

const TimeSlider: FC<TimeSliderProps> = ({ forecastBar, forecastValue, setForecastValue }) => {
  const params = useParams();
  const icao = params.icao;
  const { width } = useWindowDimensions();
  const currentTimeLabel = useRef<HTMLDivElement>(null);

  const weather = useAirportWeather(icao).report();
  const formattedTimeSteps = weather
    ?.selectForecastSource({ toggledToAw: true })
    ?.getWind()
    ?.getFormattedTimeSteps();
  const uniqueDays = weather
    ?.selectForecastSource({ toggledToAw: true })
    ?.getWind()
    ?.getUniqueDays();
  const validTimeSteps = weather?.selectForecastSource({ toggledToAw: true })?.getWind().time_steps;

  console.log(formattedTimeSteps);

  // when user clicks on slider
  const moveWeather = (value: number) => {
    if (!forecastBar.current) return;

    const valueToSet = value ?? parseInt(forecastBar.current.value);

    setForecastValue(valueToSet);
    forecastBar.current.style.setProperty("--before-width", `${(valueToSet / 300) * 100}%`);
    setCurrentTimeLabelPosition(20, valueToSet, forecastBar.current);
  };

  // update position of current date label on resize
  useEffect(() => {
    if (!forecastBar.current) return;
    moveWeather(parseInt(forecastBar.current?.value));
  }, [width]);

  // moving the blue popup
  const setCurrentTimeLabelPosition = (
    offsetValue: number,
    forecastValue: number,
    element: HTMLInputElement
  ) => {
    if (!currentTimeLabel.current) return;

    currentTimeLabel.current.style.setProperty(
      "--left-position",
      `${(forecastValue / 300) * element.getBoundingClientRect().width - offsetValue}px`
    );
    currentTimeLabel.current.style.setProperty("--left-position-beak", `${offsetValue}px`);
  };

  // calculating forecasted time when user moves the slider
  const forecastedTime = () => {
    if (!validTimeSteps?.[0]) return "HH:MM";
    const offset = forecastValue * 60 * 1000;
    const formatted = addAndFormatTime(validTimeSteps[0].valid_time, offset);
    return formatted;
  };

  const calculateMousePosition = (mousePosition: number, element: HTMLElement) => {
    let sliderValue =
      Math.floor(
        (((mousePosition - element.getBoundingClientRect().x) /
          element.getBoundingClientRect().width) *
          300) /
          10
      ) * 10;

    return sliderValue < 0 ? 0 : sliderValue > 300 ? 300 : sliderValue;
  };

  const bubbleEndDragging = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (!forecastBar.current) return;

    const sliderValue = calculateMousePosition(e.clientX, forecastBar.current);

    moveWeather(sliderValue);
  };

  const bubbleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    function onMouseMove(this: Window, ev: MouseEvent) {
      if (!forecastBar.current) return;
      const sliderValue = calculateMousePosition(ev.clientX, forecastBar.current);

      moveWeather(sliderValue);
    }
    function onMouseUp() {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    }
  };

  return (
    <StyledNowcastControl>
      <div className="forecastLabels">
        <div className="title">Nowcast</div>
        <div>{uniqueDays?.join(" / ")}</div>
      </div>

      {/* forecast slider */}
      <div className="forecastSlider">
        <input
          ref={forecastBar}
          type="range"
          min="0"
          max="300"
          step="10"
          value={forecastValue}
          id="timeSlider"
          onChange={(e) => {
            e.preventDefault();
            if (!forecastBar.current) return;
            moveWeather(parseInt(forecastBar.current?.value));
          }}
        />

        {/* 5 hours forecast hour labels */}
        <div className="sliderTimeLabels">
          {formattedTimeSteps?.map((i) => {
            return (
              <div className="timeStart" key={i}>
                {i}
              </div>
            );
          })}
        </div>

        {/* blue popup with the current time label */}
        <div
          draggable
          className="currentTimeLabel"
          ref={currentTimeLabel}
          onChange={(e) => {
            e.preventDefault();
            if (!forecastBar.current) return;
            moveWeather(parseInt(forecastBar.current?.value));
          }}
          onMouseDown={bubbleDrag}
          onDragEnd={bubbleEndDragging}
        >
          {forecastedTime()}
        </div>
      </div>
    </StyledNowcastControl>
  );
};

export default TimeSlider;

const StyledNowcastControl = styled.div`
  position: absolute;
  bottom: 20px;
  /* left: 10px; */
  width: 100%;
  z-index: 1000;
  /* margin: 0 10px; */
  display: flex;
  padding: 0 10px;
  /* padding-right: 80px; */

  .forecastLabels {
    background-color: #f1f1f2;
    width: 100px;
    font-size: 11px;
    padding: 2px;
    padding-left: 7px;
    border-radius: 0.5rem 0 0 0.5rem;
    margin-top: 1.4rem;
    transform: translateY(-5px);

    ${responsive.mobilP} {
      font-size: 9px;
      width: 80px;
    }

    ${responsive.mobilS} {
      font-size: 8px;
    }

    .title {
      text-transform: uppercase;
    }
  }

  .forecastSlider {
    width: 100%;
    /* background-color: #f1f1f2; */
    position: relative;
    padding-right: 25px;
    border-radius: 0 0.5rem 0.5rem 0;

    input {
      padding: 1.5rem 0;
    }
  }

  /* slider chrome styles */
  #timeSlider {
    /* --input-bg: #62757f; */
    --input-bg: #376072;
    /* --input-bg-hovered: #7b9bad; */
    --input-bg-hovered: #2b708e;
    /* --seek-before-color: #3e97d2e1; */
    --seek-before-color: #009ff5;
    /* --seek-before-color-hovered: #87bde6; */
    --seek-before-color-hovered: #27b6f4;
    --before-width: 100px;
    --selectedKnob: #66c6f9;

    position: relative;
    z-index: 10000;

    width: 100%;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    height: 0.7rem;
    /* background-color: var(--input-bg); */
    background-color: transparent;
    outline: none;

    cursor: pointer;

    /* slider firefox */
    &::-moz-range-track {
      width: 100%;
      margin: 0;
      appearance: none;
      -webkit-appearance: none;
      height: 0.7rem;
      background-color: var(--input-bg);
      box-shadow: 0 0 2px 0 black;
      outline: none;
      cursor: pointer;
    }

    /* slider safari */
    &::-webkit-slider-runnable-track {
      --input-bg: #376072;

      width: 100%;
      margin: 0;
      appearance: none;
      -webkit-appearance: none;
      height: 0.7rem;
      background-color: var(--input-bg);
      box-shadow: 0 0 2px 0 black;
      outline: none;
      cursor: pointer;
    }

    /* HOVERED */
    /* slider chrome when hovered */
    &:hover {
    }

    /* slider firefox when hovered */
    &:hover::-moz-range-track {
      background-color: var(--input-bg-hovered);
    }

    /* slider safari when hovered */
    &:hover::-webkit-slider-runnable-track {
      background-color: var(--input-bg-hovered);
    }

    /* BEFORE HANDLE */
    /* slider chrome safari seek before */
    &:before {
      content: "";
      position: absolute;
      top: 18px;
      left: 0;
      height: 0.7rem;
      width: var(--before-width);
      background-color: var(--seek-before-color);
    }

    /* slider firefox seek before */
    &::-moz-range-progress {
      height: 0.7rem;
      background-color: var(--seek-before-color);
    }

    /* slider chrome seek before hovered  */
    &:hover::before {
      background-color: var(--seek-before-color-hovered);
    }

    /* slider firefox seek before hovered */
    &:hover::-moz-range-progress {
      background-color: var(--seek-before-color-hovered);
    }

    /* HANDLE */
    /* slider chrome and safari handle */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 0.9rem;
      width: 0.3rem;
      background-color: var(--seek-before-color);
      box-sizing: border-box;
      /* z-index: ; */
    }

    /* slider firefox handle */
    &::-moz-range-thumb {
      -webkit-appearance: none;
      height: 0.9rem;
      width: 0.3rem;
      background-color: var(--seek-before-color);
      box-sizing: border-box;
    }

    /* HANDLE HOVERED */
    /* slider chrome handle hovered */
    &:hover::-webkit-slider-thumb {
      background-color: var(--seek-before-color-hovered);
    }

    /* slider firefox handle hovered */
    &:hover::-moz-range-thumb {
      background-color: var(--seek-before-color-hovered);
    }
  }

  .sliderTimeLabels {
    font-size: 13px;
    display: flex;
    justify-content: space-between;

    transform: translateY(-0.3rem);
    background-color: #f1f1f2;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;
    padding-top: 0.2rem;
    margin-top: -19px;
    border-radius: 0.2rem;
    padding-bottom: 0.1rem;

    ${responsive.mobilP} {
      font-size: 11px;
    }

    ${responsive.mobilS} {
      font-size: 9px;
    }
  }

  .currentTimeLabel {
    --left-position: -20px;
    --left-position-beak: 20px;

    position: absolute;
    top: -1.5rem;
    left: var(--left-position);
    font-size: 14px;
    /* background-color: #87bde6; */
    /* background-color: #00bdf5; */
    background-color: #009ff5;
    /* background-color: #376072; */
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: #f1f1f2;
    cursor: pointer;

    ${responsive.mobilP} {
      font-size: 11px;
      top: -1.7rem;
    }

    &:before {
      top: 100%;
      left: var(--left-position-beak);
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #009ff5;
      border-width: 0.5em;
      margin-left: -0.5em;
    }
  }
`;

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import premiumClouds from "assets/images/premium-clouds.png";

import { useDashboardAirport } from "logic/weather/hooks";
import { Meta } from "components/meta/Meta";

const Clouds = () => {
  const { icao } = useParams();
  const airport = useDashboardAirport(icao).airport;
  const navigate = useNavigate();

  useEffect(() => {
    if (airport === null) {
      navigate(`/${icao}`);
    }
  });
  return (
    <StyledClouds>
      <Meta pageTitle={`${icao.toUpperCase()} Clouds`} />
      <div>
        <img src={premiumClouds} alt="" />
      </div>
    </StyledClouds>
  );
};

const StyledClouds = styled.div`
  margin: 100px auto;

  img {
    max-width: 100%;
  }
`;

export default Clouds;

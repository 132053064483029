import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

export type ArticleTitleProps = PropsWithChildren<{
  className?: string;
}>;

export const ArticleTitle: FC<ArticleTitleProps> = (props) => {
  return (
    <Sh1
      className={props.className}
      onClick={(e) => {
        if (e.target instanceof HTMLElement) {
          e.target.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {props.children}
    </Sh1>
  );
};

const Sh1 = styled.h1`
  font-size: 1.5rem;
  font-family: "Encode Sans Condensed";
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 950px) {
    font-size: max(3vw, 1.25rem) !important;
  }
`;

import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useContentfulData } from "utils/useContentfulData";
import Modal from "components/modal/Modal";
import { Button } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { useNavigate } from "react-router-dom";
import { modalWithoutDispatch } from "components/modal/modalSpec";
import { useModalOptions } from "components/content/defaultOptions";
import { localApi } from "store/reducers/localApi";

export type ModalContentfulDataWithoutSelectorsType = {
  icao?: string;
  type:
    | "notAllRunwaysDataForVizFilledIn_General"
    | "notAllRunwaysDataForVizFilledIn_Manager"
    | "runwaysDataForVizNotCorrect_General"
    | "runwaysDataForVizNotCorrect_Manager";
};

export const ModalContentfulDataWithoutSelectors = ({
  type,
  icao,
}: ModalContentfulDataWithoutSelectorsType) => {
  const navigate = useNavigate();

  const isMobileApp = localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ?? false;
  const contentFromContentful = useContentfulData(modalWithoutDispatch[type].contentfulSource);
  const modalContent = contentFromContentful?.modalContent;

  const defaultOptions = useModalOptions();

  const navigateTo =
    modalWithoutDispatch[type].navigate.backButtonPath === "/icao"
      ? `/${icao}`
      : modalWithoutDispatch[type].navigate.backButtonPath;

  const onModalClose = () => {
    navigate(navigateTo, { replace: true });
  };

  if (!modalContent) return null;

  return (
    <>
      <Modal onClose={onModalClose} showModal blur={modalWithoutDispatch[type].navigation}>
        {documentToReactComponents(modalContent, defaultOptions)}
        {modalWithoutDispatch[type].navigate && (
          <Stack gap="1rem" horizontal css={{ justifyContent: "end" }}>
            <Button color="error" onClick={onModalClose}>
              {modalWithoutDispatch[type].navigate?.backButtonText}
            </Button>
            {!isMobileApp && (
              <Button href={modalWithoutDispatch[type].navigate?.subscribeButtonPath}>
                {modalWithoutDispatch[type].navigate?.subscribeButtonText}
              </Button>
            )}
          </Stack>
        )}
      </Modal>
    </>
  );
};

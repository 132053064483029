import { FC } from "react";
import { useParams } from "react-router-dom";
import { Meta } from "components/meta/Meta";
import { useAirport } from "logic/airport/useAirport";
import { AirportPropertyView } from "components/airportPropertyView/AirportPropertyView";
import { awdApi } from "store/reducers/awdApi";

type AirportSupervizorEditProps = {};

export const AirportSupervizorEdit: FC<AirportSupervizorEditProps> = (props) => {
  const icao = String(useParams().icao);
  const { describe } = useAirport(icao);
  const airport = describe()?.airport;

  const runwaysQuery = awdApi.endpoints.getAirportRunways.useQuery({
    airportId: icao,
  });

  const { data: rolesData } = awdApi.endpoints.getMyRoles.useQuery();
  const isUserSupervizor = rolesData?.roles.some((role) => role === "supervizor");

  const runways = runwaysQuery.data;
  return (
    <div css={{ padding: "3rem", paddingTop: 0 }}>
      <Meta pageTitle={`${icao.toUpperCase()} Supervizor Edit`} />
      {airport && runways && (
        <AirportPropertyView
          airport={airport}
          runways={runways}
          editability={isUserSupervizor ? "editable" : "hidden"}
          icaoIsEditable={isUserSupervizor}
        />
      )}
    </div>
  );
};

import WeatherNowCeiling from "components/Dashboard/weatherNow/WeatherNowCeiling";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";

export type WeatherWidgetCeilingProps = {};

export const WeatherWidgetCeiling: FC<WeatherWidgetCeilingProps> = () => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem 0",
      }}
      ref={ref}
    >
      {width != null && width < 250 ? (
        <ErrorAlert title="This widget variant requires at least 250 wide space." />
      ) : (
        <div
          css={{
            minWidth: "15.625rem",
            width: "22rem",
          }}
        >
          <WeatherNowCeiling />
        </div>
      )}
    </div>
  );
};

import { SpecBuilder } from "utils/general";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import WidgetsIcon from "@mui/icons-material/Widgets";
import TvIcon from "@mui/icons-material/Tv";
import WarningIcon from "@mui/icons-material/Warning";
import StarIcon from "@mui/icons-material/Star";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VideocamIcon from "@mui/icons-material/Videocam";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import Biotech from "@mui/icons-material/Biotech";
import GroupIcon from "@mui/icons-material/Group";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import { ComponentType } from "react";
import { Insights, LocalAirport } from "@mui/icons-material";

export type PageSpecification = {
  title: string;
  path: string;
  requirements?: {
    role: "manager" | "pilot" | "supervizor";
  };
  Icon?: ComponentType;
};

export const pageSpec = new SpecBuilder<PageSpecification>().build({
  confirm_email: {
    title: "Confirm Email",
    path: "/confirm-email",
  },
  signup: {
    title: "Subscribe as airport",
    path: "/signup",
  },
  registerpilot: {
    title: "Register as pilot",
    path: "/registerpilot",
  },
  login: {
    title: "Login",
    path: "/login",
  },
  reset_password_set_new: {
    title: "Set New Password",
    path: "/reset-password/set-new",
  },
  reset_password_request: {
    title: "Request Reset Password",
    path: "/reset-password/request",
  },
  dashboard: {
    title: "Dashboard",
    Icon: DashboardIcon,
    path: "/me/dashboard",
  },
  account: {
    title: "Account",
    Icon: ManageAccountsIcon,
    path: "/me/account",
  },
  airport: {
    title: "Airport",
    Icon: FlightTakeoffIcon,
    path: "/me/airport",
    requirements: { role: "manager" },
  },
  invoicing: {
    title: "Invoicing",
    Icon: ReceiptIcon,
    path: "/me/invoicing",
    requirements: { role: "manager" },
  },
  widget_editor: {
    title: "Widget Editor",
    Icon: WidgetsIcon,
    path: "/me/widget",
    requirements: { role: "manager" },
  },
  user_settings: {
    title: "User Settings",
    path: "/me/settings",
    Icon: SettingsIcon,
  },
  favorite_airports: {
    title: "Favourite Airports",
    path: "/me/favorite-airports",
    Icon: StarIcon,
    requirements: { role: "pilot" },
  },
  widget_preview: {
    title: "Widget",
    path: "/widget",
  },
  kiosk_settings: {
    title: "Kiosk",
    Icon: TvIcon,
    path: "/me/kiosk",
    requirements: { role: "manager" },
  },
  airport_warning_settings: {
    title: "Airport Notifications",
    Icon: WarningIcon,
    path: "/me/airport/notifications",
    requirements: { role: "manager" },
  },
  airport_webcam_settings: {
    title: "Webcam",
    Icon: VideocamIcon,
    path: "/me/airport/webcam",
    requirements: { role: "manager" },
  },
  airport_meteostation_settings: {
    title: "Meteostation",
    Icon: WaterDropIcon,
    path: "/me/airport/meteostation",
    requirements: { role: "manager" },
  },
  airport_detail_dashboard: {
    title: "Weather dashboard",
    path: "/:icao",
  },
  airport_detail_map: {
    title: "Weather map in the vicinity",
    path: "/:icao/map",
  },
  airport_detail_general_information: {
    title: "General information",
    path: "/:icao/general-information",
  },
  airport_detail_operational_update: {
    title: "Important operational update",
    path: "/:icao/operational-updates",
  },
  airport_detail_about: {
    title: "About airport",
    path: "/:icao/about",
  },
  airport_supervizor_edit: {
    title: "About airport",
    path: "/:icao/edit",
  },
  kiosk: {
    title: "Kiosk",
    path: "/kiosk/:icao",
  },
  terms_of_service: {
    title: "Terms of Service",
    path: "/terms-of-service",
  },
  privacy_policy: {
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
  customer_support: {
    title: "Customer Support",
    path: "/customer-support",
  },
  cookie_policy: {
    title: "Cookie Policy",
    path: "/cookie-policy",
  },
  documents: {
    title: "Terms",
    path: "/documents",
  },
  disclaimer: {
    title: "Disclaimer",
    path: "/disclaimer",
  },
  mobile_app_entrypoint: {
    title: "Mobile App Entrypoint",
    path: "/mobile-app-entrypoint",
  },
  airport_subscription: {
    title: "Airport Subscription Requests",
    path: "/me/airport-subscription",
    Icon: Biotech,
    requirements: { role: "supervizor" },
  },
  user_listing: {
    title: "User Listing",
    path: "/me/user-listing",
    Icon: GroupIcon,
    requirements: { role: "supervizor" },
  },
  registered_airports: {
    title: "Registered Airports",
    path: "/me/registered-airports",
    Icon: LocalAirport,
    requirements: { role: "supervizor" },
  },
  statistics: {
    title: "Statistics",
    path: "/me/statistics",
    Icon: Insights,
    requirements: { role: "supervizor" },
  },
  airport_listing: {
    title: "Airport Listing",
    path: "/me/airport-listing",
    Icon: ConnectingAirportsIcon,
    requirements: { role: "supervizor" },
  },
  weather_dashboard: {
    title: "Weather Dashboard",
    path: ":icao",
  },
  weather_map: {
    title: "Weather Map",
    path: ":icao/map",
  },
  runway_schema: {
    title: "Runway schema",
    path: ":icao/runway-schema",
  },
});

import { FC } from "react";
import styled from "@emotion/styled";
import { useAirportWeather, useDashboardAirport } from "logic/weather/hooks";
import { useDispatch, useSelector } from "react-redux";
import IconInfo from "components/_icons/IconInfo";
import { toggleModal, toggleWeatherSource } from "store/reducers/localStateSlice";
import { stopOtherEvents } from "utils/general";
import { useContext } from "react";
import { WidgetContext } from "components/widget/WidgetWrapper";
import { RootState } from "store/store";
import { responsive } from "mixins";
import { Theme } from "@emotion/react";
import { ModalContentfulData } from "components/modal/ModalContentfulData";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { useParams } from "react-router-dom";

export type SwitchType = {
  text: "metar" | "taf";
  placement?: string;
  theme?: Theme;
  skip?: boolean;
};

const Switch: FC<SwitchType> = ({ text, placement, theme, skip }) => {
  const dispatch = useDispatch();
  const inWidget = useContext(WidgetContext);
  const icao = String(useParams().icao);
  const url = useDashboardAirport(undefined, { skip: skip ?? false })
    .transform()
    ?.getUrl();
  const { show } = useModalContext();

  const handleOpenNewTab = () => {
    if (!window) return;
    const urlForModal = url + "?modal=" + text;
    const newTab = window.open(urlForModal, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  // based on the text, select the type of a switch - either for metar or for taf
  const checked = useSelector((state: RootState) => {
    return text === "metar"
      ? state.localState.toggledToAwMetarWeatherSource
      : state.localState.toggledToAwTafWeatherSource;
  });

  // distinguish between metar and taf switch - filter
  const weather = useAirportWeather(undefined, { skip: skip ?? false }).report();
  const disabled = placement !== "landingMap" && !weather?.hasOriginalReport(text);

  const showInfo = (e: any) => {
    stopOtherEvents(e);
    if (inWidget) {
      handleOpenNewTab();
    } else {
      dispatch(toggleModal(true, text));
      show(<ModalContentfulData icao={icao} type={`${text}Switch`} />);
    }
  };

  const toggleSwitch = (e: any, status: boolean, dontStopEvents = false) => {
    if (!dontStopEvents) {
      stopOtherEvents(e);
    }
    if (disabled) return;
    if (!status) {
      dispatch(toggleWeatherSource(!checked, text));
    } else {
      dispatch(toggleWeatherSource(status, text));
    }
  };

  return (
    <StyledSwitch placement={placement} theme={theme}>
      <button
        className={`button left ${checked ? "" : "active"} ${disabled ? "disabled" : ""}`}
        onClick={(e) => toggleSwitch(e, false)}
        title={text}
      >
        {text}
      </button>
      <label className="switch">
        {/* TODO: dot colored according to flight rules can have different color when metar is on and staf is calculated differently */}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => toggleSwitch(e, !checked, true)}
          disabled={disabled}
        />
        <span className={`slider ${disabled ? "disabled" : ""}`} />
      </label>
      <button
        className={`button right  ${checked ? "active" : ""}`}
        onClick={(e) => toggleSwitch(e, true)}
        title={`aw-${text}`}
      >
        aw-{text}
      </button>
      <button className="button icon" onClick={showInfo}>
        <IconInfo />
      </button>
    </StyledSwitch>
  );
};

const StyledSwitch = styled.div<{
  placement?: string;
  theme?: Theme;
}>`
  font-size: ${(props) => (props.placement ? "10px" : "15px")};
  font-weight: bold;
  display: flex;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: ${(props) => (props.placement ? "40px" : "51px")};
    height: 21px;

    input {
      visibility: hidden;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;

      -webkit-transition: 0.4s;
      transition: 0.4s;

      border: 2px solid #323233be;
      border-radius: 34px;

      &.disabled {
        background-color: #d1d1d1;
        cursor: not-allowed;
        border: 2px solid #3232333a;

        &:before {
          top: 50%;
          border: 2px solid #fff;
          outline: 2px solid #323233be;
        }
      }
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);

      background-color: ${(props) => (props.placement ? "#009ff5" : props.theme.primary)};
      -webkit-transition: 0.4s;
      transition: 0.4s;

      border-radius: 50%;

      border: 2px solid #fff;
      outline: 2px solid #323233be;
    }

    input:checked + .slider:before {
      -webkit-transform: translate(${(props) => (props.placement ? "20px, -50%" : "31px, -50%")});
      -ms-transform: translate(${(props) => (props.placement ? "20px, -50%" : "31px, -50%")});
      transform: translate(${(props) => (props.placement ? "20px, -50%" : "31px, -50%")});
    }
  }

  .button {
    border: none;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    text-decoration: underline;
    padding: 0 0.4rem;
    text-transform: uppercase;
    font-size: ${(props) => (props.placement ? "12px" : "15px")};

    font-family: "Encode Sans";
    white-space: nowrap;

    ${responsive.tablet} {
      font-size: ${(props) => (props.placement ? "10px" : "14px")};
    }

    &::after {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      font-weight: bold;
    }

    &.left {
      padding-left: 0;
    }

    &.right {
      padding-right: 0.5rem;
    }

    &.icon {
      border: none;
      background: none;
      margin: 0.2rem 0 0 0.2rem;
      padding: 0;
    }

    &.active {
      font-weight: bold;
    }

    &.disabled {
      color: #323233be;
      cursor: not-allowed;

      &:hover {
        font-weight: normal;
      }
    }
  }
`;

export default Switch;

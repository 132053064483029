import styled from "@emotion/styled";
import { FC, PropsWithChildren } from "react";

export type EntrancePageLayoutProps = PropsWithChildren<{
  bcg?: string;
}>;

export const EntrancePageLayout: FC<EntrancePageLayoutProps> = (props) => {
  return (
    <SGrid bcg={props.bcg}>
      <SCard bcg={props.bcg}>{props.children}</SCard>
    </SGrid>
  );
};

const SGrid = styled.div<EntrancePageLayoutProps>(({ bcg }) => ({
  display: "grid",
  gridTemplateColumns: "1fr min(100%, 30rem) 1fr",
  padding: "0 2rem",
  gridTemplateRows: "100vh",
  width: "100%",
  background: bcg ? "white" : "#eee",
}));

const SCard = styled.div<EntrancePageLayoutProps>(({ bcg }) => ({
  background: bcg ? bcg : "#eee",
  borderRadius: "0.5rem",
  padding: "2rem",
  width: "100%",
  gridColumn: "2 / 3",
  height: "fit-content",
  alignSelf: "center",
}));

import { Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { ChangeBannerButton } from "components/button/banner/ChangeBannerButton";
import { RemoveBannerButton } from "components/button/banner/RemoveBannerButton";
import { SetupBannerButton } from "components/button/banner/SetupBannerButton";
import { Stack } from "components/layout/Stack";
import { SettingsGroup } from "components/settings/group/SettingsGroup";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC, useState } from "react";
import { awdApi } from "store/reducers/awdApi";
import { encodeQuery } from "utils/general";

export type AirportBannerSettingsGroupProps = {};

export const AirportBannerSettingsGroup: FC<AirportBannerSettingsGroupProps> = (props) => {
  const airport = useMyAirportRoles().managedAirport();

  const icao = airport?.icao;
  const { isSuccess: doesBannerExist, isLoading } = awdApi.endpoints.getAirportBanner.useQuery(
    icao ? { icao } : skipToken
  );

  const [randomCacheBuster, setRandomCacheBuster] = useState<number | undefined>();

  function onSuccess() {
    setRandomCacheBuster(Math.random());
  }

  return (
    <SettingsGroup title="Banner" loading={isLoading || airport == null}>
      <Typography variant="body1">
        You can set up a custom image banner that will be shown on the kiosk page. You can use this
        as advertisement.
      </Typography>
      {airport && (
        <Stack gap="0.5rem" css={{ alignItems: "start" }}>
          {doesBannerExist ? (
            <>
              <img
                src={encodeQuery(airport.getBannerUrl(), { randomCacheBuster })}
                alt="kiosk banner preview"
                css={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "0.25rem",
                }}
              />
              <Stack horizontal gap="0.25rem">
                <ChangeBannerButton icao={airport.icao} onSuccess={onSuccess} />
                <RemoveBannerButton icao={airport.icao} onSuccess={onSuccess} />
              </Stack>
            </>
          ) : (
            <SetupBannerButton icao={airport.icao} onSuccess={onSuccess} />
          )}
        </Stack>
      )}
    </SettingsGroup>
  );
};

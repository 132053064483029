import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Block } from "components/layout/Block";
import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { describeError } from "logic/error";
import { FC } from "react";
import { useRouteError } from "react-router-dom";

export type ErrorBoundaryProps = {};

export const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  const err = describeError(useRouteError());

  const message = err.formatMessage();
  const stack =
    process.env.NODE_ENV === "development" ? err.getStack() : undefined;

  return (
    <OutsidePageLayout noFooter>
      <Block>
        <Stack gap="1.5rem" css={{ alignItems: "start", paddingTop: "4rem" }}>
          <Typography variant="h3">
            {[err.formatTitle() || "Something went wrong."]}
          </Typography>

          {message && <Typography variant="h4">{message}</Typography>}

          {stack && (
            <code
              css={{
                whiteSpace: "pre",
                padding: "1rem",
                borderRadius: "0.5rem",
                border: "1px solid #ddd",
              }}
            >
              {stack}
            </code>
          )}

          <Button onClick={() => window.location.reload()}>
            Try refreshing
          </Button>
        </Stack>
      </Block>
    </OutsidePageLayout>
  );
};

import { FC } from "react";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { Meta } from "components/meta/Meta";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Stack } from "components/layout/Stack";
import { AirportListingTable } from "components/airportListing/airportListingTable";

type AirportListingProps = {};

export const AirportListing: FC<AirportListingProps> = () => {
  return (
    <InsidePageLayout>
      <Meta pageTitle="Airport Listing" />
      <Stack gap="1rem" css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Airport Listing</InsidePageTitle>
      </Stack>
      <AirportListingTable />
    </InsidePageLayout>
  );
};

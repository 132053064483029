import { describeMutationResult } from "logic/api/result/describe";
import { postMobileAppMessage } from "logic/mobileApp/postMobileAppMessage";
import { NavigateFunction } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { localApi } from "store/reducers/localApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { createAsyncThunk } from "store/thunk";
import { setCenterPoint } from "store/reducers/localStateSlice";

export const onLogoutButtonClick = createAsyncThunk(
  "onLogoutButtonClick",
  async (args: { navigate: NavigateFunction }, ctx) => {
    const result = await ctx
      .dispatch(awdApi.endpoints.logout.initiate())
      .then(describeMutationResult);

    postMobileAppMessage({ type: "logged_out" });

    if (result.error) {
      ctx.dispatch(toastError(result.error, "Failed to logout."));
      return;
    }

    ctx.dispatch(toastMessage("success", "Logged out."));

    ctx.dispatch(setCenterPoint(undefined));

    const { data: mobileAppConfig } = await ctx.dispatch(
      localApi.endpoints.getMobileAppConfig.initiate(undefined, {
        forceRefetch: true,
      })
    );

    if (mobileAppConfig?.is_mobile_app) {
      args.navigate("/mobile-app-entrypoint");
    } else {
      args.navigate("/");
    }
  }
);

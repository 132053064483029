import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { Meta } from "components/meta/Meta";
import { Stack } from "components/layout/Stack";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { RegisteredAirports } from "components/registeredAirports/RegisteredAirports";

export const RegisteredAirportsPage = () => {
  return (
    <InsidePageLayout fullWidth>
      <Meta pageTitle="Registered Airports" />
      <Stack gap="1rem" css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Registered Airports</InsidePageTitle>
      </Stack>
      <RegisteredAirports />
    </InsidePageLayout>
  );
};

import { WidgetParameters, zWidgetParameters } from "components/widget/Widget";
import { WidgetApp } from "components/widget/WidgetApp";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { isNonNullable, safeParseJson } from "utils/general";
import { safeParse } from "utils/parse";
import { IFrameWithStyles } from "./IFrameWithStyles";

export function startWidgetMode() {
  const widgetPlacements = findWidgetPlacements()
    .map(parseWidgetPlacementElement)
    .filter(isNonNullable);

  widgetPlacements.forEach(renderWidgetPlacement);
}

function findWidgetPlacements() {
  return [...document.querySelectorAll("script[data-airportweather-widget]")].filter(
    (x): x is HTMLScriptElement => x instanceof HTMLScriptElement
  );
}

function parseWidgetPlacementElement(element: HTMLScriptElement) {
  const json = safeParseJson(element.innerText);
  const params = safeParse(zWidgetParameters, json);

  if (params == null) {
    console.error(
      "AirportWeather.com encountered a misconfigured widget code snippet. Please try copying the code from the widget configuration page again or contact customer support.",
      json
    );

    return undefined;
  }

  return { element, params };
}

function renderWidgetPlacement(placement: {
  element: HTMLScriptElement;
  params: WidgetParameters;
}) {
  const rootElement = document.createElement("div");
  rootElement.className = "awd-widget";
  placement.element.insertAdjacentElement("afterend", rootElement);
  const root = ReactDOM.createRoot(rootElement);

  root.render(<WidgetInIframe params={placement.params} />);
}

export const WidgetInIframe = (placement: { params: WidgetParameters }) => {
  return (
    <IFrameWithStyles
      style={{
        width: "100%",
        minWidth: "100%",
      }}
    >
      <StrictMode>
        <WidgetApp {...placement.params} />
      </StrictMode>
    </IFrameWithStyles>
  );
};

import { Outlet, useLocation, useParams } from "react-router-dom";

import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { Spacer } from "components/layout/Spacer";
import Navigation from "components/navigation/Navigation";

function AirportWeatherDetail() {
  const { icao } = useParams();
  const location = useLocation();
  const isMapSubpage = location.pathname.match(/\/map/) != null;

  return (
    <>
      <Navigation />
      <OutsidePageLayout
        noLogo={{ desktop: true, tablet: false }}
        showWeatherDashboardNavigationItems={{ desktop: false, tablet: true }}
        weatherDashboardIcao={icao}
        noFooter={isMapSubpage}
      >
        <Spacer size="2rem" />
        <Outlet />
      </OutsidePageLayout>
    </>
  );
}

export default AirportWeatherDetail;

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormSelectField } from "components/field/select/FormSelectField";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { z } from "zod";
import { ident } from "utils/general";

export type AddNotificationModalProps = {
  icao: string;
};

export const AddNotificationModal: FC<AddNotificationModalProps> = (props) => {
  const dispatch = useDispatch();

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: {
      category: ident("operational_update"),
    },
  });

  return (
    <ResultModal
      title="Add notification"
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.postAirportNotification.initiate({
              icao: props.icao,
              body,
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(
              toastError(result.error, "We could not add the notification.")
            );
            cancel();
          } else {
            dispatch(
              toastMessage("success", "The notification has been added.")
            );
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "20rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        <Stack gap="1rem">
          <FormTextField form={form} name="title" label="Title" />
          <FormTextField
            form={form}
            name="description"
            label="Description"
            multiline
          />
          <FormSelectField
            form={form}
            name="category"
            label="Category"
            options={[
              {
                label: "Important operational update",
                value: "operational_update",
              },
              { label: "General information", value: "general_information" },
            ]}
          />
        </Stack>
      </DialogContent>
    </ResultModal>
  );
};

const zForm = z.object({
  title: z.string(),
  description: z.string(),
  category: z.enum(["operational_update", "general_information"]),
});
type Form = z.infer<typeof zForm>;

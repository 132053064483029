import { IconButton } from "@mui/material";
import { useOpenOutsideMenu } from "components/layout/page/outside/OutsideMenuContext";
import { FC } from "react";
import MenuIcon from "@mui/icons-material/Menu";

export type OutsideHamburgerButtonProps = {};

export const OutsideHamburgerButton: FC<OutsideHamburgerButtonProps> = (
  props
) => {
  const openMenu = useOpenOutsideMenu();

  return (
    <div
      css={{
        display: "flex",
        backgroundColor: "white",
        borderRadius: "0.5rem",
      }}
    >
      <IconButton color="inherit" aria-label="open drawer" onClick={openMenu}>
        <MenuIcon />
      </IconButton>
    </div>
  );
};

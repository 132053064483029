import { Button, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { AirportMeteostation } from "components/airportMeteostation/AirportMeteostation";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { SetupMeteostationModal } from "components/modal/inside/setup/SetupMeteostationModal";
import { QueryResult } from "components/queryResult/QueryResult";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type AirportMeteostationSettingsPageProps = {};

export const AirportMeteostationSettingsPage: FC<AirportMeteostationSettingsPageProps> = (
  props
) => {
  const airportId = useMyAirportRoles().managedAirport()?.id;

  const query = awdApi.endpoints.getAirportLinkSettings.useQuery(
    airportId ? { airportId } : skipToken
  );
  const settings = query.data;

  const { show } = useModalContext();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Meteostation" />

      <InsidePageTitle>Meteostation</InsidePageTitle>

      <Spacer size="1.5rem" />

      <Typography variant="body1">
        <p>
          If you have a meteostation, you can provide us with a link so that we can show it to your
          pilots.
        </p>
      </Typography>

      <Spacer size="1.5rem" />

      <QueryResult query={query}>
        <Stack gap="2rem">
          <Button
            onClick={() =>
              settings &&
              airportId &&
              show(<SetupMeteostationModal settings={settings} airportId={airportId} />)
            }
          >
            Set up meteostation
          </Button>

          {airportId && <AirportMeteostation airportId={airportId} />}
        </Stack>
      </QueryResult>
    </InsidePageLayout>
  );
};

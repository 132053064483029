import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeMyAirportRolesQuery, describeMyUserRoleQuery } from "logic/user/me/role/describe";
import { awdApi } from "store/reducers/awdApi";

// get user roles - manager/pilot/superdemo
export function useMyRoles() {
  const query = awdApi.endpoints.getMyRoles.useQuery();
  return describeMyUserRoleQuery(query);
}

// get my airports where I'm a manager
export function useMyAirportRoles() {
  return describeMyAirportRolesQuery(awdApi.endpoints.getMyAirportRoles.useQuery());
}

export function useMyAirportRunways() {
  const { managedAirport } = useMyAirportRoles();
  const airportId = managedAirport()?.id;

  return awdApi.endpoints.getAirportRunways.useQuery(airportId ? { airportId } : skipToken).data;
}

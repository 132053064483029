import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { Button } from "@mui/material";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import {
  DailyDataPoint,
  useAirportSubscriptionChartData,
} from "utils/statistics";
import { Stack } from "components/layout/Stack";
import { useCumulativeSwitch } from "components/statistics/useCumulativeSwitch";

export const AirportSubscriptionsPerDay = () => {
  const { jsx: cumulativeJsx, cumulative } = useCumulativeSwitch();

  const chartRef =
    useRef<ChartJSOrUndefined<"line", DailyDataPoint[], unknown>>(null);

  const { datasets, isLoading, options, downloadAirportData } =
    useAirportSubscriptionChartData({
      cumulative,
    });

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  if (isLoading) return null;

  return (
    <div>
      <Stack horizontal gap={"0"} css={{ justifyContent: "space-between" }}>
        {cumulativeJsx}
        <Button onClick={downloadAirportData}>
          Download Airport Subscriptions Data
        </Button>
      </Stack>
      <Line ref={chartRef} data={{ datasets }} options={options} />

      <div css={{ marginTop: "1rem" }}>
        <Button
          type="button"
          onClick={handleResetZoom}
          variant="outlined"
          sx={{ color: "black", borderColor: "black" }}
        >
          Reset Zoom
        </Button>
      </div>
    </div>
  );
};

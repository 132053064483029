import { FC } from "react";
import { Helmet } from "react-helmet";
import { isNonNullable } from "utils/general";

export type MetaProps = {
  pageTitle?: string;
};

export const Meta: FC<MetaProps> = (props) => {
  const serviceName = "AirportWeather.com";
  const title = [props.pageTitle, serviceName]
    .filter(isNonNullable)
    .join(" | ");
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

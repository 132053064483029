import { zPassword } from "logic/signup/password/describe";
import { matchIsValidTel } from "mui-tel-input";
import { zAirportSpecification } from "awd-server-api";
import { z } from "zod";

export const zSignupForm = z
  .object({
    email: z
      .string()
      .email({ message: "Please provide a valid e-mail address." })
      .nonempty(),
    password: zPassword,
    password_again: z.string(),
    phone_number: z.string().nullish(),
    first_name: z.string().nullish(),
    last_name: z.string().nullish(),
    airport: zAirportSpecification,
    i_agree_with_tos: z.literal(true),
    confirm_airport: z.literal(true).nullish(),
  })
  .refine((data) => data.password === data.password_again, {
    message: "The passwords have to match.",
    path: ["password_again"],
  });

export const zPilotSignupForm = zSignupForm;
export const zAirportSignupForm = zSignupForm
  .refine((data) => data.phone_number != null && data.phone_number.length > 0, {
    message: "Please fill in your phone number.",
    path: ["phone_number"],
  })
  .refine(
    (data) => data.phone_number != null && matchIsValidTel(data.phone_number),
    { message: "Please provide a valid phone number.", path: ["phone_number"] }
  )
  .refine((data) => !!data.first_name?.length, {
    message: "Please fill in your first name.",
    path: ["first_name"],
  })
  .refine((data) => !!data.last_name?.length, {
    message: "Please fill in your last name.",
    path: ["last_name"],
  })
  .refine((data) => !!data.confirm_airport, {
    message: "Please confirm that you are the airport manager.",
  });

export type SignupForm = z.infer<typeof zSignupForm>;

import { createApi } from "@reduxjs/toolkit/query/react";
import { zAirportResponse, zWeatherReport } from "awd-server-api";
import { safeParseJson } from "utils/general";
import { z } from "zod";

export const localApi = createApi({
  reducerPath: "localApi",
  baseQuery: async (
    args:
      | { type: "getSession"; key: string }
      | { type: "setSession"; key: string; value: unknown }
      | { type: "getLocal"; key: string }
      | { type: "setLocal"; key: string; value: unknown }
  ) => {
    try {
      const storage =
        args.type === "setSession" || args.type === "getSession" ? sessionStorage : localStorage;
      if (args.type === "setSession" || args.type === "setLocal") {
        storage.setItem(args.key, JSON.stringify(args.value));
        return { data: "done" };
      }
      return { data: safeParseJson(storage.getItem(args.key) ?? "") };
    } catch (err) {
      return { error: "storage_error" };
    }
  },
  tagTypes: ["SignupBox", "MobileAppConfig", "KioskSettings", "LastWeatherReport"],
  endpoints: (builder) => ({
    getSignupBox: builder.query({
      query: (_arg: void) => ({
        type: "getSession",
        key: "@awd/signupBox",
      }),
      transformResponse: (res) =>
        res == null ? undefined : zSignupBoxSession.nullable().parse(res),
      providesTags: ["SignupBox"],
    }),
    setSignupBox: builder.mutation({
      query: (value: SignupBoxSession) => ({
        type: "setSession",
        key: "@awd/signupBox",
        value,
      }),
      invalidatesTags: ["SignupBox"],
    }),
    getMobileAppConfig: builder.query({
      query: (_arg: void) => ({
        type: "getSession",
        key: "@awd/mobileAppConfig",
      }),
      transformResponse: (res) =>
        res == null ? undefined : zMobileAppConfig.nullable().parse(res),
      providesTags: ["MobileAppConfig"],
    }),
    setMobileAppConfig: builder.mutation({
      query: (value: MobileAppConfig) => ({
        type: "setSession",
        key: "@awd/mobileAppConfig",
        value,
      }),
      invalidatesTags: ["MobileAppConfig"],
    }),
    getLastWeatherReport: builder.query({
      query: (_arg: void) => ({
        type: "getLocal",
        key: "@awd/lastWeatherReport",
      }),
      transformResponse: (res) =>
        res == null ? undefined : zLastWeatherReport.nullable().parse(res),
      providesTags: ["LastWeatherReport"],
    }),
    setLastWeatherReport: builder.mutation({
      query: (value: LastWeatherReport) => ({
        type: "setLocal",
        key: "@awd/lastWeatherReport",
        value,
      }),
      invalidatesTags: ["LastWeatherReport"],
    }),
  }),
});

export const zSignupBoxSession = z.object({
  display_count: z.string().nullish(),
  last_closed_at: z.string().nullish(),
});
export type SignupBoxSession = z.infer<typeof zSignupBoxSession>;

export const zMobileAppConfig = z.object({
  is_mobile_app: z.boolean(),
});
export type MobileAppConfig = z.infer<typeof zMobileAppConfig>;

export const zLastWeatherReport = z.object({
  report: zWeatherReport.nullish(),
  airport: zAirportResponse.nullish(),
});
export type LastWeatherReport = z.infer<typeof zLastWeatherReport>;

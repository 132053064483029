import { TextFieldProps } from "@mui/material";
import { FormSelectField } from "components/field/select/FormSelectField";
import { describeRunwaySurfaces } from "logic/airport/runway/surface/describe";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

export function FormRunwaySurfaceField<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
  } & TextFieldProps
) {
  const { ...pass } = props;

  return (
    <FormSelectField
      {...pass}
      options={describeRunwaySurfaces().getAllOptions()}
    />
  );
}

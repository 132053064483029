import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { LogoutButton } from "components/button/logout/LogoutButton";
import { OpenWeatherButton } from "components/button/openWeather/OpenWeatherButton";
import { Stack } from "components/layout/Stack";
import { describeInsideNavigation } from "logic/navigation/inside/describe";
import { FC, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import awLogoVertical from "assets/images/airport_weather_vertical.svg";
import { pageSpec } from "logic/navigation/spec";
import { NavigationFavoriteAirports } from "components/navigationItems/NavigationFavoriteAirports";
import { useGetMyRolesQuery } from "store/reducers/awdApi";

export type InsideNavigationDrawerProps = {
  open: boolean;
  width: string;
  onCloseButtonClick?: () => void;
  showCloseButton?: boolean;
};

const { getItems, isItemSelected } = describeInsideNavigation();

export const InsideNavigationDrawer: FC<InsideNavigationDrawerProps> = ({
  width,
  open,
  onCloseButtonClick,
  showCloseButton,
}) => {
  const rolesQuery = useGetMyRolesQuery();

  const { pathname } = useLocation();

  return (
    <Drawer open={open} variant="persistent">
      <Stack
        gap="1rem"
        horizontal
        css={{
          padding: "1rem 0.75rem 1rem 1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          gap="0"
          horizontal
          css={{ justifyContent: "center", width: "100%" }}
        >
          <Link to="/">
            <img src={awLogoVertical} alt="logo" css={{ maxWidth: "12rem" }} />
          </Link>
        </Stack>

        {showCloseButton && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onCloseButtonClick}
            edge="start"
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Stack>

      <List css={{ width }}>
        {getItems(rolesQuery.data).map((item) => (
          <Fragment key={item.title}>
            {item.path === pageSpec.favorite_airports.path && (
              <ListSubheader>Favourite Airports</ListSubheader>
            )}

            <ListItem disablePadding>
              <ListItemButton
                selected={isItemSelected(item, pathname)}
                href={item.path}
              >
                {item.Icon && (
                  <ListItemIcon>
                    <item.Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
            {item.path === pageSpec.favorite_airports.path && (
              <NavigationFavoriteAirports />
            )}
          </Fragment>
        ))}
      </List>

      <div css={{ flexGrow: 1 }} />

      <Stack gap="0.5rem" css={{ paddingBottom: "1rem" }}>
        <OpenWeatherButton />
        <LogoutButton />
      </Stack>
    </Drawer>
  );
};

import WeatherForecast from "components/Dashboard/weatherForecast/WeatherForecast";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";

export type WeatherWidgetForecastProps = {};

export const WeatherWidgetForecast: FC<WeatherWidgetForecastProps> = () => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();
  return (
    <div
      ref={ref}
      css={{
        paddingTop: "0.5rem",
      }}
    >
      {width != null && width < 278 ? (
        <ErrorAlert title="This widget variant requires at least 278 wide space." />
      ) : (
        <WeatherForecast />
      )}
    </div>
  );
};

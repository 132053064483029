import { FC, useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "@mui/material";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { DailyDataPoint, usePilotChartData } from "utils/statistics";
import { PilotStatisticsActions } from "components/statistics/PilotStatisticsActions";
import { useCumulativeSwitch } from "components/statistics/useCumulativeSwitch";

type PilotRegistrationPerDayProps = {};

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  Colors
);

ChartJS.overrides.line.spanGaps = true;

export const PilotRegistrationPerDay: FC<PilotRegistrationPerDayProps> = (
  props
) => {
  const { jsx: cumulativeJsx, cumulative } = useCumulativeSwitch();
  const chartRef =
    useRef<ChartJSOrUndefined<"line", DailyDataPoint[], unknown>>(null);

  const {
    datasets,
    isLoading,
    options,
    downloadVerifiedPilotData,
    downloadAllPilotData,
  } = usePilotChartData({ cumulative });

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  if (isLoading) return null;
  return (
    <div>
      <PilotStatisticsActions
        switchJsx={cumulativeJsx}
        downloadAllPilotData={downloadAllPilotData}
        downloadVerifiedPilotData={downloadVerifiedPilotData}
      />
      <Line ref={chartRef} data={{ datasets }} options={options} />

      <div css={{ marginTop: "1rem" }}>
        <Button
          type="button"
          onClick={handleResetZoom}
          variant="outlined"
          sx={{ color: "black", borderColor: "black" }}
        >
          Reset Zoom
        </Button>
      </div>
    </div>
  );
};

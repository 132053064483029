import { Button, Typography } from "@mui/material";
import { Block } from "components/layout/Block";
import { OutsidePageLayout } from "components/layout/page/outside/OutsidePageLayout";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { SignupFeatures } from "components/signup/features/SignupFeatures";
import { SignupForm } from "components/signup/SignupForm";
import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { AirportSpecification } from "awd-server-api";
import { useContentfulData } from "utils/useContentfulData";
import { getSignupPageData } from "utils/contentful";

export type SignupPageProps = {};

export const SignupPage: FC<SignupPageProps> = (props) => {
  const [params] = useSearchParams();
  const defaultAirportId = parseInt(params.get("airport_id") ?? "", 10);
  const defaultAirportName = params.get("airport_name");
  const defaultAirport: AirportSpecification | undefined = !Number.isNaN(
    defaultAirportId
  )
    ? { specified_by: "id", id: defaultAirportId }
    : defaultAirportName
    ? { specified_by: "name", name: defaultAirportName }
    : undefined;

  const signupPageContent = useContentfulData(getSignupPageData);

  return (
    <OutsidePageLayout>
      <Block>
        <Stack
          gap="2rem"
          css={{ alignItems: "center", width: "100%", paddingTop: "6rem" }}
          accurateSpacers
        >
          <Meta pageTitle="Get started" />
          <Typography variant="h1" css={{ textAlign: "center" }}>
            {signupPageContent?.title}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            css={{ maxWidth: "16rem" }}
            onClick={() => {
              document
                .getElementById("signup-form")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {signupPageContent?.getStartedButtonText}
          </Button>

          <Spacer size={{ desktop: "6rem", mobilL: "3rem" }} />

          <SignupFeatures />

          <Spacer size={{ desktop: "6rem", mobilL: "3rem" }} />

          <div
            id="signup-form"
            css={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <SignupForm defaultAirport={defaultAirport} variant="airport" />
          </div>

          <Spacer size="10rem" />
        </Stack>
      </Block>
    </OutsidePageLayout>
  );
};

import { Skeleton } from "@mui/material";
import { AirportPropertyView } from "components/airportPropertyView/AirportPropertyView";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Meta } from "components/meta/Meta";
import { useMyAirportRoles, useMyAirportRunways } from "logic/user/me/role/hooks";
import { FC } from "react";

export type AirportPageProps = {};

export const AirportPage: FC<AirportPageProps> = (props) => {
  const { isLoading, managedAirport } = useMyAirportRoles();

  const airport = managedAirport();
  const runways = useMyAirportRunways();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Airport" />

      <InsidePageTitle>
        {isLoading ? <Skeleton width="20rem" /> : airport ? <>{airport.name}</> : null}
      </InsidePageTitle>

      <Spacer size="2rem" />

      {airport && runways && (
        <AirportPropertyView
          airport={airport}
          runways={runways}
          editability={airport.canBeEdited() ? "editable" : "disabled"}
        />
      )}
    </InsidePageLayout>
  );
};

import { Button } from "@mui/material";
import { FC, MouseEvent, PropsWithChildren } from "react";

type MenuButtonProps = {
  open: boolean;
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
  controlId: string;
};

export const MenuButton: FC<PropsWithChildren<MenuButtonProps>> = (props) => {
  const { handleClick, open, children, controlId } = props;
  return (
    <Button
      aria-controls={open ? controlId : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      variant="text"
    >
      {children}
    </Button>
  );
};

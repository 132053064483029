import Arrows from "components/Dashboard/_icons/Arrows";
import { ParsedRunway } from "logic/airport/runway/parse";
import { useActiveMetarSource } from "logic/weather/hooks";
import { FC } from "react";

export type RunwayWindArrowsProps = {
  runway: ParsedRunway;
  height: number;
};

export const RunwayWindArrows: FC<RunwayWindArrowsProps> = ({ runway, height }) => {
  const { source } = useActiveMetarSource();

  const arrowsProps = {
    angle: source?.getWindAngleToRunway(runway),
    active: source?.getActiveWindArrows(),
    show: !source?.doesWindDirectionHaveSpecialMeaning(),
    variable: source?.isWindVariable(),
  };

  return arrowsProps.angle != null && arrowsProps.active != null ? (
    <Arrows heightCSS={120} height={height} index={0} runways={true} {...arrowsProps} />
  ) : (
    <Arrows heightCSS={120} height={height} index={0} runways={false} {...arrowsProps} />
  );
};

import { FC } from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";

import { TextWeatherReport } from "components/Dashboard/textWeatherReport/TextWeatherReport";

type WeatherWidgetTextualProps = {
  type: "aw-metar" | "metar" | "aw-taf" | "taf" | undefined;
};

export const WeatherWidgetTextual: FC<WeatherWidgetTextualProps> = ({ type }) => {
  return (
    <div
      css={{
        padding: "0.3rem 0",
      }}
    >
      {/* textual metar */}
      <STextualMetar>
        <SCaption>{type}</SCaption>
        {type && <TextWeatherReport source={type} />}
      </STextualMetar>
    </div>
  );
};

const STextualMetar = styled.div`
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;

  font-size: 13px;
  width: 60%;
  min-height: 5rem;

  ${responsive.hambrg} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  ${responsive.tablet} {
    margin-top: 0.5rem;
  }
  ${responsive.mobilL} {
    min-height: 6.5rem;
  }
  ${responsive.forecast} {
    width: 100%;
  }
  ${responsive.mobilS} {
    min-height: 7.2rem;
  }
`;

const SCaption = styled.p`
  margin: 0;
  margin-bottom: 0.3rem;
  /* margin-top: 1rem; */
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => (props.color ? props.color : " #58585b")};

  ${responsive.tablet} {
    font-size: 11px;
  }
`;

import clsx from "clsx";
import { Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";

type NavListItemProps = {
  path: string;
  activeBurger: boolean;
  setActiveBurger: Dispatch<SetStateAction<boolean>>;
  icon: ReactNode;
};

export const NavListItem: FC<PropsWithChildren<NavListItemProps>> = (props) => {
  const location = useLocation();
  const { path, activeBurger, setActiveBurger, icon } = props;
  const onClick = activeBurger ? () => setActiveBurger((a) => !a) : undefined;
  const pathFromRoot = "/" + path;
  return (
    <li
      className={clsx("linkContainer", location.pathname === pathFromRoot && "activePath")}
      onClick={onClick}
    >
      <Link className="link" to={pathFromRoot}>
        {icon}
        {props.children}
      </Link>
    </li>
  );
};

import { Stack } from "components/layout/Stack";
import { setDefaultOptions } from "date-fns";
import { enGB } from "date-fns/locale";
import { RegisteredAirportsTable } from "components/registeredAirports/RegisteredAirportsTable";

setDefaultOptions({ locale: enGB });

export const RegisteredAirports = () => {
  return (
    <div css={{ marginTop: "1rem" }}>
      <Stack gap="1rem">
        <RegisteredAirportsTable />
      </Stack>
    </div>
  );
};

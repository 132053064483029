import { ParsedRunway } from "logic/airport/runway/parse";
import {
  isWhichQuadrant,
  countRunwayInflectionPoints,
} from "components/RunwaysViz/logic/crosswindPartVizLogic";

export type ParseRunwayWithBetweenAngle = ParsedRunway & {
  angleBetweenRunwayAndWind: number;
};

const isInHeadwindQuadrant = (wind: number, runwayNumber: number) => {
  const quadrant = isWhichQuadrant(wind, countRunwayInflectionPoints(runwayNumber));
  if (quadrant === "parallelDirection" || quadrant === "quadrantOne" || quadrant === "quadrantTwo")
    return true;
  else if (
    quadrant === "edgeOfQuadrantTwoQuadrantThree" ||
    quadrant === "edgeOfQuadrantFourQuadrantOne"
  )
    return true;
  else return false;
};

export const selectRunwaysAccordingWindSmallestAngle = (
  runways: [ParsedRunway, ParsedRunway][],
  wind: number
) => {
  const flatRunways = runways.flat();

  const runwaysInRightDirection = flatRunways
    .reduce((acc: ParseRunwayWithBetweenAngle[], r) => {
      const runwayNumber = parseInt(r.name.slice(0, 2)) * 10;
      if (isInHeadwindQuadrant(wind, runwayNumber)) {
        const angleBetweenRunwayAndWind = Math.abs(runwayNumber - wind);
        return [...acc, { ...r, angleBetweenRunwayAndWind }];
      } else return acc;
    }, [] as any[])
    .reduce((acc: ParseRunwayWithBetweenAngle[], r, _, a) => {
      if (!acc[0]) return [r];
      else if (r.angleBetweenRunwayAndWind < acc[0].angleBetweenRunwayAndWind) {
        return [r];
      } else if (r.angleBetweenRunwayAndWind === acc[0].angleBetweenRunwayAndWind) {
        return [...acc, r];
      } else return acc;
    }, [] as any[]);

  return runwaysInRightDirection;
};

export const selectRunwaysAccordingWind = (
  runways: [ParsedRunway, ParsedRunway][],
  wind: number
) => {
  const flatRunways = runways.flat();

  const runwaysInRightDirection = flatRunways.reduce((acc: ParseRunwayWithBetweenAngle[], r) => {
    const runwayNumber = parseInt(r.name.slice(0, 2)) * 10;

    if (isInHeadwindQuadrant(wind, runwayNumber)) {
      const angleBetweenRunwayAndWind = Math.abs(runwayNumber - wind);
      return [...acc, { ...r, angleBetweenRunwayAndWind }];
    } else return acc;
  }, [] as any[]);

  return runwaysInRightDirection;
};

export const sortRunways = (runways: [ParsedRunway, ParsedRunway][]) => {
  return runways?.slice().sort((a, b) => {
    if (!a[0] || !b[0]) return 0;

    // checking if both first 2 characters are numbers
    // if first is string, do nothing
    // if first is number and second is number, do nothing
    // if first is number and second does not exist or is string, add 0
    let AName = a[0].name;
    const ANumberFirst = Number(AName.charAt(0));
    const ANumberSecond = AName.charAt(1) ? Number(AName.charAt(1)) : undefined;
    let BName = b[0].name;
    const BNumberFirst = Number(BName.charAt(0));
    const BNumberSecond = BName.charAt(1) ? Number(BName.charAt(1)) : undefined;

    if (ANumberFirst && !ANumberSecond) {
      AName = `0${a[0].name}`;
    }
    if (BNumberFirst && !BNumberSecond) {
      BName = `0${b[0].name}`;
    }

    // replace LCR with 123 for string comparison
    if (AName.charAt(2) === "L") {
      AName = AName.replace("L", "1");
    }
    if (AName.charAt(2) === "C") {
      AName = AName.replace("C", "2");
    }
    if (AName.charAt(2) === "R") {
      AName = AName.replace("R", "3");
    }
    if (BName.charAt(2) === "L") {
      BName = BName.replace("L", "1");
    }
    if (BName.charAt(2) === "C") {
      BName = BName.replace("C", "2");
    }
    if (BName.charAt(2) === "R") {
      BName = BName.replace("R", "3");
    }

    if (AName < BName) {
      return -1;
    } else if (AName > BName) {
      return 1;
    } else return 0;
  });
};

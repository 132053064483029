import styled from "@emotion/styled";
import { responsive } from "mixins";
import React from "react";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { WeatherDashboardContext } from "components/Dashboard/context";
import { useAutoSelectFlightRules } from "logic/weather/dashboard/useAutoSelectFlightRules";
import { useMySettings } from "logic/user/me/settings/hooks";
import { useAirportWeather, useDashboardAirport } from "logic/weather/hooks";
import { useAutoSelectRunway } from "logic/weather/dashboard/useAutoSelectRunway";
import { selectWeatherSource } from "components/RunwaysViz/selectors";
import { RunwayVizWithRunwaySelect } from "../../components/RunwaysViz/RunwayVizWithRunwaySelect";
import Loader from "components/loader/Loader";
import { Heading } from "components/heading/Heading";
import LocalTime from "components/Dashboard/airport/LocalTime";
import SwitchWithRecalc from "components/_buttons/SwitchWithRecalc";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { useAirportSubscriptionStatus } from "logic/airport/subscription";
import { Meta } from "components/meta/Meta";
import { FlexBlock } from "components/layout/FlexBlock";
import { LimitModals } from "components/RunwaysViz/LimitModals";
import { useMyAirportRoles, useMyRoles } from "logic/user/me/role/hooks";

export type RunwaySchemaType = {};

export function useRunwaySchemaData(icao?: string) {
  // 1. isSubscribed or isSuperUser
  const subscriptionRequest = useAirportSubscriptionStatus(icao);
  const { isLoading: isLoadingSubscription, isSuccess: isSuccessSubscription } =
    subscriptionRequest.query;
  const isSubscribed = subscriptionRequest.hasSubscription();
  const subscriptionError = subscriptionRequest.describeQueryError();

  const isSuperdemo = useMyRoles().transform().isSuperdemo();
  const isSupervizor = useMyRoles().transform().isSupervizor();
  const isSuperUser = isSuperdemo || isSupervizor;
  const isManagerOfCurrentAirport = useMyAirportRoles().isManagerOfCurrentAirport(icao);

  const isSubscribedOrSuperUser = isSubscribed || isSuperUser;

  const shouldSkip = isSubscribedOrSuperUser ? !isSubscribedOrSuperUser : true;
  // 2. if isSubscribed false
  // use dummy data

  // 3. if isSubscribed true
  const airportRequest = useDashboardAirport(icao, {
    skip: shouldSkip,
  });
  const { isLoading: isLoadingAirport, isSuccess: isSuccessAirport } = airportRequest.query;
  const airportError = airportRequest.describeQueryError();

  const isHeliport = airportRequest.transform()?.isHeliport();
  const isAirportOrAllRunwaysClosed = airportRequest.transform()?.isAirportOrAllRunwaysClosed();
  const isAllRunwaysDataFilledIn = airportRequest.transform()?.isAllRunwaysDataFilledIn();
  const isRunwaysDataCorrect = airportRequest.transform()?.isRunwaysDataCorrect();
  const runways = airportRequest.transform()?.runways;
  let airportName = airportRequest.transform()?.airport?.name;
  let icaos = airportRequest.transform()?.getIcaos().join(", ");

  // get weather
  const weatherRequest = useAirportWeather(icao, {
    skip: shouldSkip,
  });
  const { isLoading: isLoadingWeather, isSuccess: isSuccessWeather } = weatherRequest.query;
  const weatherError = weatherRequest.describeQueryError();

  useAutoSelectFlightRules(icao, { skip: shouldSkip });
  useAutoSelectRunway(icao, { skip: shouldSkip });

  // get angle wind from current toggled state (aw or metar) and with current settings
  const toggledToAw = useSelector(selectWeatherSource);
  const settings = useMySettings().describeOrDefault().settings;
  const weather = weatherRequest.report()?.withSettings(settings);

  const weatherWithSource = weather?.selectMetarSource({ toggledToAw });

  const angleWind = weatherWithSource?.formatWindAngleReturnNumber();
  const speedWind = weatherWithSource?.getCurrentWind()?.speed;

  return {
    isSubscribed,
    isSuperdemo,
    isSuperUser,
    isLoading: isLoadingSubscription || isLoadingWeather || isLoadingAirport,
    isSuccess: isSuccessSubscription && isSuccessAirport && isSuccessWeather,
    angleWind,
    weather,
    isHeliport,
    isAirportOrAllRunwaysClosed,
    isAllRunwaysDataFilledIn,
    isRunwaysDataCorrect,
    subscriptionError,
    airportError,
    weatherError,
    isManagerOfCurrentAirport,
    runways,
    airportName,
    icaos,
    speedWind,
  };
}

export const RunwaySchema: FC<RunwaySchemaType> = () => {
  const icao = String(useParams().icao);

  const {
    isLoading,
    isSubscribed,
    angleWind,
    isHeliport,
    isAirportOrAllRunwaysClosed,
    isAllRunwaysDataFilledIn,
    isRunwaysDataCorrect,
    isSuccess,
    subscriptionError,
    airportError,
    weatherError,
    weather,
    runways,
    speedWind,
    isSuperUser,
  } = useRunwaySchemaData(icao);

  if (isLoading) return <Loader options="dashboard" />;

  if (subscriptionError)
    return (
      <FlexBlock>
        <ErrorAlert title={"Something went wrong"} />
      </FlexBlock>
    );

  if (airportError)
    return (
      <FlexBlock>
        <ErrorAlert errorDescription={airportError} />
      </FlexBlock>
    );

  if (weatherError)
    return (
      <FlexBlock>
        <ErrorAlert errorDescription={weatherError} />
      </FlexBlock>
    );

  return (
    <>
      {
        <WeatherDashboardContext.Provider value={{ icao }}>
          <Meta pageTitle={icao?.toUpperCase()} />
          <LimitModals icao={icao} />
          <StyledRunwaySchema>
            <p className="page">runway schema</p>

            {/* dummy data */}
            {!isSubscribed && !isSuperUser && (
              <>
                <Heading dummy />
                <LocalTime weather={weather} dummy />
                <div className="switchParent">
                  <SwitchWithRecalc weather={weather} source="metar" dummy />
                </div>
                <RunwayVizWithRunwaySelect dummy angleWind={angleWind} runways={runways} />
              </>
            )}

            {isSuccess && (
              <>
                <Heading />
                <LocalTime weather={weather} />

                {isHeliport && (
                  <FlexBlock inside>
                    <ErrorAlert title={"Runway schema is not defined for heliports."} />
                  </FlexBlock>
                )}

                {isAirportOrAllRunwaysClosed && !isHeliport && (
                  <FlexBlock inside>
                    <ErrorAlert title={"Airport is closed or all of its runways are closed."} />
                  </FlexBlock>
                )}

                {!runways && (
                  <FlexBlock inside>
                    <ErrorAlert title={"There are no runways data available."} />
                  </FlexBlock>
                )}

                {(angleWind == null || runways == null || speedWind == null) && (
                  <FlexBlock inside>
                    <ErrorAlert title={"Weather or Runways data are not available."} />
                  </FlexBlock>
                )}

                {/* show this part only if there are no errors */}
                {!isAirportOrAllRunwaysClosed &&
                  isAllRunwaysDataFilledIn &&
                  isRunwaysDataCorrect &&
                  runways &&
                  angleWind != null && (
                    <>
                      <div className="switchParent">
                        <SwitchWithRecalc weather={weather} source="metar" />
                      </div>
                      <RunwayVizWithRunwaySelect angleWind={angleWind} runways={runways} />
                    </>
                  )}
              </>
            )}
          </StyledRunwaySchema>
        </WeatherDashboardContext.Provider>
      }
    </>
  );
};

const StyledRunwaySchema = styled.div`
  /* margin: 0 auto; */
  padding: 2.7rem;
  padding-top: 2rem;

  max-width: 1200px;
  min-width: 380px;

  display: flex;
  flex-direction: column;

  ${responsive.deskSM} {
    margin: 0;
    padding: 1rem;
    padding-top: 0;
  }

  ${responsive.mobilL} {
    padding: 0;
  }

  .page {
    color: #6b6c6c;
    font-family: "Encode Sans";
    font-weight: bold;
    margin-bottom: 0rem;
    text-transform: uppercase;

    font-size: 14px;

    ${responsive.deskSM} {
      margin-left: 1rem;
    }

    ${responsive.mobilP} {
      font-size: 10px;
    }
  }
  .switchParent {
    ${responsive.deskSM} {
      margin-left: 1.5rem;
    }
  }
`;

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Typography } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { EntrancePageLayout } from "components/layout/page/entrance/EntrancePageLayout";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import {
  ResetPasswordForm,
  zResetPasswordForm,
} from "logic/login/resetPassword/form";
import { onResetPasswordFormSubmitted } from "logic/login/resetPassword/onResetPasswordFormSubmitted";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store/hooks";

export type RequestResetPasswordPageProps = {};

export const RequestResetPasswordPage: FC<RequestResetPasswordPageProps> = (
  props
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useForm<ResetPasswordForm>({
    resolver: zodResolver(zResetPasswordForm),
  });
  const onSuccess = async (values: ResetPasswordForm) => {
    await dispatch(onResetPasswordFormSubmitted({ values, navigate }));
  };
  return (
    <EntrancePageLayout>
      <Meta pageTitle="Reset password" />
      <form onSubmit={form.handleSubmit(onSuccess)}>
        <Stack gap="1rem">
          <Typography variant="body1">
            Enter your e-mail address and we will send you a link to reset your
            password.
          </Typography>
          <FormTextField form={form} name="email" type="email" label="E-mail" />
          <Button type="submit" variant="contained" size="large">
            Reset password
          </Button>
        </Stack>
      </form>
    </EntrancePageLayout>
  );
};

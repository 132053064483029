import { skipToken } from "@reduxjs/toolkit/query/react";
import { WeatherDashboardContext } from "components/Dashboard/context";
import { describeAirportQuery } from "logic/airport/describe";
import { useFingerprint } from "logic/fingerprint";
import { useMySettings } from "logic/user/me/settings/hooks";
import { describeAirportWeatherQuery } from "logic/weather/describe";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { awdApi } from "store/reducers/awdApi";
import { localApi } from "store/reducers/localApi";
import { RootState } from "store/store";

export type useHookOptionsType =
  | {
      skip: boolean;
    }
  | undefined;

export function useAirportWeather(icao?: string, useHookOptions?: useHookOptionsType) {
  const dashboardCtx = useContext(WeatherDashboardContext);
  icao = icao ?? dashboardCtx?.icao;

  const lastReportQuery = localApi.endpoints.getLastWeatherReport.useQuery();

  const fingerprint = useFingerprint();

  const { skip } = useHookOptions || {};

  const query = awdApi.endpoints.getAirportWeather.useQuery(
    icao && fingerprint && !skip ? { fingerprint, icao } : skipToken,
    // refetch every 30 seconds
    { pollingInterval: 30e3 }
  );

  return describeAirportWeatherQuery(query, lastReportQuery);
}

export function useDashboardAirport(icao?: string, useHookOptions?: useHookOptionsType) {
  const dashboardCtx = useContext(WeatherDashboardContext);
  icao = icao ?? dashboardCtx?.icao;

  // taking data from local storage
  const lastReportQuery = localApi.endpoints.getLastWeatherReport.useQuery();

  const { skip } = useHookOptions || {};

  // console.log("hook", skip);

  const query = awdApi.endpoints.getAirport.useQuery(
    icao && !skip ? { airportId: icao } : skipToken
  );

  return describeAirportQuery(query, lastReportQuery);
}

export function useActiveMetarSource() {
  const settings = useMySettings().settings;
  const weather = useAirportWeather().report()?.withSettings(settings);
  const awMetar = useSelector((state: RootState) => state.localState.toggledToAwMetarWeatherSource);

  return {
    weather,
    awMetar,
    source: weather?.selectMetarSource({ toggledToAw: awMetar }),
  };
}

export function useActiveTafSource() {
  const settings = useMySettings().settings;
  const weather = useAirportWeather().report()?.withSettings(settings);
  const toggledToAw = useSelector(
    (state: RootState) => state.localState.toggledToAwTafWeatherSource
  );

  return {
    source: weather?.selectForecastSource({ toggledToAw }),
  };
}

import { UserRolesResponse } from "awd-server-api";
import { AirportNotificationIcon } from "components/airportNotificationIcon/AirportNotificationIcon";
import { pageSpec, PageSpecification } from "logic/navigation/spec";
import { bound } from "utils/class";
import { isNonNullable } from "utils/general";

import iconDashboard from "assets/images/dashboardGray.svg";
import iconMap from "assets/images/map.svg";
import InfoIcon from "@mui/icons-material/Info";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { iconColor } from "utils/themes";

const IconDashboard = () => <img src={iconDashboard} alt="" />;
const IconMap = () => <img src={iconMap} alt="" />;

export function describeOutsideNavigation() {
  return bound({
    getItems(context: {
      isLoggedIn: boolean;
      isMobileApp: boolean;
      hasResolvedCookieConsent: boolean;
      showWeatherDashboardNavigationItems?: boolean;
      weatherDashboardIcao?: string;
      isTabletSize?: boolean;
      roles: UserRolesResponse | undefined;
    }) {
      const loginStatusConditionalPages = context.isLoggedIn
        ? [
            context.roles?.roles.includes("pilot")
              ? { type: "favorite_airports" as const }
              : undefined,
            pageSpec.dashboard,
          ]
        : !context.isMobileApp
        ? [pageSpec.signup, pageSpec.registerpilot, pageSpec.login]
        : [pageSpec.registerpilot, pageSpec.login];

      const webcamPage = context?.isTabletSize ? ({ type: "webcam" } as const) : undefined;

      const weatherDashboardConditionalPages =
        context.showWeatherDashboardNavigationItems && context.weatherDashboardIcao != null
          ? [
              {
                ...pageSpec.airport_detail_dashboard,
                Icon: IconDashboard,
              },
              {
                ...pageSpec.airport_detail_map,
                Icon: IconMap,
              },
              {
                ...pageSpec.runway_schema,
                Icon: BubbleChartIcon,
              },
              {
                ...pageSpec.airport_detail_general_information,
                Icon: () => (
                  <AirportNotificationIcon
                    category="general_information"
                    icao={context.weatherDashboardIcao ?? ""}
                  />
                ),
              },
              {
                ...pageSpec.airport_detail_operational_update,
                Icon: () => (
                  <AirportNotificationIcon
                    category="operational_update"
                    icao={context.weatherDashboardIcao ?? ""}
                  />
                ),
              },
              {
                ...pageSpec.airport_detail_about,
                Icon: () => <InfoIcon htmlColor={iconColor} />,
              },
            ].map((page) => ({
              ...page,
              path: page.path.replace(":icao", context.weatherDashboardIcao ?? ""),
            }))
          : [];

      const pageItems = [
        ...weatherDashboardConditionalPages,
        webcamPage,
        ...loginStatusConditionalPages,
        pageSpec.documents,
        pageSpec.customer_support,
      ]
        .filter(isNonNullable)
        .map((page) =>
          "type" in page
            ? page
            : {
                type: "page" as const,
                title: page.title,
                page,
              }
        );

      return [
        context.isTabletSize
          ? {
              type: "search_airport" as const,
              title: "Search airport",
              path: undefined,
            }
          : undefined,
        ...pageItems,
        context.hasResolvedCookieConsent && !context.isMobileApp
          ? {
              type: "revoke_cookie_consent" as const,
              title: "Cookie settings",
              path: undefined,
            }
          : null,
      ].filter(isNonNullable);
    },
    isItemSelected(
      item:
        | { type: "page"; page: PageSpecification }
        | { type: "revoke_cookie_consent" }
        | { type: "search_airport" }
        | { type: "webcam" },
      pathname: string
    ) {
      if (item.type !== "page") return false;
      return item.page.path === pathname;
    },
  });
}

import { MyEmailVerificationRequestResponse } from "awd-server-api";
import { bound } from "utils/class";

export function describeMyUserVerificationRequest(
  request: NonNullable<MyEmailVerificationRequestResponse["request"]>
) {
  return bound({
    isVerified() {
      return request.status === "verified";
    },
    showWarning() {
      return !this.isVerified();
    },
    isEmailChange() {
      return request.source === "change_email";
    },
    showAirportManagementError() {
      return this.isVerified();
    },
  });
}

export type MyUserVerificationRequestQuery = {
  data?: MyEmailVerificationRequestResponse;
};

export function describeMyUserVerificationRequestQuery(
  query: MyUserVerificationRequestQuery
) {
  return bound({
    request: () =>
      query.data?.request
        ? describeMyUserVerificationRequest(query.data.request)
        : undefined,
  });
}

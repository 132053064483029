import React from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";

import {
  sortSelectedRunwayOnTop,
  transformRunwayWindDataForViz,
} from "components/RunwaysViz/logic/runwayVisualizationLogic";
import Compass from "components/RunwaysViz/Compass";
import Runways from "components/RunwaysViz/Runways";
import WindArrow from "components/RunwaysViz/WindArrow";
import Crosswind from "components/RunwaysViz/Crosswind";

import { useMySettings } from "logic/user/me/settings/hooks";
import { describeUnitValue } from "logic/unit/describe";

import { Position } from "components/RunwaysViz/types";
import { RunwayLongView } from "awd-server-api";

import { FlexBlock } from "components/layout/FlexBlock";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";

const SVG_WIDTH = 600;

export type RunwaySchemaSvgType = {
  angleWind: number;
  runways: RunwayLongView[];
  skip?: boolean;
  speedWind: {
    unit?: string | undefined;
    value?: number | undefined;
    meaning?: string | undefined;
  };
  selectedRunway: string;
};

export const RunwaySchemaSvg = ({
  angleWind,
  runways,
  skip,
  speedWind,
  selectedRunway,
}: RunwaySchemaSvgType) => {
  const settings = useMySettings().describeOrDefault().settings;

  if (angleWind === 0) angleWind = 0.001;

  const data = transformRunwayWindDataForViz(runways, angleWind);

  if (!data)
    return (
      <FlexBlock inside>
        <ErrorAlert title={"Runway Schema could not be rendered."} />
      </FlexBlock>
    );

  const {
    compass,
    runways: runwaysViz,
    crosswind,
    svg: { svgBounds },
  } = data;

  const sortedRunwaysData = selectedRunway && sortSelectedRunwayOnTop(selectedRunway, runwaysViz);

  const isCrossWindVisible = crosswind && selectedRunway && speedWind && angleWind;
  const isWindArrowVisible = compass && angleWind && speedWind;
  const compassCenter = {
    x: compass.centerOfCompass[0],
    y: compass.centerOfCompass[1],
  } as Position;

  return (
    <StyledSvgContainer>
      <svg
        width={SVG_WIDTH}
        viewBox={`${svgBounds.x[0]} ${svgBounds.y[0]} ${svgBounds.x[1] - svgBounds.x[0]} ${
          svgBounds.y[1] - svgBounds.y[0]
        }`}
      >
        {sortedRunwaysData && (
          <Runways runwaysData={sortedRunwaysData} selectedRunway={selectedRunway} />
        )}

        {compass && (
          <Compass
            compassCenter={compassCenter}
            circleRadius={compass.circleRadius}
            angleWind={angleWind}
          />
        )}

        {isCrossWindVisible && (
          <Crosswind
            crosswindData={crosswind}
            angleWind={angleWind}
            speedWind={speedWind}
            selectedRunway={selectedRunway}
          />
        )}

        {isWindArrowVisible && (
          <WindArrow
            angleWind={angleWind}
            centerOfCompass={compass.centerOfCompass}
            circleRadius={compass.circleRadius}
            speedWind={describeUnitValue(speedWind)
              ?.convertToUnit(settings.speed_unit, true)
              ?.format({ postprocess: "round" })}
          />
        )}
      </svg>
    </StyledSvgContainer>
  );
};

const StyledSvgContainer = styled.div`
  width: 100%;

  svg {
    ${responsive.deskSM} {
      width: 100%;
    }
  }
`;

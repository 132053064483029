import { FormEvent } from "react";
import { stopOtherEvents } from "utils/general";
import { postMobileAppMessage } from "logic/mobileApp/postMobileAppMessage";

export const handleDebugMode = (value: string) => (e: FormEvent) => {
  stopOtherEvents(e);
  if (value.toLowerCase() === "debug_mode: staging") {
    postMobileAppMessage({
      type: "enable_debug_mode_staging",
    });
  } else if (value.toLowerCase() === "debug_mode: rc") {
    postMobileAppMessage({
      type: "enable_debug_mode_rc",
    });
  } else if (value.toLowerCase() === "debug_mode: off") {
    postMobileAppMessage({
      type: "disable_debug_mode",
    });
  }
};

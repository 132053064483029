import { WeatherDescribedType } from "logic/weather/describe";
import Switch from "components/_buttons/Switch";
import { FC } from "react";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { formatDataAge } from "utils/global.functions";
import styled from "@emotion/styled";
import { responsive } from "mixins";
import { Theme } from "@emotion/react";

export type SwitchWithRecalcType = {
  weather?: WeatherDescribedType;
  source?: "taf" | "metar";
  theme?: Theme;
  dummy?: boolean;
};

const SwitchWithRecalc: FC<SwitchWithRecalcType> = ({ weather, source, theme, dummy }) => {
  const toggledToAw = useSelector((state: RootState) =>
    source === "metar"
      ? state.localState.toggledToAwMetarWeatherSource
      : state.localState.toggledToAwTafWeatherSource
  );

  const windIssuedTime =
    source === "metar"
      ? weather?.selectMetarSource({ toggledToAw }).getTime()
      : weather?.selectForecastSource({ toggledToAw }).getTime();

  return (
    <StyledSwitchWithRecalc>
      <div className="weatherSource">
        <StyledRecalculation placement={toggledToAw} theme={theme} source={source}>
          <p className="recalculationData">
            {(windIssuedTime && formatDataAge(windIssuedTime)) ?? "?"} min
          </p>
        </StyledRecalculation>

        <div className="switchPosition">
          <Switch text={source === "metar" ? "metar" : "taf"} theme={theme} skip={dummy} />
        </div>
      </div>
    </StyledSwitchWithRecalc>
  );
};

const StyledSwitchWithRecalc = styled.div`
  .weatherSource {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .switchPosition {
    margin-top: -0.2rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledRecalculation = styled.div<{
  placement: boolean;
  theme?: Theme;
  source?: "metar" | "taf";
}>`
  padding-left: ${(props) =>
    props.source === "metar"
      ? props.placement
        ? "7.5rem"
        : "0rem"
      : props.placement
      ? "6rem"
      : "0rem"};
  display: flex;
  align-items: baseline;
  gap: 0.5rem;

  transition: padding-left 0.7s;

  ${responsive.deskSM} {
    /* margin-top: 1rem; */
  }

  .recalculationData {
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.theme.primary};
    transition: color 0.5s;

    margin: 0;

    ${responsive.tablet} {
      font-size: 18px;
    }
    ${responsive.mobilP} {
      font-size: 16px;
    }
  }
`;

export default SwitchWithRecalc;

import { bound } from "utils/class";
import { encodeQuery } from "utils/general";
import { themes } from "utils/themes";

export function describeFlightConditionsTable() {
  return bound({
    getRows() {
      return [
        {
          key: "green",
          color: "Green",
          flight_condition: "VFR",
          name: "Visual Flight Rules",
          visibility: "> 8 km",
          cloud_ceiling: "> 3000 ft",

          // TODO: @ako replace by non-placeholder url
          icon_image_url: encodeQuery("/cond-vfr.png", {
            width: 64,
            height: 64,
            textColor: themes.vfr.primary,
          }),
        },
        {
          key: "blue",
          color: "Blue",
          flight_condition: "MVFR",
          name: "Marginal Visual Flight Rules",
          visibility: "5 - 8 km",
          cloud_ceiling: "1000 - 3000 ft",

          // TODO: @ako replace by non-placeholder url
          icon_image_url: encodeQuery("/cond-mvfr.png", {
            width: 64,
            height: 64,
            textColor: themes.mvfr.primary,
          }),
        },
        {
          key: "red",
          color: "Red",
          flight_condition: "IFR",
          name: "Instrument Flight Rules",
          visibility: "1.5 - 5 km",
          cloud_ceiling: "500 - 1000 ft",

          // TODO: @ako replace by non-placeholder url
          icon_image_url: encodeQuery("/cond-ifr.png", {
            width: 64,
            height: 64,
            textColor: themes.ifr.primary,
          }),
        },
        {
          key: "magenta",
          color: "Magenta",
          flight_condition: "LIFR",
          name: "Low Instrument Flight Rules",
          visibility: "< 1.5 km",
          cloud_ceiling: "< 500 ft",

          // TODO: @ako replace by non-placeholder url
          icon_image_url: encodeQuery("/cond-lifr.png", {
            width: 64,
            height: 64,
            textColor: themes.lifr.primary,
          }),
        },
      ];
    },
  });
}

import { FC, MouseEvent, ReactNode, useState } from "react";
import { Stack } from "components/layout/Stack";
import { Button, Menu, MenuItem } from "@mui/material";

type PilotStatisticsActionsProps = {
  switchJsx: ReactNode;
  downloadAllPilotData: () => void;
  downloadVerifiedPilotData: () => void;
};

export const PilotStatisticsActions: FC<PilotStatisticsActionsProps> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack horizontal gap={"0"} css={{ justifyContent: "space-between" }}>
      {props.switchJsx}
      <Button
        aria-controls={open ? "download-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
      >
        Download Actions
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={props.downloadVerifiedPilotData}>
          Download Verified Pilot Accounts Data
        </MenuItem>
        <MenuItem onClick={props.downloadAllPilotData}>
          Download All Pilot Accounts Data
        </MenuItem>
      </Menu>
    </Stack>
  );
};

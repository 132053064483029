import React, { FC, SetStateAction } from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";

import iconClouds from "assets/images/clouds-map.svg";
import iconRain from "assets/images/rain.svg";
import iconThunder from "assets/images/thunder.svg";
import iconResetLocation from "assets/images/reset.svg";
import { OverlayType } from "components/kiosk/slides/KioskMapSlide";

export type ExternalControlsType = {
  weatherOverlay: OverlayType;
  setWeatherOverlay: React.Dispatch<SetStateAction<OverlayType>>;
  reset: () => void;
};

const ExternalControls: FC<ExternalControlsType> = ({
  weatherOverlay,
  setWeatherOverlay,
  reset,
}) => {
  return (
    <StyledMapControls>
      <StyledLayersControls>
        <StyledWeatherLayer
          onClick={() => setWeatherOverlay("clouds")}
          selected={weatherOverlay === "clouds"}
        >
          <span>Clouds</span>
          <img src={iconClouds} alt="" />
        </StyledWeatherLayer>
        <StyledWeatherLayer
          onClick={() => setWeatherOverlay("crr-pc")}
          selected={weatherOverlay === "crr-pc"}
        >
          <span>Rain</span>
          <img src={iconRain} alt="" />
        </StyledWeatherLayer>
        <StyledWeatherLayer
          onClick={() => setWeatherOverlay("rdt")}
          selected={weatherOverlay === "rdt"}
        >
          <span>Thunderstorms</span>
          <img src={iconThunder} alt="" />
        </StyledWeatherLayer>
      </StyledLayersControls>
      <button className="resetPosition" onClick={reset} title="Reset location">
        <img src={iconResetLocation} alt="" />
      </button>
    </StyledMapControls>
  );
};

const StyledMapControls = styled.div`
  .resetPosition {
    position: absolute;
    top: 80px;
    left: 10px;
    z-index: 1000;

    /* width: 30px; */
    /* height: 30px; */
    padding: 0;
    border: 2px solid rgba(0, 0, 0, 0.363);
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
  }
`;

const StyledLayersControls = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

const StyledWeatherLayer = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  /* width: 30px; */

  align-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  text-shadow: 0px 0px 8px white;
  font-weight: bold;
  color: #231f20;

  /* &:before {
    left: 0;
    width: 25px;
    background-color: rgba(68, 65, 65, 0.84);
    border-radius: 25px;
  } */

  &:after {
    content: "";
    background-color: #f1f1f2;
    position: absolute;
    top: -2px;
    right: 0;
    width: 30px;
    /* with surface wind layer */
    /* height: 165px; */
    height: 125px;
    z-index: -10;
    border-radius: 5px;

    /* position: absolute;
    top: -3px;
    bottom: -3px;
    right: 0;
    content: "";

    width: 100px;
    z-index: -10; */
  }

  span {
    padding-left: 5px;
    /* text-decoration: ${(props) =>
      props.selected ? "underline" : "none"}; */
    font-weight: ${(props) => (props.selected ? 900 : "bold")};

    ${responsive.mobilP} {
      font-size: 12px;
    }
  }

  img {
    background-color: #fff;
    /* background-color: ${(props) =>
      props.selected ? "#3e97d2e1" : "#fff"}; */
    background-color: ${(props) => (props.selected ? "#009ff5" : "#fff")};
    /* border: 2px solid rgba(0, 0, 0, 0.363); */
    margin: 5px 0px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 black;
    /* box-shadow: ${(props) => (props.selected ? "0 0 4px 0 black" : "")}; */
  }
  :hover {
    span {
      text-decoration: underline;
    }
    img {
      /* background-color: #3e97d2e1; */
      background-color: #009ff5;
      border-radius: 5px;
    }
  }
`;

export default ExternalControls;

import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@mui/material";
import {
  FieldPath,
  FieldValues,
  UseFormReturn,
  useController,
} from "react-hook-form";

export function FormToggleButtonGroup<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
    options: { value: string; label?: string }[];
  } & ToggleButtonGroupProps
) {
  const { form, name, options, ...pass } = props;

  const { field } = useController({ name, control: form.control });

  return (
    <ToggleButtonGroup {...field} {...pass}>
      {options.map((option) => {
        return (
          <ToggleButton value={option.value} key={option.value}>
            {option.label ?? option.value}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

import { css, SerializedStyles } from "@emotion/react";
import { CSSResult, ResponsiveValue } from "logic/responsive/types";
import { responsive, responsiveSpec } from "mixins";
import { bound } from "utils/class";
import { entriesOf, fromEntries, isKeyof } from "utils/general";
import useWindowDimensions from "utils/useWindowDimensions";

export function getResponsiveStyles<Key extends string | number>(
  value: ResponsiveValue<Key> | undefined,
  valueMap: Record<Key, CSSResult> | ((value: Key) => CSSResult | undefined)
): SerializedStyles | undefined {
  if (value == null) return undefined;

  if (typeof value === "string" || typeof value === "number") {
    return css(applyStyle(value, valueMap));
  }

  const rules = entriesOf(value).map(([breakpoint, value]) => {
    const mediaQuery = isKeyof(breakpoint, responsive)
      ? responsive[breakpoint]
      : undefined;

    if (mediaQuery)
      return {
        [mediaQuery]: applyStyle(value as Key, valueMap),
      };
    else return applyStyle(value as Key, valueMap);
  });

  return css(rules);
}

export function useResponsiveValue<Key extends string | number | boolean>(
  value: ResponsiveValue<Key> | undefined
) {
  const width = useWindowDimensions().width;

  if (value == null) return undefined;

  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  ) {
    return value;
  }

  const match = Object.entries(value).reduce(
    (prevMatch, [breakpoint, value]) => {
      const maxWidth = isKeyof(breakpoint, responsiveSpec)
        ? responsiveSpec[breakpoint].maxWidth
        : Infinity;

      const isMatch = width <= maxWidth;

      if (isMatch && (prevMatch == null || prevMatch.maxWidth > maxWidth)) {
        return { maxWidth, value };
      }

      return prevMatch;
    },
    undefined as
      | undefined
      | {
          maxWidth: number;
          value: Key;
        }
  );

  return match?.value;
}

function applyStyle<Key extends string | number>(
  value: Key,
  resolved: Record<Key, CSSResult> | ((value: Key) => CSSResult | undefined)
): CSSResult | undefined {
  if (typeof resolved === "function") {
    return resolved(value);
  }
  return resolved[value];
}

export function describeResponsiveValue<T extends string | number | boolean>(
  value: ResponsiveValue<T>
) {
  return bound({
    map<X>(map: (value: T) => X): ResponsiveValue<X> {
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      )
        return map(value);

      return fromEntries(
        entriesOf(value).map(([breakpoint, value]) => {
          return [breakpoint, map(value as T)];
        })
      );
    },
  });
}

import { Typography } from "@mui/material";
import { useAirportNotifications } from "logic/airport/notification";
import { FC } from "react";
import { AirportNotification } from "awd-server-api";
import styled from "@emotion/styled";
import { Stack } from "components/layout/Stack";
import { TitleSection } from "components/Dashboard/weatherNow/WeatherNow";
import { css } from "@emotion/react";
import { responsive } from "mixins";

export type DashboardAirportNotificationProps = {
  icao?: string;
};

export const DashboardAirportNotification: FC<DashboardAirportNotificationProps> = (props) => {
  const notifications = useAirportNotifications(props.icao);
  const operationalUpdateList = notifications.getOperationalUpdateList();

  return (
    <Stack
      gap="0.5rem"
      css={css`
        margin-top: 1rem;

        ${responsive.deskSM} {
          margin-top: 0.3rem;
        }
      `}
    >
      {operationalUpdateList && operationalUpdateList.length > 0 && (
        <NotificationList list={operationalUpdateList} type="operational_update" />
      )}
    </Stack>
  );
};

type NotificationListProps = {
  list: AirportNotification[];
  type: AirportNotification["category"];
};

const NotificationList: FC<NotificationListProps> = ({ list, type }) => {
  const title = type === "operational_update" ? "Operational Updates" : "General Information";
  return (
    <div css={{ textAlign: "start" }}>
      <TitleSection color={"#f44336"}>{title}</TitleSection>
      <SUl>
        {list.map((notification) => (
          <li key={notification.id}>
            <Typography variant="h5">
              <strong>{notification.title}:</strong> {notification.description}
            </Typography>
          </li>
        ))}
      </SUl>
    </div>
  );
};

const SUl = styled.ul`
  padding-top: 0;
  padding-left: 1rem;
  margin: 0;
`;

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect, useState } from "react";

const fingerprintLoadedPromise = FingerprintJS.load();

export async function takeFingerprint() {
  const fp = await fingerprintLoadedPromise;
  return (await fp.get()).visitorId;
}

let lastFingerprint: string | undefined = undefined;

export function useFingerprint() {
  const [fingerprint, setFingerprint] = useState<string>();

  useEffect(() => {
    if (lastFingerprint) {
      setFingerprint(lastFingerprint);
    } else {
      takeFingerprint().then((fingerprint) => {
        lastFingerprint = fingerprint;
        setFingerprint(fingerprint);
      });
    }
  }, []);

  return fingerprint;
}

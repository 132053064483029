import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { describeError } from "logic/error";
import { Toast } from "logic/toast/types";
import { RootState } from "store/store";

type ToastSliceState = {
  activeToast?: Toast;
  previousToast?: Toast;
};

const initialState: ToastSliceState = {};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    show: (st, act: PayloadAction<Toast>) => {
      st.previousToast = st.activeToast;
      st.activeToast = act.payload;
    },
    closeToast: (st) => {
      st.previousToast = st.activeToast;
      st.activeToast = undefined;
    },
  },
});

export function toastMessage(
  severity: Toast["severity"],
  message: Toast["message"]
) {
  return toastSlice.actions.show({ severity, message });
}

export function toastError(error: unknown, fallbackMessage: string) {
  const { severity = "error", message = fallbackMessage } =
    describeError(error).asToastMessage() ?? {};

  return toastMessage(severity, message);
}

export const selToast = (st: RootState) => st.toast;

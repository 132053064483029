import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeRunway } from "logic/airport/runway/describe";
import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";

export function useAirportRunways(airportId: number | string | undefined) {
  const query = awdApi.endpoints.getAirportRunways.useQuery(
    airportId == null ? skipToken : { airportId }
  );
  return bound({
    query,
    describeItems() {
      return query.data ? query.data.runways.map(describeRunway) : undefined;
    },
  });
}

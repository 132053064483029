import { describeAirport } from "logic/airport/describe";
import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";

export function useFavoriteAirports() {
  const query = awdApi.endpoints.getMyFavoriteAirports.useQuery();
  const [mutation, mutationState] =
    awdApi.endpoints.putMyFavoriteAirports.useMutation();

  return bound({
    query,
    mutation,
    mutationState,
    getItems() {
      return query.data;
    },
    getItemsAsRequest() {
      return (this.getItems() ?? []).map(({ airport_id, is_home_airport }) => ({
        airport_id,
        is_home_airport,
      }));
    },
    homeAirport() {
      const favoriteAirport = this.getItems()?.find(
        (item) => item.is_home_airport
      );
      return favoriteAirport
        ? describeAirport(favoriteAirport.airport)
        : undefined;
    },
    mutateHomeAirport(airport_id: number) {
      return this.mutation({
        favorite_airports: this.getItemsAsRequest()?.map((item) => ({
          ...item,
          is_home_airport: airport_id === item.airport_id,
        })),
      });
    },
    addFavorite(airport_id: number) {
      return this.mutation({
        favorite_airports: [
          ...this.getItemsAsRequest(),
          { airport_id, is_home_airport: false },
        ],
      });
    },
    removeFavorite(airport_id: number) {
      return this.mutation({
        favorite_airports: this.getItemsAsRequest().filter(
          (item) => item.airport_id !== airport_id
        ),
      });
    },
  });
}

import { Typography } from "@mui/material";
import { AirportBannerSettingsGroup } from "components/airportBannerSettingsGroup/AirportBannerSettingsGroup";
import { OpenKioskButton } from "components/button/kiosk/OpenKioskButton";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";

export type KioskSettingsPageProps = {};

export const KioskSettingsPage: FC<KioskSettingsPageProps> = (props) => {
  const icao = useMyAirportRoles().managedAirport()?.icao;

  return (
    <InsidePageLayout>
      <Meta pageTitle="Kiosk" />

      <Stack gap="1rem" horizontal css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Kiosk</InsidePageTitle>
        {icao != null && <OpenKioskButton icao={icao} />}
      </Stack>

      <Spacer size="1.5rem" />

      <Typography variant="body1">
        You can use Kiosk mode to show the Airport Weather Dashboard on a TV in
        your lobby.
      </Typography>

      <Spacer size="1.5rem" />

      <Spacer size="1.5rem" />

      <AirportBannerSettingsGroup />
    </InsidePageLayout>
  );
};

import styled from "@emotion/styled";
import { getResponsiveStyles } from "logic/responsive/styles";
import { ResponsiveValue } from "logic/responsive/types";

export const Spacer = styled.div<{
  size: ResponsiveValue<string>;
  horizontal?: boolean;
  parentStackGap?: string;
}>(({ size, horizontal = false, parentStackGap = 0 }) =>
  getResponsiveStyles(size, (size) =>
    horizontal
      ? {
          width: size,
          marginLeft: `-${parentStackGap}`,
          marginRight: `-${parentStackGap}`,
        }
      : {
          height: size,
          marginTop: `-${parentStackGap}`,
          marginBottom: `-${parentStackGap}`,
        }
  )
);

import { Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { FC, PropsWithChildren } from "react";

export const Property: FC<PropsWithChildren<{ name: string }>> = ({
  name,
  children,
}) => (
  <Stack gap="0">
    <Typography variant="subtitle1">{name}</Typography>
    <Typography variant="body1">
      <strong>{children}</strong>
    </Typography>
  </Stack>
);

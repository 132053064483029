import { useAirportWeather, useHookOptionsType } from "logic/weather/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "store/hooks";
import { setFlightRulesCode } from "store/reducers/localStateSlice";
import { RootState } from "store/store";

export function useAutoSelectFlightRules(
  icao: string | undefined,
  useHookOptions?: useHookOptionsType
) {
  const dispatch = useDispatch();
  const toggledToAw = useSelector(
    (state: RootState) => state.localState.toggledToAwMetarWeatherSource
  );

  const { skip } = useHookOptions || {};

  const weather = useAirportWeather(icao, { skip: skip ? skip : false }).report();
  const source = weather?.selectMetarSource({ toggledToAw });
  const flightRulesCode = source?.getFlightRulesCode() ?? "default";

  useEffect(() => {
    dispatch(setFlightRulesCode(flightRulesCode));
  }, [flightRulesCode]);
}

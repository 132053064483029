import { bound } from "utils/class";
import { SpecBuilder } from "utils/general";
import { Unit } from "./types";

export const unitSpec = new SpecBuilder<{
  displayName: string;
  conversion: {
    toBase: (x: number) => number;
    fromBase: (x: number) => number;
  };
}>().build<Unit>({
  hPa: {
    displayName: "hPa",
    conversion: multiplyBy(100),
  },
  Pa: {
    displayName: "Pa",
    conversion: baseUnit(),
  },
  m: {
    displayName: "m",
    conversion: baseUnit(),
  },
  meter: {
    displayName: "m",
    conversion: baseUnit(),
  },
  degC: {
    displayName: "°C",
    conversion: baseUnit(),
  },
  degF: {
    displayName: "°F",
    conversion: {
      toBase: (quantity) => ((quantity - 32) * 5) / 9,
      fromBase: (quantity) => (quantity * 9) / 5 + 32,
    },
  },
  ft: {
    displayName: "ft",
    conversion: multiplyBy(0.3048),
  },
  km: {
    displayName: "km",
    conversion: multiplyBy(1000),
  },
  kt: {
    displayName: "kt",
    conversion: multiplyBy(0.51444),
  },
  inHg: {
    displayName: "inHg",
    conversion: multiplyBy(3386.39),
  },
  mile: {
    displayName: "mi",
    conversion: multiplyBy(1609.344),
  },
  "m/s": {
    displayName: "m/s",
    conversion: baseUnit(),
  },
  "km/h": {
    displayName: "km/h",
    conversion: multiplyBy(1 / 3.6),
  },
  "mile/h": {
    displayName: "mph",
    conversion: multiplyBy(1 / 2.237),
  },
  nautical_mile: {
    displayName: "NM",
    conversion: multiplyBy(1852),
  },
});

function multiplyBy(factor: number) {
  return bound({
    toBase: (quantity: number) => quantity * factor,
    fromBase: (quantity: number) => quantity / factor,
  });
}

function baseUnit() {
  return multiplyBy(1);
}

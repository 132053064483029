import React, { FC } from "react";
import { useTheme } from "@emotion/react";

export type LittleArrowType = {
  xCoord: number;
  yCoord: number;
  rotate: number;
};

const LittleArrow: FC<LittleArrowType> = ({ xCoord, yCoord, rotate }) => {
  const theme = useTheme();
  return (
    <g id="animateCircle">
      <g transform={`rotate(${rotate - 180},${xCoord},${yCoord}) translate(${xCoord},${yCoord}) `}>
        <path
          fill={theme.primary}
          d="M-6.4,14.9c-0.446,-0.567,-0.555,-1.339,-0.269,-2.015l4.606,-10.878c0.313,-0.741,1.04,-1.221,1.844,-1.22c0.622,0.001,1.197,0.29,1.569,0.764c0.109,0.139,0.201,0.293,0.272,0.461l4.578,10.889c0.316,0.751,0.144,1.618,-0.433,2.193c-0.577,0.574,-1.445,0.741,-2.195,0.422l-3.808,-1.623l-3.812,1.614c-0.75,0.318,-1.619,0.148,-2.194,-0.427c-0.056,-0.058,-0.109,-0.118,-0.158,-0.18z"
        />
      </g>
    </g>
  );
};
export default LittleArrow;

import styled from "@emotion/styled";
import Compass from "components/Dashboard/_icons/Compass";
import { ForecastWindArrows } from "./ForecastWindArrows";
import { ForecastedWindSpeed } from "./ForecastedWindSpeed";
import { ForecastedWindDirection } from "./ForecastedWindDirection";
import { useObserveElementSize } from "utils/hooks";

type ForecastedWindType = {
  index: number;
  theme: any;
  wind: any;
  numOfArrows: number;
};

export const ForecastWindCompass = ({ wind, index, theme, numOfArrows }: ForecastedWindType) => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  return (
    <StyledForecastWind index={index} ref={ref}>
      <SRunwayWindDataRow
        css={{
          top: 4 * 12,
          gap: width != null && width < 300 ? "3rem" : "6rem",
        }}
      >
        <ForecastedWindDirection index={index} theme={theme} />
        <ForecastedWindSpeed index={index} theme={theme} />
      </SRunwayWindDataRow>
      <div className="compassWithArrows">
        <div className="arrows">
          <ForecastWindArrows type={"compass"} wind={wind} index={index} theme={theme} />
        </div>
        <div className="compass">
          <Compass height={200} />
        </div>
      </div>
    </StyledForecastWind>
  );
};

const SRunwayWindDataRow = styled.div({
  display: "flex",
  position: "absolute",
  justifyContent: "space-around",
  width: "100%",
  fontSize: "12px",
});

const StyledForecastWind = styled.div<{ index: number }>`
  font-weight: 100;

  align-self: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  .compassWithArrows {
    margin-top: 2rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
  }

  .arrows {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    z-index: 90;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .compass {
    max-width: 10rem;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;

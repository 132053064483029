import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { Stack } from "components/layout/Stack";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Meta } from "components/meta/Meta";
import { FC, MouseEvent, useState } from "react";
import { awdApi } from "store/reducers/awdApi";
import { getGps, getLocalTime } from "utils/general";
import {
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useModalContext } from "components/modal/inside/ModalProvider";
import {
  DeclineModal,
  DowngradeToPilotAccountModal,
  SubscribeModal,
} from "components/airportSubscription/AirportSubscriptionModals";
import {
    AirportSpecification,
  MyAirportRoleRequestsResponse,
  UserAirportSubscriptionRequest,
} from "awd-server-api";
import { describeMutationResult } from "logic/api/result/describe";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useDispatch } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { useMySettings } from "logic/user/me/settings/hooks";
import { MenuButton } from "components/menu/MenuButton";
import { AirportSelectValue } from "components/airportSelect/AirportSelect";

export type AirportSubscriptionPageProps = {};

const successMessage: Record<UserAirportSubscriptionRequest["status"], string> =
  {
    declined: "Airport subscription is declined",
    downgraded: "User is downgraded to pilot account",
    resolved: "Airport subscription is confirmed",
  };

const RequestTableRow = ({
  request,
}: {
  request: MyAirportRoleRequestsResponse["requests"][number];
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show } = useModalContext();
  const time_format = useMySettings().describe()?.settings?.time_format;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const airport_code = request.airport ? request.airport.icao : (request.airport_name ?? "N/A")
  const gps = request.airport ? getGps(request.airport, 2) : "N/A";
  const url = request.airport && (request.airport.homeLink || request.airport.wikipediaLink);

  const userName = request.User?.first_name + " " + request.User?.last_name;

  const requestTime = getLocalTime(request.created_at, time_format);
  const onSubmitFactory =
    (status: UserAirportSubscriptionRequest["status"]) => async (airport?: AirportSelectValue) => {
      let airportId;
      if (airport && airport.specified_by === "name") {
        const result = await dispatch(
          awdApi.endpoints.postAirport.initiate({
            body: {
              name: airport.name,
              latitude: 0.0,
              longitude: 0.0,
            },
          })
        ).then(describeMutationResult);

        if (result.error) {
          dispatch(toastError(result.error, `Adding airport '${airport.name}' went wrong`));
          return;
        } else {
          airportId = result.data.id;
        }
      } else if (airport) {
        airportId = airport.id;
      }

      const result = await dispatch(
        awdApi.endpoints?.putAirportSubscriptionRequest.initiate({
          status,
          requestId: request.id,
          airportId
        })
      ).then(describeMutationResult);

      if (result.error) {
        dispatch(toastError(result.error, "Something went wrong"));
      } else {
        dispatch(toastMessage("success", successMessage[status]));
        if (airport && airport.specified_by === "name") {
          navigate(`/${airportId}/edit`);
        }
      }
    };

  const airportSpecification: AirportSpecification = request.airport
    ? { specified_by: "id", id: request.airport?.icao ?? '' }
    : { specified_by: "name", name: request.airport_name ?? '' };

  return (
    <TableRow>
      <TableCell>
        <Link
          href={url ? url : undefined}
          aria-disabled={!url}
          underline={url ? "hover" : "none"}
          sx={{ pointerEvents: url ? "initial" : "none" }}
          color={url ? "primary" : "inherit"}
          target="_blank"
        >
          {airport_code}
        </Link>
      </TableCell>
      <TableCell>{gps}</TableCell>
      <TableCell>{userName}</TableCell>
      <TableCell>
        <a href={`mailto:${request.User?.email}`}>{request.User?.email}</a>
      </TableCell>
      <TableCell>{request.User?.phone_number}</TableCell>
      <TableCell>{requestTime}</TableCell>
      <TableCell>
        <div>
          <MenuButton
            open={open}
            handleClick={handleClick}
            controlId="basic-menu"
          >
            Actions
          </MenuButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() =>
                show(
                  <SubscribeModal
                    userName={userName}
                    airport={airportSpecification}
                    onSubmit={onSubmitFactory("resolved")}
                  />
                )
              }
            >
              Subscribe to airport
            </MenuItem>
            <MenuItem
              onClick={() =>
                show(
                  <DowngradeToPilotAccountModal
                    userName={userName}
                    onSubmit={onSubmitFactory("downgraded")}
                  />
                )
              }
            >
              Downgrade to Pilot Account
            </MenuItem>
            <MenuItem
              onClick={() =>
                show(
                  <DeclineModal
                    userName={userName}
                    airport={airportSpecification}
                    onSubmit={onSubmitFactory("declined")}
                  />
                )
              }
            >
              Decline
            </MenuItem>
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const AirportSubscriptionPage: FC<AirportSubscriptionPageProps> = () => {
  const subscriptionList =
    awdApi.endpoints.getAirportSubscriptionRequests.useQuery();

  const requests = subscriptionList?.data?.requests;
  return (
    <InsidePageLayout>
      <Meta pageTitle="Airport Subscription" />
      <Stack gap="1rem" css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>Airport Subscription Requests</InsidePageTitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Airport ICAO</TableCell>
              <TableCell>Airport Lat/Long</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Request Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {requests?.map((request) => (
              <RequestTableRow key={request.id} request={request} />
            ))}
          </TableBody>
        </Table>
      </Stack>
    </InsidePageLayout>
  );
};

import { QueryResult } from "components/queryResult/QueryResult";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";
import { describeAirportIcao } from "logic/airport/icao/describe";

export type AirportWebcamProps = {
  airportId: string | number;
};
export const AirportWebcam: FC<AirportWebcamProps> = ({ airportId }) => {
  const airportIdString = String(airportId);
  const query = awdApi.endpoints.getAirportLinkSettings.useQuery({ airportId });
  const settings = query.data;

  return (
    <QueryResult query={query}>
      {settings?.webcam_url && (
        <img
          src={describeAirportIcao(airportIdString).getWebcamUrl()}
          alt="Airport Webcam"
          css={{ maxWidth: "90vw" }}
        />
      )}
    </QueryResult>
  );
};

import { addDays, format } from "date-fns";
import { awdApi } from "store/reducers/awdApi";
import { ChartDataset, ChartOptions } from "chart.js";
import { match } from "ts-pattern";

export interface DailyDataPoint {
  day: string;
  new_pilots: number;
}

export function fillMissingDates(data?: DailyDataPoint[]): DailyDataPoint[] {
  // Convert data array to a map for easier access
  if (!data || data.length === 0) return [];

  data.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());
  const dataMap = new Map(data.map((item) => [item.day, item]));

  const first = data[0];
  const last = data[data.length - 1];
  if (!first || !last) return [];

  // Get the start and end date
  const startDate = new Date(first.day);
  const endDate = new Date(last.day);

  const result: DailyDataPoint[] = [];

  // Iterate through dates and add missing ones with 0 value
  for (let date = startDate; date <= endDate; date = addDays(date, 1)) {
    const dateKey = format(date, "yyyy-MM-dd");
    const existingData = dataMap.get(dateKey);

    if (existingData) {
      result.push(existingData);
    } else {
      result.push({ day: dateKey, new_pilots: 0 });
    }
  }

  return result;
}

export function fillCumulativeData(data?: DailyDataPoint[]): DailyDataPoint[] {
  if (!data || data.length === 0) return [];
  const filledData = fillMissingDates(data);
  const result: DailyDataPoint[] = [];
  let sum = 0;
  for (const d of filledData) {
    sum += d.new_pilots;
    result.push({ day: d.day, new_pilots: sum });
  }
  return result;
}

export function downloadCsv(
  filename: string,
  dataColumnName: string,
  data?: DailyDataPoint[]
) {
  if (data) {
    const csvData = data
      .map((item) => `${item.day},${item.new_pilots}`)
      .join("\n");
    const blob = new Blob([`Day,${dataColumnName}\n${csvData}`], {
      type: "text/csv",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename} ${new Date().toLocaleDateString()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

export function usePilotChartData(args: { cumulative: boolean }) {
  const { cumulative } = args;
  const { data, isLoading } = awdApi.endpoints?.getStatisticsDaily.useQuery();

  const verifiedFilteredData = data?.pilots?.verified?.filter(
    (a) => a.day !== "SUM"
  );
  const allFilteredData = data?.pilots?.all?.filter((a) => a.day !== "SUM");

  const verifiedPilots = match(cumulative)
    .with(true, () => fillCumulativeData(verifiedFilteredData))
    .with(false, () => fillMissingDates(verifiedFilteredData))
    .exhaustive();

  const allPilots = match(cumulative)
    .with(true, () => fillCumulativeData(allFilteredData))
    .with(false, () => fillMissingDates(allFilteredData))
    .exhaustive();

  const downloadAllPilotData = () => {
    downloadCsv("All Pilots", "New Pilots", data?.pilots.all);
  };
  const downloadVerifiedPilotData = () => {
    downloadCsv("Verified Pilots", "New Pilots", data?.pilots.verified);
  };

  const datasets: ChartDataset<"line", DailyDataPoint[]>[] = [
    {
      label: "Verified Pilots",
      data: verifiedPilots,
    },
    {
      label: "All pilots",
      data: allPilots,
    },
  ];

  const max = Math.max.apply(
    Math,
    allPilots.map((o) => o.new_pilots)
  );

  const options = getDailyChartOptions({
    max,
    title: `New Pilot Registrations Per Day${
      cumulative ? " (Cumulative)" : ""
    }`,
  });

  return {
    datasets,
    options,
    isLoading,
    downloadAllPilotData,
    downloadVerifiedPilotData,
  };
}

export function useAirportSubscriptionChartData(args: { cumulative: boolean }) {
  const { cumulative } = args;
  const { data, isLoading } = awdApi.endpoints?.getStatisticsDaily.useQuery();

  const transformedData = data?.airports?.map((d) => ({
    day: d.day,
    new_pilots: d.new_airports,
  }));

  const downloadAirportData = () => {
    downloadCsv("New Airports", "New Airports", transformedData);
  };

  const filteredData: DailyDataPoint[] | undefined = transformedData?.filter(
    (a) => a.day !== "SUM"
  );

  const airportSubscriptions = match<boolean, DailyDataPoint[]>(cumulative)
    .with(true, () => fillCumulativeData(filteredData))
    .with(false, () => fillMissingDates(filteredData))
    .exhaustive();

  const datasets: ChartDataset<"line", DailyDataPoint[]>[] = [
    {
      label: "Airport Subscriptions",
      data: airportSubscriptions,
    },
  ];

  const max = Math.max.apply(
    Math,
    airportSubscriptions.map((o) => o.new_pilots)
  );

  const options = getDailyChartOptions({
    max,
    title: `New Airport Subscriptions Per Day${
      cumulative ? " (Cumulative)" : ""
    }`,
  });

  return { datasets, isLoading, options, downloadAirportData };
}

export function getDailyChartOptions({
  max,
  title,
}: {
  max: number;
  title: string;
}) {
  const options: ChartOptions<"line"> = {
    parsing: {
      xAxisKey: "day",
      yAxisKey: "new_pilots",
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
        max: max + 1,
      },
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "yyyy-MM-dd",
          displayFormats: {
            day: "yyyy-MM-dd",
          },
        },
      },
    },
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
      zoom: {
        zoom: {
          mode: "x",
          drag: {
            enabled: true,
          },
        },
      },
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  return options;
}

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

import premium24h from "assets/images/premium-24h.png";

import { useDashboardAirport } from "logic/weather/hooks";
import { Meta } from "components/meta/Meta";

const Forecast = () => {
  const { icao } = useParams();
  const airport = useDashboardAirport(icao).airport;
  const navigate = useNavigate();

  useEffect(() => {
    if (airport === null) {
      navigate(`/${icao}`);
    }
  });
  return (
    <StyledForecast>
      <Meta pageTitle={`${icao.toUpperCase()} Forecast`} />
      <div>
        <img src={premium24h} alt="" />
      </div>
    </StyledForecast>
  );
};

const StyledForecast = styled.div`
  margin: 100px auto;
  img {
    max-width: 100%;
  }
`;

export default Forecast;

import { Toast } from "logic/toast/types";
import { bound } from "utils/class";
import { isKeyof } from "utils/general";
import { z } from "zod";

export function describeError(error: unknown) {
  return bound({
    extractApiErrorType() {
      const parsed = z
        .object({
          data: z.object({
            error: z.string(),
          }),
        })
        .safeParse(error);

      return parsed.success ? parsed.data.data.error : undefined;
    },
    asToastMessage(): { severity: Toast["severity"]; message: Toast["message"] } | undefined {
      return this.extractApiMessageAndSeverity();
    },
    asErrorInstance() {
      if (error instanceof Error) {
        return error;
      }
      return undefined;
    },
    extractApiMessageAndSeverity() {
      const apiError = this.extractApiErrorType();

      if (apiError) {
        if (isKeyof(apiError, apiErrorMessages)) {
          return {
            severity: "error" as const,
            message: apiErrorMessages[apiError],
          };
        }
        if (isKeyof(apiError, apiErrorInfoMessages)) {
          return {
            severity: "info" as const,
            message: apiErrorInfoMessages[apiError],
          };
        }
      }

      return undefined;
    },
    formatTitle() {
      return (
        this.extractApiMessageAndSeverity()?.message ??
        this.asErrorInstance()?.name ??
        apiErrorMessages.unspecified_failure
      );
    },
    formatMessage() {
      return this.asErrorInstance()?.message;
    },
    getStack() {
      return this.asErrorInstance()?.stack;
    },
  });
}

const apiErrorMessages = {
  login_failed: "You have entered incorrect login credentials.",
  not_authenticated: "You must be logged in to perform this action.",
  invalid_file_provided: "The provided file could not be processed.",
  airport_not_found: "Could not find airport.",
  user_not_found: "Could not find user.",
  user_with_email_exists: "This e-mail address is already taken.",
  airport_banner_not_found: "Could not find airport banner.",
  insufficient_permissions: "You are not authorized to perform this action.",
  wrong_old_password: "You have entered an incorrect old password.",
  wrong_password: "You have entered an incorrect password.",
  wrong_email_verification_token: "This e-mail verification link could have been already used.",
  runway_not_found: "Could not find runway.",
  user_password_reset_request_not_found: "Could not find password reset request.",
  airport_does_not_have_subscription: "The airport does not have a subscription.",
  airport_icao_must_be_unique: "Airport ICAO must be unique.",
  could_not_fetch_weather: "Could not fetch weather.",
  weather_could_not_be_accessed: "Weather could not be accessed.",
  unspecified_failure: "Something went wrong.",
};

const apiErrorInfoMessages = {
  email_already_verified: "Your e-mail address has already been verified.",
  email_request_send_limit_reached: "Please wait for a moment before resending again.",
};

import styled from "@emotion/styled";
import { AirportNotificationIcon } from "components/airportNotificationIcon/AirportNotificationIcon";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import awLogoVertical from "assets/images/airport_weather_vertical.svg";
import iconDashboard from "assets/images/dashboardGray.svg";
import iconMap from "assets/images/map.svg";
import { awdApi } from "store/reducers/awdApi";
import { responsive } from "mixins";
import InfoIcon from "@mui/icons-material/Info";
import VideocamIcon from "@mui/icons-material/Videocam";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { iconColor } from "utils/themes";
import { BurgerIcon } from "components/_icons/BurgerIcon";
import { NavListItem } from "./NavListItem";
import { Creator } from "./Creator";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";

const Navigation = () => {
  const { icao } = useParams();
  const [activeBurger, setActiveBurger] = useState(false);

  const query = awdApi.endpoints.getAirportLinkSettings.useQuery({
    airportId: icao,
  });
  const settings = query.data;

  const RUNWAY_VIZ_APPEAR = process.env.REACT_APP_VIZ === "true";

  return (
    <StyledNavigation active={activeBurger} className={`${activeBurger ? "activeBurger" : ""}`}>
      <div className="awLogo">
        <Link to="/">
          <img src={awLogoVertical} alt="" />
        </Link>
      </div>
      <ul>
        <NavListItem
          path={icao}
          activeBurger={activeBurger}
          setActiveBurger={setActiveBurger}
          icon={<img className="image" src={iconDashboard} alt="" />}
        >
          <p>
            weather <br /> dashboard
          </p>
        </NavListItem>

        <NavListItem
          path={`${icao}/map`}
          activeBurger={activeBurger}
          setActiveBurger={setActiveBurger}
          icon={<img className="image" src={iconMap} alt="" />}
        >
          <p>
            weather map
            <br />
            in the vicinity
          </p>
        </NavListItem>

        {RUNWAY_VIZ_APPEAR && (
          <NavListItem
            path={`${icao}/runway-schema`}
            activeBurger={activeBurger}
            setActiveBurger={setActiveBurger}
            icon={<BubbleChartIcon htmlColor={iconColor} />}
          >
            <p>runway schema</p>
          </NavListItem>
        )}

        <NavListItem
          path={`${icao}/operational-updates`}
          activeBurger={activeBurger}
          setActiveBurger={setActiveBurger}
          icon={<AirportNotificationIcon icao={icao} category="operational_update" />}
        >
          <p>
            operational <br />
            updates
          </p>
        </NavListItem>

        <NavListItem
          path={`${icao}/general-information`}
          activeBurger={activeBurger}
          setActiveBurger={setActiveBurger}
          icon={<AirportNotificationIcon icao={icao} category="general_information" />}
        >
          <p>
            general <br /> information
          </p>
        </NavListItem>

        <NavListItem
          path={`${icao}/about`}
          activeBurger={activeBurger}
          setActiveBurger={setActiveBurger}
          icon={<InfoIcon htmlColor={iconColor} />}
        >
          <p>about airport</p>
        </NavListItem>

        {settings?.webcam_url != null && (
          <NavListItem
            path={`${icao}/webcam`}
            activeBurger={activeBurger}
            setActiveBurger={setActiveBurger}
            icon={<VideocamIcon htmlColor={iconColor} />}
          >
            <p>webcam</p>
          </NavListItem>
        )}

        {settings?.meteostation_url != null && (
          <NavListItem
            path={`${icao}/meteostation`}
            activeBurger={activeBurger}
            setActiveBurger={setActiveBurger}
            icon={<WaterDropIcon htmlColor={iconColor} />}
          >
            <p>meteostation</p>
          </NavListItem>
        )}
      </ul>

      <div className="brand">
        <Creator />
      </div>

      <BurgerIcon activeBurger={activeBurger} setActiveBurger={setActiveBurger} />
    </StyledNavigation>
  );
};

const StyledNavigation = styled.nav`
  overflow: auto;

  /* HAMBURGER */
  .hamburger {
    display: none;
    justify-content: center;

    align-items: center;
    position: relative;

    ${responsive.tabletM} {
      display: grid;
      grid-template-columns: 1;
      grid-template-rows: 1;
      align-items: start;
    }
  }

  .brand {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 0 0.5rem 1rem;
  }

  .defs {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .burger {
    height: 60px;
    width: 60px;

    ${responsive.mobilP} {
      height: 50px;
      width: 50px;
    }
  }
  .x {
    height: 60px;
    width: 60px;

    ${responsive.mobilP} {
      height: 50px;
      width: 50px;
    }
  }

  .burger,
  .x {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .burger {
    filter: url(#gooeyness);
  }
  .x {
    transform: scale(0);
    transition: transform 400ms;
  }
  .line {
    fill: none;
    stroke: black;
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: 50%;
    transition:
      stroke-dasharray 500ms 200ms,
      stroke-dashoffset 500ms 200ms,
      transform 400ms 100ms;

    ${responsive.mobilP} {
      stroke-width: 7px;
    }
  }
  .x .line {
    stroke-width: 4px;
  }
  .activeBurger .line {
    transition: transform 400ms;
  }
  .activeBurger .line1 {
    transform: translateX(18px) translateY(-3px) rotate(-45deg) scale(0.7);
  }
  .activeBurger .line2 {
    transform: translateX(-18px) translateY(-3px) rotate(45deg) scale(0.7);
  }
  .activeBurger .line3 {
    transform: translateY(0px) rotate(45deg) scale(0.7);
  }
  .activeBurger .line4 {
    transform: translateY(0px) rotate(-45deg) scale(0.7);
  }
  .activeBurger .line5 {
    transform: translateX(18px) translateY(3px) rotate(45deg) scale(0.7);
  }
  .activeBurger .line6 {
    transform: translateX(-18px) translateY(3px) rotate(-45deg) scale(0.7);
  }
  .activeBurger .x {
    transition: transform 400ms 100ms;
    transform: scale(1);
  }

  /* NAVIGATION GENERAL */
  text-align: center;

  height: 100vh;
  min-width: 170px;

  background-color: #f1f1f2;
  position: sticky;
  top: 0;
  z-index: 1300;

  display: flex;
  flex-direction: column;

  ${responsive.tablet} {
    flex-direction: row;
  }

  &.activeBurger {
    display: flex;
    height: 110%;
    width: 100%;
    position: fixed;

    .logo {
      align-self: flex-start;
      padding-top: 12px;
    }

    ul {
      padding-top: 50px;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;

      .linkContainer {
        display: flex;
        padding: 20px;

        &.activePath {
          border-radius: 0;
        }
      }
    }
  }

  ${responsive.tablet} {
    display: none;
  }

  ${responsive.hambrg} {
    min-width: 260px;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    margin-top: 3rem;

    ${responsive.tablet} {
      display: flex;
      margin: 0;
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  li {
    margin: 1rem 0 1rem 1rem;
    padding: 0.6rem;

    ${responsive.tablet} {
      margin: 0;
    }

    &:hover {
      background-color: white;
      cursor: pointer;
      border-radius: 1rem 0 0 1rem;
      font-weight: 500;
    }
  }

  .creator {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 0.5rem;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ${responsive.deskS} {
      font-size: 12px;
    }

    ${responsive.tablet} {
      display: none;
      margin: 0.5rem;
      margin-left: 1rem;
      align-items: center;
    }

    p {
      font-weight: 100;
      font-size: 11px;
      margin: 0.1rem;
      /* margin-left: -1.2rem; */
      margin-left: 0;
      margin-bottom: -0.2rem;

      ${responsive.deskSM} {
        font-size: 10px;
      }

      ${responsive.tablet} {
        display: none;
      }
    }

    a {
      color: #52a3e6;

      /* .image { */
      img {
        width: 4rem;

        ${responsive.deskSM} {
          width: 4rem;
        }

        ${responsive.mobilP} {
          width: 3.2rem;
        }

        ${responsive.mobilS} {
          width: 3rem;
        }
      }
    }
  }

  .isDisabled {
    /* cursor: not-allowed; */
    color: #b9b9be !important;
  }

  .logo {
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 0.5rem;

    ${responsive.deskSM} {
      font-size: 12px;
    }

    ${responsive.tablet} {
      display: flex;
      margin: 0.5rem;
      margin-left: 1rem;
      align-items: center;
    }

    p {
      font-weight: 100;
      font-size: 14px;
      margin-top: 0.1rem;

      ${responsive.deskSM} {
        font-size: 12px;
      }

      ${responsive.tablet} {
        display: none;
      }
    }

    a {
      color: #52a3e6;

      /* .image { */
      img {
        width: 8.5rem;

        ${responsive.deskSM} {
          width: 6rem;
        }

        ${responsive.mobilP} {
          width: 4.2rem;
        }

        ${responsive.mobilS} {
          width: 3.9rem;
        }
      }
    }
  }

  .awLogo {
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 0.5rem;

    ${responsive.deskSM} {
      font-size: 12px;
    }

    ${responsive.tablet} {
      display: flex;
      margin: 0.5rem;
      margin-left: 1rem;
      align-items: center;
    }

    p {
      font-weight: 100;
      font-size: 14px;
      margin-top: 0.1rem;

      ${responsive.deskSM} {
        font-size: 12px;
      }

      ${responsive.tablet} {
        display: none;
      }
    }

    a {
      color: #52a3e6;

      /* .image { */
      img {
        width: 8.5rem;

        ${responsive.deskSM} {
          width: 8.5rem;
        }

        ${responsive.tablet} {
          width: 7rem;
        }

        ${responsive.mobilP} {
          width: 6rem;
        }

        ${responsive.mobilS} {
          width: 5rem;
        }
      }
    }
  }

  .linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    font-weight: 300;
    font-size: 14px;

    &.isDisabled {
      /* cursor: not-allowed; */
    }

    ${responsive.tabletM} {
      display: none;
    }

    &.activePath {
      display: flex;
      font-weight: 600;
      background-color: #fff;
      border-radius: 1rem 0 0 1rem;

      ${responsive.tablet} {
        border-radius: 1rem 1rem 0 0;
        /* flex-grow: 1; */
      }

      &.activeBurger {
        display: flex;
      }
    }

    .link {
      color: #504b4d;
      width: 100%;

      ${responsive.tablet} {
        display: flex;
        gap: 0.5rem;
        /* width: 117px; */
      }

      ${responsive.mobilS} {
        /* width: 5.5rem; */
      }

      p {
        margin: 0;

        ${responsive.tablet} {
          text-align: left;
          margin-left: 5px;
          font-size: 13px;
        }

        ${responsive.mobilP} {
          font-size: 11px;
        }
      }
    }

    .image {
      width: 1.5rem;

      ${responsive.mobilP} {
        width: 1.2rem;
      }

      ${responsive.mobilS} {
        width: 1rem;
      }
    }
    .imageBigger {
      width: 2rem;
      ${responsive.mobilP} {
        width: 1.2rem;
      }
    }
  }
`;

export default Navigation;

import { skipToken } from "@reduxjs/toolkit/query/react";
import { describeAirportIcao } from "logic/airport/icao/describe";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchAirportQuery } from "store/reducers/awdApi";

export function useInfiniteAirportSearch() {
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [offset, setOffset] = useState(0);

  const { currentData, isLoading, isFetching } = useSearchAirportQuery(
    value === "" ? skipToken : { user_input: value, offset }
  );

  const result = value.trim() === "" ? undefined : currentData;

  return {
    offset,
    setOffset,
    value,
    setValue,
    isLoading,
    isFetching,
    result,
    onSearchResultClick: (icao: string | undefined | null) => {
      if (icao != null) {
        navigate(describeAirportIcao(icao).getWeatherUrl());
        setValue("");
      }
    },
  };
}

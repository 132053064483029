import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";

type KioskWrapperProps = { key: number };
export const KioskWrapper: FC<PropsWithChildren<KioskWrapperProps>> = (props) => {
  const [scale, setScale] = useState(1);
  const [visibility, setVisibility] = useState<"hidden" | "visible">("hidden");
  const [squareRef, { height }] = useElementSize();

  // console.log(height, "height");
  useEffect(() => {
    setTimeout(() => {
      const windowHeight = window.innerHeight;
      if (height > windowHeight) {
        const scale = windowHeight / height;
        console.log(scale, "scale");
        setScale(scale);
      }
      setVisibility("visible");
    }, 50);
  }, [height, props.key]);

  return (
    <div
      ref={squareRef}
      css={{
        transformOrigin: "top",
        transform: `scale(${scale})`,
        visibility: visibility,
      }}
    >
      {props.children}
    </div>
  );
};

import { describeMutationResult } from "logic/api/result/describe";
import { LoginForm } from "logic/login/form";
import { queryMobileAppConfig } from "logic/mobileApp/isMobileApp";
import { postMobileAppMessage } from "logic/mobileApp/postMobileAppMessage";
import { NavigateFunction } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { createAsyncThunk } from "store/thunk";

export const onLoginFormSubmitted = createAsyncThunk(
  "onLoginFormSubmitted",
  async (
    args: {
      values: LoginForm;
      navigate: NavigateFunction;
      next?: string | null;
    },
    ctx
  ) => {
    const result = await ctx
      .dispatch(awdApi.endpoints.login.initiate(args.values))
      .then(describeMutationResult);

    if (result.error) {
      ctx.dispatch(toastError(result.error, "Failed to login."));
      return;
    }

    const { isMobileApp } = await queryMobileAppConfig(ctx.dispatch);

    if (isMobileApp()) {
      const authTokenResult = await ctx
        .dispatch(awdApi.endpoints.postMobileAppAuthenticationToken.initiate())
        .then(describeMutationResult);

      if (!authTokenResult.error) {
        postMobileAppMessage({
          type: "logged_in",
          authToken: authTokenResult.data.token,
        });
      }
    }

    ctx.dispatch(toastMessage("success", "Logged in."));
  }
);

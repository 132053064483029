import React, { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

type SignupOfferButtonProps = {
  url: string;
  variant: "outlined" | "contained";
};
export const SignupOfferButton: FC<
  PropsWithChildren<SignupOfferButtonProps>
> = ({ children, url, variant }) => {
  const navigate = useNavigate();
  return (
    <Button
      color="info"
      variant={variant}
      size="small"
      onClick={() => {
        navigate(url);
      }}
      sx={{
        width: "100%",
        fontSize: "12px",
        height: "100%",
        paddingLeft: "2px",
        paddingRight: "2px",
      }}
    >
      {children}
    </Button>
  );
};

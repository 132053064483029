import { Link } from "@mui/material";
import { ArticlePage } from "components/content/ArticlePage";
import { Stack } from "components/layout/Stack";
import { pageSpec } from "logic/navigation/spec";
import { FC } from "react";
import { Meta } from "components/meta/Meta";

export type DocumentsPageProps = {};

export const DocumentsPage: FC<DocumentsPageProps> = (props) => {
  return (
    <ArticlePage>
      <Meta pageTitle="Documents" />
      <h1>Terms</h1>
      <Stack gap="1rem">
        <Link href={pageSpec.terms_of_service.path}>Terms of Service</Link>
        <Link href={pageSpec.privacy_policy.path}>Privacy Policy</Link>
        <Link href={pageSpec.cookie_policy.path}>Cookie Policy</Link>
        <Link href={pageSpec.disclaimer.path}>Disclaimer</Link>
      </Stack>
    </ArticlePage>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
  CEILING_FORMATS,
  PRESSURE_FORMATS,
  SPEED_FORMATS,
  TEMPERATURE_FORMATS,
  TIME_FORMATS,
  VISIBILITY_FORMATS,
  zUserSettingsRequest,
} from "awd-server-api";
import { FormToggleButtonGroup } from "components/formToggleButtonGroup/FormToggleButtonGroup";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { SettingsGroup } from "components/settings/group/SettingsGroup";
import { SettingsItem } from "components/settings/item/SettingsItem";
import { errorHandled } from "logic/error/handle";
import { useMySettings } from "logic/user/me/settings/hooks";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { entriesOf } from "utils/general";
import { useLatestCallback, useRunOnceAllValuesAreDefined } from "utils/hooks";
import { safeParse } from "utils/parse";
import { z } from "zod";

export type UserSettingsPageProps = {};

function unitOption(value: string) {
  return { value, label: value.replace(/_/g, " ").replace(/^deg/, "°") };
}

export const UserSettingsPage: FC<UserSettingsPageProps> = (props) => {
  const mySettings = useMySettings();
  const form = useForm<FormValues>({ resolver: zodResolver(zFormValues) });
  const [hasFinishedInit, setHasFinishedInit] = useState(false);

  const onFormChange = useLatestCallback(
    errorHandled(async (values: Partial<FormValues>) => {
      const parsed = safeParse(zUserSettingsRequest, values);
      if (hasFinishedInit && parsed) {
        await mySettings.mutate(parsed);
      }
    }, "Failed to update settings.")
  );

  useRunOnceAllValuesAreDefined(
    [mySettings.settings] as const,
    ([settings]) => {
      entriesOf(settings).forEach(([key, value]) => {
        form.setValue(key, value);
      });
      setHasFinishedInit(true);
    }
  );

  useEffect(() => {
    const { unsubscribe } = form.watch((values) => onFormChange(values));
    return () => unsubscribe();
  }, [form.watch, onFormChange]);

  return (
    <InsidePageLayout>
      <Meta pageTitle="User Settings" />
      <Stack gap="1rem" horizontal css={{ justifyContent: "space-between" }}>
        <InsidePageTitle>User Settings</InsidePageTitle>
      </Stack>
      <Spacer size="1.5rem" />
      <SettingsGroup title="Time">
        <SettingsItem
          title="Format"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="time_format"
              options={TIME_FORMATS.map(unitOption)}
            />
          }
        />
      </SettingsGroup>
      <Spacer size="1.5rem" />
      <SettingsGroup title="Units">
        <SettingsItem
          title="Visibility"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="visibility_unit"
              options={VISIBILITY_FORMATS.map(unitOption)}
            />
          }
        />
        <SettingsItem
          title="Pressure"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="pressure_unit"
              options={PRESSURE_FORMATS.map(unitOption)}
            />
          }
        />
        <SettingsItem
          title="Temperature"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="temperature_unit"
              options={TEMPERATURE_FORMATS.map(unitOption)}
            />
          }
        />
        <SettingsItem
          title="Ceiling"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="ceiling_unit"
              options={CEILING_FORMATS.map(unitOption)}
            />
          }
        />
        <SettingsItem
          title="Speed"
          button={false}
          actions={
            <FormToggleButtonGroup
              exclusive
              form={form}
              size="small"
              name="speed_unit"
              options={SPEED_FORMATS.map(unitOption)}
            />
          }
        />
      </SettingsGroup>

      <Spacer size="1.5rem" />
    </InsidePageLayout>
  );
};

const zFormValues = zUserSettingsRequest;
type FormValues = z.infer<typeof zFormValues>;

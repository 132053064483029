import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { PatchAirportBody } from "awd-server-api";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useResolveOnceDefined } from "utils/hooks";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

export type EditAirportIdentifierModalProps = {
  icao: string;
  icaoIsEditable?: boolean;
};

const zForm = z.object({
  name: z.string().nonempty({
    message: "Please provide the airport name.",
  }),
  icao: z.string().optional(),
  icaoAlias: z.string(),
});

type Form = z.infer<typeof zForm>;

function preparePatchBody(
  values: Form,
  icaoIsEditable: boolean
): Pick<PatchAirportBody, keyof Form> {
  return {
    name: values.name,
    icaoAlias: values.icaoAlias,
    ...(icaoIsEditable && { icao: values.icao }),
  };
}

export const EditAirportIdentifierModal: FC<EditAirportIdentifierModalProps> = (props) => {
  const icaoIsEditable = !!props.icaoIsEditable;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const airport = awdApi.endpoints.getAirport.useQuery({
    airportId: props.icao,
  }).data;
  const airportPromise = useResolveOnceDefined(airport);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      airportPromise.then((airport) => ({
        name: airport.name ?? "",
        icao: airport?.icao ?? "",
        icaoAlias: airport.icaoAlias ?? "",
      })),
  });

  return (
    <ResultModal
      title="Edit airport"
      onSubmit={(cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.patchAirport.initiate({
              airportId: props.icao,
              body: preparePatchBody(body, icaoIsEditable),
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(toastError(result.error, "We could not update the airport."));
            cancel();
            return;
          } else {
            dispatch(toastMessage("success", "The airport has been updated."));
            if (result.data?.newIcao) {
              navigate(`/${result.data.newIcao}/edit`);
            }
            return;
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        {!form.formState.isLoading && (
          <Stack gap="1rem">
            <FormTextField form={form} name="name" label="Airport Name" />
            {icaoIsEditable && <FormTextField form={form} name="icao" label="Icao" />}
            <FormTextField form={form} name="icaoAlias" label="ICAO Alias" />
          </Stack>
        )}
      </DialogContent>
    </ResultModal>
  );
};

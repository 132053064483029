import { UserSettingsResponse } from "awd-server-api";
import { bound } from "utils/class";

export function describeSettings(settings: UserSettingsResponse) {
  return bound({
    settings,
  });
}

export function describeSettingsOrDefault(
  settings: UserSettingsResponse | undefined
) {
  return describeSettings(settings ?? defaultSettings());
}

export function defaultSettings(): UserSettingsResponse {
  return {
    temperature_unit: "degC",
    speed_unit: "kt",
    visibility_unit: "km",
    time_format: "24h",
    ceiling_unit: "ft",
    pressure_unit: "hPa",
  };
}

import { useEffect } from "react";
import { isIos } from "utils/general";

export function usePreventGestureZooming(enabled = true) {
  useEffect(() => {
    if (!enabled) return;
    const listener = (e: TouchEvent) => {
      if ((e as { scale?: number }).scale !== 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", listener, { passive: false });

    return () => document.removeEventListener("touchmove", listener);
  }, [enabled]);
}

export function usePreventGestureZoomingOnIos() {
  usePreventGestureZooming(isIos());
}

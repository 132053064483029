import { Alert, Snackbar } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "store/hooks";
import { selToast, toastSlice } from "store/reducers/toastSlice";

export type ToastProviderProps = PropsWithChildren<{}>;

export const ToastProvider: FC<ToastProviderProps> = (props) => {
  const dispatch = useDispatch();
  const { activeToast, previousToast } = useSelector(selToast);
  const toast = activeToast ?? previousToast;
  return (
    <>
      {props.children}
      <Snackbar
        open={activeToast != null}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        autoHideDuration={3e3}
        onClose={(...args) => {
          dispatch(toastSlice.actions.closeToast());
        }}
      >
        {toast && (
          <Alert severity={toast.severity} variant="filled">
            {toast.message}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

import { ResultModal } from "components/modal/inside/ResultModal";
import { Button, Checkbox, DialogContent, FormControlLabel, FormGroup } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { matchIsValidTel } from "mui-tel-input";
import { Stack } from "components/layout/Stack";
import { FormTextField } from "components/field/text/FormTextField";
import { FormPhoneNumberField } from "components/field/phoneNumber/FormPhoneNumberField";
import { awdApi } from "store/reducers/awdApi";
import { useResolveOnceDefined } from "utils/hooks";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { describeMutationResult } from "logic/api/result/describe";
import { useModalContext } from "components/modal/inside/ModalProvider";

const zForm = z.object({
  email: z.string().email({
    message: "Please provide a valid e-mail address.",
  }),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  phone_number: z.string().refine(
    (text) => {
      return text === "" || matchIsValidTel(text);
    },
    {
      message: "Please provide a valid phone number.",
    }
  ),
  internal_account: z.boolean(),
});
type EditUserForm = z.infer<typeof zForm>;

export const EditUserModal = ({ userId }: { userId: number }) => {
  const { modalContext } = useModalContext();

  const dispatch = useDispatch();

  const user = awdApi.endpoints?.getUser.useQuery({ userId }).data;
  const userPromise = useResolveOnceDefined(user);

  const form = useForm<EditUserForm>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      userPromise.then((user) => ({
        email: user.email,
        first_name: user.first_name ?? "",
        last_name: user.last_name ?? "",
        phone_number: user.phone_number ?? "",
        internal_account: user.internal_account ?? false,
      })),
  });

  const onSuccess: SubmitHandler<EditUserForm> = async (data) => {
    const body = {
      email: data.email,
      first_name: data.first_name || null,
      last_name: data.last_name || null,
      phone_number: data.phone_number || null,
      internal_account: data.internal_account || false,
    };

    const result = await dispatch(
      awdApi.endpoints.patchUser.initiate({
        userId,
        body,
      })
    ).then(describeMutationResult);

    if (result.error) {
      dispatch(toastError(result.error, "We could not update the user."));
      return;
    } else {
      dispatch(toastMessage("success", "The user is updated."));
    }
  };

  const closeModal = () => {
    form.reset();
    modalContext?.close();
  };
  const values = form.watch();
  console.log(values, "vals");

  return (
    <ResultModal title="Edit User" submitButton={{ hidden: true }} cancelButton={{ hidden: true }}>
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <form
          onSubmit={form.handleSubmit(onSuccess)}
          css={{
            maxWidth: "min(100%, 20rem)",
            width: "100%",
            margin: "auto",
            paddingTop: "2rem",
          }}
        >
          <FormGroup>
            <Stack gap="1rem">
              <FormTextField
                form={form}
                name="email"
                label="E-mail"
                type="email"
                InputLabelProps={{ shrink: true }}
              />
              <FormTextField
                form={form}
                name="first_name"
                label="First name"
                InputLabelProps={{ shrink: true }}
              />
              <FormTextField
                form={form}
                name="last_name"
                label="Last name"
                InputLabelProps={{ shrink: true }}
              />
              <FormPhoneNumberField
                form={form}
                name="phone_number"
                label="Phone number"
                InputLabelProps={{ shrink: true }}
              />
              <Controller
                control={form.control}
                name="internal_account"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Internal Account"
                  />
                )}
              />
            </Stack>
          </FormGroup>
          <FormGroup sx={{ mt: "2rem" }}>
            <Stack gap="1rem" horizontal css={{ justifyContent: "flex-end" }}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type="submit" variant="contained" size="large">
                Edit User
              </Button>
            </Stack>
          </FormGroup>
        </form>
      </DialogContent>
    </ResultModal>
  );
};

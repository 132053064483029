import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as rawCreateAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "store/store";

type AppThunkApiConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra: unknown;
  rejectValue?: unknown;
  serializedErrorType?: SerializedError;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export function createAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    AppThunkApiConfig
  >,
  options?: AsyncThunkOptions<ThunkArg, AppThunkApiConfig>
): AsyncThunk<Returned, ThunkArg, AppThunkApiConfig> {
  return rawCreateAsyncThunk<Returned, ThunkArg, AppThunkApiConfig>(
    typePrefix,
    payloadCreator,
    {
      ...options,
    }
  );
}

import { awdApi } from "store/reducers/awdApi";
import { describeMyAirportRolesQuery } from "logic/user/me/role/describe";
import { describeUserMeQuery } from "logic/user/me/describe";
import { useFavoriteAirports } from "logic/user/me/pilot/favoriteAirport/hooks";

export type HomeAirportLatLongType = {
  isLoggedIn: boolean;
  isLoading: boolean;
  homeAirport?: [number, number];
};

export function useMyHomeAirportLatLong(): HomeAirportLatLongType {
  const { isLoggedIn, isLoading: isLoggedInLoading } = describeUserMeQuery(
    awdApi.endpoints.getUserMe.useQuery()
  );

  const favoriteAirportsQuery = useFavoriteAirports();
  const homeAirportPilotLatLong = favoriteAirportsQuery.homeAirport()?.latlong();

  const { isLoading: isHomeAirportManagerLoading, managedAirport } = describeMyAirportRolesQuery(
    awdApi.endpoints.getMyAirportRoles.useQuery()
  );

  const homeAirportManagerLatLong = managedAirport()?.latlong();

  const isLoading =
    isHomeAirportManagerLoading || favoriteAirportsQuery.query.isLoading || isLoggedInLoading;

  if (isLoading) {
    return {
      isLoading: true,
      isLoggedIn: false,
      homeAirport: undefined,
    };
  }

  if (isLoggedIn())
    return {
      isLoggedIn: true,
      homeAirport: homeAirportManagerLatLong ?? homeAirportPilotLatLong,
      isLoading: false,
    };

  return {
    isLoggedIn: false,
    homeAirport: undefined,
    isLoading: false,
  };
}

import styled from "@emotion/styled";

import { useSelector } from "react-redux";

import { showCeiling } from "utils/global.functions";
import Ceiling from "./Ceiling";

import { useTheme } from "@emotion/react";
import { describeUnitValue } from "logic/unit/describe";
import { useMySettings } from "logic/user/me/settings/hooks";
import { useAirportWeather } from "logic/weather/hooks";
import { RootState } from "store/store";

const WeatherNowCeiling = () => {
  const settings = useMySettings().describeOrDefault();
  const theme = useTheme();

  const toggledToAw = useSelector(
    (state: RootState) => state.localState.toggledToAwMetarWeatherSource
  );
  const report = useAirportWeather().report();
  const source = report?.selectMetarSource({ toggledToAw });
  const ceiling = source?.getCurrentQuantity("ceiling_agl");
  const cloudCoverOctas = source?.getCloudCoverOctas();

  let ceilingMeaning = ceiling ? ceiling.meaning ?? "CEILING" : "\u00A0";
  let cloudCoverFormat = source?.formatCloudCover() ?? "\u00A0";

  let ceilingShow = false;
  let ceilingText = "" as string | undefined;
  let cloudShow = true;
  let sideCloudShow = false;
  let sideCloudDashed = false;

  if (cloudCoverOctas || cloudCoverOctas === 0) {
    if (cloudCoverOctas === 0) {
      ceilingMeaning = "NO CEILING";
      cloudShow = false;
    } else if (cloudCoverOctas <= 4) {
      ceilingMeaning = "NO CEILING";
      cloudShow = false;
      sideCloudShow = true;
    } else {
      ceilingMeaning = "CEILING";
    }
  }

  if (cloudShow && ceiling && showCeiling(ceiling)) {
    ceilingShow = true;
    ceilingText = describeUnitValue(ceiling)
      ?.convertToUnit(settings.settings.ceiling_unit, true)
      ?.format({ postprocess: "floor10" });
  }

  if (!toggledToAw && report && report?.getTextReport("metar")?.includes("CAVOK")) {
    ceilingMeaning = "CAVOK";
    cloudCoverFormat = "";
    ceilingShow = false;
    cloudShow = false;
    sideCloudShow = true;
    sideCloudDashed = true;
  }

  return (
    <StyledWeatherNowCeiling alert={ceilingMeaning}>
      <div css={{ textAlign: "right", marginBottom: "1.5rem", fontWeight: 300 }}>ceiling</div>
      <SMeaningText
        css={{
          color: ceiling?.meaning === "no data" ? "red" : theme.primary,
        }}
      >
        {ceilingMeaning}
      </SMeaningText>
      <div css={{ textAlign: "center", fontWeight: 300, minHeight: "3em" }}>{cloudCoverFormat}</div>
      <div css={{ flexGrow: 1 }} />
      <SGraphic>
        <div className="level" css={{ fontWeight: 300 }}>
          ground
        </div>
        <Ceiling
          ceilingShow={ceilingShow}
          cloudShow={cloudShow}
          sideCloudShow={sideCloudShow}
          sideCloudDashed={sideCloudDashed}
          ceilingText={ceilingText}
          theme={theme}
          index={0}
        />
      </SGraphic>
    </StyledWeatherNowCeiling>
  );
};

const SMeaningText = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  /* margin-bottom: 2.5rem; */
`;

const StyledWeatherNowCeiling = styled.div<{ alert: string }>`
  /* style css */
  border: 1px solid #58585b96;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 0 1rem;

  background-color: #f6f7f7;

  font-weight: 100;
  display: flex;
  flex-direction: column;
`;

const SGraphic = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: end;
  align-self: center;

  padding-bottom: 1rem;

  font-size: 14px;
  position: relative;
`;

export default WeatherNowCeiling;

import { responsive } from "mixins";
import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

type ModalProps = {
  showModal: boolean;
  onClose: () => void;
  blur?: boolean;
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  showModal,
  onClose,
  blur,
  children,
}) => {
  return (
    <StyledModal showModal={showModal} onClick={() => onClose()} blur={!!blur}>
      <div className="outline" onClick={(event) => event.stopPropagation()}>
        <button className="close" onClick={() => onClose()}>
          &times;
        </button>

        <div className="content">{children}</div>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div<{ showModal: boolean; blur: boolean }>`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 10000;

  background-color: rgba(0, 0, 0, 0.4);
  ${(p) => (p.blur ? { backdropFilter: "blur(0.25rem)" } : undefined)}

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  /* display: flex; */

  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  .outline {
    width: 50%;
    /* height: 50%; */
    background-color: aliceblue;
    position: relative;
    border-radius: 8px;
    margin-top: 10vh;

    ${responsive.hambrg} {
      width: 70%;
    }

    ${responsive.forecast} {
      width: 80%;
    }
    ${responsive.mobilP} {
      width: 90%;
    }

    .content {
      padding: 3rem;
      padding-top: 0;
      margin-top: 3rem;
      max-height: 70vh;
      overflow-y: auto;

      ${responsive.forecast} {
        padding: 2.5rem;
      }
      ${responsive.mobilP} {
        padding: 1.5rem;
      }

      p {
        margin: 0;
        margin-bottom: 1.5rem;
        margin-top: 0.2rem;
        line-height: 1.33;
        font-size: 12pt;

        ${responsive.mobilP} {
          font-size: 10pt;
        }
      }
    }
  }

  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 30px;
    font-weight: bold;

    border: none;
    cursor: pointer;
    background-color: transparent;
  }
`;

export const ModalTitle = styled.h3`
  font-size: 1.5rem;
  line-height: 2;
  margin: 0;
`;

export const ModalSubtitle = styled.h4`
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0 0 0.5rem;
`;

export default Modal;

// import { WeatherNowWind } from "components/Dashboard/weatherNow/WeatherNowWind";
import { WeatherNowWindCard } from "components/Dashboard/weatherNow/WeatherNowWindCard";
import { ErrorAlert } from "components/errorAlert/ErrorAlert";
import { WidgetParameters } from "components/widget/Widget";
import { useDashboardAirport } from "logic/weather/hooks";
import { FC } from "react";
import { useObserveElementSize } from "utils/hooks";

export type WeatherWidgetWindProps = WidgetParameters;

export const WeatherWidgetWind: FC<WeatherWidgetWindProps> = (props) => {
  const airport = useDashboardAirport().transform?.();
  const windParams = props.variantConfiguration?.wind;

  const runway =
    (windParams?.runwayIdentifier
      ? airport?.selectRunwayWithIdentifier(windParams?.runwayIdentifier)
      : undefined) ?? airport?.getFirstRunway();

  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        padding: "1rem 0",
      }}
      ref={ref}
    >
      {width != null && width < 250 ? (
        <ErrorAlert title="This widget variant requires at least 250 wide space." />
      ) : (
        <div
          css={{
            minWidth: "15.625rem",
            width: "22rem",
          }}
        >
          <WeatherNowWindCard runway={runway} />
        </div>
      )}
    </div>
  );
};

import { Button, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { FC, PropsWithChildren, ReactNode } from "react";

export type SettingsItemProps = PropsWithChildren<{
  title: ReactNode;
  button?: "edit" | "delete" | false;
  onClick?: () => void;
  actions?: ReactNode;
}>;

export const SettingsItem: FC<SettingsItemProps> = (props) => {
  return (
    <Stack
      gap="1rem"
      horizontal
      css={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Stack gap="0.25rem">
        <Typography variant="subtitle2">{props.title}</Typography>
        <Typography variant="body1">{props.children}</Typography>
      </Stack>
      <Stack horizontal gap="0.5rem" css={{ alignItems: "center" }}>
        {props.actions}
        {props.button !== false && (
          <Button
            color={props.button === "delete" ? "error" : "primary"}
            onClick={props.onClick}
          >
            {props.button ?? "edit"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

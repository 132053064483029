import { zWeatherReport } from "./reportTypes";
import { z } from "zod";

export const zMapSessionResponse = z.object({
  token: z.string(),
  issued_at: z.number(),
});

export type MapSessionResponse = z.infer<typeof zMapSessionResponse>;

export const zSignupRequest = z.object({
  signup_as: z.enum(["pilot", "airport"]),
  email: z.string().email(),
  phone_number: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  airport: z.discriminatedUnion("specified_by", [
    z.object({ specified_by: z.literal("id"), id: z.number().int() }),
    z.object({ specified_by: z.literal("name"), name: z.string() }),
  ]),
  password: z.string(),
});

export type SignupRequest = z.infer<typeof zSignupRequest>;

export const zUserEditRequest = z.object({
  email: z.string().email(),
  phone_number: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  internal_account: z.boolean().nullish(),
});
export type UserEditRequest = z.infer<typeof zUserEditRequest>;

export const zResetPasswordRequest = z.object({
  email: z.string().email(),
});
export type ResetPasswordRequest = z.infer<typeof zResetPasswordRequest>;

export const zCompletePasswordResetRequest = z.object({
  token: z.string(),
  password: z.string(),
});
export type CompletePasswordResetRequest = z.infer<
  typeof zCompletePasswordResetRequest
>;

export const zLoginRequest = z.object({
  email: z.string().email(),
  password: z.string(),
});
export type LoginRequest = z.infer<typeof zLoginRequest>;

export const zResultResponse = z.object({
  result: z.string(),
});
export type ResultResponse = z.infer<typeof zResultResponse>;

export const zEmailVerificationRequestResponse = z.object({
  request: z
    .object({
      id: z.number(),
      user_id: z.number(),
      email: z.string(),
      status: z.enum(["verified", "requested"]),
      source: z.enum([
        "user_signed_up_as_airport",
        "user_signed_up_as_pilot",
        "resend",
        "change_email",
      ]),
    })
    .nullish(),
});
export type EmailVerificationRequestResponse = z.infer<
  typeof zEmailVerificationRequestResponse
>;

export const zConfirmEmailRequest = z.object({
  token: z.string(),
});

export type ConfirmEmailRequest = z.infer<typeof zConfirmEmailRequest>;

export const zUserResponse = z.object({
  id: z.number(),
  email: z.string(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  phone_number: z.string().nullish(),
  internal_account: z.boolean().nullish(),
});
export type UserResponse = z.infer<typeof zUserResponse>;

export const zUserListingResponse = z.object({
  users: z.array(zUserResponse),
  count: z.number(),
});

export type UserListingResponse = z.infer<typeof zUserListingResponse>;

export const zMyEmailVerificationRequestResponse = z.object({
  request: z
    .object({
      id: z.number(),
      user_id: z.number(),
      email: z.string(),
      status: z.enum(["verified", "requested"]),
      source: z.enum([
        "user_signed_up_as_airport",
        "user_signed_up_as_pilot",
        "resend",
        "change_email",
      ]),
    })
    .nullish(),
});

export type MyEmailVerificationRequestResponse = z.infer<
  typeof zMyEmailVerificationRequestResponse
>;

export const zRunwayResponse = z.object({
  id: z.number(),
  length: z.number().nullish(),
  width: z.number().nullish(),
  surface: z.string().nullish(),
  lighted: z.boolean().nullish(),
  closed: z.boolean().nullish(),
  leIdent: z.string().nullish(),
  leLatitude: z.number().nullish(),
  leLongitude: z.number().nullish(),
  leElevation: z.number().nullish(),
  leHeading: z.number().nullish(),
  leDisplacedThreshold: z.number().nullish(),
  heIdent: z.string().nullish(),
  heLatitude: z.number().nullish(),
  heLongitude: z.number().nullish(),
  heElevation: z.number().nullish(),
  heHeading: z.number().nullish(),
  heDisplacedThreshold: z.number().nullish(),
});
export type RunwayResponse = z.infer<typeof zRunwayResponse>;

export const zRunwayLongView = zRunwayResponse.pick({
  leElevation: true,
  heElevation: true,
  closed: true,

  leHeading: true,
  leIdent: true,
  leLongitude: true,
  leLatitude: true,

  heHeading: true,
  heIdent: true,
  heLongitude: true,
  heLatitude: true,
});
export type RunwayLongView = z.infer<typeof zRunwayLongView>;

export const zAirportNameResponse = z.object({
  name: z.string(),
});
export type AirportNameResponse = z.infer<typeof zAirportNameResponse>;

export const zAirportResponse = z.object({
  id: z.number(),
  icao: z.string(),
  name: z.string(),
  metarStation: z.boolean().nullish(),
  isoCountry: z.string().nullish(),
  continent: z.string().nullish(),
  latitude: z.number(),
  longitude: z.number(),
  closed: z.boolean().nullish(),
  contactEmail: z.string().nullish(),
  contactPhone: z.string().nullish(),
  icaoAlias: z.string().nullish(),
  runways: z.array(zRunwayLongView).nullish(),
  homeLink: z.string().nullish(),
  wikipediaLink: z.string().nullish(),
  type: z.string().nullish(),
});
export type AirportResponse = z.infer<typeof zAirportResponse>;

export const zAllAirportsResponse = z.array(zAirportResponse);

export const zAirportRunwaysResponse = z.object({
  runways: z.array(zRunwayResponse),
});
export type AirportRunwaysResponse = z.infer<typeof zAirportRunwaysResponse>;

export const zAirportSubscriptionResponse = z.object({
  airport_id: z.number(),
});
export type AirportSubscriptionResponse = z.infer<
  typeof zAirportSubscriptionResponse
>;

export const zAirportSubscriptionStatusResponse = z
  .object({
    hasSubscription: z.boolean(),
    icao: z.string(),
  })
  .partial();

export type AirportSubscriptionStatusResponse = z.infer<
  typeof zAirportSubscriptionStatusResponse
>;

export const zMyAirportRolesResponse = z.object({
  roles: z.array(
    z.object({
      airport_id: z.number(),
      role: z.enum(["manager"]),
      created_at: z.string(),
      updated_at: z.string(),
      airport: zAirportResponse,
      airport_subscription: zAirportSubscriptionResponse.nullish(),
    })
  ),
});
export type MyAirportRolesResponse = z.infer<typeof zMyAirportRolesResponse>;

export const zAirportRoleRequestStatus = z.enum([
  "requested",
  "resolved",
  "declined",
]);
export type AirportRoleRequestStatus = z.infer<
  typeof zAirportRoleRequestStatus
>;

export const zMyAirportRoleRequestsResponse = z.object({
  requests: z.array(
    z.object({
      id: z.number(),
      status: zAirportRoleRequestStatus,
      airport_id: z.number().nullish(),
      airport_name: z.string().nullish(),
      role: z.enum(["manager"]),
      created_at: z.string(),
      updated_at: z.string(),
      User: zUserResponse,
      airport: zAirportResponse
        .pick({
          id: true,
          icao: true,
          name: true,
          latitude: true,
          longitude: true,
          homeLink: true,
          wikipediaLink: true,
        })
        .nullish(),
    })
  ),
});
export type MyAirportRoleRequestsResponse = z.infer<
  typeof zMyAirportRoleRequestsResponse
>;

export const zAirportSpecification = z.discriminatedUnion(
  "specified_by",
  [
    z.object({
      specified_by: z.literal("id"),
      id: z.union([z.number().int(), z.string().nonempty()]),
    }),
    z.object({ specified_by: z.literal("name"), name: z.string() }),
  ],
  { required_error: "Please select an airport or add a new one." }
);
export type AirportSpecification = z.infer<typeof zAirportSpecification>;

export const zAirportSearchResponse = z.object({
  airports: z.array(zAirportResponse),
});

export type AirportSearchResponse = z.infer<typeof zAirportSearchResponse>;

export const zPostAirportBody = z.object({
  icao: z.string().nullish(),
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});
export type PostAirportBody = z.infer<typeof zPostAirportBody>;

export const zPatchAirportBody = z.object({
  name: z.string().optional(),
  icao: z.string().optional(),
  icaoAlias: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  contactEmail: z.string().email().nullish(),
  contactPhone: z.string().nullish(),
});
export type PatchAirportBody = z.infer<typeof zPatchAirportBody>;

export const zPostRunwayBody = z.object({
  length: z.number().nullable().describe("Length in meters"),
  width: z.number().nullable().describe("Width in meters"),
  lighted: z.boolean().nullable(),
  closed: z.boolean().nullable(),
  surface: z.string().nullable(),

  leIdent: z.string().nullable(),
  heIdent: z.string().nullable(),

  leHeading: z.number().nullable(),
  heHeading: z.number().nullable(),

  leElevation: z.number().nullable(),
  heElevation: z.number().nullable(),

  leLatitude: z.number().nullable(),
  heLatitude: z.number().nullable(),

  leLongitude: z.number().nullable(),
  heLongitude: z.number().nullable(),

  leDisplacedThreshold: z.number().nullable(),
  heDisplacedThreshold: z.number().nullable(),
});
export type PostRunwayBody = z.infer<typeof zPostRunwayBody>;

export const zPatchRunwayBody = zPostRunwayBody.partial();
export type PatchRunwayBody = z.infer<typeof zPatchRunwayBody>;

export const zAirportWeatherResponse = z
  .object({
    icao: z.string(),
    isOverAccessLimit: z.boolean(),
    canShowKiosk: z.boolean(),
    weather: zWeatherReport,
  })
  .partial();

export type AirportWeatherResponse = z.infer<typeof zAirportWeatherResponse>;

export const zAirportNotification = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  category: z.enum(["operational_update", "general_information"]),
  created_at: z.string(),
});
export type AirportNotification = z.infer<typeof zAirportNotification>;

export const zAirportNotificationsResponse = z.object({
  notifications: z.array(zAirportNotification),
});
export type AirportNotificationsResponse = z.infer<
  typeof zAirportNotificationsResponse
>;

export const zMobileAppAuthenticationTokenLoginBody = z.object({
  token: z.string(),
});
export type MobileAppAuthenticationTokenLoginBody = z.infer<
  typeof zMobileAppAuthenticationTokenLoginBody
>;

export const zMobileAppAuthenticationTokenResponse = z.object({
  token: z.string(),
});
export type MobileAppAuthenticationTokenResponse = z.infer<
  typeof zMobileAppAuthenticationTokenResponse
>;

export const zPatchUserRequest = z.object({
  email: z.string().email().optional(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  phone_number: z.string().nullish(),
  password: z.string().optional(),
  old_password: z.string().optional(),
});

export type PatchUserRequest = z.infer<typeof zPatchUserRequest>;

export const zDeleteUserMeRequest = z.object({
  password: z.string(),
});

export type DeleteUserMeRequest = z.infer<typeof zDeleteUserMeRequest>;

export const zFavoriteAirportResponse = z.object({
  id: z.number(),
  user_id: z.number(),
  airport_id: z.number(),
  airport: zAirportResponse,
  is_home_airport: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
});
export type FavoriteAirportResponse = z.infer<typeof zFavoriteAirportResponse>;

export const zPutFavoriteAirportsRequest = z.object({
  favorite_airports: z.array(
    z.object({
      airport_id: z.number(),
      is_home_airport: z.boolean(),
    })
  ),
});
export type PutFavoriteAirportsRequest = z.infer<
  typeof zPutFavoriteAirportsRequest
>;

export const zUserRole = z.enum([
  "manager",
  "pilot",
  "supervizor",
  "superdemo",
]);

export type UserRoleEnums = z.infer<typeof zUserRole>;

export const zUserRolesResponse = z.object({ roles: z.array(zUserRole) });
export type UserRolesResponse = z.infer<typeof zUserRolesResponse>;

export const TIME_FORMATS = ["24h", "12h"] as const;
export const zTimeFormat = z.enum(TIME_FORMATS);
export type TimeFormat = z.infer<typeof zTimeFormat>;

export const VISIBILITY_FORMATS = ["km", "mile", "nautical_mile"] as const;
export const zVisibilityUnit = z.enum(VISIBILITY_FORMATS);
export type VisibilityUnit = z.infer<typeof zVisibilityUnit>;

export const ALTITUDE_FORMATS = ["ft"] as const;
export const zAltitudeUnit = z.enum(ALTITUDE_FORMATS);
export type AltitudeUnit = z.infer<typeof zAltitudeUnit>;

export const PRESSURE_FORMATS = ["hPa", "inHg", "Pa"] as const;
export const zPressureUnit = z.enum(PRESSURE_FORMATS);
export type PressureUnit = z.infer<typeof zPressureUnit>;

export const TEMPERATURE_FORMATS = ["degC", "degF"] as const;
export const zTemperatureUnit = z.enum(TEMPERATURE_FORMATS);
export type TemperatureUnit = z.infer<typeof zTemperatureUnit>;

export const CEILING_FORMATS = ["ft", "m"] as const;
export const zCeilingUnit = z.enum(CEILING_FORMATS);
export type CeilingUnit = z.infer<typeof zCeilingUnit>;

export const SPEED_FORMATS = ["km/h", "m/s", "kt", "mile/h"] as const;
export const zSpeedUnit = z.enum(SPEED_FORMATS);
export type SpeedUnit = z.infer<typeof zSpeedUnit>;

export const zUserSettingsResponse = z.object({
  time_format: zTimeFormat,
  visibility_unit: zVisibilityUnit,
  pressure_unit: zPressureUnit,
  temperature_unit: zTemperatureUnit,
  ceiling_unit: zCeilingUnit,
  speed_unit: zSpeedUnit,
});
export type UserSettingsResponse = z.infer<typeof zUserSettingsResponse>;

export const zUserSettingsRequest = zUserSettingsResponse;

export type UserSettingsRequest = z.infer<typeof zUserSettingsRequest>;

export const zAirportLinkSettingsResponse = z.object({
  meteostation_url: z.string().nullable(),
  webcam_url: z.string().nullable(),
});
export type AirportLinkSettingsResponse = z.infer<
  typeof zAirportLinkSettingsResponse
>;

export const zAirportLinkSettingsRequest = zAirportLinkSettingsResponse;

export type AirportLinkSettingsRequest = z.infer<
  typeof zAirportLinkSettingsRequest
>;

export const zUserAirportSubscriptionRequest = z.object({
  status: z.enum(["downgraded", "resolved", "declined"]),
  airport_id: z.string().or(z.number()).optional(),
});

export type UserAirportSubscriptionRequest = z.infer<
  typeof zUserAirportSubscriptionRequest
>;

export const zDailyPilotsStatisticData = z.object({
  day: z.string(),
  new_pilots: z.number(),
});
export const zDailyAirportsStatisticData = z.object({
  day: z.string(),
  new_airports: z.number(),
});

export const zDailyStatisticsResponse = z.object({
  pilots: z.object({
    all: z.array(zDailyPilotsStatisticData),
    verified: z.array(zDailyPilotsStatisticData),
  }),
  airports: z.array(zDailyAirportsStatisticData),
});
export type DailyStatisticsResponse = z.infer<typeof zDailyStatisticsResponse>;

const zHomeAirportCount = z.object({
  count_home_airport: z.number(),
  icao: z.string(),
  name: z.string(),
});

export type HomeAirportCountDataPoint = z.infer<typeof zHomeAirportCount>;
export const zHomeAirportCounts = z.array(zHomeAirportCount);

export const zHomeAirportCountResponse = z.object({
  homeAirportCounts: zHomeAirportCounts,
});
export type HomeAirportCountResponse = z.infer<
  typeof zHomeAirportCountResponse
>;

export const zAirportAccessCountData = z.object({
  access_count: z.number(),
  icao: z.string(),
  name: z.string(),
});

export const zAirportAccessCounts = z.array(zAirportAccessCountData);
export type AirportAccessCountDataPoint = z.infer<
  typeof zAirportAccessCountData
>;

export const zAirportAccessCountResponse = z.object({
  airportAccessCount: zAirportAccessCounts,
});
export type AirportAccessCountResponse = z.infer<
  typeof zAirportAccessCountResponse
>;

export const zEmailListingDataPoint = z.object({
  id: z.number(),
  email: z.string(),
  verified: z.boolean(),
  subscribed_airport: z.boolean(),
});
export type EmailListingDataPoint = z.infer<typeof zEmailListingDataPoint>;
export const zEmailListings = z.array(zEmailListingDataPoint);

export const zEmailListingResponse = z.object({
  emailListing: zEmailListings,
});
export type EmailListingResponse = z.infer<typeof zEmailListingResponse>;

export const zRegisteredAirportResponse = z.object({
  airport_id: z.number(),
  airport_name: z.string(),
  airport_icao: z.string(),
  subscription_is_active: z.boolean(),
  subscription_end_date: z.string().nullable(),
  user_id: z.number().nullish(),
  user_name: z.string().nullish(),
  user_email: z.string().nullish(),
  user_phone_number: z.string(),
});

export type RegisteredAirportResponse = z.infer<
  typeof zRegisteredAirportResponse
>;

export const zRegisteredAirportsResponse = z.object({
  airports: z.array(zRegisteredAirportResponse),
  count: z.number(),
});

export type RegisteredAirportsResponse = z.infer<
  typeof zRegisteredAirportsResponse
>;

export const zChangeAirportSubscriptionEndDateRequest = z.object({
  end_date: z.string(),
});

export type ChangeAirportSubscriptionEndDateRequest = z.infer<
  typeof zChangeAirportSubscriptionEndDateRequest
>;

export const zAirportListingResponse = z.object({
  airports: z.array(zAirportResponse),
  count: z.number(),
});

export type AirportListingResponse = z.infer<typeof zAirportListingResponse>;

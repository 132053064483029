import { Button } from "@mui/material";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";

export type RemoveBannerButtonProps = {
  icao: string;
  onSuccess?: () => void;
};

export const RemoveBannerButton: FC<RemoveBannerButtonProps> = (props) => {
  const { show } = useModalContext();
  const dispatch = useDispatch();
  return (
    <Button
      color="error"
      onClick={() =>
        show(
          <ResultModal
            title="Do you really want to do remove the banner?"
            submitButton={{ color: "error", text: "Remove" }}
            onSubmit={async () => {
              const result = await dispatch(
                awdApi.endpoints.deleteAirportBanner.initiate({
                  icao: props.icao,
                })
              ).then(describeMutationResult);

              if (result.error) {
                dispatch(
                  toastError(result.error, "We could not remove the banner.")
                );
                return "cancel";
              } else {
                dispatch(
                  toastMessage("success", "The banner was successfully removed")
                );
                props.onSuccess?.();
                return;
              }
            }}
          />
        )
      }
    >
      Remove banner
    </Button>
  );
};

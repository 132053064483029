import { describeUserMeQuery, UserMeQuery } from "logic/user/me/describe";
import { SignupBoxSession } from "store/reducers/localApi";
import { bound } from "utils/class";
import { add, isAfter, startOfDay } from "date-fns";

export function describeSignupBoxSessionQuery(query: {
  isSuccess: boolean;
  data?: SignupBoxSession | undefined | null;
}) {
  return bound({
    getNextShowDate() {
      const lastClosedAt = this.getLastClosedAt();
      if (lastClosedAt == null) return startOfDay(new Date());
      return startOfDay(add(lastClosedAt, { days: 1 }));
    },
    isNowAfterNextShowDate() {
      return isAfter(Date.now(), this.getNextShowDate());
    },
    getLastClosedAt() {
      return query.data?.last_closed_at
        ? new Date(query.data.last_closed_at)
        : undefined;
    },
    getLastDisplayCount() {
      const displayCount = query.data?.display_count ?? "";
      const displayCountParsedInt = parseInt(displayCount);
      const displayCountDefinitelyNum = isNaN(displayCountParsedInt)
        ? -1
        : displayCountParsedInt;
      return displayCountDefinitelyNum;
    },
    getIfThirdTime() {
      return this.getLastDisplayCount() === 0;
    },
    withUserMeQuery(userMeQuery: UserMeQuery) {
      const { getIfThirdTime, isNowAfterNextShowDate } = this;
      const { getLoginStatus } = describeUserMeQuery(userMeQuery);

      return bound({
        shouldShow() {
          return (
            getLoginStatus() === "logged_out" &&
            (isNowAfterNextShowDate() || getIfThirdTime())
          );
        },
      });
    },
  });
}

// MEDIA QUERIES

import { entriesOf, fromEntries, SpecBuilder } from "utils/general";

//  - 350: phoneXSM
// 350 - 600: phoneSM
// 600 - 900: - phoneLG
// 900 - 1050: tablet portrait - tabSM
// 1050 - 1200: tablet landscape - tabLG
// 1200-1700: desktop smaller - deskSM
// 1700-1900: desktop bigger - default styling

// -600 phoneS
// -1100: tabL about, teachers, themes, smaller fonts
// -1350: hambrg
// -1730: heroM
// 2300px +:  big screens deskLG - not applied

// 1310 and up - default (desktop first)
//

function maxWidthMedia(width: number) {
  return `@media (max-width: ${width}px)`;
}

export const responsiveSpec = new SpecBuilder<{ maxWidth: number }>().build({
  deskSM: { maxWidth: 1300 },
  tablet: { maxWidth: 1000 },
  tabletM: { maxWidth: 900 },
  hambrg: { maxWidth: 780 },
  mobilL: { maxWidth: 700 },
  highlights: { maxWidth: 600 },
  forecast: { maxWidth: 500 },
  mobilP: { maxWidth: 400 },
  mobilS: { maxWidth: 300 },
});

export const responsive = fromEntries(
  entriesOf(responsiveSpec).map((entry) => [
    entry[0],
    maxWidthMedia(entry[1].maxWidth),
  ])
);

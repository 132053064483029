import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";
import { Stack } from "components/layout/Stack";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { HomeAirportCountDataPoint } from "awd-server-api";
import { ROW_PER_PAGE, StyledTableCell, StyledTableRow, useTablePage } from "utils/table";

type HomeAirportCountsProps = {};

export const HomeAirportCounts: FC<HomeAirportCountsProps> = (props) => {
  const [ page,, handleChangePage ] = useTablePage();

  const { data, isLoading } = awdApi.endpoints?.getStatisticsHomeAirportCount.useQuery();

  const download = () => {
    if (data) {
      const csvData = data.homeAirportCounts
        .map((item) => `${item.name},${item.icao},${item.count_home_airport}`)
        .join("\n");

      const blob = new Blob([`Airport Name,ICAO,Home Airport Count\n${csvData}`], {
        type: "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Home Airport Counts ${new Date().toLocaleDateString()}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const paginatedData = data?.homeAirportCounts?.slice(page * ROW_PER_PAGE, (page + 1) * ROW_PER_PAGE);

  if (isLoading) return null;
  return (
    <div>
      <Stack horizontal gap={"0"} css={{ justifyContent: "space-between" }}>
        <Button onClick={download}>Download Home Airport Counts Data</Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Airport Name</StyledTableCell>
              <StyledTableCell>ICAO</StyledTableCell>
              <StyledTableCell>Home Airport Count</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData?.map((airport) => (
              <HomeAirportTableRow key={airport.icao} airport={airport} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data?.homeAirportCounts?.length ?? 0}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

type HomeAirportTableRowProps = {
  airport: HomeAirportCountDataPoint;
};

const HomeAirportTableRow: FC<HomeAirportTableRowProps> = ({ airport }) => {
  return (
    <StyledTableRow>
      <StyledTableCell>{airport.name}</StyledTableCell>
      <StyledTableCell>{airport.icao}</StyledTableCell>
      <StyledTableCell>{airport.count_home_airport}</StyledTableCell>
    </StyledTableRow>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { EntrancePageLayout } from "components/layout/page/entrance/EntrancePageLayout";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import {
  SetNewPasswordForm,
  zSetNewPasswordForm,
} from "logic/login/resetPassword/form";
import { onSetNewPasswordFormSubmitted } from "logic/login/resetPassword/onSetNewPasswordFormSubmitted";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "store/hooks";

export type SetNewPasswordPageProps = {};

export const SetNewPasswordPage: FC<SetNewPasswordPageProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const form = useForm<SetNewPasswordForm>({
    resolver: zodResolver(zSetNewPasswordForm),
  });
  const onSuccess = async (values: SetNewPasswordForm) => {
    await dispatch(
      onSetNewPasswordFormSubmitted({
        values,
        navigate,
        token: search.get("token"),
      })
    );
  };
  return (
    <EntrancePageLayout>
      <Meta pageTitle="Log in" />
      <form onSubmit={form.handleSubmit(onSuccess)}>
        <Stack gap="1rem">
          <FormTextField
            form={form}
            name="password"
            type="password"
            label="Password"
          />
          <FormTextField
            form={form}
            name="password_again"
            type="password"
            label="Confirm password"
          />

          <Button type="submit" variant="contained" size="large">
            Set new password
          </Button>
        </Stack>
      </form>
    </EntrancePageLayout>
  );
};

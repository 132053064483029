import { FC } from "react";
import { SubscribedAirportProps } from "components/registeredAirports/RegisteredAirportTableRow";
import { ResultModal } from "components/modal/inside/ResultModal";
import { DialogContent, Typography } from "@mui/material";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastMessage } from "store/reducers/toastSlice";

export const EndSubscriptionDialog: FC<SubscribedAirportProps> = (props) => {
  const dispatch = useDispatch();

  const endSubscription = () => {
    try {
      const startOfToday = new Date();
      dispatch(
        awdApi.endpoints.patchRegisteredAirportSubscriptionEndDate.initiate({
          airportId: props.airport.airport_id,
          endDate: startOfToday,
        })
      );
      dispatch(toastMessage("success", "Subscription ended successfully."));
    } catch (error) {
      dispatch(
        toastMessage("error", "Something went wrong, please try again later.")
      );
    }
  };

  return (
    <ResultModal
      title="End Subscription"
      submitButton={{
        text: "End Subscription",
        color: "error",
      }}
      onSubmit={endSubscription}
    >
      <DialogContent>
        <Typography>
          Are you sure you want to end the subscription for this{" "}
          {props.airport.airport_icao} airport?
        </Typography>
      </DialogContent>
    </ResultModal>
  );
};

import styled from "@emotion/styled";
import { FC, PropsWithChildren } from "react";

export type BlockProps = PropsWithChildren<{}>;

const maxBlockWidth = "50rem";
const sidePadding = "2rem";

export const Block: FC<BlockProps> = (props) => {
  return (
    <SGrid>
      <SSide />
      {props.children}
      <SSide />
    </SGrid>
  );
};

const SGrid = styled.div({
  display: "grid",
  width: "100vw",
  overflowX: "hidden",
  gridTemplateColumns: `1fr min(calc(100vw - 2 * ${sidePadding}), ${maxBlockWidth}) 1fr`,
});

const SSide = styled.div();

import React, { FC } from "react";
import { Position } from "components/RunwaysViz/types";
import { isNearCompassLabel } from "components/RunwaysViz/logic/compassLogic";

export type CompassType = {
  compassCenter: Position;
  circleRadius: number;
  angleWind: number;
};

const Compass: FC<CompassType> = ({ compassCenter, circleRadius, angleWind }) => {
  const isNearLabel = isNearCompassLabel(angleWind);
  return (
    <g id="compass">
      <circle
        cx={compassCenter.x}
        cy={compassCenter.y}
        r={circleRadius + 10}
        fill="none"
        stroke="#353535"
        strokeDasharray="2 5"
      />
      <circle
        cx={compassCenter.x}
        cy={compassCenter.y}
        r={circleRadius + 135}
        fill="none"
        stroke="#000"
        strokeDasharray="2 5"
      />
      {/* 90 */}
      <text
        x={compassCenter.x + circleRadius + 95}
        y={compassCenter.y}
        textAnchor="middle"
        fontWeight="800"
        fontSize="34px"
        fill="#dad9d9"
        transform={`rotate(270, ${compassCenter.x + circleRadius + 95}, ${compassCenter.y})`}
        display={isNearLabel.near90 ? "none" : undefined}
      >
        90°
      </text>
      {/* 180 */}
      <text
        x={compassCenter.x}
        y={compassCenter.y + circleRadius + 95}
        textAnchor="middle"
        fontWeight="800"
        fontSize="34px"
        fill="#dad9d9"
        display={isNearLabel.near180 ? "none" : undefined}
      >
        180°
      </text>
      {/* 270  */}
      <text
        x={compassCenter.x - circleRadius - 95}
        y={compassCenter.y}
        textAnchor="middle"
        fontWeight="800"
        fontSize="34px"
        fill="#dad9d9"
        transform={`rotate(90, ${compassCenter.x - circleRadius - 95}, ${compassCenter.y})`}
        display={isNearLabel.near270 ? "none" : undefined}
      >
        270°
      </text>
      {/* 360 */}
      <text
        x={compassCenter.x}
        y={compassCenter.y - circleRadius - 70}
        textAnchor="middle"
        fontWeight="800"
        fontSize="34px"
        fill="#dad9d9"
        display={isNearLabel.near360 ? "none" : undefined}
      >
        360°
      </text>
      {/* north */}
      <text
        x={compassCenter.x}
        y={compassCenter.y - circleRadius - 160}
        textAnchor="middle"
        fontWeight="800"
        fontSize="26px"
        fill="#c6c6c6"
      >
        N
      </text>
      {/* north arrow */}
      <path
        d="M 0,0
           L 20,45
           L 0,35
           L -20,45
           L 0,0 z"
        fill="#c6c6c6"
        transform={`translate(${compassCenter.x}, ${
          compassCenter.y - circleRadius - 160
        }) scale(0.5)`}
      />
    </g>
  );
};

export default Compass;

import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Link, Typography } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { EntrancePageLayout } from "components/layout/page/entrance/EntrancePageLayout";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { LoginForm, zLoginForm } from "logic/login/form";
import { onLoginFormSubmitted } from "logic/login/onLoginFormSubmitted";
import { pageSpec } from "logic/navigation/spec";
import { describeUserMeQuery } from "logic/user/me/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { useRunOnceReady } from "utils/hooks";
import { localApi } from "store/reducers/localApi";
import { queryUserHome } from "logic/user/me/home";

export type LoginPageProps = {};

export const LoginPage: FC<LoginPageProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const form = useForm<LoginForm>({
    resolver: zodResolver(zLoginForm),
  });
  const { hasCurrentData, isLoggedIn } = describeUserMeQuery(
    awdApi.endpoints.getUserMe.useQuery()
  );
  useRunOnceReady(hasCurrentData() && isLoggedIn(), async () => {
    navigate(search.get("next") ?? (await queryUserHome(dispatch)), {
      replace: true,
    });
  });
  const isMobileApp =
    localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ??
    false;

  const onSuccess = async (values: LoginForm) => {
    await dispatch(
      onLoginFormSubmitted({ values, navigate, next: search.get("next") })
    );
  };
  return (
    <EntrancePageLayout>
      <Meta pageTitle="Log in" />
      <form onSubmit={form.handleSubmit(onSuccess)}>
        <Stack gap="1rem">
          <FormTextField form={form} name="email" type="email" label="E-mail" />
          <FormTextField
            form={form}
            name="password"
            type="password"
            label="Password"
          />
          <Button type="submit" variant="contained" size="large">
            Login
          </Button>

          <Typography variant="body1">
            Don't have an account yet?
            <br />
            <SLink href={pageSpec.registerpilot.path}>
              <strong>Register as a pilot</strong>
            </SLink>
            {!isMobileApp && (
              <>
                {" "}
                or{" "}
                <SLink href={pageSpec.signup.path}>
                  <strong>Subscribe as an airport</strong>
                </SLink>
              </>
            )}
            .
          </Typography>

          <Typography variant="body1">
            Forgotten password?{" "}
            <SLink href={pageSpec.reset_password_request.path}>
              <strong>Reset your password</strong>
            </SLink>
          </Typography>
        </Stack>
      </form>
    </EntrancePageLayout>
  );
};

const SLink = styled(Link)({
  whiteSpace: "nowrap",
});

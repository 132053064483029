import { Button } from "@mui/material";
import { onLogoutButtonClick } from "logic/login/onLogoutButtonClick";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store/hooks";

export type LogoutButtonProps = {};

export const LogoutButton: FC<LogoutButtonProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Button
      variant="text"
      color="error"
      onClick={() => dispatch(onLogoutButtonClick({ navigate }))}
    >
      Log out
    </Button>
  );
};

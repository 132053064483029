import { GeoProjection } from "d3-geo";

export const calculateRunwayPolygons = ({
  lowerPoint,
  higherPoint,
  anglePerpendicular,
  runwayWidth,
  projectionFitted,
}: {
  lowerPoint: [number, number];
  higherPoint: [number, number];
  anglePerpendicular: number;
  runwayWidth: number;
  projectionFitted: GeoProjection;
}) => {
  const projectionLowerPoint = projectionFitted(lowerPoint);
  const projectionHigherPoint = projectionFitted(higherPoint);
  if (!projectionLowerPoint || !projectionHigherPoint) return undefined;

  const xPart = Math.sin(anglePerpendicular) * runwayWidth;
  const yPart = Math.cos(anglePerpendicular) * runwayWidth;

  return [
    [projectionLowerPoint[0] - xPart, projectionLowerPoint[1] + yPart],
    [projectionLowerPoint[0] + xPart, projectionLowerPoint[1] - yPart],
    [projectionHigherPoint[0] - xPart, projectionHigherPoint[1] + yPart],
    [projectionHigherPoint[0] + xPart, projectionHigherPoint[1] - yPart],
  ] as [[number, number], [number, number], [number, number], [number, number]];
};

export const calculateInnerPolygons = ({
  lowerPoint,
  higherPoint,
  inParallelX,
  inParallelY,
  inPerpX,
  inPerpY,
  projectionFitted,
  runwayWidth,
}: {
  lowerPoint: [number, number];
  higherPoint: [number, number];
  inParallelX: number;
  inParallelY: number;
  inPerpX: number;
  inPerpY: number;
  projectionFitted: GeoProjection;
  runwayWidth: number;
}) => {
  const projectionLowerPoint = projectionFitted(lowerPoint);
  const projectionHigherPoint = projectionFitted(higherPoint);
  if (!projectionLowerPoint || !projectionHigherPoint) return undefined;

  // parameters for inside rectangles on both edges of a runway
  const paddingOuter = 3;
  const paddingYcoord = 4;
  const paddingInner = 1;
  const rectLength = paddingYcoord + 12;
  const edgeRectWidth = (runwayWidth - 2 * paddingOuter - 3 * paddingInner) / 3;
  const firstRectEnd = paddingOuter + edgeRectWidth;
  const secondRectStart = firstRectEnd + paddingInner;
  const secondRectEnd = secondRectStart + edgeRectWidth;
  const thirdRectStart = secondRectEnd + paddingInner;
  const thirdRectEnd = thirdRectStart + edgeRectWidth;

  const calculateLePointLeft = (xParam: number, yParam: number) => {
    return [
      projectionLowerPoint[0] + (inPerpX * xParam + inParallelX * yParam),
      projectionLowerPoint[1] - (inPerpY * xParam + inParallelY * yParam),
    ];
  };
  const calculateLePointRight = (xParam: number, yParam: number) => {
    return [
      projectionLowerPoint[0] + (-inPerpX * xParam + inParallelX * yParam),
      projectionLowerPoint[1] - (-inPerpY * xParam + inParallelY * yParam),
    ];
  };
  const calculateHePointLeft = (xParam: number, yParam: number) => {
    return [
      projectionHigherPoint[0] - (inPerpX * xParam + inParallelX * yParam),
      projectionHigherPoint[1] + (inPerpY * xParam + inParallelY * yParam),
    ];
  };
  const calculateHePointRight = (xParam: number, yParam: number) => {
    return [
      projectionHigherPoint[0] - (-inPerpX * xParam + inParallelX * yParam),
      projectionHigherPoint[1] + (-inPerpY * xParam + inParallelY * yParam),
    ];
  };
  const calculateLePolygons = (
    firstParameter: number,
    secondParameter: number,
    thirdParameter: number,
    fourthParameter: number
  ) => {
    return [
      [
        calculateLePointLeft(firstParameter, thirdParameter),
        calculateLePointLeft(secondParameter, thirdParameter),
        calculateLePointLeft(secondParameter, fourthParameter),
        calculateLePointLeft(firstParameter, fourthParameter),
      ],
      [
        calculateLePointRight(firstParameter, thirdParameter),
        calculateLePointRight(firstParameter, fourthParameter),
        calculateLePointRight(secondParameter, fourthParameter),
        calculateLePointRight(secondParameter, thirdParameter),
      ],
    ];
  };
  const calculateHePolygons = (
    firstParameter: number,
    secondParameter: number,
    thirdParameter: number,
    fourthParameter: number
  ) => {
    return [
      [
        calculateHePointLeft(firstParameter, thirdParameter),
        calculateHePointLeft(secondParameter, thirdParameter),
        calculateHePointLeft(secondParameter, fourthParameter),
        calculateHePointLeft(firstParameter, fourthParameter),
      ],
      [
        calculateHePointRight(firstParameter, thirdParameter),
        calculateHePointRight(firstParameter, fourthParameter),
        calculateHePointRight(secondParameter, fourthParameter),
        calculateHePointRight(secondParameter, thirdParameter),
      ],
    ];
  };
  return [
    ...calculateLePolygons(paddingOuter, firstRectEnd, paddingYcoord, rectLength), // c and f
    ...calculateLePolygons(secondRectStart, secondRectEnd, paddingYcoord, rectLength), // d and g
    ...calculateLePolygons(thirdRectStart, thirdRectEnd, paddingYcoord, rectLength), // e and h
    ...calculateHePolygons(paddingOuter, firstRectEnd, paddingYcoord, rectLength), // i and l
    ...calculateHePolygons(secondRectStart, secondRectEnd, paddingYcoord, rectLength), // j and m
    ...calculateHePolygons(thirdRectStart, thirdRectEnd, paddingYcoord, rectLength), // k and n
  ] as [[number, number], [number, number], [number, number], [number, number]][];
};

export const calculateCentralLine = ({
  lowerPoint,
  higherPoint,
  inParallelX,
  inParallelY,
  lineParameter,
  projectionFitted,
}: {
  lowerPoint: [number, number];
  higherPoint: [number, number];
  inParallelX: number;
  inParallelY: number;
  lineParameter: number;
  projectionFitted: GeoProjection;
}) => {
  const projectionLowerPoint = projectionFitted(lowerPoint);
  const projectionHigherPoint = projectionFitted(higherPoint);

  if (!projectionLowerPoint || !projectionHigherPoint) return undefined;

  const xParamLine = inParallelX * lineParameter;
  const yParamLine = inParallelY * lineParameter;
  return [
    [projectionLowerPoint[0] + xParamLine, projectionLowerPoint[1] - yParamLine],
    [projectionHigherPoint[0] - xParamLine, projectionHigherPoint[1] + yParamLine],
  ] as [[number, number], [number, number]];
};

export const calculateTextLe = ({
  lowerPoint,
  inParallelX,
  inParallelY,
  textParameter,
  projectionFitted,
}: {
  lowerPoint: [number, number];
  inParallelX: number;
  inParallelY: number;
  textParameter: number;
  projectionFitted: GeoProjection;
}) => {
  const projectionLowerPoint = projectionFitted(lowerPoint);
  if (!projectionLowerPoint) return undefined;
  // projected parameters into x,y coordinate system to position the name of runway
  const xParamText = inParallelX * textParameter;
  const yParamText = inParallelY * textParameter;
  return [projectionLowerPoint[0] + xParamText, projectionLowerPoint[1] - yParamText];
};

export const calculateTextHe = ({
  higherPoint,
  inParallelX,
  inParallelY,
  textParameter,
  projectionFitted,
}: {
  higherPoint: [number, number];
  inParallelX: number;
  inParallelY: number;
  textParameter: number;
  projectionFitted: GeoProjection;
}) => {
  const projectionHigherPoint = projectionFitted(higherPoint);
  if (!projectionHigherPoint) return undefined;
  // projected parameters into x,y coordinate system to position the name of runway
  const xParamText = inParallelX * textParameter;
  const yParamText = inParallelY * textParameter;
  return [projectionHigherPoint[0] - xParamText, projectionHigherPoint[1] + yParamText];
};

export const calculateRunwayEdge = ({
  point,
  anglePerpendicular,
  projectionFitted,
  runwayWidth,
}: {
  point: [number, number];
  anglePerpendicular: number;
  projectionFitted: GeoProjection;
  runwayWidth: number;
}) => {
  const projection = projectionFitted(point);
  if (!projection) return undefined;
  const xPart = Math.sin(anglePerpendicular) * runwayWidth;
  const yPart = Math.cos(anglePerpendicular) * runwayWidth;
  return [
    [projection[0] - xPart, projection[1] + yPart],
    [projection[0] + xPart, projection[1] - yPart],
  ] as [[number, number], [number, number]];
};

import { awdApi } from "store/reducers/awdApi";
import { bound } from "utils/class";
import { describeSettings, describeSettingsOrDefault } from "./describe";

export function useMySettings() {
  const query = awdApi.endpoints.getMySettings.useQuery();
  const [mutate, mutationState] = awdApi.endpoints.putMySettings.useMutation();

  return bound({
    query,
    settings: query.data,
    mutate,
    mutationState,
    describe() {
      return this.settings ? describeSettings(this.settings) : undefined;
    },
    describeOrDefault() {
      return describeSettingsOrDefault(this.settings);
    },
  });
}

import { createTheme } from "@mui/material";
import { LinkBehavior } from "components/link/behavior/LinkBehavior";

const muiTheme = createTheme({
  typography: {
    fontFamily: "'Encode Sans', sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "0.625rem",
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "1.5px",
      color: "#999",
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#234",
    },
    body1: {
      fontSize: "0.875rem",
      color: "#444",
    },
    button: {
      fontWeight: 700,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        // @ts-ignore not sure why ts has problem with this
        component: LinkBehavior,
        underline: "hover",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#111112fe",
          padding: "0.5rem 0.75rem",
          fontSize: "0.75rem",
          fontFamily: "Encode Sans",
        },
      },
    },
  },
});

export const themes = {
  default: {
    ...muiTheme,
    primary: "#6b6c6c",
    backgroundPrimary: "#c7c8c8",
    backgroundSecondary: "#c7c8c8",
  },
  vfr: {
    ...muiTheme,
    primary: "#69bd4f",
    backgroundPrimary: "#d4e0d2",
    backgroundSecondary: "#bec8bc",
  },
  mvfr: {
    ...muiTheme,
    primary: "#4893cf",
    backgroundPrimary: "#d3dbe2",
    backgroundSecondary: "#a7b8c7",
  },
  ifr: {
    ...muiTheme,
    primary: "#d74d38",
    backgroundPrimary: "#f4d5c7",
    backgroundSecondary: "#c7b0a5",
  },
  lifr: {
    ...muiTheme,
    primary: "#8151a0",
    backgroundPrimary: "#c0acd3",
    backgroundSecondary: "#9d8eab",
  },
};

export const iconColor = "#808285";
export const disabledIconColor = "#b9b9be";

import { Alert, AlertTitle } from "@mui/material";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";
import { describeMyAirportRoleRequestsQuery, describeMyRoles } from "logic/user/me/role/describe";

export type AirportEditabilityStatusProps = { airportId: number };

export const AirportEditabilityStatus: FC<AirportEditabilityStatusProps> = (props) => {
  const { isLoading, managedAirport } = useMyAirportRoles();
  const myRolesData = awdApi.endpoints?.getMyRoles.useQuery();
  const { getMyRoleRequestAirportIds } = describeMyAirportRoleRequestsQuery(
    awdApi.endpoints.getMyAirportRoleRequests.useQuery()
  );

  const myRoleRequestAirportIds = getMyRoleRequestAirportIds();
  const { isPilotAccount } = describeMyRoles(myRolesData?.data);
  const isAirportOneOfMyRequests = myRoleRequestAirportIds?.includes(props.airportId);

  if (isLoading) return null;
  if (!managedAirport()?.hasSubscription() && !isPilotAccount() && isAirportOneOfMyRequests) {
    return (
      <>
        <Alert severity="info">
          <AlertTitle>Editability</AlertTitle>
          To be able to edit your airport's data, you need to start your subscription.
        </Alert>
      </>
    );
  }
  return null;
};

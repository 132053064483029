import { List, SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import { SignupBenefitListItem } from "components/signupOfferBox/SignupBenefitsListItem";
import ReactMarkdown from "react-markdown";

type SignupOfferParaProps = {
  sx?: SxProps<Theme>;
};

export const SignupOfferParagraph: FC<
  PropsWithChildren<SignupOfferParaProps>
> = ({ children, sx }) => (
  <Typography sx={{ fontSize: "12px", ...sx }}>{children}</Typography>
);

type SignupOfferParagraphMarkdownProps = {
  text: string;
};

export const SignupOfferParagraphMarkdown: FC<
  SignupOfferParagraphMarkdownProps
> = ({ text }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => (
          <SignupOfferParagraph sx={{ mb: "0.5rem" }} {...props} />
        ),
        ul: ({ node, ...props }) => (
          <List {...props} sx={{ listStyleType: "disc", pl: 2, py: 0 }} />
        ),
        li: ({ node, ...props }) => <SignupBenefitListItem {...props} />,
      }}
    >
      {text}
    </ReactMarkdown>
  );
};

import React, { useEffect, useState, useCallback, FC, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { useMap } from "react-leaflet";
import { useLazyGetAllAirportsQuery } from "store/reducers/awdApi";
import AirportMarker from "./AirportMarker";

import { RootState } from "store/store";
import { mobileTabletCheck } from "utils/global.functions";

export type AirportPopupsType = {
  setMobile: Dispatch<SetStateAction<boolean | undefined>>;
};

const AirportPopups: FC<AirportPopupsType> = ({ setMobile }) => {
  const map = useMap();

  const [bounds, setBounds] = useState<string>();
  const awMetar = useSelector((state: RootState) => state.localState.toggledToAwMetarWeatherSource);

  const [trigger, { data: allAirports, isSuccess }] = useLazyGetAllAirportsQuery();

  // getting all airports on the first load and when bounds change to place dots, set gps
  useEffect(() => {
    if (!map || !bounds) return;
    if (map.getZoom() < 10) return;
    getAirports(bounds);
  }, [bounds]);

  const getAirports = async (bounds: string) => {
    trigger({ bounds });
  };

  const setMapBounds = useCallback(() => {
    if (!map) return;

    const region = map.getBounds();

    setBounds(
      JSON.stringify({
        bottomLeft: region.getSouthWest(),
        topRight: region.getNorthEast(),
      })
    );
  }, [map]);

  useEffect(() => {
    if (!map) return;
    setMapBounds();
  }, [map]);

  useEffect(() => {
    if (!map) return;
    setMobile(mobileTabletCheck());
  }, [map, bounds]);

  useEffect(() => {
    if (!map) return;
    map.addEventListener("moveend", (e) => {
      setMapBounds();
    });
  }, [map, setMapBounds]);

  return (
    <>
      {isSuccess && allAirports && map && map.getZoom() >= 10
        ? allAirports
            .filter((a) => {
              return !awMetar ? a.metarStation : a;
            })
            .map((i) => {
              return (
                <AirportMarker
                  icao={i.icao}
                  icaoAlias={i.icaoAlias}
                  key={i.icao}
                  name={i.name}
                  lat={i.latitude}
                  long={i.longitude}
                  country={i.isoCountry}
                  status={i.closed}
                />
              );
            })
        : null}
    </>
  );
};

export default AirportPopups;

import { Alert, AlertTitle } from "@mui/material";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { useRunwaySchemaData } from "pages/weather-dashboards/RunwaySchema";
import { useContentfulData } from "utils/useContentfulData";
import { getNotAllRunwaysDataFilledInManager, getRunwaysDataNotCorrectManager, getRunwaysNotFullySubscribedManager, getRunwaysOrAirportClosedManager } from "utils/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from '@contentful/rich-text-types';
import React, { ReactNode } from "react";

export const RunwaysErrorStatuses = () => {
  const icao = useMyAirportRoles().getMyAirportIcao();
  const {
    isAllRunwaysDataFilledIn,
    isRunwaysDataCorrect,
    isHeliport,
    isSubscribed,
    isAirportOrAllRunwaysClosed,
    isManagerOfCurrentAirport,
  } = useRunwaySchemaData(icao);

  const invalidContent: Document = {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content: [
      {
        nodeType: BLOCKS.PARAGRAPH,
        content: [
          {
            nodeType: 'text',
            value: "",
            marks: [],
            data: {},
          },
        ],
        data: {},
      },
    ],
  };

  const notAllRunwaysDataFilledInManager
    = useContentfulData(getNotAllRunwaysDataFilledInManager)?.modalContent ?? invalidContent;
  const runwaysDataNotCorrectManager
    = useContentfulData(getRunwaysDataNotCorrectManager)?.modalContent ?? invalidContent;
  const notFullySubscribedManager
    = useContentfulData(getRunwaysNotFullySubscribedManager)?.modalContent ?? invalidContent;
  const runwaysOrAirportClosedManager
    = useContentfulData(getRunwaysOrAirportClosedManager)?.modalContent ?? invalidContent;

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_: any, children: ReactNode) => <AlertTitle>{children}</AlertTitle>,
      [BLOCKS.HEADING_2]: (_: any, children: ReactNode) => <AlertTitle>{children}</AlertTitle>,
    },
  };

  if (!isSubscribed && isManagerOfCurrentAirport) {
    return (
      <Alert severity="warning">
        {documentToReactComponents(notFullySubscribedManager, options)}
      </Alert>
    );
  }
  if (isSubscribed && !isAllRunwaysDataFilledIn && !isHeliport && isManagerOfCurrentAirport) {
    return (
      <Alert severity="warning">
        {documentToReactComponents(notAllRunwaysDataFilledInManager, options)}
      </Alert>
    );
  }
  if (isSubscribed && !isRunwaysDataCorrect && !isHeliport && isManagerOfCurrentAirport) {
    return (
      <Alert severity="warning">
        {documentToReactComponents(runwaysDataNotCorrectManager, options)}
      </Alert>
    );
  }
  if (isSubscribed && isAirportOrAllRunwaysClosed && !isHeliport && isManagerOfCurrentAirport) {
    return (
      <Alert severity="warning">
        {documentToReactComponents(runwaysOrAirportClosedManager, options)}
      </Alert>
    );
  }

  return null;
};

import styled, { CSSObject } from "@emotion/styled";
import Runway from "components/Dashboard/_icons/Runway2";
import { CurrentWindAngle } from "components/Dashboard/weatherNow/CurrentWindAngle";
import { CurrentWindSpeed } from "components/Dashboard/weatherNow/CurrentWindSpeed";
import { RunwayIdentifier } from "components/Dashboard/weatherNow/RunwayIdentifier";
import { RunwayWindArrows } from "components/Dashboard/weatherNow/RunwayWindArrows";
import { ParsedRunway } from "logic/airport/runway/parse";
import { useActiveMetarSource } from "logic/weather/hooks";
import { FC } from "react";
import { SpecBuilder } from "utils/general";
import { useObserveElementSize } from "utils/hooks";

export type RunwayWindGraphicProps = {
  runway: ParsedRunway;
};

export const RunwayWindGraphic: FC<RunwayWindGraphicProps> = (props) => {
  const { source } = useActiveMetarSource();
  const numberOfArrowsShown = Math.max(...(source?.getActiveWindArrows() ?? []));

  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  const sizeVariant = width != null && width < 380 ? "small" : "large";
  const { arrowsHeight, runwayHeight, runwayIdentifierStyles } = sizeVariantSpecs[sizeVariant];

  return (
    <SGraphic ref={ref}>
      <RunwayWindArrows runway={props.runway} height={arrowsHeight} />
      <Runway height={runwayHeight} />
      <SRunwayWindDataRow
        css={{
          top: (5 - numberOfArrowsShown) * 25 - 15,
          gap: width != null && width < 300 ? "5rem" : "4rem",
        }}
      >
        <CurrentWindAngle />
        <CurrentWindSpeed />
      </SRunwayWindDataRow>
      <RunwayIdentifier
        selectedRunway={props.runway}
        css={[{ position: "absolute" }, runwayIdentifierStyles]}
      />
    </SGraphic>
  );
};

const sizeVariantSpecs = new SpecBuilder<{
  runwayHeight: number;
  arrowsHeight: number;
  runwayIdentifierStyles?: CSSObject;
}>().build({
  large: {
    runwayHeight: 191,
    arrowsHeight: 145,
    runwayIdentifierStyles: {
      bottom: "60px",
    },
  },
  small: {
    runwayHeight: 140,
    arrowsHeight: 125,
    runwayIdentifierStyles: {
      bottom: "45px",
      fontSize: "35px",
    },
  },
});

const SRunwayWindDataRow = styled.div({
  display: "flex",
  position: "absolute",
  justifyContent: "space-around",
  width: "100%",
});

const SGraphic = styled.div({
  flexGrow: 1,
  width: "100%",
  position: "relative",
  overflow: "hidden",
});

import React, { FC } from "react";
import { useTheme } from "@emotion/react";

export type RunwaysType = {
  runwaysData: {
    runwayPolygon: [[number, number], [number, number], [number, number], [number, number]];
    innerPolygons: [[number, number], [number, number], [number, number], [number, number]][];
    centralLine: [[number, number], [number, number]];
    runwayEdgeLe: [[number, number], [number, number]];
    runwayEdgeHe: [[number, number], [number, number]];
    nameLe: string;
    textLe: number[];
    nameHe: string;
    textHe: number[];
    angleOfRunway: number;
  }[];
  selectedRunway: string;
};

const Runways: FC<RunwaysType> = ({ runwaysData, selectedRunway }) => {
  const innerPolygonFill = "#6b6c6c";
  const theme = useTheme();
  return (
    <>
      {runwaysData.map((r, i) => {
        const points = `${r.runwayPolygon[2][0]},${r.runwayPolygon[2][1]} ${r.runwayPolygon[3][0]},${r.runwayPolygon[3][1]} ${r.runwayPolygon[1][0]},${r.runwayPolygon[1][1]} ${r.runwayPolygon[0][0]},${r.runwayPolygon[0][1]}`;

        return (
          <g id="runways" key={i}>
            {/* all the runway polygons */}
            <g id="runwayPolygons">
              <polygon points={points} fill="#e6e7e8" />
              {/* stroke the selected polygon */}
              {(selectedRunway === r.nameLe || selectedRunway === r.nameHe) && (
                <polygon points={points} fill="none" stroke="#000" strokeWidth={2} />
              )}
            </g>
            <line
              x1={r.centralLine[0][0]}
              y1={r.centralLine[0][1]}
              x2={r.centralLine[1][0]}
              y2={r.centralLine[1][1]}
              stroke="black"
              strokeWidth={0.2}
              strokeDasharray="6 4"
            />
            <line
              x1={r.runwayEdgeLe[0][0]}
              y1={r.runwayEdgeLe[0][1]}
              x2={r.runwayEdgeLe[1][0]}
              y2={r.runwayEdgeLe[1][1]}
              stroke="black"
              strokeWidth={2}
            />
            <line
              x1={r.runwayEdgeHe[0][0]}
              y1={r.runwayEdgeHe[0][1]}
              x2={r.runwayEdgeHe[1][0]}
              y2={r.runwayEdgeHe[1][1]}
              stroke="black"
              strokeWidth={2}
            />
            <text
              x={r.textLe[0]}
              y={r.textLe[1]}
              textAnchor="middle"
              transform={`rotate(${r.angleOfRunway}, ${r.textLe[0]}, ${r.textLe[1]})`}
              fontWeight="900"
              fontSize="22px"
              fill={r.nameLe === selectedRunway ? theme.primary : "default"}
            >
              {r.nameLe}
            </text>
            <text
              x={r.textHe[0]}
              y={r.textHe[1]}
              textAnchor="middle"
              transform={`rotate(${r.angleOfRunway + 180}, ${r.textHe[0]}, ${r.textHe[1]})`}
              fontWeight="900"
              fontSize="22px"
              fill={r.nameHe === selectedRunway ? theme.primary : "default"}
            >
              {r.nameHe}
            </text>

            <g id="innerPolygons">
              {r.innerPolygons.map((p, i) => {
                return (
                  <polygon
                    points={`${p[0][0]},${p[0][1]} ${p[1][0]},${p[1][1]} ${p[2][0]},${p[2][1]} ${p[3][0]},${p[3][1]}`}
                    fill={innerPolygonFill}
                    key={i}
                  />
                );
              })}
            </g>
          </g>
        );
      })}
    </>
  );
};

export default Runways;

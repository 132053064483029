import { Button, DialogContent } from "@mui/material";
import { FileDropzone } from "components/fileDropzone/FileDropzone";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC, useState } from "react";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useRunOnceReady, useRunOnChange } from "utils/hooks";

export type UploadKioskBannerModalProps = {
  icao: string;
  onUploadSuccess?: () => void;
};

export const UploadKioskBannerModal: FC<UploadKioskBannerModalProps> = (
  props
) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | undefined>();
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  useRunOnChange(file, (_, next) => {
    const nextUrl = next && URL.createObjectURL(next);

    setFileUrl((currentUrl) => {
      if (currentUrl != null) URL.revokeObjectURL(currentUrl);
      return nextUrl;
    });
  });

  useRunOnceReady(true, () => () => {
    // runs on unmount
    if (fileUrl) URL.revokeObjectURL(fileUrl);
  });

  return (
    <ResultModal
      title="Upload kiosk banner"
      submitButton={{ disabled: file == null, text: "Upload" }}
      onSubmit={async () => {
        if (!file) return "cancel";
        const result = await dispatch(
          awdApi.endpoints.putAirportBanner.initiate({
            icao: props.icao,
            imageFile: file,
          })
        ).then(describeMutationResult);

        if (result.error) {
          dispatch(toastError(result.error, "We could not update the banner."));
          return "cancel";
        } else {
          dispatch(toastMessage("success", "The banner has been updated."));
          props.onUploadSuccess?.();
          return;
        }
      }}
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        {fileUrl == null ? (
          <FileDropzone
            accept={{
              "image/jpeg": [".jpg", ".jpeg"],
              "image/png": [".png"],
            }}
            onDrop={(files) => {
              setFile(files[0]);
            }}
          />
        ) : (
          <Stack gap="0.5rem" css={{ alignItems: "start" }}>
            <div css={{ width: "100%" }}>
              <img
                src={fileUrl}
                alt="uploaded banner preview"
                css={{ width: "100%", height: "auto", borderRadius: "0.25rem" }}
              />
            </div>
            <Button
              onClick={() => {
                setFile(undefined);
              }}
            >
              Select another image
            </Button>
          </Stack>
        )}
      </DialogContent>
    </ResultModal>
  );
};

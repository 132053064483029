import { Skeleton, Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { FC, PropsWithChildren, ReactNode } from "react";

export type SettingsGroupProps = PropsWithChildren<{
  title: string;
  loading?: boolean;
  skeleton?: ReactNode;
}>;

export const SettingsGroup: FC<SettingsGroupProps> = (props) => {
  return (
    <Stack gap="1rem">
      <Typography variant="subtitle1">
        {props.loading ? <Skeleton width="10rem" /> : props.title}
      </Typography>

      {props.loading ? props.skeleton ?? <Skeleton /> : props.children}
    </Stack>
  );
};

import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import { ReactNode } from "react";
import {
  Controller,
  FieldPath,
  FieldValues,
  UseFormReturn,
} from "react-hook-form";

export function FormSelectField<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
    options: { value: string; label: ReactNode }[];
  } & TextFieldProps
) {
  const { form, name, options, ...pass } = props;
  const state = form.getFieldState(props.name, form.formState);
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <TextField
          inputRef={ref}
          {...field}
          value={field.value ?? ""}
          error={state.error != null}
          helperText={state.error?.message}
          select
          {...pass}
        >
          {options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}

import { RunwayLongView } from "awd-server-api";
import { isNonNullable } from "utils/general";

// addition runways and runways check helper function
export function parseRunways(
  runways: RunwayLongView[]
): [ParsedRunway, ParsedRunway][] {
  // cleaning runways from letters that do not make sense (H, M, N..)
  // in that case return 36 and 18
  // cleaning numbers - only allowing between 0 and 36
  const runwaysCleaned = runways
    ?.map((runwayData) => {
      let r = {
        name: String(runwayData.leIdent),
        elevation: runwayData.leElevation,
        elevationOpposite: runwayData.heElevation,
        closed: runwayData.closed,
      };

      if (r.name.length === 3 && r.name.charAt(2) === "0") {
        r.name = r.name.slice(0, 2);
      }
      const runway = { ...r, name: parseInt(r.name) };

      let cleanedRunwayNumber = {
        ...runway,
        name: runway.name > 36 ? `36` : runway.name < 0 ? `36` : r.name,
      };

      if (cleanedRunwayNumber.name.length === 1) {
        cleanedRunwayNumber = {
          ...runway,
          name: `0${cleanedRunwayNumber.name.toString()}`,
        };
      }

      let cleanedRunwayLetter;
      if (
        r.name.length === 3 &&
        r.name.charAt(2) !== "U" &&
        r.name.charAt(2) !== "W" &&
        r.name.charAt(2) !== "S" &&
        r.name.charAt(2) !== "G" &&
        r.name.charAt(2) !== "C" &&
        r.name.charAt(2) !== "L" &&
        r.name.charAt(2) !== "R"
      ) {
        cleanedRunwayLetter = {
          ...r,
          name: `00`,
        };
        return cleanedRunwayLetter;
      } else {
        return cleanedRunwayNumber;
      }
    })
    .filter((r) => !r.closed);

  // generating runway numbers in the flipped direction
  const runwaysOtherDirection = runwaysCleaned?.map((runway) => {
    // normalizing data, when there is 160 instead of 16
    if (runway.name.length === 3 && runway.name.charAt(2) === "0") {
      runway = { ...runway, name: runway.name.slice(0, 2) };
    }

    // typing into number
    const runwayNumber = parseInt(runway.name);
    const oppositeDirectionNumber = (runwayNumber + 18) % 36;

    let oppositeRunwayName = String(oppositeDirectionNumber).padStart(2, "0");

    if (oppositeRunwayName === "00") {
      oppositeRunwayName = "36";
    }

    const thirdCharacter = runway.name[2];

    if (thirdCharacter != null) {
      if (thirdCharacter === "L") {
        oppositeRunwayName = oppositeRunwayName + "R";
      } else if (thirdCharacter === "R") {
        oppositeRunwayName = oppositeRunwayName + "L";
      } else if ("CGSWU".includes(thirdCharacter)) {
        oppositeRunwayName = oppositeRunwayName + thirdCharacter;
      } else {
        oppositeRunwayName = "18";
      }
    }

    const { elevationOpposite, ...runwayWithoutElevationOpposite } = runway;
    return [
      runwayWithoutElevationOpposite,
      { ...runwayWithoutElevationOpposite, name: oppositeRunwayName, elevation: runway.elevationOpposite }
    ] as [ParsedRunway, ParsedRunway];
  });

  return runwaysOtherDirection?.filter(isNonNullable) ?? [];
}

export type ParsedRunway = {
  name: string;
  elevation: number | null | undefined;
  closed: boolean | null | undefined;
};

import { Typography } from "@mui/material";
import { Stack } from "components/layout/Stack";
import IconInfo from "@mui/icons-material/Info";
import { FC, PropsWithChildren, ReactNode } from "react";

export const PropertyRow: FC<
  PropsWithChildren<{
    title?: string;
    titleGap?: string;
    action?: ReactNode;
    onInfoClick?: () => void;
  }>
> = ({ title, children, titleGap, action, onInfoClick }) => (
  <Stack gap={titleGap ?? "1rem"}>
    {title && (
      <Stack gap="1rem" horizontal css={{ justifyContent: "space-between" }}>
        <Stack horizontal gap="0.5rem" css={{ alignItems: "center" }}>
          <Typography variant="h4">{title}</Typography>
          {onInfoClick && (
            <div
              onClick={onInfoClick}
              css={{
                height: "1.5rem",
                color: "#999",
                cursor: "pointer",
                "&:hover": { color: "#888" },
              }}
            >
              <IconInfo />
            </div>
          )}
        </Stack>
        {action}
      </Stack>
    )}

    <Stack gap="3rem" horizontal wrapGap="1rem">
      {children}
    </Stack>
  </Stack>
);

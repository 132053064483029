import { Spacer } from "components/layout/Spacer";
import {
  describeResponsiveValue,
  getResponsiveStyles,
} from "logic/responsive/styles";
import { ResponsiveValue } from "logic/responsive/types";
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  isValidElement,
  PropsWithChildren,
} from "react";

export type StackProps = ComponentProps<"div"> &
  PropsWithChildren<{
    gap: ResponsiveValue<string>;
    horizontal?: ResponsiveValue<boolean>;
    className?: string;
    accurateSpacers?: boolean;
    wrapGap?: string;
  }>;

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      gap,
      horizontal = false,
      accurateSpacers = false,
      children,
      wrapGap,
      ...passProps
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        {...passProps}
        css={[
          getResponsiveStyles(gap, (gap) => ({ gap })),
          getResponsiveStyles(
            describeResponsiveValue(horizontal).map((horizontal) =>
              horizontal ? "row" : "column"
            ),
            (flexDirection) => ({ flexDirection })
          ),
          { display: "flex" },
          wrapGap && {
            flexWrap: "wrap",
            rowGap: wrapGap,
          },
        ]}
      >
        {!accurateSpacers
          ? children
          : Children.map(children, (child) => {
              if (!child || !isValidElement(child)) return child;

              if (child.type === Spacer) {
                return cloneElement(child, {
                  // @ts-expect-error
                  parentStackGap: gap,
                });
              }
              return child;
            })}
      </div>
    );
  }
);
Stack.displayName = "Stack";

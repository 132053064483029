import styled from "@emotion/styled";
import { Button } from "components/button/Button";
import { Stack } from "components/layout/Stack";
import { FC } from "react";
import { Link } from "react-router-dom";
import { consentApi } from "store/reducers/consentApi";
import { localApi } from "store/reducers/localApi";
import { useRunOnceReady, useRunOnChange } from "utils/hooks";

export type CookieConsentProps = {};

export const CookieConsent: FC<CookieConsentProps> = () => {
  const consentQuery = consentApi.endpoints.getConsent.useQuery();
  const [putConsent] = consentApi.endpoints.putConsent.useMutation();

  const isAllowed = consentQuery.data?.permissions.allowsNonEssentialCookies;
  const isMobileApp =
    localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app;

  useRunOnceReady(isMobileApp === true, () => {
    if (isMobileApp) {
      putConsent({ cookie_consent: false });
    }
  });

  useRunOnChange(isAllowed, (_, isAllowed) => {
    if (isAllowed == null) return;
    const consent = isAllowed ? "granted" : "denied";

    // keep gtag in sync with what user entered
    gtag("consent", "update", {
      ad_storage: consent,
      analytics_storage: consent,
    });
  });

  if (consentQuery.isLoading || consentQuery.isError) return null;

  // user accepted cookies
  if (consentQuery.data?.cookie_consent != null) return null;

  return (
    <SBox>
      <Stack
        horizontal
        gap="1rem"
        css={{ justifyContent: "space-between", alignItems: "end" }}
      >
        <div css={{ p: { margin: 0, lineHeight: 1.5 } }}>
          <p>
            We use cookies to provide, secure and improve our services to you.
            Please learn more in our{" "}
            <Link
              to="/cookie-policy"
              css={{
                color: "black",
                fontWeight: "bold",
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Cookie Policy
            </Link>
            .
          </p>
          <p>
            Select Accept to consent or Reject to decline non-essential cookies
            for this use.
          </p>
        </div>

        <Stack gap="0.5rem" horizontal>
          <Button onClick={() => putConsent({ cookie_consent: true })}>
            Accept all
          </Button>
          <Button onClick={() => putConsent({ cookie_consent: false })}>
            Reject non-essential
          </Button>
        </Stack>
      </Stack>
    </SBox>
  );
};

const SBox = styled.div({
  position: "fixed",
  bottom: "7rem",
  left: "1rem",
  right: "1rem",
  padding: "1rem",
  backgroundColor: "white",
  borderRadius: "0.25rem",
  boxShadow: "var(--box-shadow-light)",
  zIndex: 10500,
});

import Airport from "components/Dashboard/airport/Airport";
import CurrentData from "components/Dashboard/currentData/CurrentData";
import WeatherNow from "components/Dashboard/weatherNow/WeatherNow";
import { Stack } from "components/layout/Stack";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { toggleWeatherSource } from "store/reducers/localStateSlice";
import { useRunOnChange } from "utils/hooks";

export type KioskMetarSlideProps = {
  awMetar?: boolean;
};

export const KioskMetarSlide: FC<KioskMetarSlideProps> = (props) => {
  const dispatch = useDispatch();
  useRunOnChange(!!props.awMetar, (_, metar) => {
    dispatch(toggleWeatherSource(metar, "metar"));
  });
  return (
    <Stack
      gap={{ deskSM: "0.5rem", desktop: "3rem" }}
      horizontal={{ deskSM: false, desktop: true }}
      css={{ width: "100%" }}
    >
      <Stack gap="0" css={{ flexGrow: 1 }}>
        <Airport />
        <WeatherNow />
      </Stack>
      <div>
        <CurrentData />
      </div>
    </Stack>
  );
};

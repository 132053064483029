import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { themes } from "./utils/themes";
import { responsive } from "./mixins";
import { ThemeProvider } from "@mui/material";
import { Meta } from "components/meta/Meta";

import { ToastProvider } from "components/toast/ToastProvider";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { GlobalStyles } from "styles/GlobalStyles";
import { useEffect } from "react";
import { RootState } from "store/store";

function App() {
  const theme = useSelector((state: RootState) => state.localState.flightRulesCode) ?? "default";

  useEffect(() => {
    function setVhVariable() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    window.addEventListener("resize", () => {
      // Update the --vh value when the window is resized
      setVhVariable();
    });

    setVhVariable();
  }, []);

  return (
    <ThemeProvider theme={themes[theme]}>
      <StyledApp>
        <GlobalStyles />
        <ToastProvider>
          <Meta />
          <RouterProvider router={router} />
        </ToastProvider>
      </StyledApp>
    </ThemeProvider>
  );
}

const StyledApp = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  --box-shadow-light: 4px 0 30px 0 rgba(0, 0, 0, 0.21);

  ${responsive.tablet} {
    flex-direction: column;
  }
`;

export default App;

import styled from "@emotion/styled";
import { responsive } from "mixins";
import logo from "assets/images/meandair.svg";

export const Creator = () => (
  <SCreator>
    <p>Powered by</p>
    <a href="https://www.meandair.com" target="_blank" rel="noreferrer">
      <img src={logo} alt="" />
    </a>
  </SCreator>
);

const SCreator = styled.div`
  justify-self: center;

  p {
    font-weight: 300;
    font-size: 11px;
    margin: 0.1rem 0 -0.2rem;

    ${responsive.deskSM} {
      font-size: 0.625rem;
    }
  }

  a {
    color: #52a3e6;

    img {
      width: 5rem;

      ${responsive.deskSM} {
        width: 4.5rem;
      }

      ${responsive.mobilP} {
        width: 3.2rem;
      }

      ${responsive.mobilS} {
        width: 3rem;
      }
    }
  }
`;

import styled from "@emotion/styled";
import { useDashboardAirport } from "logic/weather/hooks";

import { responsive } from "mixins";

import { RunwayWindGraphic } from "components/Dashboard/weatherNow/RunwayWindGraphic";
import { WindCompassGraphic } from "components/Dashboard/weatherNow/WindCompassGraphic";
import { Spacer } from "components/layout/Spacer";
import { ParsedRunway } from "logic/airport/runway/parse";
import Crosswind from "./Crosswind";
import { useMySettings } from "logic/user/me/settings/hooks";
import { q2u } from "utils/global.functions";


export type WeatherNowWindProps = {
  runway?: ParsedRunway;
};

export function WeatherNowWind(props: WeatherNowWindProps) {
  const airport = useDashboardAirport().transform();
  const runways = airport?.getRunwayPairsIfAirportOpen();
  const settings = useMySettings().settings;

  if (runways === undefined) return null;

  const graphic =
    runways?.length !== 0 && props.runway
      ? { type: "runway" as const, runway: props.runway }
      : { type: "compass" as const };

  let elevation = undefined;
  if (graphic.runway?.elevation) {
    const elevationUnit = settings?.ceiling_unit ?? "ft";
    elevation = q2u({
      value: graphic.runway?.elevation,
      unit: "ft"
    }, elevationUnit, "round") + " " + elevationUnit;
  }

  return (
    // airport with runways
    <SWeatherNowWind>
      {graphic.type === "runway" ? (
        <>
          <STitle>wind on runway</STitle>

          <Spacer size="1rem" />

          <RunwayWindGraphic runway={graphic.runway} />

          <Crosswind runway={graphic.runway} />

          {elevation && (
            <SAltitude>runway altitude: {elevation}</SAltitude>
          )}
        </>
      ) : (
        <>
          <STitle>wind on a compass</STitle>

          <Spacer size="1rem" />

          <WindCompassGraphic />
        </>
      )}
    </SWeatherNowWind>
  );
}

const STitle = styled.div({
  alignSelf: "end",
  fontWeight: 300,
});

const SWeatherNowWind = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${responsive.tabletM} {
    font-size: 14px;
  }
`;

const SAltitude = styled.div`
  margin: 1rem 0 0 1rem;
  align-self: start;
  font-weight: 300;
  font-size: 14px;

  ${responsive.tablet} {
    margin-top: 10px;
  }
`;

import styled from "@emotion/styled";
import { useActiveMetarSource } from "logic/weather/hooks";
import { responsive } from "mixins";
import { FC } from "react";

export type CurrentWindAngleProps = {};

export const CurrentWindAngle: FC<CurrentWindAngleProps> = (props) => {
  const { source } = useActiveMetarSource();

  let windAngle;
  if (source?.isWindCalm() && !source?.shouldShowWindAlertColor()) {
    windAngle = "CALM";
  } else {
    windAngle = source?.formatWindAngle();
  }

  return (
    <StyledAngle alert={source?.shouldShowWindAlertColor()}>
      {windAngle}
    </StyledAngle>
  );
};

const StyledAngle = styled.div<{ alert?: boolean }>`
  line-height: 22px;
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => (props.alert ? "red" : props.theme.primary)};
  ${(props) => (props.alert ? "font-family: Encode Sans Condensed" : "")};
  text-transform: ${(props) => (props.alert ? "uppercase" : "default")};

  ${responsive.tablet} {
    font-size: 20px;
  }
`;

import { CircularProgress } from "@mui/material";
import { Stack } from "components/layout/Stack";
import { FC, ReactNode } from "react";

export type QueryResultQuery = { isLoading?: boolean };

export type QueryResultProps = {
  query: QueryResultQuery | QueryResultQuery[];
  children?: ReactNode;
};

export const QueryResult: FC<QueryResultProps> = (props) => {
  const queries = [props.query].flat();

  if (queries.some((q) => q.isLoading)) {
    return (
      <Stack
        gap="0.5rem"
        horizontal
        css={{ justifyContent: "center", padding: "1rem 1rem" }}
      >
        <CircularProgress size="3rem" color="primary" />
      </Stack>
    );
  }

  return <>{props.children}</>;
};

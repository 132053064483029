import React from "react";
import styled from "@emotion/styled";

function IconInfo() {
  return (
    <StyledSvg viewBox="0 0 500 500">
      {/* <circle cx="250" cy="250" r="230" /> */}
      {/* <path d="m224 130a31 31 0 1 1 63 0 31 31 0 1 1-63 0zm-31 94c0-12 9-21 21-21h42c12 0 21 9 21 21v146h21c12 0 21 9 21 21s-9 21-21 21h-84c-12 0-21-9-21-21s9-21 21-21h21v-125h-21c-12 0-21-9-21-21z" /> */}
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
    </StyledSvg>
  );
}

const StyledSvg = styled.svg`
  width: 20px;

  circle {
    /* fill: ${(props) => props.theme.primary}; */
    fill: #fff;
    stroke: #323233be;
    stroke-width: 40;
  }
  path {
    fill: #67676a;
    &:hover {
      fill: black;
    }
  }
`;

export default IconInfo;

import React from "react";
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

type CeilingProps = {
  ceilingShow: boolean;
  cloudShow: boolean;
  sideCloudShow: boolean;
  sideCloudDashed: boolean;
  ceilingText: string | undefined;
  index: number;
  theme: Theme;
};

function Ceiling({
  ceilingShow,
  cloudShow,
  sideCloudShow,
  sideCloudDashed,
  ceilingText,
  index,
  theme,
}: CeilingProps) {
  return (
    <StyledCeiling theme={theme}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100%"
        viewBox="-515.5 585.5 290 210"
      >
        <g id="Layer_2">
          <g>
            {ceilingShow && (
              <StyledText
                transform="matrix(1 0 0 1 -364.7439 753.1593)"
                fontSize="22px"
                fontWeight="700"
                index={index}
                theme={theme}
              >
                {ceilingText}
              </StyledText>
            )}
          </g>

          <line
            fill="none"
            stroke="#58595B"
            strokeWidth="3"
            strokeMiterlimit="10"
            x1="-515.412"
            y1="791.723"
            x2="-225.516"
            y2="791.723"
          />

          <line
            fill="none"
            stroke="#929497"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="2,4"
            x1="-515.412"
            y1="764.289"
            x2="-225.516"
            y2="764.289"
          />

          <line
            fill="none"
            stroke="#929497"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="2,4"
            x1="-515.412"
            y1="720.286"
            x2="-225.516"
            y2="720.286"
          />

          <line
            fill="none"
            stroke="#929497"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="2,4"
            x1="-515.412"
            y1="676.284"
            x2="-225.516"
            y2="676.284"
          />

          <line
            fill="none"
            stroke="#929497"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="2,4"
            x1="-515.412"
            y1="632.281"
            x2="-225.516"
            y2="632.281"
          />

          <line
            fill="none"
            stroke="#929497"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeDasharray="2,4"
            x1="-515.412"
            y1="588.278"
            x2="-225.516"
            y2="588.278"
          />
          {ceilingShow && (
            <g>
              <g>
                <StyledLine
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  x1="-378.094"
                  y1="711.364"
                  x2="-378.094"
                  y2="780.426"
                  index={index}
                  theme={theme}
                />
                <g>
                  <StyledPolygon
                    points="-378.094,700.067 -384.599,715.986 -378.094,712.209 -371.591,715.986"
                    index={index}
                    theme={theme}
                  />
                </g>
                <g>
                  <StyledPolygon
                    // fill="#52a3e6"
                    points="-378.094,791.723 -384.599,775.804 -378.094,779.581 -371.591,775.804"
                    index={index}
                    theme={theme}
                  />
                </g>
              </g>
            </g>
          )}
        </g>
        {cloudShow && (
          <g id="Layer_11">
            <path
              fill="#FFFFFF"
              stroke="#58595B"
              strokeMiterlimit="10"
              d="M-463.958,674.373c7.315-7.963,18.924-4.3,19.72,7.142
              c4.301-0.763,8.072,0.106,10.729,3.856c2.233,3.151,2.458,6.594,0.724,9.962c-1.72,3.34-4.525,5.282-8.457,5.28
              c-17.741-0.01-35.483,0.003-53.224-0.016c-6.88-0.008-12.224-4.929-12.448-11.392c-0.24-6.923,4.542-12.412,11.46-12.89
              c1.628-0.113,2.321-0.58,2.677-2.145c1.729-7.599,8.684-11.31,13.976-11.404c6.498-0.114,12.101,3.848,14.464,10.52
              C-464.217,673.624-464.1,673.964-463.958,674.373z"
            />
            <path
              fill="#FFFFFF"
              stroke="#58595B"
              strokeMiterlimit="10"
              d="M-372.772,674.373c7.315-7.963,18.924-4.3,19.72,7.142
              c4.301-0.763,8.072,0.106,10.729,3.856c2.233,3.151,2.458,6.594,0.724,9.962c-1.72,3.34-4.525,5.282-8.457,5.28
              c-17.741-0.01-35.483,0.003-53.224-0.016c-6.88-0.008-12.224-4.929-12.448-11.392c-0.24-6.923,4.542-12.412,11.46-12.89
              c1.628-0.113,2.321-0.58,2.677-2.145c1.729-7.599,8.684-11.31,13.976-11.404c6.498-0.114,12.101,3.848,14.464,10.52
              C-373.031,673.624-372.915,673.964-372.772,674.373z"
            />
            <path
              fill="#FFFFFF"
              stroke="#58595B"
              strokeMiterlimit="10"
              d="M-285.988,674.373c7.315-7.963,18.924-4.3,19.72,7.142
              c4.301-0.763,8.072,0.106,10.729,3.856c2.233,3.151,2.458,6.594,0.724,9.962c-1.72,3.34-4.525,5.282-8.457,5.28
              c-17.741-0.01-35.483,0.003-53.224-0.016c-6.88-0.008-12.224-4.929-12.448-11.392c-0.24-6.923,4.542-12.412,11.46-12.89
              c1.628-0.113,2.321-0.58,2.677-2.145c1.729-7.599,8.684-11.31,13.976-11.404c6.498-0.114,12.101,3.848,14.464,10.52
              C-286.247,673.624-286.131,673.964-285.988,674.373z"
            />
          </g>
        )}
        {sideCloudShow && (
          <g id="Layer_11">
            <path
              fill="#FFFFFF"
              stroke="#58595B"
              strokeMiterlimit="10"
              strokeDasharray={sideCloudDashed ? "10,10" : ""}
              d="M-500.772,674.373c7.315-7.963,18.924-4.3,19.72,7.142
              c4.301-0.763,8.072,0.106,10.729,3.856c2.233,3.151,2.458,6.594,0.724,9.962c-1.72,3.34-4.525,5.282-8.457,5.28
              c-17.741-0.01-35.483,0.003-53.224-0.016c-6.88-0.008-12.224-4.929-12.448-11.392c-0.24-6.923,4.542-12.412,11.46-12.89
              c1.628-0.113,2.321-0.58,2.677-2.145c1.729-7.599,8.684-11.31,13.976-11.404c6.498-0.114,12.101,3.848,14.464,10.52
              .12.338.236.678.379 1.087z"
            />
            <path
              fill="#FFFFFF"
              stroke="#58595B"
              strokeMiterlimit="10"
              strokeDasharray={sideCloudDashed ? "10,10" : ""}
              d="M-230.772,674.373c7.315-7.963,18.924-4.3,19.72,7.142
              c4.301-0.763,8.072,0.106,10.729,3.856c2.233,3.151,2.458,6.594,0.724,9.962c-1.72,3.34-4.525,5.282-8.457,5.28
              c-17.741-0.01-35.483,0.003-53.224-0.016c-6.88-0.008-12.224-4.929-12.448-11.392c-0.24-6.923,4.542-12.412,11.46-12.89
              c1.628-0.113,2.321-0.58,2.677-2.145c1.729-7.599,8.684-11.31,13.976-11.404c6.498-0.114,12.101,3.848,14.464,10.52
              .12.338.236.678.379 1.087z"
            />
          </g>
        )}
      </svg>
    </StyledCeiling>
  );
}
const StyledCeiling = styled.div`
  position: relative;
`;

const StyledText = styled.text<{ index: number }>`
  fill: ${(props) => (props.index === 0 ? props.theme.primary : "#6d6e70")};
`;
const StyledLine = styled.line<{ index: number }>`
  stroke: ${(props) => (props.index === 0 ? props.theme.primary : "#6d6e70")};
`;
const StyledPolygon = styled.polygon<{ index: number }>`
  fill: ${(props) => (props.index === 0 ? props.theme.primary : "#6d6e70")};
`;

export default Ceiling;

import { ParsedRunway } from "logic/airport/runway/parse";
import { describeRunwaySurfaces } from "logic/airport/runway/surface/describe";
import { RunwayDirectionProperty } from "logic/airport/runway/types";
import {
  formatFeet,
  formatMeter,
  formatOptional,
  formatYesNo,
} from "logic/format";
import { RunwayResponse } from "awd-server-api";
import { bound } from "utils/class";
import { isNonNullable } from "utils/general";
import { describeUnitValue } from "logic/unit/describe";

export function describeRunwayIdentifier(identifier: string) {
  return bound({
    identifier,
    parse() {
      const parts = identifier.split("/");

      return parts.filter((x) => !!x);
    },
    doesIdentifyParsedRunway(runway: ParsedRunway) {
      return this.parse().some((ident) => ident === runway.name);
    },
  });
}

type Unit = "feet" | "meters";

export function describeRunway(runway: RunwayResponse) {
  return bound({
    convertFromFeetToMeter(value: number | null | undefined): string {
      const valueInMeter = describeUnitValue({
        value: value ?? undefined,
        unit: "ft",
      })?.convertToUnit("meter");
      return valueInMeter?.unitValue.value?.toFixed(2) ?? "";
    },
    convertFromFeetToMeterReturnNumber(
      value: number | null | undefined
    ): number {
      const valueInMeter = describeUnitValue({
        value: value ?? undefined,
        unit: "ft",
      })?.convertToUnit("meter");
      return Number(valueInMeter?.unitValue.value?.toFixed(2)) ?? -99;
    },
    formatIdentifier() {
      return [runway.leIdent, runway.heIdent].join(" / ");
    },
    formatLength(unit: Unit = "feet") {
      if (unit === "feet") {
        return formatFeet(runway.length);
      }
      return formatMeter(runway.length);
    },
    formatWidth(unit: Unit = "feet") {
      if (unit === "feet") {
        return formatFeet(runway.width);
      }
      return formatMeter(runway.width);
    },
    formatSurface() {
      if (!runway.surface) return formatOptional(runway.surface);
      return describeRunwaySurfaces().formatString(runway.surface);
    },
    formatLighted() {
      return formatYesNo(runway.lighted);
    },
    formatClosed() {
      return formatYesNo(runway.closed);
    },
    asDirectionOptions() {
      return this.getBothDirections()
        .map((dir) => dir.asOption())
        .filter(isNonNullable);
    },
    getBothDirections() {
      return [this.selectDirection("lower"), this.selectDirection("higher")];
    },
    selectDirection(which: "lower" | "higher") {
      return bound({
        which,
        asOption() {
          const value = this.getProperty("Ident");
          if (value == null) return undefined;
          return {
            value,
            label: value,
          };
        },
        getPropertyPrefix() {
          return which === "lower" ? "le" : "he";
        },
        getPropertyKey<T extends RunwayDirectionProperty>(property: T) {
          return `${this.getPropertyPrefix()}${property}` as const;
        },
        getProperty<T extends RunwayDirectionProperty>(property: T) {
          return runway[this.getPropertyKey(property)];
        },
        formatIdentifier() {
          return formatOptional(this.getProperty("Ident"));
        },
        formatHeading() {
          return formatOptional(this.getProperty("Heading"));
        },
        formatElevation() {
          return formatFeet(this.getProperty("Elevation"));
        },
        formatLatitude() {
          return formatOptional(this.getProperty("Latitude"));
        },
        formatLongitude() {
          return formatOptional(this.getProperty("Longitude"));
        },
        formatDisplacedThreshold() {
          return formatFeet(this.getProperty("DisplacedThreshold"));
        },
      });
    },
  });
}

import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent } from "@mui/material";
import { FormTextField } from "components/field/text/FormTextField";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { ResultModal } from "components/modal/inside/ResultModal";
import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { PatchAirportBody } from "awd-server-api";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { useResolveOnceDefined } from "utils/hooks";
import {
  parseFloatInputString,
  zRequiredLatitudeInput,
  zRequiredLongitudeInput,
} from "utils/parse";
import { z } from "zod";

export type EditAirportLocationModalProps = {
  icao: string;
};

const zForm = z.object({
  latitude: zRequiredLatitudeInput,
  longitude: zRequiredLongitudeInput,
});
type Form = z.infer<typeof zForm>;

function prepareBody(values: Form): Pick<PatchAirportBody, keyof Form> {
  return {
    latitude: parseFloatInputString(values.latitude) ?? undefined,
    longitude: parseFloatInputString(values.longitude) ?? undefined,
  };
}

export const EditAirportLocationModal: FC<EditAirportLocationModalProps> = (
  props
) => {
  const dispatch = useDispatch();
  const airport = awdApi.endpoints.getAirport.useQuery({
    airportId: props.icao,
  }).data;
  const airportPromise = useResolveOnceDefined(airport);

  const form = useForm<Form>({
    resolver: zodResolver(zForm),
    defaultValues: () =>
      airportPromise.then((airport) => ({
        latitude: String(airport.latitude ?? ""),
        longitude: String(airport.longitude ?? ""),
      })),
  });

  return (
    <ResultModal
      title="Edit airport location"
      onSubmit={async (cancel) =>
        form.handleSubmit(async (body) => {
          const result = await dispatch(
            awdApi.endpoints.patchAirport.initiate({
              airportId: props.icao,
              body: prepareBody(body),
            })
          ).then(describeMutationResult);
          if (result.error) {
            dispatch(
              toastError(
                result.error,
                "We could not update the airport location."
              )
            );
            cancel();
          } else {
            dispatch(
              toastMessage("success", "The airport location has been updated.")
            );
          }
        }, cancel)()
      }
    >
      <DialogContent sx={{ width: "30rem", maxWidth: "100%" }}>
        <Spacer size="0.5rem" />
        {!form.formState.isLoading && (
          <Stack gap="1rem">
            <FormTextField
              form={form}
              name="latitude"
              label="Latitude"
              fullWidth
              inputMode="numeric"
            />
            <FormTextField
              form={form}
              name="longitude"
              label="Longitude"
              fullWidth
              inputMode="numeric"
            />
          </Stack>
        )}
      </DialogContent>
    </ResultModal>
  );
};

import { Alert, AlertTitle } from "@mui/material";
import { AirportNotification } from "components/airportNotification/AirportNotification";
import { Stack } from "components/layout/Stack";
import {
  useAirportNotifications,
  UseAirportNotificationsReturn,
} from "logic/airport/notification";
import { FC } from "react";
import { AirportNotification as ApiAirportNotification } from "awd-server-api";
import { matchMap } from "utils/general";

export type AirportNotificationListProps = {
  icao: string;
  location: "airport_dashboard" | "inside_settings";
  airportName?: string;
  category: ApiAirportNotification["category"];
};

export const AirportNotificationList: FC<AirportNotificationListProps> = (
  props
) => {
  const notifications = useAirportNotifications(props.icao);

  return (
    <AirportNotificationListView
      {...props}
      notifications={notifications}
      category={props.category}
    />
  );
};

export type AirportNotificationListViewProps = AirportNotificationListProps & {
  notifications: UseAirportNotificationsReturn;
};

export const AirportNotificationListView: FC<
  AirportNotificationListViewProps
> = ({ location, notifications, icao, airportName, category }) => {
  const showRemove = location === "inside_settings";

  const notificationList = matchMap(category, {
    operational_update: notifications.getOperationalUpdateList(),
    general_information: notifications.getGeneralInformationList(),
  });

  const hasNotification = matchMap(category, {
    operational_update: notifications.hasOperationalUpdate(),
    general_information: notifications.hasGeneralInformation(),
  });

  return (
    <Stack gap={location === "inside_settings" ? "2rem" : "1rem"}>
      {notificationList?.map((notification) => (
        <AirportNotification
          key={notification.id}
          notification={notification}
          showRemove={showRemove}
          category={category}
        />
      ))}
      {!hasNotification && (
        <Alert severity="success">
          <AlertTitle>
            There are currently no updates issued by{" "}
            <strong>
              {icao.toUpperCase()}
              {airportName ? ` - ${airportName}` : ""}
            </strong>{" "}
            via AirportWeather.com.
          </AlertTitle>
        </Alert>
      )}
    </Stack>
  );
};

import { FormEventHandler, useState } from "react";
import { awdApi } from "store/reducers/awdApi";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { UserResponse } from "awd-server-api";
import { Stack } from "components/layout/Stack";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { ROW_PER_PAGE, StyledTableCell, StyledTableRow, useTablePage } from "utils/table";
import { EditUserModal } from "components/userListing/EditUserModal";

export const UserListingTable = () => {
  const [page, setPage, handleChangePage] = useTablePage();
  const [controlledText, setControlledText] = useState("");
  const [searchText, setSearchText] = useState("");

  const userListing = awdApi.endpoints.getUserListing.useQuery({
    page,
    text: searchText,
  });

  const users = userListing?.data?.users;
  const count = userListing?.data?.count;

  const textSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchText(controlledText);
  };

  return (
    <div css={{ marginTop: "1rem" }}>
      <Stack gap="0" horizontal css={{ justifyContent: "flex-end", marginBottom: "1rem" }}>
        <form onSubmit={textSearch}>
          <TextField
            label="Search via email"
            variant="outlined"
            css={{ width: "250px" }}
            value={controlledText}
            onChange={(e) => setControlledText(e.target.value)}
          />
        </form>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>User Id</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>{users?.map((user) => <UserTableRow key={user.id} user={user} />)}</TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count ?? 0}
        rowsPerPage={ROW_PER_PAGE}
        page={page}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

type UserTableRowProps = {
  user: UserResponse;
};
const UserTableRow = ({ user }: UserTableRowProps) => {
  const { show } = useModalContext();
  const showModal = () => {
    show(<EditUserModal userId={user.id} />);
  };
  return (
    <StyledTableRow>
      <StyledTableCell>{user.id}</StyledTableCell>
      <StyledTableCell>{user.email}</StyledTableCell>
      <StyledTableCell>{user.first_name}</StyledTableCell>
      <StyledTableCell>{user.last_name}</StyledTableCell>
      <StyledTableCell>{user.phone_number}</StyledTableCell>
      <StyledTableCell>{<Button onClick={showModal}>Edit</Button>}</StyledTableCell>
    </StyledTableRow>
  );
};

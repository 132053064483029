import { describeMutationResult } from "logic/api/result/describe";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { toastError, toastMessage } from "store/reducers/toastSlice";
import { awdApi } from "store/reducers/awdApi";

export type ResendTextProps = {};

export const ResendText: FC<ResendTextProps> = (props) => {
  const dispatch = useDispatch();

  async function onResendClick() {
    const result = await dispatch(
      awdApi.endpoints.postResendConfirmationEmail.initiate()
    ).then(describeMutationResult);

    if (result.error) {
      dispatch(
        toastError(result.error, "We could not resend the confirmation e-mail.")
      );
    } else {
      dispatch(
        toastMessage("success", "We have sent you a new confirmation e-mail.")
      );
    }
  }
  return (
    <>
      Did the e-mail not arrive? Please check your spam-box.{" "}
      <strong
        onClick={onResendClick}
        css={{
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Resend
      </strong>{" "}
      verification email.
    </>
  );
};

import { Skeleton } from "@mui/material";
import { AirportRoleStatus } from "components/airportRoleStatus/AirportRoleStatus";
import { EmailConfirmationStatus } from "components/emailConfirmationStatus/EmailConfirmationStatus";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { RunwaysErrorStatuses } from "components/modal/RunwaysErrorStatuses";
import { describeUserMe } from "logic/user/me/describe";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type DashboardPageProps = {};

export const DashboardPage: FC<DashboardPageProps> = (props) => {
  const userMe = awdApi.endpoints.getUserMe.useQuery().data;
  const { getShortDisplayName = undefined } = userMe ? describeUserMe(userMe) : {};
  return (
    <InsidePageLayout>
      <Meta pageTitle="Dashboard" />
      <InsidePageTitle>
        {getShortDisplayName ? <>Welcome, {getShortDisplayName()}.</> : <Skeleton width="20rem" />}
      </InsidePageTitle>

      <Spacer size="1rem" />

      <Stack gap="1rem">
        <AirportRoleStatus />
        <EmailConfirmationStatus />
        <RunwaysErrorStatuses />
      </Stack>
    </InsidePageLayout>
  );
};

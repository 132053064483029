import styled from "@emotion/styled";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { FC, useCallback, useEffect, useState } from "react";
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useNavigate, useParams } from "react-router-dom";

import { awdApi } from "store/reducers/awdApi";

import markerIconRetina from "assets/images/marker-2x.svg";
import markerIcon from "assets/images/marker.svg";

import { useDashboardAirport } from "logic/weather/hooks";
import { getWeatherTilesUrl } from "logic/weather/tiles";

import TimeSlider from "components/Map/TimeSliderMui";
import ExternalControls from "components/Map/ExternalControls";
import { Meta } from "components/meta/Meta";
import { OverlayType } from "components/kiosk/slides/KioskMapSlide";
import { LegendWithIcon } from "components/Map/Legend";
import { currentDateTime } from "utils/currentDateTime";

// @ts-expect-error
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  // iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconRetinaUrl: markerIconRetina,
  // iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconUrl: markerIcon,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: new L.Point(40, 54),
  iconAnchor: [20, 35],
});

const { Overlay } = LayersControl;

export type MapType = {
  icao?: string;
  overlay?: OverlayType;
};

const Map: FC<MapType> = (props) => {
  const [map, setMap] = useState<L.Map>();
  const params = useParams();
  const navigate = useNavigate();
  const icao = props.icao ?? params.icao;
  const airport = useDashboardAirport(icao).transform()?.airport;

  const [forecastValue, setForecastValue] = useState(0);
  const [weatherOverlay, setWeatherOverlay] = useState<OverlayType>(props.overlay ?? "crr-pc");

  // when refreshing, redirect to dashboard
  useEffect(() => {
    // when there is no location stored
    if (airport == null) {
      navigate(`/${icao}`);
    }
  }, []);

  const reset = useCallback(() => {
    map && airport && map.setView([airport.latitude, airport.longitude], 8);
  }, [map]);

  const weatherDateTime = currentDateTime(forecastValue)().toISOString();

  function altitude(altitude = -20) {
    const result = altitude >= 0 ? altitude : 999;
    return result.toString(10);
  }

  const weatherAltitude = altitude().toString();

  let sessionTokenQuery = awdApi.endpoints.getMapSessionToken.useQuery();
  const [sessionToken, setSessionToken] = useState("");
  if (!sessionToken && sessionTokenQuery.isSuccess) {
    setSessionToken(sessionTokenQuery.data.token);
  }

  const showMap = () => {
    return (
      <>
        {airport && (
          <MapContainer
            center={[airport.latitude, airport.longitude]}
            zoom={8}
            scrollWheelZoom={true}
            ref={(mapInstance) => {
              mapInstance && setMap(mapInstance);
            }}
            maxZoom={12}
            minZoom={5}
          >
            {/* Base Layer with OSM map */}
            <TileLayer
              updateWhenIdle={false}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank"
          rel="noreferrer">OpenStreetMap</a> contributors | Weather by <a href="https://www.meandair.com" target="_blank"
          rel="noreferrer">Meandair</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              className="baseMap"
            />
            <LayersControl collapsed={false}>
              {/* Overlay that changes based on active button. Clouds layer is the default. */}
              <Overlay name="clouds" checked={true}>
                <TileLayer
                  updateWhenIdle={false}
                  url={getWeatherTilesUrl({
                    weatherLayer: weatherOverlay,
                    weatherDateTime,
                    weatherAltitude,
                    sessionToken,
                  })}
                  minNativeZoom={0}
                  maxNativeZoom={20}
                  opacity={0.5}
                  className="weatherTiles"
                />
              </Overlay>
            </LayersControl>

            <Marker position={[airport.latitude, airport.longitude]}>
              <Popup>
                <p className="airportName">{airport.name}</p>
                {Math.round((airport.latitude + Number.EPSILON) * 1000) / 1000}
                {"; "}
                {Math.round((airport.longitude + Number.EPSILON) * 1000) / 1000}
              </Popup>
            </Marker>
          </MapContainer>
        )}
      </>
    );
  };

  return (
    <>
      <Meta pageTitle={`${icao ? icao.toUpperCase() : ""} Map`} />
      <StyledMap>
        <ExternalControls
          weatherOverlay={weatherOverlay}
          setWeatherOverlay={setWeatherOverlay}
          reset={reset}
        />
        <TimeSlider forecastValue={forecastValue} setForecastValue={setForecastValue} />
        <LegendWithIcon weatherOverlay={weatherOverlay} type="vicinity" />

        {showMap()}
      </StyledMap>
    </>
  );
};

// -----------------------------------------------------------------
// STYLING

const StyledMap = styled.div`
  display: flex;
  align-items: stretch;
  & > .leaflet-container {
    height: auto;
  }
  position: relative;
  width: 100%;
  background-color: lavender;
  flex-grow: 1;

  min-width: 260px;

  p {
    margin: 0;
  }

  .airportName {
    font-family: "Encode Sans";
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  /* margin: 100px auto; */
`;

export default Map;

import { bound } from "utils/class";
import { entriesOf, SpecBuilder } from "utils/general";
import { z } from "zod";

export function describeRunwaySurfaces() {
  return bound({
    formatString(surface: string) {
      const parsedEnum = zRunwaySurfaceEnum.safeParse(surface);
      if (parsedEnum.success) return runwaySurfaceSpec[parsedEnum.data].label;
      return surface;
    },
    getAllOptions() {
      return entriesOf(runwaySurfaceSpec).map((entry) => ({
        label: entry[1].label,
        value: entry[0],
      }));
    },
  });
}

const zRunwaySurfaceEnum = z.enum([
  "ASP",
  "TURF",
  "CON",
  "GRS",
  "GRE",
  "WATER",
  "UNK",
]);
type RunwaySurfaceEnum = z.infer<typeof zRunwaySurfaceEnum>;

const runwaySurfaceSpec = new SpecBuilder<{
  label: string;
}>().build<RunwaySurfaceEnum>({
  ASP: { label: "Asphalt" },
  TURF: { label: "Turf" },
  CON: { label: "Concrete" },
  GRS: { label: "Grass" },
  GRE: { label: "Gravel" },
  WATER: { label: "Water" },
  UNK: { label: "Unknown" },
});

import Airport from "components/Dashboard/airport/Airport";
import WeatherForecast from "components/Dashboard/weatherForecast/WeatherForecast";
import { Spacer } from "components/layout/Spacer";
import { FC } from "react";
import { useDispatch } from "store/hooks";
import { toggleWeatherSource } from "store/reducers/localStateSlice";
import { useRunOnChange } from "utils/hooks";

export type KioskTafSlideProps = {
  awTaf?: boolean;
};

export const KioskTafSlide: FC<KioskTafSlideProps> = (props) => {
  const dispatch = useDispatch();
  useRunOnChange(!!props.awTaf, (_, taf) => {
    dispatch(toggleWeatherSource(taf, "taf"));
  });
  return (
    <div>
      <Airport />
      <Spacer size="2rem" />
      <WeatherForecast />
    </div>
  );
};

import { useEffect, useState } from "react";
import { ContentfulResponse } from "utils/contentful";

export function useContentfulData<T>(
  fetcher: () => Promise<ContentfulResponse<T>>
) {
  const [contentfulData, setContentfulData] = useState<T | null>(null);
  useEffect(() => {
    (async () => {
      const data = await fetcher();
      setContentfulData(data.data);
    })();
  }, []);
  return contentfulData;
}

import { TextField, TextFieldProps } from "@mui/material";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

export function FormTextField<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>(
  props: {
    form: UseFormReturn<TFieldValues>;
    name: TFieldName;
  } & TextFieldProps
) {
  const { form, name, ...pass } = props;
  const state = form.getFieldState(props.name, form.formState);
  const inputProps = {
    ...pass.inputProps,
    style: { ...pass.inputProps?.style, fontSize: 16 },
  };
  return (
    <TextField
      {...form.register(name)}
      error={state.error != null}
      helperText={state.error?.message}
      {...pass}
      inputProps={inputProps}
    />
  );
}

export type MobileAppMessage =
  | {
      type: "logged_in";
      authToken: string;
    }
  | {
      type: "logged_out";
    }
  | {
      type: "enable_debug_mode_staging";
    }
  | {
      type: "enable_debug_mode_rc";
    }
  | {
      type: "disable_debug_mode";
    };

export function postMobileAppMessage(message: MobileAppMessage) {
  const json = JSON.stringify(message);

  //@ts-ignore
  window.ReactNativeWebView?.postMessage(json);
}

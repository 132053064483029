import { Button } from "@mui/material";
import { AirportResponse, AirportRunwaysResponse, RunwayResponse } from "awd-server-api";
import { AirportEditabilityStatus } from "components/airportEditabilityStatus/AirportEditabilityStatus";
import { Stack } from "components/layout/Stack";
import { AddRunwayModal } from "components/modal/inside/add/AddRunwayModal";
import { EditAirportContactModal } from "components/modal/inside/edit/EditAirportContactModal";
import { EditAirportIdentifierModal } from "components/modal/inside/edit/EditAirportIdentifierModal";
import { EditAirportLocationModal } from "components/modal/inside/edit/EditAirportLocationModal";
import { EditRunwayModal } from "components/modal/inside/edit/EditRunwayModal";
import { InfoAirportEditationModal } from "components/modal/inside/info/InfoAirportEditationModal";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { Property } from "components/property/Property";
import { PropertyRow } from "components/property/row/PropertyRow";
import { describeRunway } from "logic/airport/runway/describe";
import { formatOptional } from "logic/format";
import { FC, Fragment, useMemo } from "react";

export type AirportPropertyViewProps = {
  airport: AirportResponse;
  runways: AirportRunwaysResponse;
  editability: "hidden" | "disabled" | "editable";
  icaoIsEditable?: boolean;
};

export const AirportPropertyView: FC<AirportPropertyViewProps> = ({
  airport,
  editability,
  runways,
  icaoIsEditable,
}) => {
  const disabled = editability === "disabled";
  const showEditButtons = editability !== "hidden";

  const sortedRunways = useMemo(
    () => runways.runways.slice().sort((p, n) => p.id - n.id),
    [runways]
  );

  const { show } = useModalContext();

  function editAirportIdentifier() {
    if (airport.icao) {
      show(<EditAirportIdentifierModal icao={airport.icao} icaoIsEditable={icaoIsEditable} />);
    }
  }

  function editContact() {
    if (airport.icao) {
      show(<EditAirportContactModal icao={airport.icao} />);
    }
  }

  function addRunway() {
    if (airport.icao) {
      show(<AddRunwayModal icao={airport.icao} />);
    }
  }

  function editLocation() {
    if (airport.icao) {
      show(<EditAirportLocationModal icao={airport.icao} />);
    }
  }

  return (
    <Stack gap="0">
      <Stack gap="1.5rem" accurateSpacers>
        <AirportEditabilityStatus airportId={airport.id} />
        <Stack gap="1rem" horizontal css={{ justifyContent: "space-between" }}>
          <PropertyRow>
            <Property name="ICAO">{airport.icao}</Property>
            {airport.icaoAlias && <Property name="ICAO Alias">{airport.icaoAlias}</Property>}
          </PropertyRow>

          {showEditButtons && (
            <Button onClick={editAirportIdentifier} disabled={disabled}>
              Edit Airport
            </Button>
          )}
        </Stack>

        <PropertyRow
          title="Location"
          onInfoClick={() => show(<InfoAirportEditationModal section="location" />)}
          action={
            showEditButtons && (
              <Button onClick={editLocation} disabled={disabled}>
                Edit Location
              </Button>
            )
          }
        >
          <Property name="Latitude">{airport.latitude}</Property>
          <Property name="Longitude">{airport.longitude}</Property>
        </PropertyRow>

        <PropertyRow
          title="Contact"
          onInfoClick={() => show(<InfoAirportEditationModal section="contact" />)}
          action={
            showEditButtons && (
              <Button onClick={editContact} disabled={disabled}>
                Edit Contact
              </Button>
            )
          }
        >
          <Property name="E-mail">{formatOptional(airport.contactEmail)}</Property>
          <Property name="Phone number">{formatOptional(airport.contactPhone)}</Property>
        </PropertyRow>

        <PropertyRow
          title="Runways"
          titleGap="2rem"
          onInfoClick={() => show(<InfoAirportEditationModal section="runways" />)}
          action={
            showEditButtons && (
              <Button onClick={addRunway} disabled={disabled}>
                Add Runway
              </Button>
            )
          }
        >
          <Stack gap="3rem" css={{ flexGrow: 1 }}>
            {sortedRunways?.map((runway) => (
              <RunwayRow
                key={runway.id}
                runway={runway}
                icao={airport.icao}
                editability={editability}
              />
            ))}
          </Stack>
        </PropertyRow>
      </Stack>
    </Stack>
  );
};

export type RunwayRowProps = {
  icao: string;
  runway: RunwayResponse;
  editability: AirportPropertyViewProps["editability"];
};

export const RunwayRow: FC<RunwayRowProps> = (props) => {
  const d = describeRunway(props.runway);
  const disabled = props.editability === "disabled";
  const showEditButtons = props.editability !== "hidden";
  const { show } = useModalContext();

  function editRunway() {
    show(<EditRunwayModal icao={props.icao} runwayId={props.runway.id} />);
  }

  return (
    <Stack gap="1rem">
      <Stack horizontal gap="1rem" css={{ justifyContent: "space-between" }}>
        <strong css={{ fontSize: "1.25rem" }}>{d.formatIdentifier()}</strong>
        {showEditButtons && (
          <Button onClick={editRunway} disabled={disabled}>
            Edit runway
          </Button>
        )}
      </Stack>
      <Stack horizontal gap="3rem" wrapGap="1rem">
        <Property name="Length">{d.formatLength("meters")}</Property>
        <Property name="Width">{d.formatWidth("meters")}</Property>
        <Property name="Surface">{d.formatSurface()}</Property>
        <Property name="Lighted">{d.formatLighted()}</Property>
        <Property name="Closed">{d.formatClosed()}</Property>
      </Stack>
      {d.getBothDirections().map((dir) => (
        <Fragment key={dir.which}>
          <strong css={{ fontSize: "1rem" }}>Direction {dir.formatIdentifier()}</strong>
          <Stack horizontal gap="3rem" wrapGap="1rem">
            <Property name="Heading">{dir.formatHeading()}</Property>
            <Property name="Elevation">{dir.formatElevation()}</Property>
            <Property name="Latitude">{dir.formatLatitude()}</Property>
            <Property name="Longitude">{dir.formatLongitude()}</Property>
            <Property name="Displaced Threshold">{dir.formatDisplacedThreshold()}</Property>
          </Stack>
        </Fragment>
      ))}
    </Stack>
  );
};

import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { responsive } from "mixins";

export type ArticleProps = PropsWithChildren<{
  className?: string;
}>;

export const Article: FC<ArticleProps> = (props) => {
  return (
    <SArticle className={props.className}>
      <div
        css={{
          maxWidth: "80rem",
          width: "100%",
        }}
      >
        {props.children}
      </div>
    </SArticle>
  );
};

const SArticle = styled.article({
  gridRow: "2 / 4",
  gridColumn: "1 / 2",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  padding: "2rem",
  [responsive.mobilL]: {
    padding: "2rem 1rem",
  },
  "h1, h2, h3, h4, h5, h6, p": {
    margin: "1.5rem 0",
  },
  p: {
    lineHeight: 1.75,
  },
  h1: {
    fontSize: "2rem",
    marginTop: 0,
    fontFamily: "Encode Sans Condensed",
  },
  h2: {
    fontSize: "1.5rem",
    fontFamily: "Encode Sans Condensed",
  },
  img: {
    maxWidth: "100%",
  },
});

import React, { FC } from "react";
import { useTheme } from "@emotion/react";

export type CrosswindArrowType = {
  speed: string | undefined;
  rotate: number;
  x: number;
  y: number;
};

const CrosswindArrow: FC<CrosswindArrowType> = ({ rotate, x, y, speed }) => {
  const theme = useTheme();

  if (rotate !== 999) {
    return (
      <g transform={`translate(${x},${y}) scale(0.7) rotate(${rotate})`}>
        <path
          fill={theme.primary}
          d="M3.5,0v-101c0,-1.933,-1.567,-3.5,-3.5,-3.5s-3.5,1.567,-3.5,3.5v49.525l-22.422,-9.526l25.922,61.448l25.922,-61.448l-22.422,9.526z"
        />
        {/* M3.5-51.922v-49.525c0-1.933-1.567-3.5-3.5-3.5s-3.5,1.567-3.5,3.5v49.525l-22.422-9.526L0,0l25.922-61.448
         L3.5-51.922z */}
        {/* <circle cx={25.922} cy={-61.448} r={2} fill="black" />
        <circle cx={-25.922} cy={-61.448} r={2} fill="black" />
        <circle cx={0} cy={-115} r={2} fill="black" /> */}
        <text
          x={0}
          y={-115}
          textAnchor="middle"
          fontWeight="800"
          fontSize="38px"
          fill={theme.primary}
          stroke=" #e6e7e8"
          strokeWidth="10px"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          paintOrder="stroke"
          // textLength="6rem"
        >
          {speed ?? ""}
        </text>
      </g>
    );
  } else return null;
};

export default CrosswindArrow;

// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   xml:space="preserve"
//   width="51.844"
//   height="104.947"
//   viewBox="-25.922 -104.947 51.844 104.947"
// >
//   <path
//     fill="#4893CF"
//     d="M3.5-51.922v-49.525a3.5 3.5 0 1 0-7 0v49.525l-22.422-9.526L0 0l25.922-61.448L3.5-51.922z"
//   />

// </svg>;

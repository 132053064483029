import styled from "@emotion/styled";
import { WeatherNowWind } from "components/Dashboard/weatherNow/WeatherNowWind";
import { ParsedRunway } from "logic/airport/runway/parse";
import { useSelectedRunway } from "logic/airport/runway/useSelectedRunway";
import { FC } from "react";

export type WeatherNowWindCardProps = {
  className?: string;
  runway?: ParsedRunway;
};

export const WeatherNowWindCard: FC<WeatherNowWindCardProps> = (props) => {
  const runway = useSelectedRunway();

  return (
    <SCard className={props.className}>
      <WeatherNowWind runway={props.runway ? props.runway : runway} />
    </SCard>
  );
};

const SCard = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #58585b96;
  border-radius: 1rem;
  width: 100%;
  height: 100%;

  padding: 1rem;

  background-color: #f6f7f7;
`;

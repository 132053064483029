import React from "react";
import styled from "@emotion/styled";
import { responsive } from "../../../mixins";

type CompassProps = {
  height: number;
};

function Compass({ height }: CompassProps) {
  return (
    <StyledCompass height={height}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 -20 235 240">
        <text x="47%" y="-5">
          N
        </text>
        <g>
          <circle fill="none" stroke="#231F20" cx="117.5" cy="117.5" r="100" />
        </g>
        <g>
          <line
            fill="none"
            stroke="#8d8d8f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            strokeDasharray={2}
            x1="117.5"
            y1="0"
            x2="117.5"
            y2="240"
          />
        </g>

        <g>
          <line
            fill="none"
            stroke="#8d8d8f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            strokeDasharray={2}
            x1="0"
            y1="117.5"
            x2="240"
            y2="117.5"
          />
        </g>
      </svg>
    </StyledCompass>
  );
}
const StyledCompass = styled.div<{ height: number }>`
  svg {
    height: ${(props) => `${props.height}px`};

    ${responsive.mobilP} {
      height: 200px;
    }

    text {
      fill: "#6d6e70";
      font-weight: bold;
      font-size: 17px;
    }
  }
`;

export default Compass;

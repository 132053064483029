import { Button, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { AirportWebcam } from "components/airportWebcam/AirportWebcam";
import { InsidePageLayout } from "components/layout/page/inside/InsidePageLayout";
import { InsidePageTitle } from "components/layout/page/inside/InsidePageTitle";
import { Spacer } from "components/layout/Spacer";
import { Stack } from "components/layout/Stack";
import { Meta } from "components/meta/Meta";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { SetupWebcamModal } from "components/modal/inside/setup/SetupWebcamModal";
import { QueryResult } from "components/queryResult/QueryResult";
import { useMyAirportRoles } from "logic/user/me/role/hooks";
import { FC } from "react";
import { awdApi } from "store/reducers/awdApi";

export type AirportWebcamSettingsPageProps = {};

export const AirportWebcamSettingsPage: FC<AirportWebcamSettingsPageProps> = (props) => {
  const airportId = useMyAirportRoles().managedAirport()?.id;

  const query = awdApi.endpoints.getAirportLinkSettings.useQuery(
    airportId ? { airportId } : skipToken
  );
  const settings = query.data;
  const webcamExists = settings?.webcam_url != null;
  const { show } = useModalContext();

  return (
    <InsidePageLayout>
      <Meta pageTitle="Webcam" />

      <InsidePageTitle>Webcam</InsidePageTitle>

      <Spacer size="1.5rem" />

      <Typography variant="body1">
        <p>
          If you have a webcam, you can provide us with a link so that we can show it to your
          pilots.
        </p>
      </Typography>

      <Spacer size="1.5rem" />

      <QueryResult query={query}>
        <Stack gap="2rem">
          <Button
            onClick={() =>
              settings &&
              airportId &&
              show(<SetupWebcamModal settings={settings} airportId={airportId} />)
            }
          >
            {!webcamExists ? "Set up webcam" : "Edit webcam"}
          </Button>

          {airportId && <AirportWebcam airportId={airportId} />}
        </Stack>
      </QueryResult>
    </InsidePageLayout>
  );
};

import styled from "@emotion/styled";

import { responsive } from "mixins";

import { useAirportWeather, useDashboardAirport } from "logic/weather/hooks";
import { useMySettings } from "logic/user/me/settings/hooks";
import { FC } from "react";

import { Heading } from "components/heading/Heading";
import LocalTime from "./LocalTime";

export type AirportType = {};

const Airport: FC<AirportType> = () => {
  const settings = useMySettings().settings;
  const weather = useAirportWeather().report()?.withSettings(settings);
  const selectedAirport = useDashboardAirport().transform()?.airport;

  return (
    <StyledAirport>
      <div className="airportGroup">
        <p className="status">{selectedAirport && selectedAirport.closed ? "closed" : ""}</p>
        <Heading />
        <LocalTime weather={weather} />
      </div>
    </StyledAirport>
  );
};

const StyledAirport = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  display: flex;
  flex-direction: column;

  ${responsive.deskSM} {
    grid-row-start: 1;
    grid-row-end: 2;
    padding-top: 1rem;
  }

  ${responsive.highlights} {
    padding-top: 1rem;
  }

  .airportGroup {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .status {
    color: #e65552;
    font-family: "Encode Sans";
    font-weight: bold;

    text-transform: uppercase;

    font-size: 18px;
    min-height: 23px;

    ${responsive.deskSM} {
      margin-left: 1rem;
    }

    ${responsive.mobilP} {
      font-size: 14px;
    }
  }
`;

export default Airport;

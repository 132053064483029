import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Stack } from "components/layout/Stack";
import { useModalContext } from "components/modal/inside/ModalProvider";
import { FC, PropsWithChildren, ReactNode, useState } from "react";
import { toastError } from "store/reducers/toastSlice";
import { sleep } from "utils/general";

export type ResultModalResult = "close" | "submit";

export type ResultModalProps = PropsWithChildren<{
  title: string;
  onSubmit?: (cancel: () => void) => Promise<"cancel" | void> | "cancel" | void;
  onCancel?: (cancel: () => void) => Promise<"cancel" | void> | "cancel" | void;
  cancelButton?: {
    hidden?: boolean;
  };
  submitButton?: {
    hidden?: boolean;
    disabled?: boolean;
    color?: "error";
    text?: string;
  };
  extraActions?: ReactNode;
  extraActionsAfter?: ReactNode;
}>;

export const ResultModal: FC<ResultModalProps> = (props) => {
  const { modalContext } = useModalContext();
  const [isClosing, setClosing] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleResult = async (result: ResultModalResult) => {
    try {
      let hasCancelled = false;
      if (result === "submit") {
        setLoading(true);
      }
      const resultHandlerResult =
        result === "submit"
          ? await sleep(300).then(
              () =>
                props.onSubmit?.(() => {
                  hasCancelled = true;
                })
            )
          : await props.onCancel?.(() => {
              hasCancelled = true;
            });

      if (resultHandlerResult === "cancel" || hasCancelled) {
        return;
      }
      setClosing(true);
      await sleep(500);
      modalContext?.close();
    } catch (err) {
      toastError(err, "We could not submit the modal.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={!isClosing}
      onClose={() => handleResult("close")}
      css={isLoading && { pointerEvents: "none" }}
      maxWidth={false}
    >
      <DialogTitle>{props.title}</DialogTitle>

      {props.children}

      <DialogActions>
        {props.extraActions}
        {props.cancelButton?.hidden ? null : (
          <Button onClick={() => handleResult("close")}>Cancel</Button>
        )}
        {props.submitButton?.hidden ? null : (
          <Button
            disabled={props.submitButton?.disabled}
            color={props.submitButton?.color}
            onClick={() => handleResult("submit")}
          >
            <Stack horizontal gap="0.5rem">
              {isLoading && (
                <CircularProgress
                  size="1.5rem"
                  color={props.submitButton?.color}
                />
              )}
              {props.submitButton?.text ?? "Save"}
            </Stack>
          </Button>
        )}
        {props.extraActionsAfter}
      </DialogActions>
    </Dialog>
  );
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { z } from "zod";

export const consentApi = createApi({
  reducerPath: "consentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URI,
    credentials: "include",
  }),
  tagTypes: ["UserConsent"],
  endpoints: (builder) => ({
    getConsent: builder.query({
      query: (_: void) => `/api/consent`,
      transformResponse: (res) => zUserConsentResponse.parse(res),
      providesTags: ["UserConsent"],
    }),

    putConsent: builder.mutation({
      query: (body: UserConsent) => ({
        method: "PUT",
        url: `/api/consent`,
        body,
      }),
      invalidatesTags: ["UserConsent"],
    }),

    revokeConsent: builder.mutation({
      query: (_: void) => ({
        method: "DELETE",
        url: `/api/consent`,
      }),
      invalidatesTags: ["UserConsent"],
    }),
  }),
});

export const zCookiePermissions = z.object({
  allowsNonEssentialCookies: z.boolean(),
});
export type CookiePermissions = z.infer<typeof zCookiePermissions>;

export const zUserConsent = z.object({
  cookie_consent: z.boolean().nullish(),
});
export type UserConsent = z.infer<typeof zUserConsent>;

export const zUserConsentResponse = zUserConsent.extend({
  permissions: zCookiePermissions,
});
export type UserConsentResponse = z.infer<typeof zUserConsentResponse>;
